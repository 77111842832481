import { ReactNode } from 'react';
import styles from './PreviewLayout.module.css';

const PreviewLayout = ({
    title,
    subtitle,
    info,
    children,
}: {
    title: string;
    subtitle?: string | ReactNode;
    info: object;
    children?: ReactNode;
}) => {
    return (
        <>
            <div className={styles.wrapper}>
                <h2 className={styles.title}>{title}</h2>
                <h4 className={styles.subtitle}>{subtitle}</h4>
                <div>{children}</div>
                {Object.entries(info).map(([key, value]) => (
                    <p className={styles.infoField}>
                        <span>{key}:</span>
                        {value}
                    </p>
                ))}
            </div>
        </>
    );
};

export default PreviewLayout;
