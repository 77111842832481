import React, { ChangeEvent, useReducer, useState } from 'react';
import { ContextUser } from '../../store/manage-user-context';
import styles from './EditUserInfo.module.css';
import Button, { ButtonType } from '../general-components/Button';
import DeleteUser from './DeleteUser';
import FormInput from '../general-components/FormInput';
import {
    formReducer,
    errorReducer,
    camelCaseToSentence,
} from '../../utils/utils';

const EditUserInfo: React.FC<{
    user: ContextUser;
    onSave: Function;
    hasDelete: boolean;
    onChange?: (obj: any, remove?: boolean) => void;
}> = ({ user, onSave, hasDelete, onChange }) => {
    const [editedUser, setEditedUser] = useReducer(formReducer, user);
    const initialErrorData = {
        name: '',
        email: '',
    };
    const [errorData, setErrorData] = useReducer(
        errorReducer,
        initialErrorData
    );

    const [openedConfirmation, setOpenedConfirmation] = useState(false);

    // Define a list of required fields in the form
    const requiredFields = Object.keys(initialErrorData);

    const updateUser = (e: React.FormEvent) => {
        e.preventDefault();
        let allChecksPassed = false;

        Object.entries(editedUser).forEach(([key, value]) => {
            if (requiredFields.includes(key) && value === '') {
                setErrorData({
                    type: 'update',
                    name: key,
                    value: `Please fill ${camelCaseToSentence(key)}`,
                });
            }
        });

        allChecksPassed = Object.entries(editedUser)
            .filter(([key]) => requiredFields.includes(key))
            .every(([key, value]) => value !== '');

        if (allChecksPassed) {
            onSave(editedUser);
        }
    };

    const handleInputChange = (
        e: ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
    ) => {
        const { name, value } = e.target;

        setEditedUser({
            type: 'update',
            name,
            value,
        });
        requiredFields.includes(name) &&
            setErrorData({ type: 'update', name, value: '' });
    };

    const closeConfirmation = () => {
        setOpenedConfirmation(false);
    };

    const confirmDelete = () => {
        setOpenedConfirmation(true);
    };

    return (
        <React.Fragment>
            {openedConfirmation && (
                <DeleteUser onChange={onChange} onClose={closeConfirmation} />
            )}
            <div className={styles.info}>
                <form>
                    <FormInput
                        label="Name"
                        name="name"
                        type="text"
                        defaultValue={editedUser.name}
                        errorMsg={errorData.name}
                        onChange={handleInputChange}
                    />
                    <FormInput
                        label="Email"
                        name="email"
                        type="email"
                        defaultValue={editedUser.email}
                        errorMsg={errorData.email}
                        onChange={handleInputChange}
                    />
                    <FormInput
                        label="Role"
                        name="role"
                        defaultValue={editedUser.role}
                        onSelect={handleInputChange}
                        tag="selectRole"
                    />
                    <FormInput
                        label="Department"
                        name="department"
                        defaultValue={editedUser.department}
                        onSelect={handleInputChange}
                        tag="selectDepartment"
                    />
                    <div className={styles.buttons}>
                        <Button type={ButtonType.primary} onClick={updateUser}>
                            Save
                        </Button>
                        {hasDelete && (
                            <Button
                                className={styles.delete}
                                type={ButtonType.tietary}
                                onClick={confirmDelete}
                            >
                                Delete user
                            </Button>
                        )}
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
};

export default EditUserInfo;
