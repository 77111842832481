import React, { useState, useEffect } from 'react';
import {
    deleteFilterSetting,
    getAllPredefined,
    publishPredefined,
} from '../../services/apiService';
import LoadingRing from '../ui-components/LoadingRing';
import styles from './SavedFilter.module.css';
import Button, { ButtonType } from '../general-components/Button';
import Modal from '../general-components/Modal';

const SavedFilter = () => {
    const [savedFilters, setSavedFilters] = useState([]);
    const [isLoading, setIsloading] = useState(true);

    const [modalId, setModalId] = useState('');

    const getData = async () => {
        const res = await getAllPredefined();

        setSavedFilters(res);
        setIsloading(false);
    };

    const publishFilter = async (
        event: { stopPropagation: () => void },
        filterId: string
    ) => {
        event.stopPropagation();
        await publishPredefined(filterId);
        const updatedSavedFilterList = savedFilters.map((filter) => {
            if (filter._id === filterId) {
                filter.published = true;
            }
            return filter;
        });
        setSavedFilters(updatedSavedFilterList);
    };

    const deletFilter = async (
        event: { stopPropagation: () => void },
        filterId: string
    ) => {
        event.stopPropagation();
        await deleteFilterSetting(filterId);
        const updatedSavedFilterList = savedFilters.filter(
            (filter) => filter._id !== filterId
        );
        setSavedFilters(updatedSavedFilterList);
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <React.Fragment>
            <div className={styles.wrapper}>
                <div className={styles.savedFilter}>
                    <h2>Saved filter settings</h2>

                    <Button
                        onClick={(event: any) =>
                            (window.location.href = '/predefined/new')
                        }
                        type={ButtonType.submit}
                        small={false}
                    >
                        Add New
                    </Button>
                    {!isLoading && savedFilters.length === 0 && (
                        <div className={styles.message}>Nothing saved yet!</div>
                    )}
                    {isLoading && (
                        <div className={styles.loading}>
                            <LoadingRing size="large" />
                        </div>
                    )}

                    <Modal
                        isOpen={modalId !== ''}
                        onClose={() => setModalId('')}
                    >
                        <h3>Are you sure to delete this group?</h3>
                        <Button
                            onClick={(event: any) =>
                                deletFilter(event, modalId)
                            }
                            type={ButtonType.cancel}
                            small={false}
                        >
                            Delete
                        </Button>
                    </Modal>

                    {!isLoading && savedFilters.length > 0 && (
                        <table>
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Status</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {savedFilters.map((filter) => (
                                    <tr className={styles.row} key={filter._id}>
                                        <td
                                            className={styles.link}
                                            onClick={() =>
                                                (window.location.href =
                                                    '/predefined/edit/' +
                                                    filter._id)
                                            }
                                        >
                                            {filter.title}
                                        </td>
                                        <td>
                                            {filter.published ? (
                                                <div>
                                                    Publsihed
                                                    <Button
                                                        onClick={(event: any) =>
                                                            (window.location.href =
                                                                '/predefined/edit/' +
                                                                filter._id)
                                                        }
                                                        type={
                                                            ButtonType.primary
                                                        }
                                                        small={false}
                                                    >
                                                        Edit
                                                    </Button>
                                                </div>
                                            ) : (
                                                <Button
                                                    onClick={(event: any) =>
                                                        publishFilter(
                                                            event,
                                                            filter._id
                                                        )
                                                    }
                                                    type={ButtonType.primary}
                                                    small={false}
                                                >
                                                    Publish
                                                </Button>
                                            )}
                                        </td>
                                        <td>
                                            <Button
                                                onClick={(event: any) =>
                                                    setModalId(filter._id)
                                                }
                                                type={ButtonType.cancel}
                                                small={false}
                                            >
                                                Delete
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default SavedFilter;
