import { useParams } from 'react-router-dom';
import ProtocolPage from './ProtocolPage';

const ProtocolPageWrapper = () => {
    let { id } = useParams<{ id: string }>();

    return <ProtocolPage id={id} />;
};

export default ProtocolPageWrapper;
