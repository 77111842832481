import React, { useEffect, useState } from 'react';
import styles from './ReportedImplant.module.css';
import {
    deleteImplantReport,
    getAllReportedImplants,
    sendNotificationEmail,
} from '../../services/apiService';
import Button, { ButtonType } from '../general-components/Button';
import { getTimeDifference } from '../../utils/utils';
import OverlayWindow from '../ui-components/OverlayWindow';
import Implant from '../implant/Implant';
import Select from '../general-components/Select';
import FlashMessage from '../general-components/FlashMessage';
import SETTING_KEYS from '../../constants/setting-keys';
import Modal from '../manage-user/Modal';
import { set } from 'shelljs';

interface IRepot {
    _id: string;
    implantId: string;
    implantModel: string;
    department: string;
    createdAt: string;
}

const ReportedImplants = () => {
    const [reportList, setReportList] = useState<IRepot[]>([]);
    const [repId, setRepId] = useState('');
    const [editId, setEditId] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [loginLoading, setLoginLoading] = useState(false);
    const [flashMessage, setFlashMessage] = useState(null);

    const [isDeleting, setIsDeleting] = useState(false);
    const [deletingId, setDeletingId] = useState<string>('');

    const getData = async () => {
        const reports = await getAllReportedImplants();
        setReportList(reports);
    };

    const onDelete = (e: { stopPropagation: () => void }) => {
        e.stopPropagation();
        deleteImplantReport(deletingId);
        const updatedReportList = reportList.filter(
            (r) => r._id !== deletingId
        );
        setReportList(updatedReportList);
    };

    const onSendEmail = async () => {
        setLoginLoading(true);
        setFlashMessage(false);
        const res = await sendNotificationEmail(repId).then((res) => res);
        if (res) {
            setFlashMessage(true);
            setIsEmailSent(true);
        }
        setLoginLoading(false);
    };

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    useEffect(() => {
        getData();
    }, []);

    function departmentCounts(departments: string[]): string {
        const counts: Record<string, number> = {};
        for (const department of departments) {
            if (counts[department]) {
                counts[department]++;
            } else {
                counts[department] = 1;
            }
        }

        return Object.entries(counts)
            .map(
                ([department, count], index) =>
                    `${index > 0 ? '\n' : ''}${department}: ${count}`
            )
            .join('');
    }

    return (
        <React.Fragment>
            <h1 className={styles.reportTitle}>
                Missing conditional implants report
            </h1>
            <table className={styles.container}>
                <thead className={styles.tableHeader}>
                    <tr>
                        <th>Model</th>
                        <th>Department</th>
                        <th>Visited</th>
                        <th>Time</th>
                    </tr>
                </thead>
                {isDeleting && (
                    <Modal
                        info={{
                            title: 'Delete',
                            message:
                                'Are you sure you want to delete this entry?',
                        }}
                    >
                        <Button
                            onClick={(e: MouseEvent) => {
                                onDelete(e);
                                setIsDeleting(false);
                            }}
                            type={ButtonType.cancel}
                            small={true}
                        >
                            Delete
                        </Button>
                        <Button
                            onClick={() => setIsDeleting(false)}
                            type={ButtonType.tietary}
                            small={true}
                        >
                            Cancel
                        </Button>
                    </Modal>
                )}
                <tbody className={styles.tableBody}>
                    {reportList.map((report: any) => (
                        <React.Fragment>
                            <tr className={styles['edit-button']}>
                                <td>
                                    <Button
                                        key={report._id + '_button'}
                                        onClick={() => {
                                            setEditId(report.implant._id);
                                            setRepId(report._id);
                                        }}
                                        type={ButtonType.tietary}
                                        small={true}
                                    >
                                        Edit
                                    </Button>
                                </td>
                            </tr>
                            <tr key={report._id}>
                                <td
                                    onClick={() =>
                                        (window.location.href =
                                            '/implant/' + report.implant._id)
                                    }
                                >
                                    {report.implant.Model}
                                </td>
                                <td>
                                    <pre>
                                        {departmentCounts(
                                            report.users.map(
                                                (user: any) =>
                                                    user?.department_id?.name
                                            )
                                        )}
                                    </pre>
                                </td>
                                {report.users.length === 1 && (
                                    <td>{report.users.length} time</td>
                                )}
                                {report.users.length > 1 && (
                                    <td>{report.users.length} times</td>
                                )}
                                <td>
                                    <span>Last visit:</span>
                                    {getTimeDifference(
                                        new Date(report.createdAt).getTime()
                                    )}{' '}
                                    ago
                                </td>
                                <td>
                                    <Button
                                        onClick={(e: MouseEvent) =>
                                            window.open(
                                                'https://google.com/search?q=' +
                                                    report.implant[
                                                        SETTING_KEYS.model
                                                    ] +
                                                    ' ' +
                                                    report.implant[
                                                        SETTING_KEYS
                                                            .manufacturer
                                                    ] +
                                                    ' MRI safety',
                                                '_blank'
                                            )
                                        }
                                        type={ButtonType.submit}
                                        small={true}
                                    >
                                        open
                                    </Button>

                                    <Button
                                        onClick={(e: MouseEvent) => {
                                            setDeletingId(report._id);
                                            setIsDeleting(true);
                                        }}
                                        type={ButtonType.cancel}
                                        small={true}
                                    >
                                        delete
                                    </Button>
                                </td>
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
            {editId && repId && (
                <OverlayWindow
                    close={() => {
                        setEditId('');
                    }}
                >
                    <Implant isEdit={true} id={editId} />
                    {process.env.REACT_APP_ENV === 'production' && (
                        <div className={styles.sendEmail}>
                            <Select
                                type={'checkbox'}
                                name={''}
                                options={['added missing conditionals']}
                                changeSelected={handleCheckboxChange}
                            />
                            <Button
                                className={styles.checkbox}
                                type={ButtonType.primary}
                                onClick={onSendEmail}
                                disabled={!isChecked}
                                loading={loginLoading}
                            >
                                Send Email
                            </Button>
                            {isEmailSent && (
                                <FlashMessage
                                    type={'Success'}
                                    message={`Email sent to ${
                                        reportList.map(
                                            (item: any) => item.users.length
                                        )[0]
                                    } users`}
                                    duration={2500}
                                    key={flashMessage}
                                />
                            )}
                        </div>
                    )}
                </OverlayWindow>
            )}
        </React.Fragment>
    );
};

export default ReportedImplants;
