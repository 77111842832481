import { useParams } from 'react-router-dom';
import Implant from './Implant';
import styles from './ImplantPage.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const ImplantPage = () => {
    let { id } = useParams<{ id: string }>();

    const pagePath = () => {
        window.history.go(-1);
    };

    return (
        <div className={styles.page}>
            <button onClick={pagePath} className={styles['back']}>
                <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <div className={styles.container}>
                <Implant id={id} />
            </div>
        </div>
    );
};

export default ImplantPage;
