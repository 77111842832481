import { useEffect, useState } from 'react';
import {
    getConditionalsForCopy,
    getImplantNames,
} from '../../services/apiService';
import styles from './CopyConditionals.module.css';
import SETTING_KEYS from '../../constants/setting-keys';

const CopyConditionals: React.FC<{
    setConditionals: React.Dispatch<React.SetStateAction<any[]>>;
}> = ({ setConditionals }) => {
    const [implants, setImplants] = useState<any[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [showExamples, setShowExamples] = useState<boolean>(false);

    const [filteredImplants, setFilteredImplants] = useState<any[]>([]);

    const [error, setError] = useState<string>('');

    const getData = async () => {
        const res = await getImplantNames();
        setImplants(res);
    };

    const retrieveAndSetConditionals = async (implant: any) => {
        setSearchTerm(
            implant[SETTING_KEYS.manufacturer] +
                ': ' +
                implant[SETTING_KEYS.model]
        );
        const res = await getConditionalsForCopy(implant._id);
        if (typeof res === 'string') {
            setError(res);
        } else {
            setConditionals(res);
        }
        setShowExamples(false);
    };

    const updateSearch = (e: any) => {
        const currentSearchTerm = e.target.value;
        setShowExamples(true);
        setSearchTerm(currentSearchTerm);
        
        const fil = implants.filter((implant) => {
            const model = implant[SETTING_KEYS.model]?.toLowerCase() ?? "";
            const manufacturer = implant[SETTING_KEYS.manufacturer]?.toLowerCase() ?? "";
            return model.includes(currentSearchTerm.toLowerCase()) || manufacturer.includes(currentSearchTerm.toLowerCase());
        });
    
        setFilteredImplants(fil);
    };
    

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className={styles.wrapper}>
            <label>Existing implant</label>
            <input onChange={updateSearch} value={searchTerm} type="text" />
            {error && <p>{error}</p>}
            {showExamples && searchTerm.length > 0 && (
                <div className={styles.examples}>
                    <ul>
                        {filteredImplants.map((implant) => (
                            <li
                                onClick={() =>
                                    retrieveAndSetConditionals(implant)
                                }
                                key={implant._id}
                            >
                                {implant[SETTING_KEYS.manufacturer] +
                                    ': ' +
                                    implant[SETTING_KEYS.model]}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default CopyConditionals;
