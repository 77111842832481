import React, { useEffect } from 'react';
import { type Editor } from '@tiptap/react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import styles from './RichText.module.css';

interface TiptapEditorProps {
    description: any;
    onChange: (richText: any) => void;
    label?: string;
}

const RichText: React.FC<TiptapEditorProps> = ({
    description,
    onChange,
    label,
}) => {
    const editor = useEditor({
        extensions: [StarterKit.configure()],
        content: description,
        editorProps: {
            attributes: {
                class: styles.content,
            },
        },
        onUpdate({ editor }) {
            onChange(editor.getHTML());
        },
    });

    useEffect(() => {
        if (editor && description !== editor.getHTML()) {
            editor.commands.setContent(description);
        }
    }, [description, editor]);

    return (
        <div className={styles.wrapper}>
            <label className={styles.infoLabel}>{label}</label>
            <MenuBar editor={editor} />
            <EditorContent editor={editor} />
        </div>
    );
};

export default RichText;

interface MenuBarProps {
    editor: Editor | null;
}

const MenuBar: React.FC<MenuBarProps> = ({ editor }) => {
    if (!editor) {
        return null;
    }

    const toggleBold = () => {
        editor.chain().focus().toggleBold().run();
    };

    const toggleBulletList = () => {
        editor.chain().focus().toggleBulletList().run();
    };

    return (
        <div className={styles.menubar}>
            <button
                onClick={toggleBold}
                className={`${styles.menuButton} ${
                    editor.isActive('bold') ? styles.isActive : ''
                }`}
            >
                <FormatBoldIcon />
            </button>
            <button
                onClick={toggleBulletList}
                className={`${styles.menuButton} ${
                    editor.isActive('bulletList') ? styles.isActive : ''
                }`}
            >
                <FormatListBulletedIcon />
            </button>
        </div>
    );
};
