import Button, { ButtonType } from './general-components/Button';
import FileUpload from './general-components/FileUpload';
import styles from './Styles.module.css';
import Select from './general-components/Select';
import FormInput from './general-components/FormInput';

const Styles: React.FC = () => {
    return (
        <div className={styles.wrapper}>
            <h1>Header 1</h1>
            <h2>Header 2</h2>
            <h3>Header 3</h3>
            <h4>Header 4</h4>
            <h5>Header 5</h5>
            <p style={{ width: '500px' }}>
                Paragraph with a width of 500px. Curabitur at facilisis elit.
                Duis congue varius mauris a interdum. Suspendisse interdum leo
                eget justo auctor suscipit. Nunc tellus ex, rutrum sed dui in,
                lobortis scelerisque lorem.{' '}
                <a href="https://nordinsight.com">Dette er et inline link.</a>{' '}
                Suspendisse nec turpis massa. Nullam in pretium arcu.{' '}
                <em>Emphazied tekts</em> Curabitur at elit sit amet dui euismod
                varius. Sed vel tellus risus. Mauris eget nunc sed leo tincidunt
                gravida et non ipsum. Nulla ut placerat est, consequat finibus
                velit. <b>Denne tekst er fed</b>
            </p>
            <ul>
                <li>DEt er unordered list</li>
                <li>med flere ting</li>
                <ul>
                    <li>HEj</li>
                </ul>
                <li>DEt er unordered list</li>
            </ul>
            <ol>
                <li>En sorteret liste her</li>
                <ol>
                    <li>Smoothie time</li>
                </ol>
                <li>med flere ting</li>
                <li>DEt er ordered list</li>
            </ol>
            <Button onClick={() => {}} type={ButtonType.submit}>
                Submit
            </Button>
            <Button onClick={() => {}} type={ButtonType.primary}>
                Primary
            </Button>
            <Button onClick={() => {}} type={ButtonType.secondary}>
                Secondary
            </Button>
            <FileUpload filesChange={() => {}}>Upload</FileUpload>
            <Button onClick={() => {}} type={ButtonType.tietary}>
                Tietary
            </Button>
            <Button onClick={() => {}} type={ButtonType.cancel}>
                Cancel
            </Button>
            <br />
            <Button onClick={() => {}} small={true} type={ButtonType.submit}>
                Submit
            </Button>
            <Button onClick={() => {}} small={true} type={ButtonType.primary}>
                Primary
            </Button>
            <Button onClick={() => {}} small={true} type={ButtonType.secondary}>
                Secondary
            </Button>
            <FileUpload filesChange={() => {}} small={true}>
                Upload
            </FileUpload>
            <Button onClick={() => {}} small={true} type={ButtonType.tietary}>
                Tietary
            </Button>
            <Button onClick={() => {}} small={true} type={ButtonType.cancel}>
                Cancel
            </Button>
            <section className={styles.section}>
                The FormInput component is represented below in the different
                states and forms. FormInput provides a flexible and reusable way
                to create input fields, text areas, and select dropdowns within
                a form. It includes features such as password visibility
                toggling, styling for focused and disabled states, and the
                ability to display error and information messages. FormInput has
                no width, so it takes up all the space inside a container.
                <FormInput
                    label="Password"
                    type="password"
                    name="name"
                    placeholder={'Enter your password'}
                    onChange={() => {}}
                    errorMsg={''}
                />
                <FormInput
                    label="Password"
                    type="text"
                    name="name"
                    placeholder={'Disabled input'}
                    onChange={() => {}}
                    errorMsg={''}
                    disabled
                />
                <FormInput
                    label="Date"
                    type="date"
                    name="name"
                    onChange={() => {}}
                    errorMsg={''}
                />
                <FormInput
                    label="Email"
                    type="email"
                    name="name"
                    placeholder={'The input demonstrates its error state'}
                    onChange={() => {}}
                    errorMsg={'Error'}
                />
                <FormInput
                    label="Label for select dropdown"
                    name="actor"
                    defaultValue={'default'}
                    errorMsg={''}
                    tag="select"
                    onSelect={() => {}}
                >
                    <option value="default" disabled>
                        Choose an option
                    </option>
                    <option value="Another option">Another option</option>
                </FormInput>
                <FormInput
                    label="Label for text area"
                    placeholder="Type something"
                    tag="textArea"
                    errorMsg=""
                    className="textArea"
                    onChange={() => {}}
                />
            </section>

            <Select
                type="radio"
                name="test"
                options={['Option1', 'Option2', 'Option3']}
            />

            <Select
                type="checkbox"
                name="test"
                options={['Check1', 'Check1', 'Check1']}
            />

            <textarea placeholder="textarea" />
            <select>
                <option>Option</option>
                <option>2</option>
                <option>3</option>
            </select>
        </div>
    );
};
export default Styles;
