import React, { ReactNode, useEffect, useState } from 'react';
import styles from './EditLog.module.css';

const EditLog: React.FC<{
    approve?: boolean;
    title: ReactNode | string;
    log: any;
    children?: ReactNode;
}> = ({ title, log, approve = false, children }) => {
    return (
        <div className={`${styles.wrapper} ${approve ? styles.approve : ''}`}>
            <h5 className={styles.title}>{title}</h5>
            <div className={styles.log}>
                {log.map((edit: any, index: number) => (
                    <div key={index} className={styles.entry}>
                        <p className={styles.date}>
                            {new Date(edit.date).toLocaleDateString()} |{' '}
                            {edit.name}
                        </p>
                        <p
                            className={styles.comment}
                            dangerouslySetInnerHTML={{ __html: edit.comment }}
                        ></p>
                    </div>
                ))}
                {children}
            </div>
        </div>
    );
};

export default EditLog;
