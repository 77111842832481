import axios from 'axios'
import React, {useEffect, useState} from 'react'
import { getAllNews } from '../services/apiService'
import styles from './News.module.css'

const News = () => {

    const [news, setNews] = useState<any[]>([])

    const getNews = async() => {
        const news = await getAllNews();
        setNews(news);
    }

    useEffect(() => {
        getNews()
    }, [])

    const extendInfo = (event: React.MouseEvent<HTMLLIElement>) => {
        let parent = (event.target as HTMLElement)
        while(parent.tagName !== 'LI'){
            parent = (parent.parentElement as HTMLElement)
        }
        const id = parent.id
        const extInfoDiv = (document.getElementById('hidden_' + id) as HTMLElement);
        const subtitleDiv = (document.getElementById('subtitle_' + id) as HTMLElement);
        const baseInfoDiv = (document.getElementById(id) as HTMLElement);


        if(extInfoDiv.style.display !== 'block'){
            baseInfoDiv.style.borderBottom = 'none';
            subtitleDiv.style.display = 'none';
            extInfoDiv.style.display = 'block';
        }
        else {
            baseInfoDiv.style.borderBottom = '1px solid #999';
            subtitleDiv.style.display = 'block';
            extInfoDiv.style.display = 'none';
        }
    }

    return (
        <div className={styles['news-page']}>
            <h1>News</h1>
            {news.length > 0 && <ul>
                {news.map(n => (
                    <React.Fragment  key={n.title}>
                        <li id={n.id} onClick={extendInfo} className={styles['news-title']}>
                            <h3>{n.author}<small> - {new Date(n.date).toLocaleDateString('en-UK')}</small></h3>
                            <h4 id={'subtitle_' + n.id}>{n.title}</h4>
                            <div id={'hidden_' + n.id} className={`${styles['news-element']} ${styles['extended-info']}`}>
                                <h2>{n.title}</h2>
                                <span>{n.category}</span>
                                <p>{n.description}</p>
                            </div>
                        </li>
                    </React.Fragment>
                ))}
            </ul>}
            {news.length === 0 && <h3 className={styles.nonews}>No recent news to show</h3>}
        </div>
    )
}

export default News;