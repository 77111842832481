import React, { ReactNode, useState } from 'react';
import Modal from '../manage-user/Modal';
import Button, { ButtonType } from '../general-components/Button';
import { updateSearchIndices } from '../../services/apiService';

const UpdateElasticButton: React.FC<{ children?: ReactNode }> = ({
    children,
}) => {
    const [showConfirmation, setShowConfrmation] = useState(false);

    const confirm = () => {
        updateSearchIndices();
        setShowConfrmation(false);
    };

    return (
        <>
            <Button
                type={ButtonType.primary}
                onClick={() => setShowConfrmation(true)}
            >
                {children}
            </Button>
            {showConfirmation && (
                <Modal
                    info={{
                        title: 'Update Search Index',
                        message:
                            'Confirm that you want to run the search index update now',
                    }}
                    updateView={() => setShowConfrmation(false)}
                >
                    <Button onClick={confirm} type={ButtonType.submit}>
                        Confirm
                    </Button>
                    <Button
                        onClick={() => setShowConfrmation(false)}
                        type={ButtonType.cancel}
                    >
                        Cancel
                    </Button>
                </Modal>
            )}
        </>
    );
};

export default UpdateElasticButton;
