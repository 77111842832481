import { useState, useEffect } from 'react';
import Modal from './Modal';
import {
    deleteInvitation,
    deactivateUser,
    deleteUser,
} from '../../services/apiService';
import Button, { ButtonType } from '../general-components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';

const modalInfoInitial = {
    title: '',
    submitButtonText: '',
    message: '',
    description: '',
    submitButtonDanger: true,
    isSubmitted: false,
};
const GuestRow: React.FC<{
    guest: any;
    refreshData: Function;
}> = ({ guest, refreshData }) => {
    const [showModal, setShowModal] = useState(false);
    const [modalInfo, setModalInfo] = useState(modalInfoInitial);
    let daysLeft = new Date(guest.expiryDate).getTime() - new Date().getTime();
    daysLeft = Math.ceil(daysLeft / (1000 * 3600 * 24));

    useEffect(() => {
        switch (guest.status) {
            case 'pending':
                setModalInfo({
                    ...modalInfo,
                    title: 'Revoke invite',
                    submitButtonText: 'Revoke',
                    message: 'Invite revoked',
                    description:
                        'Revoking a guest will no longer allow this person to become a member of NordInsight. You can always invite them again if you change your mind.',
                });
                break;
            case 'active':
                setModalInfo({
                    ...modalInfo,
                    title: 'Deactivate user',
                    submitButtonText: 'Deactivate',
                    message: 'Deactivated',
                    description:
                        'The "End Date" for the user access will be set as today.',
                });
                break;
            case 'expired':
                setModalInfo({
                    ...modalInfo,
                    title: 'Delete user',
                    submitButtonText: 'Delete',
                    message: 'Deleted',
                    description: 'User will be deleted.',
                });
                break;
        }
    }, []);

    const handleRevoke = async () => {
        let success = false;
        if (guest.status === 'pending') {
            success = await deleteInvitation(guest._id);
        } else if (guest.status === 'active') {
            success = await deactivateUser(guest._id, new Date());
        } else if (guest.status === 'expired') {
            success = await deleteUser(guest._id);
        }

        if (success) {
            setModalInfo({
                ...modalInfo,
                isSubmitted: true,
            });
            refreshData();
        }
    };

    return (
        <>
            {showModal && (
                <Modal
                    info={modalInfo}
                    updateView={() => {
                        setShowModal(false);
                    }}
                    submitModal={handleRevoke}
                >
                    <p>{modalInfo.description}</p>
                </Modal>
            )}
            <tr>
                <td>{guest.email}</td>
                <td>{guest.status}</td>
                <td>{new Date(guest.activationDate).toLocaleDateString()}</td>
                <td>{new Date(guest.expiryDate).toLocaleDateString()}</td>
                <td>{daysLeft > 0 ? daysLeft : 0}</td>
                <td>
                    <Button
                        type={ButtonType.cancel}
                        onClick={() => {
                            setShowModal(true);
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faTrashCan}
                            color="var(--main-white)"
                        />
                    </Button>
                </td>
            </tr>
        </>
    );
};

export default GuestRow;
