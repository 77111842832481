import { Link } from 'react-router-dom';
import { formatTimestamp } from '../../utils/utils';
import Button, { ButtonType } from '../general-components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import PROTOCOL_KEYS from '../../constants/protocol-keys';
import ProtocolPage from '../protocols/ProtocolPage';
import { useState } from 'react';
import Modal from '../manage-user/Modal';
import {
    archiveInternalProtocol,
    verifyInternalProtocol,
} from '../../services/apiService';
import SETTING_KEYS from '../../constants/setting-keys';

const InternalProtocolListRow = ({
    protocol,
    buttonStyle,
    onEdit,
    onVerify,
}: {
    protocol: any;
    buttonStyle: string;
    onEdit: Function;
    onVerify: Function;
}) => {
    const [showArchiveWindow, setShowArchiveWindow] = useState(false);

    const archiveProtocol = async () => {
        await archiveInternalProtocol(protocol._id);
        setShowArchiveWindow(false);
    };

    return (
        <tr>
            {showArchiveWindow && (
                <Modal
                    info={{
                        title: 'Archive Protocol',
                        message:
                            'Are you sure you want to archive this protocol. It will no longer be visible to your colleagues.',
                    }}
                    updateView={() => {
                        setShowArchiveWindow(false);
                    }}
                >
                    <Button
                        className={buttonStyle}
                        type={ButtonType.cancel}
                        onClick={() => {
                            archiveProtocol();
                        }}
                    >
                        Archive
                    </Button>
                    <Button
                        className={buttonStyle}
                        type={ButtonType.tietary}
                        onClick={() => {
                            setShowArchiveWindow(false);
                        }}
                    >
                        Cancel
                    </Button>
                </Modal>
            )}
            <td>
                <Link to={`/protocols/${protocol._id}`}>
                    <h3>{protocol[PROTOCOL_KEYS.title]}</h3>
                </Link>
            </td>
            <td>
                <p>{formatTimestamp(protocol.createdAt)}</p>
            </td>
            <td>
                <p>{formatTimestamp(protocol.updatedAt)}</p>
            </td>
            <td>
                {protocol[SETTING_KEYS.editStatus] === 3 && protocol.verifiedBy}
                {protocol[SETTING_KEYS.editStatus] === 2 && (
                    <Button
                        className={buttonStyle}
                        type={ButtonType.submit}
                        key={protocol._id + '_button'}
                        // TODO: take user directly to edit
                        onClick={() => {
                            onVerify(protocol._id);
                        }}
                        small={true}
                    >
                        Verify
                    </Button>
                )}
            </td>
            <td>
                <Button
                    className={buttonStyle}
                    type={ButtonType.primary}
                    key={protocol._id + '_button'}
                    // TODO: take user directly to edit
                    onClick={() => {
                        onEdit({ edit: true, id: protocol._id });
                    }}
                    small={true}
                >
                    <FontAwesomeIcon icon={faPen} />
                    Edit
                </Button>
            </td>
            <td>
                <Button
                    className={buttonStyle}
                    type={ButtonType.tietary}
                    key={protocol._id + '_button'}
                    onClick={() => {
                        setShowArchiveWindow(true);
                    }}
                    small={true}
                >
                    <FontAwesomeIcon icon={faTrash} color={'#f55'} />
                </Button>
            </td>
        </tr>
    );
};

export default InternalProtocolListRow;
