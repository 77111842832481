import { useContext, useEffect, useState } from 'react';
import styles from './CreatePredefinedConditionals.module.css';
import { useLocation, useParams } from 'react-router-dom';
import {
    FilterContext,
    FilterContextProvider,
} from '../../store/filter-context';
import FilterMenu from './FilterMenu';
import ImplantList from './ImplantList';
import ConditionalsView from './ConditionalsView';
import EditPredefinedMenu from './EditPredefinedMenu';

const EditPredefinedConditionals = () => {
    let { id } = useParams<{ id: string }>();

    const { getResultById } = useContext(FilterContext);

    const [title, setTitle] = useState('');
    const [includedTypes, setIncludedTypes] = useState<string[]>([]);

    useEffect(() => {
        (async () => {
            const res = await getResultById(id);
            console.log(res);
            setTitle(res.title);
            setIncludedTypes(res.settings.types);
        })();
    }, []);

    return (
        <div className={styles.wrapper}>
            <h2>Editing group {title}</h2>
            <EditPredefinedMenu isEdit={true} />
            <div className={styles.implant}>
                <ImplantList />
                <ConditionalsView />
            </div>
        </div>
    );
};

export default EditPredefinedConditionals;
