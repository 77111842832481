import DOMPurify from 'dompurify';

export const getTimeDifference = (timestamp: number): string => {
    const seconds = Math.floor((Date.now() - timestamp) / 1000) + 1;
    if (seconds < 60) {
        return `${seconds}s`;
    }
    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) {
        return `${minutes}m`;
    }
    const hours = Math.floor(minutes / 60);
    if (hours < 24) {
        return `${hours}h`;
    }
    const days = Math.floor(hours / 24);
    if (days < 7) {
        return `${days}d`;
    }
    if (days < 365) {
        const weeks = Math.floor(days / 7);
        return `${weeks}w`;
    }
    const years = Math.floor(days / 365);
    return `${years}y`;
};

export function formatTimestamp(timestamp: string): string {
    if (timestamp === undefined) {
        return '';
    }
    const date = new Date(timestamp);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}

export const sanitizeAndFormatTextareaInput = (input: string): string => {
    const sanitizedCommentInput = DOMPurify.sanitize(input, {
        ALLOWED_TAGS: ['br'],
    });
    return sanitizedCommentInput
        .replace(/\r?\n{3,}/g, '\n\n')
        .replace(/\r?\n/g, '<br/>');
};

export const formReducer = (formState: any, action: any) => {
    switch (action.type) {
        case 'update': {
            return { ...formState, [action.name]: action.value };
        }
        case 'addFieldSet': {
            return {
                ...formState,
                [action.name]: action.value,
            };
        }
        case 'reset': {
            return action.initialFormData;
        }
    }
};

export const errorReducer = (errorState: any, action: any) => {
    switch (action.type) {
        case 'update': {
            return {
                ...errorState,
                [action.name]: action.value,
            };
        }
        case 'reset': {
            return action.initialErrorData;
        }
    }
};

export const camelCaseToSentence = (inputString: string) => {
    // Use regular expression to find camel case patterns
    const words = inputString.match(/[A-Z]?[a-z]+|[A-Z]+(?=[A-Z]|$)/g);

    // Join the words with space to form the final result
    const result = words.join(' ').toLowerCase();

    return result;
};

export const normalizeManufacturer = (
    name: string | undefined | null
): string => {
    if (!name) return '';

    let normalized = name.toLowerCase();
    normalized = normalized.replace(
        /\b(inc|llc|co|corporation|company|gmbh)\b[\.,]?/g,
        ''
    );
    normalized = normalized.replace(/[.,]/g, '');
    normalized = normalized.trim();

    return normalized;
};

export const onlyUnique = (value: string, index: number, array: string[]) => {
    return (
        array
            .map((i) => i?.toLowerCase().trim())
            .indexOf(value?.toLowerCase().trim()) === index
    );
};

export const toTitleCase = (str: string) => {
    return str
        ?.toLowerCase()
        .split(' ')
        .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
};
