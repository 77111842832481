import { useState, useContext } from 'react';
import styles from './Appearance.module.css';
import AuthContext from '../../store/auth-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon } from '@fortawesome/free-solid-svg-icons';

const Appearance = () => {
    const context = useContext(AuthContext);
    const [checked, setChecked] = useState(true);

    const handleTheme = (e: any) => {
        setChecked(!checked);
        context.themeToggle();
    };
    const selectedTheme = localStorage.getItem('selectedTheme');
    return (
        <div className={styles.appearance}>
            <div className={styles.iconPart}>
                <div className={styles.icon}>
                    <FontAwesomeIcon icon={faMoon} />
                </div>
                <span>Dark Mode</span>
            </div>

            <label className={styles.switch}>
                <input
                    type="checkbox"
                    onChange={handleTheme}
                    defaultChecked={selectedTheme === 'dark'}
                />
                <span className={styles.slider}></span>
            </label>
        </div>
    );
};

export default Appearance;
