import React, { useContext, useReducer, useRef } from 'react';
import { useEffect, useState } from 'react';
import SETTING_KEYS from '../../constants/setting-keys';
import {
    getAllFieldstrength,
    getAllTypes,
    uploadSourceFile,
    editInternalProtocol,
    getInternalProtocolById,
    createNewInternalProtocol,
} from '../../services/apiService';
import Button, { ButtonType } from '../general-components/Button';
import AuthContext from '../../store/auth-context';
import {
    conditionalsReducer,
    infoReducer,
    initConditionals,
} from '../../store/implant-context';
import TypeSearchField from '../edit-implant/TypeSearchField';
import styles from './EditInternalProtocol.module.css';
import Conditional from '../conditionals/Conditional';
import CustomFields from '../custom-fields/CustomFields';
import Category from '../edit-implant/Category';
import MriStatus from '../edit-implant/MriStatus';
import RichText from '../general-components/RichText';
import { AutofilledFieldsKeys } from '../../utils/pdfExtractor';
import PROTOCOL_KEYS from '../../constants/protocol-keys';
import EditReferences from './EditReferences';
import ProtocolComponent from '../protocols/ProtocolComponent';
import Modal from '../manage-user/Modal';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { set } from 'shelljs';
import FormInput from '../general-components/FormInput';
import ConfirmationModal from '../general/modal/ConfirmationModal';

const settings = [
    SETTING_KEYS.waitingPeriod,
    SETTING_KEYS.fieldStrength,
    SETTING_KEYS.mriArc,
    SETTING_KEYS.prep,
    SETTING_KEYS.slewRate,
    SETTING_KEYS.brms,
    SETTING_KEYS.coilType,
    SETTING_KEYS.spatialGradient,
    SETTING_KEYS.sar,
    SETTING_KEYS.artefacts,
    SETTING_KEYS.temp,
];

export const initialInfo = {
    [PROTOCOL_KEYS.title]: '',
    [PROTOCOL_KEYS.description]: '',
    [PROTOCOL_KEYS.scope]: '',
    [PROTOCOL_KEYS.def]: '',
    [PROTOCOL_KEYS.procedure]: '',
    [PROTOCOL_KEYS.ref]: [] as string[],
    [PROTOCOL_KEYS.expiry]: '' as string | Date,
    [SETTING_KEYS.type]: '',
    [SETTING_KEYS.category]: '',
    [SETTING_KEYS.bodyPlacement]: [] as string[],
    [SETTING_KEYS.mriStatus]: '',
    [SETTING_KEYS.editStatus]: '',
    [SETTING_KEYS.filename]: '',
    editLogProtocol: [] as any[],
    verifiedBy: '',
    [SETTING_KEYS.notes]: '',
    [SETTING_KEYS.customFields]: [] as any[],
};

const EditInternalProtocol: React.FC<{
    id?: string;
    isNew: boolean;
    onSubmit?: Function;
}> = ({ id, isNew, onSubmit }) => {
    const userContext = useContext(AuthContext);

    const [conditionals, dispatchConditionals] = useReducer(
        conditionalsReducer,
        initConditionals
    );
    const [info, dispatchInfo] = useReducer(infoReducer, initialInfo);
    const [fieldStrengths, setFieldStrengths] = useState([]);
    const [editComment, setEditComment] = useState('');

    const [types, setTypes] = useState<string[]>([]);
    const [showWarningIcon, setShowWarningIcon] = useState(false);

    const [uploadStatus, setUploadStatus] = useState<
        | 'initial'
        | 'source-missing'
        | 'source-uploading'
        | 'source-done'
        | 'source-error'
    >('initial');

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [isSaving, setIsSaving] = useState(false);
    const [saved, setSaved] = useState(false);
    const [isError, setIsError] = useState(false);
    const [newImplantError, setNewImplantError] = useState(false);
    const [errorCond, setErrorCond] = useState('');

    const [fileError, setFileError] = useState(false);

    const [returnedError, setReturnedError] = useState('');
    const [showSavedMessage, setShowSavedMessage] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [fileName, setFileName] = useState<string | null>(null);

    const [usesExistingFile, setUsesExistingFile] = useState(false);

    const [showImplantDetails, setShowImplantDetails] = useState(false);
    const [showPreview, setShowPreview] = useState(false);

    const getData = async () => {
        const types = await getAllTypes();
        const fieldStrengths = await getAllFieldstrength();
        setTypes(types);
        setFieldStrengths(fieldStrengths);
        setIsLoading(false);
    };

    useEffect(() => {
        getData();
    }, []);

    const getInternalProtocol = async () => {
        if (id !== undefined) {
            const { info, conditionals } = await getInternalProtocolById(id);
            dispatchInfo({
                type: 'BULK_UPDATE',
                payload: info,
            });
            dispatchConditionals({
                type: 'BULK_UPDATE',
                payload: conditionals,
            });
        }
    };

    useEffect(() => {
        getInternalProtocol();
    }, [id]);

    const findMatchingType = (
        externalTypeName: string,
        types: string[]
    ): string | null => {
        const normalizedExternalType = externalTypeName.toLowerCase();

        for (const typeName of types) {
            if (typeName.toLowerCase() === normalizedExternalType) {
                return typeName;
            }
        }

        return null;
    };

    const handleActivePassiveChange = (value: string) => {
        updateInfoField(SETTING_KEYS.category, value);
    };

    useEffect(() => {
        const uploadFile = async () => {
            if (file) {
                try {
                    setUploadStatus('source-uploading');
                    const succeeded = await uploadSourceFile(file);
                    if (succeeded) {
                        setUploadStatus('source-done');
                    } else {
                        setUploadStatus('source-error');
                    }
                } catch (error) {
                    setFileError(true);
                }
            }
        };

        uploadFile();
    }, [file]);

    type SaveState = 'draft' | 'versioned' | 'edits';

    const save = async (saveState: SaveState) => {
        setIsSaving(true);
        setNewImplantError(false);
        setSaved(false);
        const updatedInfo: any = {
            ...info,
            [SETTING_KEYS.mriStatus]: parseInt(info[SETTING_KEYS.mriStatus]),
            [SETTING_KEYS.editStatus]: saveState === 'draft' ? 1 : 2,
        };

        await editInternalProtocol(updatedInfo, conditionals, editComment);

        setIsSaving(false);
        setSaved(true);
        setIsModalOpen(false);
    };

    const addNewProtocol = async (isDraft: boolean) => {
        setIsSaving(true);
        setSaved(false);

        const res = await createNewInternalProtocol(
            {
                ...info,
                [SETTING_KEYS.mriStatus]: parseInt(
                    info[SETTING_KEYS.mriStatus]
                ),
                [SETTING_KEYS.editStatus]: isDraft ? 1 : 2,
            },
            conditionals
        );

        // if (res.status === 200) {
        //     if (fileName) {
        //         await completeQueuedImplant(fileName);
        //     }
        // }

        setIsSaving(false);
        if (res.status === 400 || res.status === 500) {
            setNewImplantError(true);
            setReturnedError(res.data.error);
            setSaved(false);
        } else {
            setSaved(true);
        }
    };

    const handleOnSave = (saveState: SaveState) => {
        let errorFlag = false;
        let errorConStr = '';

        // Add Error handling here

        errorConStr = errorConStr.slice(1).replaceAll(' ', ', ');
        setIsError(errorFlag);
        setErrorCond(errorConStr);

        if (!errorFlag && !isNew) {
            save(saveState);
        } else if (!errorFlag && isNew) {
            addNewProtocol(saveState === 'draft');
        }
    };

    useEffect(() => {
        if (saved) {
            if (onSubmit) {
                onSubmit();
            }
            setShowSavedMessage(true);
            const timer = setTimeout(() => {
                setShowSavedMessage(false);
            }, 3000);
            setSaved(false);
            return () => clearTimeout(timer);
        }
    }, [saved, onSubmit]);

    const itemsRef = useRef<Array<HTMLTextAreaElement | null>>([]);

    const updateInfoField = (fieldName: any, value: any) => {
        dispatchInfo({
            type: 'UPDATE',
            payload: { name: fieldName, value: value },
        });
    };

    const updateConditionalField = (fieldName: any, value: any) => {
        dispatchConditionals({
            type: 'UPDATE',
            payload: { name: fieldName, value: value },
        });
    };

    const updateConditional = (e: any) => {
        updateConditionalField(e.target.name, e.target.value);

        e.target.style.height = 0;
        e.target.style.height = `${e.target.scrollHeight}px`;
    };

    useEffect(() => {
        itemsRef.current = itemsRef.current.slice(0, conditionals.length);
        for (let i of itemsRef.current) {
            i.style.height = '' + i.scrollHeight + 'px';
        }
    }, [isLoading]);

    const handleFieldStrengthChange = (e: any) => {
        const { value, checked } = e.target;
        const fieldStrength = conditionals[SETTING_KEYS.fieldStrength];

        let updatedFieldStrength;
        if (checked) {
            if (fieldStrength) {
                updatedFieldStrength = [...fieldStrength, parseFloat(value)];
            } else {
                updatedFieldStrength = [parseFloat(value)];
            }
        } else {
            updatedFieldStrength = fieldStrength.filter(
                (i: any) => i !== parseFloat(value)
            );
        }

        updateConditionalField(
            SETTING_KEYS.fieldStrength,
            updatedFieldStrength
        );
    };

    const addCustomField = () => {
        dispatchInfo({ type: 'ADD_CUSTOM_FIELD' });
    };

    const updateCustomField = (index: number, field: any) => {
        dispatchInfo({
            type: 'UPDATE_CUSTOM_FIELD',
            payload: { index, field },
        });
    };

    const deleteCustomField = (index: number) => {
        const updatedCustomFields = [...info[SETTING_KEYS.customFields]];
        updatedCustomFields.splice(index, 1);
        dispatchInfo({
            type: 'UPDATE',
            payload: {
                name: SETTING_KEYS.customFields,
                value: updatedCustomFields,
            },
        });
    };

    const updateType = (type: string) => {
        updateInfoField(SETTING_KEYS.type, type);
    };

    const resetHighlights = (fieldNames: AutofilledFieldsKeys[]) => {
        return;
    };

    useEffect(() => {
        if (
            info[SETTING_KEYS.type] ||
            info[SETTING_KEYS.bodyPlacement] ||
            info[SETTING_KEYS.mriStatus]
        ) {
            setShowImplantDetails(true);
        }
    }, [info]);

    return (
        <>
            {isModalOpen && (
                <ConfirmationModal
                    title="Confirm edits"
                    message="Please add a note about the edits"
                    onConfirm={() => handleOnSave('versioned')}
                    onClose={() => setIsModalOpen(false)}
                    confirmText="Save"
                    cancelText="Cancel"
                >
                    <div style={{ width: '400px' }}></div>
                    <RichText
                        description={''}
                        onChange={(text) => setEditComment(text)}
                    />
                </ConfirmationModal>
            )}
            <div
                className={`${styles.implant} ${!isNew ? styles.preview : ''}`}
            >
                <>
                    <div
                        className={`${styles.flex} ${!isNew ? 'preview' : ''}`}
                    >
                        <div
                            className={`${styles.conditionals} ${
                                !isNew ? 'preview' : ''
                            } ${styles.backgroundContainer}`}
                        >
                            <h3>New Department protocol</h3>
                            <label className={styles.infoLabel}>Title</label>
                            <input
                                type="text"
                                name={PROTOCOL_KEYS.title}
                                value={info[PROTOCOL_KEYS.title] || ''}
                                onChange={(e) =>
                                    updateInfoField(
                                        PROTOCOL_KEYS.title,
                                        e.target.value
                                    )
                                }
                            />
                            <RichText
                                label={PROTOCOL_KEYS.description}
                                description={info[PROTOCOL_KEYS.description]}
                                onChange={(richText) => {
                                    if (updateInfoField) {
                                        updateInfoField(
                                            PROTOCOL_KEYS.description,
                                            richText
                                        );
                                    }
                                }}
                            />
                            <RichText
                                label={PROTOCOL_KEYS.def}
                                description={info[PROTOCOL_KEYS.def]}
                                onChange={(richText) => {
                                    if (updateInfoField) {
                                        updateInfoField(
                                            PROTOCOL_KEYS.def,
                                            richText
                                        );
                                    }
                                }}
                            />
                            <RichText
                                label={PROTOCOL_KEYS.scope}
                                description={info[PROTOCOL_KEYS.scope]}
                                onChange={(richText) => {
                                    if (updateInfoField) {
                                        updateInfoField(
                                            PROTOCOL_KEYS.scope,
                                            richText
                                        );
                                    }
                                }}
                            />
                            <RichText
                                label={PROTOCOL_KEYS.procedure}
                                description={info[PROTOCOL_KEYS.procedure]}
                                onChange={(richText) => {
                                    if (updateInfoField) {
                                        updateInfoField(
                                            PROTOCOL_KEYS.procedure,
                                            richText
                                        );
                                    }
                                }}
                            />
                            <EditReferences
                                references={info[PROTOCOL_KEYS.ref]}
                                onChange={updateInfoField}
                            />
                            <label className={styles.infoLabel}>
                                Expiry date
                            </label>
                            <input
                                name={PROTOCOL_KEYS.expiry}
                                type="date"
                                value={
                                    info[PROTOCOL_KEYS.expiry] !== '' &&
                                    info[PROTOCOL_KEYS.expiry] !== null &&
                                    info[PROTOCOL_KEYS.expiry] !== undefined
                                        ? new Date(info[PROTOCOL_KEYS.expiry])
                                              .toISOString()
                                              .split('T')[0]
                                        : ''
                                }
                                onChange={(e) =>
                                    updateInfoField(
                                        PROTOCOL_KEYS.expiry,
                                        e.target.value
                                    )
                                }
                            />
                        </div>

                        <div
                            style={{ marginTop: '50px' }}
                            className={`${styles.conditionals} ${
                                !isNew ? 'preview' : ''
                            } ${styles.backgroundContainer}`}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    cursor: 'pointer',
                                    alignItems: 'center',
                                }}
                                onClick={() =>
                                    setShowImplantDetails(!showImplantDetails)
                                }
                            >
                                {!showImplantDetails && <KeyboardArrowRight />}
                                {showImplantDetails && <KeyboardArrowDown />}
                                <h2
                                    style={{
                                        textAlign: 'left',
                                        textDecoration: 'none',
                                    }}
                                >
                                    Implant Details
                                </h2>
                            </div>

                            {showImplantDetails && (
                                <>
                                    <Category
                                        categoryString={SETTING_KEYS.category}
                                        isStent={
                                            info[SETTING_KEYS.category] ===
                                            'Stent'
                                                ? true
                                                : false
                                        }
                                        maxStentLengthString={
                                            SETTING_KEYS.maxStentLength
                                        }
                                        info={info}
                                        onActivePassiveToggle={
                                            handleActivePassiveChange
                                        }
                                        onUpdateInfoField={updateInfoField}
                                    />

                                    <div className={styles['inline-inputs']}>
                                        <TypeSearchField
                                            types={types}
                                            updateType={updateType}
                                            currentValue={
                                                info[SETTING_KEYS.type]
                                            }
                                        />
                                    </div>

                                    <>
                                        <label className={styles.infoLabel}>
                                            Body placement
                                        </label>
                                        <input
                                            name={SETTING_KEYS.bodyPlacement}
                                            value={info[
                                                SETTING_KEYS.bodyPlacement
                                            ].join(',')}
                                            type="text"
                                            placeholder="Enter body placement"
                                            onChange={(e) =>
                                                updateInfoField(
                                                    SETTING_KEYS.bodyPlacement,
                                                    e.target.value.split(',')
                                                )
                                            }
                                        />
                                    </>

                                    <MriStatus
                                        mriStatusString={SETTING_KEYS.mriStatus}
                                        info={info}
                                        onUpdateInfoField={updateInfoField}
                                    />
                                </>
                            )}
                        </div>

                        {/* <div className={styles.additionalInfo}>
                            <ImplantSource
                                info={info}
                                fileName={fileName}
                                uploadStatus={uploadStatus}
                                onSetModal={setIsModalOpen}
                                onUpdateInfoField={updateInfoField}
                                source={SETTING_KEYS.source}
                                documentDate={SETTING_KEYS.docDate}
                            />

                            {uploadStatus === 'source-error' && (
                                <Alert
                                    message={
                                        'An error occured in uploading the source file. Please check the link and upload again if necessary.'
                                    }
                                    onClose={() =>
                                        setUploadStatus('source-done')
                                    }
                                />
                            )} 
                            <AdditionalInfo
                                conditionals={conditionals}
                                info={info}
                                onUpdateConditionalField={
                                    updateConditionalField
                                }
                                onUpdateInfoField={updateInfoField}
                            />
                        </div> */}
                    </div>
                    {/* 
                    <Modal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                    >
                        <SelectSource
                            isPreview={true}
                            onSelect={() => setIsModalOpen(false)}
                        />
                    </Modal> */}

                    {parseInt(info[SETTING_KEYS.mriStatus]) === 2 && (
                        <Conditional
                            settings={settings}
                            conditionals={conditionals}
                            fieldStrengths={fieldStrengths}
                            showWarningIcon={showWarningIcon}
                            itemsRef={itemsRef}
                            onSetShowWarningIcon={setShowWarningIcon}
                            onFieldStrengthChange={handleFieldStrengthChange}
                            onUpdateConditionalField={updateConditionalField}
                            onUpdateConditional={updateConditional}
                            onResetHighlights={resetHighlights}
                        />
                    )}
                    <CustomFields
                        info={info}
                        onAddField={addCustomField}
                        onUpdateField={updateCustomField}
                        onDeleteField={deleteCustomField}
                    />
                </>
            </div>

            {showPreview && (
                <Modal
                    scrollable={true}
                    info={{
                        title: '',
                        message: '',
                    }}
                    updateView={() => {
                        setShowPreview(false);
                    }}
                >
                    <ProtocolComponent
                        info={info}
                        conditionals={conditionals}
                    />
                </Modal>
            )}

            <Button
                type={ButtonType.secondary}
                onClick={() => setShowPreview(true)}
            >
                Preview Protocol
            </Button>

            <div className={styles.save}>
                <Button
                    type={ButtonType.primary}
                    onClick={() => handleOnSave('draft')}
                    disabled={saved}
                >
                    Save draft
                </Button>
                <Button
                    type={ButtonType.submit}
                    onClick={() => {
                        if (!isNew) {
                            setIsModalOpen(true);
                        } else {
                            handleOnSave('versioned');
                        }
                    }}
                    disabled={saved}
                >
                    {isNew ? 'Save and Complete' : 'Save'}
                </Button>
            </div>

            {isError && (
                <div>
                    Please check empty fields: {errorCond}. Delete empty or fill
                    them before saving.
                </div>
            )}
            {isSaving && <div>Saving...</div>}
            {showSavedMessage && <div>Protocol has been Saved</div>}
            {isNew && newImplantError && <div>{returnedError}</div>}
        </>
    );
};

export default EditInternalProtocol;
