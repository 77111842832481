import { useReducer, useState } from 'react';
import { reportMissingImplant } from '../../services/apiService';
import Button, { ButtonType } from '../general-components/Button';
import Modal, { ModalInfo } from '../manage-user/Modal';
import styles from './ReportMissingImplant.module.css';
import FormInput from '../general-components/FormInput';

const formReducer = (state: any, event: any): any => {
    return {
        ...state,
        [event.name]: event.value,
    };
};

const ReportMissingImplant: React.FC<{
    searchTerm: string;
    onClose: () => void;
}> = ({ searchTerm, onClose }) => {
    const [info, setInfo] = useState({
        title: 'Report missing implant',
        message: '',
    });

    const [data, setData] = useReducer(formReducer, {});
    const [submitted, setSubmitted] = useState(false);

    const submit = async () => {
        const res = await reportMissingImplant({ searchTerm, ...data });
        if (res.status === 200) {
            setSubmitted(true);
            setInfo({
                title: 'Report is submitted',
                message: '',
            });
        }
    };

    const setFormData = ({ target }: any) => {
        console.log(target.value);
        setData({
            name: target.name,
            value: target.value,
        });
    };

    return (
        <Modal updateView={onClose} info={info}>
            {!submitted && (
                <form className={styles.form}>
                    <FormInput
                        label="Search term"
                        name="term"
                        type="text"
                        errorMsg=""
                        defaultValue={searchTerm}
                        disabled
                    />
                    <FormInput
                        label="Model/Manufacture name"
                        name="name"
                        type="text"
                        errorMsg=""
                        onChange={setFormData}
                    />
                    <FormInput
                        label="Implant type"
                        name="type"
                        type="text"
                        errorMsg=""
                        onChange={setFormData}
                    />
                    <FormInput
                        label="Additional information (eg. UDI, LOT)"
                        name="info"
                        tag="textArea"
                        errorMsg=""
                        className="textArea"
                        onChange={setFormData}
                    />
                    <Button type={ButtonType.submit} onClick={submit}>
                        Submit
                    </Button>
                </form>
            )}
            {submitted && (
                <Button type={ButtonType.primary} onClick={onClose}>
                    Back to search
                </Button>
            )}
        </Modal>
    );
};

export default ReportMissingImplant;
