import React, { useEffect, useState } from 'react';
import styles from './FlashMessage.module.css';
import { ReactComponent as SuccessIcon } from '../../icons/success-icon.svg';
import { ReactComponent as ErrorIcon } from '../../icons/error-icon.svg';

interface IAlert {
    type?: string;
    title?: string;
    message: string;
    duration?: number;
    className?: string;
}

const FlashMessage: React.FC<IAlert> = ({ type, message, duration }) => {
    
    const [show, setShow] = useState(true);

    let messageStyle = styles.messageWrapper;

    if (type === 'Success') {
        messageStyle = styles.successStyle;
    }
    if (type === 'Error') {
        messageStyle = styles.errorStyle;
    }

    useEffect(() => {
        const timemout = setTimeout(() => {
            setShow(false);
        }, duration);

        return () => {
            clearTimeout(timemout);
        };
    }, [duration])


    return (
        <div>
            {show && (
                <div className={styles.messageWrapper}>
                    <div className={type === 'Success' ? `${styles.successStyle}` : `${styles.errorStyle}`}>
                        {type === 'Success' ? <SuccessIcon/> : <ErrorIcon/>}
                        {message}
                    </div>
                </div>
            )}
        </div>
    )
}

export default FlashMessage;