import styles from "./ImplantVerificationPage.module.css"
import ImplantExpiry from "./ImplantVerification";

const ImplantExpiryPage = () => {
    return(
        <div className={styles.wrapper}>
            <h1>Implant verification</h1>
            <ImplantExpiry />
        </div>
    )
}

export default ImplantExpiryPage;