import CloseIcon from '@mui/icons-material/Close';
import Button, { ButtonType } from '../general-components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import styles from './Conditional.module.css';

const Temperature = ({
    d,
    value,
    autofilledFields,
    onUpdateConditionalField,
    onResetHighlights,
}: {
    d: string;
    value: any;
    autofilledFields: any;
    onUpdateConditionalField: Function;
    onResetHighlights: Function;
}) => {
    return (
        <div>
            {value?.map((tem: any, index: number) => (
                <div className={styles['conditional-inputs']} key={tem + index}>
                    <div className={styles.conditionalGroup}>
                        <input
                            type="number"
                            step="any"
                            defaultValue={tem?.maxRise}
                            placeholder="Temp max rise: °C"
                            style={
                                autofilledFields.temperature
                                    ? {
                                          background: 'rgb(128, 128, 128)',
                                          border: '1px solid rgb(78, 165, 217)',
                                      }
                                    : {}
                            }
                            onChange={(e) => {
                                let tempNew = [...value];
                                tempNew[index].maxRise = Number(e.target.value);
                                onUpdateConditionalField(d, tempNew);
                                onResetHighlights(['temperature']);
                            }}
                        />
                        <input
                            type="text"
                            placeholder="Scanning Conditions"
                            value={tem?.condition}
                            style={
                                autofilledFields.tempCondition
                                    ? {
                                          background: 'rgb(128, 128, 128)',
                                          border: '1px solid rgb(78, 165, 217)',
                                      }
                                    : {}
                            }
                            onChange={(e) => {
                                let tempNew = [...value];
                                tempNew[index].condition = e.target.value;
                                onUpdateConditionalField(d, tempNew);
                                onResetHighlights(['tempCondition']);
                            }}
                        />
                    </div>
                    <Button
                        type={ButtonType.cancel}
                        onClick={() => {
                            let temDel = [...value];
                            temDel.splice(index, 1);
                            onUpdateConditionalField(d, temDel);
                            onResetHighlights(['tempCondition', 'temperature']);
                        }}
                    >
                        <CloseIcon />
                    </Button>
                </div>
            ))}
            <Button
                className={styles['add-btn']}
                type={ButtonType.primary}
                onClick={() => {
                    onUpdateConditionalField(d, [
                        ...value,
                        { maxRise: '', condition: '' },
                    ]);
                }}
            >
                <FontAwesomeIcon icon={faPlus} />
            </Button>
        </div>
    );
};

export default Temperature;
