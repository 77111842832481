import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';

import styles from './UserImageIcon.module.css';

const UserImageIcon = () => {
    return (
        <div className={styles['user-image-container']}>
            <FontAwesomeIcon icon={faCircleUser} color="var(--main-white)" />
        </div>
    );
};

export default UserImageIcon;
