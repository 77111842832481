
import { useState, useEffect } from 'react'
import { getAllActors } from '../../services/apiService'

const ManageActors = () => {

    const [actors, setActors] = useState([])

    const getActors = async() => {
        const actors = await getAllActors();
        setActors(actors)
    }

    useEffect( () => {
        getActors()
    }, [])

    const deleteActor = (e) => {
    }

    return (
        <ul>
            {actors.map(a => (
            <li key={a.role}>{a.role}
                <ul>{a.rights.map(r => <li key={r}><em>{r}</em></li>)}</ul>
                <button id={a.role} onClick={deleteActor}>DELETE</button>
            </li>
            ))}
        </ul>
    )
}

export default ManageActors;