import styles from './AllImplantsList.module.css';
import { useEffect, useRef, useState } from 'react';
import {
    allImplantsGetImplantsByManufacturer,
    allImplantsGetImplantsByType,
    allImplantsGetManufacturers,
    allImplantsGetTypes,
} from '../../services/apiService';
import { toTitleCase } from '../../utils/utils';

const AllImplantsList: React.FC<{
    category: '' | 'types' | 'manufacturers';
    defaultValue: string;
}> = ({ category, defaultValue }) => {
    const [types, setTypes] = useState([]);
    const [manufacturers, setManufacturers] = useState([]);

    const [selectedCat, setSelectedCat] = useState('');
    const [implants, setImplants] = useState([]);

    const selRef = useRef(null);

    const selectManufacturer = async (mId: string) => {
        setSelectedCat(mId);
        const imps = await allImplantsGetImplantsByManufacturer(mId);
        setImplants(imps);
    };

    const selectType = async (t: string) => {
        setSelectedCat(t);
        const imps = await allImplantsGetImplantsByType(t);
        console.log(defaultValue, t, imps);
        setImplants(imps);
    };

    const getData = async () => {
        if (category === 'types') {
            const t = await allImplantsGetTypes();
            setTypes(t);
        }
        if (category === 'manufacturers') {
            const m = await allImplantsGetManufacturers();
            setManufacturers(m);
        }
    };
    useEffect(() => {
        getData();
    }, [category]);

    useEffect(() => {
        if (category === 'types') {
            selectType(defaultValue);
        }
        if (category === 'manufacturers') {
            selectManufacturer(defaultValue);
        }
    }, [defaultValue]);

    useEffect(() => {
        console.log('ref', selRef);
        if (selRef.current) {
            selRef.current.scrollIntoView();
        }
    }, [types]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.category}>
                {category === 'types' && (
                    <ul>
                        {types.map((t) => (
                            <li
                                ref={selectedCat === t ? selRef : null}
                                className={
                                    selectedCat === t ? styles.selected : ''
                                }
                                onClick={() => selectType(t)}
                            >
                                {t}
                            </li>
                        ))}
                    </ul>
                )}
                {category === 'manufacturers' && (
                    <ul>
                        {manufacturers.map((m) => (
                            <li
                                className={
                                    selectedCat === m ? styles.selected : ''
                                }
                                onClick={() => selectManufacturer(m)}
                            >
                                {toTitleCase(m)}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            <div className={styles.implants}>
                <ul>
                    {implants.map((i) => (
                        <li
                            onClick={() =>
                                (window.location.href = '/implant/' + i._id)
                            }
                        >
                            {i.Model}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default AllImplantsList;
