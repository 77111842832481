import SETTING_KEYS from '../../constants/setting-keys';
import Badge from '../ui-components/Badge';
import styles from './FilteredCard.module.css';

const FilteredRow = ({
    id,
    onClick,
    info,
    limitingFields,
}: {
    id: string;
    onClick: any;
    info: any;
    limitingFields: string[];
}) => {
    return (
        <div className={styles.wrapper} key={id}>
            <div className={styles.link} onClick={onClick}>
                <div>{info[SETTING_KEYS.model]}</div>
                <div style={{ fontSize: '0.8rem' }}>
                    {info[SETTING_KEYS.manufacturer]}
                </div>
            </div>
            <div>
                {limitingFields?.map((f) => (
                    <Badge color="light-grey">{f}</Badge>
                ))}
            </div>
        </div>
    );
};

export default FilteredRow;
