import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ImplantVerification.module.css';
import { getExpiringImplants } from '../../services/apiService';
import SETTING_KEYS from '../../constants/setting-keys';
import LoadingRing from '../ui-components/LoadingRing';
import VerificationWindow from './VerificationWindow';

const ImplantVerification = () => {
    // Context and navigation hooks
    const navigate = useNavigate();

    // States for data, loading, and error handling
    const [implants, setImplants] = useState([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    // Calculating days to expiry
    const getDaysToExpiry = (expiryDateString: string) => {
        const currentDate = new Date();
        const expiryDate = new Date(expiryDateString);
        return Math.ceil(
            (expiryDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24)
        );
    };

    // Fetching expiring implants
    const fetchImplantsData = async () => {
        try {
            const implantsData = await getExpiringImplants();
            setImplants(implantsData.implants);
            setLoading(false);
            setError(null);
        } catch (error) {
            setLoading(false);
            setError(
                'There was an error fetching the data. Please try again later.'
            );
        }
    };

    useEffect(() => {
        fetchImplantsData();
    }, []);

    if (!loading && implants && implants.length === 0) {
        return (
            <p className={styles['empty-state']}>
                No implants available for verification.
            </p>
        );
    }

    return (
        <div className={styles.wrapper}>
            {/* <h2>Total number of implants to be verified {implants.length}</h2> */}
            {error && <p>{error}</p>}
            {loading ? (
                <div className={styles['loading-wrapper']}>
                    <LoadingRing size="large" />
                </div>
            ) : (
                <div className={styles['table-wrapper']}>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th>Manufacturer</th>
                                <th>Model</th>
                                <th>Last updated</th>
                                <th>Expiration</th>
                                <th>Verification</th>
                            </tr>
                        </thead>

                        <tbody>
                            {implants.map((implant: any) => {
                                return (
                                    <tr key={implant._id}>
                                        <td>{implant.Manufacturer}</td>
                                        <td
                                            onClick={() => {
                                                navigate(
                                                    `/implant/${implant._id}`
                                                );
                                            }}
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                        >
                                            {implant.Model}
                                        </td>
                                        <td>
                                            {new Date(
                                                implant.updatedAt
                                            ).toLocaleDateString()}
                                        </td>
                                        <td className={styles.expiration}>
                                            <span
                                                className={
                                                    !implant[
                                                        SETTING_KEYS.expiryDate
                                                    ]
                                                        ? ''
                                                        : getDaysToExpiry(
                                                              implant[
                                                                  SETTING_KEYS
                                                                      .expiryDate
                                                              ]
                                                          ) <= 0
                                                        ? styles.expired
                                                        : getDaysToExpiry(
                                                              implant[
                                                                  SETTING_KEYS
                                                                      .expiryDate
                                                              ]
                                                          ) < 8
                                                        ? styles.soonToExpire
                                                        : ''
                                                }
                                            >
                                                {!implant[
                                                    SETTING_KEYS.expiryDate
                                                ]
                                                    ? ''
                                                    : getDaysToExpiry(
                                                          implant[
                                                              SETTING_KEYS
                                                                  .expiryDate
                                                          ]
                                                      ) <= 0
                                                    ? 'Expired'
                                                    : `${getDaysToExpiry(
                                                          implant[
                                                              SETTING_KEYS
                                                                  .expiryDate
                                                          ]
                                                      )} days`}
                                            </span>
                                        </td>
                                        <td className={styles.verification}>
                                            <VerificationWindow
                                                implantId={implant._id}
                                                onSubmit={() =>
                                                    fetchImplantsData()
                                                }
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default ImplantVerification;
