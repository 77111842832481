import SelectItem from '../general-components/SelectItem';
import styles from './Region.module.css';

const Region = ({
    regions,
    info,
    regionString,
    onRegionChange,
}: {
    regions: any[];
    info: any;
    regionString: string;
    onRegionChange: Function;
}) => {
    return (
        <>
            <label className={styles.infoLabel}>Region</label>

            <div className={styles.inputWrapper}>
                {regions.map((region) => (
                    <SelectItem
                        key={region}
                        checked={info[regionString]?.includes(region) || false}
                        type="checkbox"
                        value={region}
                        onChange={() => onRegionChange(region)}
                    />
                ))}
            </div>
        </>
    );
};

export default Region;
