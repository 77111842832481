import { ChangeEvent, useReducer, useState } from 'react';
import Modal from './Modal';
import {
    deleteInvitation,
    sendInvitationReminders,
} from '../../services/apiService';
import styles from './Organizations.module.css';
import Button, { ButtonType } from '../general-components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import FormInput from '../general-components/FormInput';
import {
    formReducer,
    errorReducer,
    camelCaseToSentence,
} from '../../utils/utils';

const initialFormData = { title: '', news: '' };

// initialErrorData includes only required fields
const initialErrorData = { title: '', news: '' };

const PendingRow: React.FC<{
    invite: any;
    refreshData: Function;
}> = ({ invite, refreshData }) => {
    const [showModalRevoke, setShowModalRevoke] = useState(false);
    const [showModalRemind, setShowModalRemind] = useState(false);
    const [isIvited, setIsInvited] = useState(false);
    const [isRevoked, setIsRevoked] = useState(false);
    const [remindInvitationData, setRemindInvitationData] = useReducer(
        formReducer,
        initialFormData
    );
    const [errorData, setErrorData] = useReducer(
        errorReducer,
        initialErrorData
    );
    // Define a list of required fields in the form
    const requiredFields = Object.keys(initialErrorData);

    const handleInputChange = (
        e: ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
    ) => {
        const { name, value } = e.target;

        setRemindInvitationData({
            type: 'update',
            name,
            value,
        });
        requiredFields.includes(name) &&
            setErrorData({ type: 'update', name, value: '' });
    };

    const revokeInvite = async () => {
        const success = await deleteInvitation(invite._id);
        if (success) {
            setIsRevoked(true);
            refreshData();
        }
    };

    const sendRemind = async () => {
        let allChecksPassed = false;

        Object.entries(remindInvitationData).forEach(([key, value]) => {
            if (requiredFields.includes(key) && value === '') {
                setErrorData({
                    type: 'update',
                    name: key,
                    value: `Please fill ${camelCaseToSentence(key)}`,
                });
            }
        });

        allChecksPassed = Object.entries(remindInvitationData)
            .filter(([key]) => requiredFields.includes(key))
            .every(([key, value]) => value !== '');

        if (allChecksPassed) {
            const res = await sendInvitationReminders(
                [{ email: invite.email, id: invite._id }],
                remindInvitationData.title,
                remindInvitationData.news
            );
            if (res) {
                setIsInvited(true);
            }
        }
    };

    return (
        <>
            {showModalRevoke && (
                <Modal
                    info={{
                        title: 'Revoke invite',
                        submitButtonText: 'Revoke',
                        message: 'Revoke invite',
                        submitButtonDanger: true,
                        isSubmitted: isRevoked,
                    }}
                    updateView={() => {
                        setShowModalRevoke(false);
                    }}
                    submitModal={revokeInvite}
                >
                    <p>
                        Revoking an invite will no longer allow this person to
                        become a member of organization.
                    </p>
                </Modal>
            )}
            {showModalRemind && (
                <Modal
                    info={{
                        title: 'Reminder',
                        submitButtonText: 'Send',
                        message: 'Reminder sent',
                        submitButtonDanger: false,
                        isSubmitted: isIvited,
                        className: 'remind-modal',
                    }}
                    updateView={() => {
                        setShowModalRemind(false);
                        setIsInvited(false);
                        setRemindInvitationData({
                            type: 'reset',
                            initialFormData,
                        });
                        setErrorData({ type: 'reset', initialErrorData });
                    }}
                    submitModal={sendRemind}
                >
                    <p>Sign-up Reminder</p>
                    <FormInput
                        label="Title"
                        name="title"
                        defaultValue={remindInvitationData.title}
                        placeholder="Description"
                        type="text"
                        onChange={handleInputChange}
                        errorMsg={errorData.title}
                    />
                    <FormInput
                        label="News"
                        placeholder="Type something"
                        name="news"
                        defaultValue={remindInvitationData.news}
                        tag="textArea"
                        errorMsg={errorData.news}
                        className="textArea"
                        onChange={handleInputChange}
                    />
                </Modal>
            )}
            <tr>
                <td>{invite.departmentName}</td>
                <td>{invite.licenses}</td>
                <td>{invite.email}</td>
                <td>
                    {new Date(invite.createdAt).toLocaleDateString('en-US')}
                </td>
                <td>
                    <Button
                        type={ButtonType.tietary}
                        onClick={() => {
                            setShowModalRemind(true);
                        }}
                    >
                        Invite
                    </Button>
                </td>
                <td>
                    <Button
                        type={ButtonType.tietary}
                        onClick={() => {
                            setShowModalRevoke(true);
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faTrashCan}
                            color="var(--main-white)"
                        />
                    </Button>
                </td>
            </tr>
        </>
    );
};

export default PendingRow;
