import styles from './MriStatus.module.css';

const MRISafetyStatuses = {
    'MRI Unknown': 0,
    'MRI Unsafe': 1,
    'MRI Conditional': 2,
    'MRI Safe': 3,
};

const MriStatus = ({
    mriStatusString,
    info,
    style,
    onResetHighlights,
    onUpdateInfoField,
    onUpdateProtocolField,
}: {
    mriStatusString: string;
    info: any;
    style?: any;
    onResetHighlights?: Function;
    onUpdateInfoField?: Function;
    onUpdateProtocolField?: Function;
}) => {
    const renderOptions = () => {
        return Object.entries(MRISafetyStatuses).map(([key, value], index) => (
            <option key={index} value={value}>
                {key}
            </option>
        ));
    };
    return (
        <>
            <label className={`${styles.infoLabel} ${styles.required}`}>
                MRI Status
            </label>
            <select
                name={mriStatusString}
                value={
                    info[mriStatusString] !== -1
                        ? info[mriStatusString]
                        : 'default'
                }
                style={style}
                onChange={(e) => {
                    if (onResetHighlights) onResetHighlights(['status']);
                    if (onUpdateInfoField) {
                        onUpdateInfoField(mriStatusString, e.target.value);
                    } else {
                        console.log('update protocol');
                        onUpdateProtocolField(parseInt(e.target.value));
                    }
                }}
            >
                <option value="default" disabled>
                    Select MRI status of implant
                </option>
                {renderOptions()}
            </select>
        </>
    );
};

export default MriStatus;
