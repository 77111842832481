import safe from '../../images/MRsafe.png';
import unsafe from '../../images/MRunsafe.png';
import conditional from '../../images/MRconditional.png';
import styles from './Status.module.css';
import Tooltip from './Tooltip';

const Status: React.FC<{ status: number; small?: boolean }> = ({
    status,
    small = false,
}) => {
    let img;

    if (status === 1) {
        img = <img src={unsafe} alt={'unsafe'} />;
    } else if (status === 2) {
        img = <img src={conditional} alt={'conditional'} />;
    } else if (status === 3) {
        img = <img src={safe} alt={'safe'} />;
    } else if (status === 0) {
        img = (
            <div className={`${styles.noStatus} ${small ? styles.small : ''}`}>
                <Tooltip
                    // invisble={true}
                    content={'MRI Status is unknown'}
                >
                    ?
                </Tooltip>
            </div>
        );
    }

    return <div className={styles.wrapper}>{img}</div>;
};

export default Status;
