import Button, { ButtonType } from '../general-components/Button';
import LoadingRing from '../ui-components/LoadingRing';
import styles from './ImplantSource.module.css';

const ImplantSource = ({
    info,
    fileName,
    uploadStatus,
    onSetModal,
    onUpdateInfoField,
    source,
    documentDate,
}: {
    info: any;
    fileName: string;
    uploadStatus: string;
    onSetModal: Function;
    onUpdateInfoField: Function;
    source: string;
    documentDate: string;
}) => {
    return (
        <div className={`${styles.backgroundContainer} `}>
            <div className={styles['inline-inputs']}>
                <div>
                    <h3>Source</h3>
                    <p>
                        <a
                            href={
                                'https://files.nordinsight.com/source/' +
                                fileName
                            }
                            target="_blank"
                            rel="noreferrer" // remove?
                        >
                            {fileName}
                        </a>
                    </p>
                    <Button
                        type={ButtonType.primary}
                        className={styles.greyBtn}
                        onClick={() => onSetModal(true)}
                    >
                        Update Source
                    </Button>
                </div>
                {uploadStatus === 'source-uploading' && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <LoadingRing size="large" />
                    </div>
                )}
            </div>
            <>
                <label className={styles.infoLabel}>Link / email</label>
                <input
                    name={source}
                    type="text"
                    placeholder="Enter source of information. E.g. Manufacturer webiste or contact email etc."
                    value={info[source]}
                    onChange={(e) => onUpdateInfoField(source, e.target.value)}
                />
            </>
            <>
                <label className={styles.infoLabel}>Document date</label>
                <input
                    name={documentDate}
                    type="date"
                    value={info[documentDate]}
                    onChange={(e) =>
                        onUpdateInfoField(documentDate, e.target.value)
                    }
                />
            </>
        </div>
    );
};

export default ImplantSource;
