import React, { ReactNode } from 'react';
import Tooltip from '@mui/material/Tooltip';
import styles from './Tooltip.module.css'


interface CustomTooltipProps {
    content: string;
    children: ReactNode;
    className?: string;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ content, children, className }) => {

    const tooltipClass = className ? `${styles.tooltip} ${className}` : styles.tooltip;

    return (
        <Tooltip 
            title={content} 
            arrow 
            classes={{ tooltip: tooltipClass }}
        >
            <span>{children}</span>
        </Tooltip>
    );
};

export default CustomTooltip;
