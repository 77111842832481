import SelectItem from '../general-components/SelectItem';
import styles from './Brand.module.css';

const Brand = ({
    manufacturers,
    isCustomManufacturer,
    info,
    manufacturerString,
    onUpdateInfoField,
    toggleCustomManufacturer,
}: {
    manufacturers: any[];
    isCustomManufacturer: boolean;
    info: any;
    manufacturerString: string;
    onUpdateInfoField: Function;
    toggleCustomManufacturer: Function;
}) => {
    const customManufacturerInput = () => {
        return (
            <input
                value={info}
                type="text"
                placeholder="Add custom brand"
                required
                onChange={(e) =>
                    onUpdateInfoField(manufacturerString, e.target.value)
                }
            />
        );
    };

    const manufacturerDropdown = () => {
        return (
            <select
                name={manufacturerString}
                value={info || ''}
                required
                onChange={(e) =>
                    onUpdateInfoField(manufacturerString, e.target.value)
                }
            >
                <option value="" disabled>
                    Select manufacturer
                </option>
                {manufacturers.map((m) => (
                    <option key={m.name}>{m.name}</option>
                ))}
            </select>
        );
    };

    const customManufacturerToggle = () => {
        return (
            <div className={styles.typeCheckboxWrapper}>
                <SelectItem
                    checked={isCustomManufacturer}
                    type="checkbox"
                    value={'Enter custom brand'}
                    onChange={(o: any) => {
                        onUpdateInfoField(manufacturerString, '');
                        toggleCustomManufacturer((prev: boolean) => !prev);
                    }}
                />
            </div>
        );
    };

    return (
        <div className={`${styles.brand}`}>
            <label className={`${styles.infoLabel} ${styles.required}`}>
                Brand
            </label>
            {isCustomManufacturer
                ? customManufacturerInput()
                : manufacturerDropdown()}
            {customManufacturerToggle()}
        </div>
    );
};

export default Brand;
