import { useState } from 'react';
import Button, { ButtonType } from '../general-components/Button';
import styles from './CatalogNumbers.module.css';
import ConditionalsTable from './conditionals/ConditionalsTable';

const CatalogNumbers: React.FC<{ data: any[] }> = ({ data }) => {
    const [showInfo, setShowInfo] = useState(false);

    const toggle = () => {
        setShowInfo(!showInfo);
    };

    return (
        <>
            {data.filter((c) => c.value !== '').length > 0 && (
                <div className={styles.wrapper}>
                    <p className={styles.title}>
                        {data.length} Catalog number(s){' '}
                        {!showInfo && (
                            <Button
                                small={true}
                                className={styles.button}
                                type={ButtonType.primary}
                                onClick={toggle}
                            >
                                See Info
                            </Button>
                        )}
                        {showInfo && (
                            <Button
                                small={true}
                                className={styles.button}
                                type={ButtonType.secondary}
                                onClick={toggle}
                            >
                                Hide
                            </Button>
                        )}
                    </p>
                    {showInfo && (
                        <div className={styles.table}>
                            <ConditionalsTable
                                headers={[
                                    'Catalog number',
                                    'Info',
                                    'Diameter',
                                    'Length',
                                ]}
                                fields={data
                                    .filter((c) => c.value !== '')
                                    .map((c) => [
                                        c.value,
                                        c.info,
                                        c.diameter !== undefined &&
                                        c.diameter !== null
                                            ? `${c.diameter} mm`
                                            : '',
                                        c.length !== undefined &&
                                        c.length !== null
                                            ? `${c.length} mm`
                                            : '',
                                    ])}
                            />
                        </div>
                    )}
                </div>
            )}{' '}
        </>
    );
};

export default CatalogNumbers;
