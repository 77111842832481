import stylesSelect from '../general-components/SelectItem.module.css';
import styles from './Conditional.module.css';

const FieldStrength = ({
    value,
    fieldStrengths,
    onFieldStrengthChange,
}: {
    value: any;
    fieldStrengths: any;
    onFieldStrengthChange: (e: any) => void;
}) => {
    const isValueArray = Array.isArray(value);
    return (
        <div className={styles.inputWrapper}>
            {fieldStrengths
                ?.filter((f: any) => f !== null)
                .map((f: any) => (
                    <label className={stylesSelect.wrapper} key={f}>
                        <input
                            checked={
                                isValueArray ? value.indexOf(f) > -1 : false
                            }
                            type="checkbox"
                            value={f.toString()}
                            onChange={onFieldStrengthChange}
                        />
                        <span> {f}</span>
                    </label>
                ))}
        </div>
    );
};

export default FieldStrength;
