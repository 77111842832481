import React, { useContext, useReducer, useState, useEffect } from 'react'
import AuthContext from '../../store/auth-context'
import axios from 'axios'
import { Navigate } from 'react-router'
import RoleSelector from './RoleSelector'
import RIGHTS_KEYS from '../../constants/rights-keys'
import DepartmentSelector from './DepartmentSelector'
import { registerUser } from '../../services/apiService'

const formReducer = (state, event) => {
    return {
      ...state,
      [event.name]: event.value
    }
   }

const Register = () => {
    const [formData, setFormData] = useReducer(formReducer, {});
    const [errorData, setErrorData] = useReducer(formReducer, {});

    const context = useContext(AuthContext);

    const validatingInputs = [
        'name',
        'email',
        'repeatemail',
        'department',
        'id',
        'password',
        'repeatpassword'
    ]

    const setErrorOnField = (field) => {
        if(!formData[field]){
          setErrorData({
            name: field,
            value: 'Please fill out ' + field,
          })
        }
      }

    const submitHandler = async(event) => {
        event.preventDefault();
        for(let inp of validatingInputs){
            setErrorOnField(inp)
        }
        const role = document.getElementById('role-selector').value;
        if(role === 'default'){
            setErrorOnField('role')
        }

        const isValid = validatingInputs.every(x => formData[x]?.length > 0) && role !== 'default'
        if(isValid){
            const success = registerUser({
                email: formData.email,
                name: formData.name,
                role: role,
                password: formData.password,
                department: formData.department,
                institution: formData.institution
            });
            if (success){
                window.location.assign('/');
            }
        }
    }

    const handleChange = event => {
        if(event.target.value.length > 0){
          errorData[event.target.name] = ''
        }
        setFormData({
          name: event.target.name,
          value: event.target.value,
        });
      }

    const handleSelectorChange = (event) => {
        if(event.target.value !== 'default'){
            setErrorData({
                name: 'role',
                value: '',
              })
        }
    }

    return (
        <div className="register-box">
            <div className="register-form">
                <form onSubmit={submitHandler}>
                    <h2>Sign up</h2>
                <div className="form-inputs">
                <fieldset>
                        <input type="text" name="name" placeholder="name" onChange={handleChange}/>
                        <span className="error" aria-live="polite">{errorData.name}</span>
                        <input type="email" name="email" placeholder="email" onChange={handleChange}/>
                        <span className="error" aria-live="polite">{errorData.email}</span>
                        <input type="email" name="repeatemail" placeholder="repeat email" onChange={handleChange}/>
                        <span className="error" aria-live="polite">{errorData.repeatemail}</span>
                </fieldset>
                <fieldset>
                    {/* <input type="text" name="institution" placeholder="institution" onChange={handleChange}/>
                        <span className="error" aria-live="polite">{errorData.institution}</span>
                    <input type="text" name="department" placeholder="department" onChange={handleChange}/>
                        <span className="error" aria-live="polite">{errorData.department}</span> */}
                    <DepartmentSelector onChange={handleChange} defaultValue='default'/>
                    <input type="text" name="id" placeholder="work id" onChange={handleChange}/>
                        <span className="error" aria-live="polite">{errorData.id}</span>
                </fieldset>
                <fieldset>
                        <input type="password" name="password" placeholder="password" onChange={handleChange}/>
                        <span className="error" aria-live="polite">{errorData.password}</span>
                        <input type="password" name="repeatpassword" placeholder="repeat password" onChange={handleChange}/>
                        <span className="error" aria-live="polite">{errorData.repeatpassword}</span>
                </fieldset>
                {context.orRights([RIGHTS_KEYS.manageEmployees, RIGHTS_KEYS.manageAllUsers]) && <fieldset>
                    <RoleSelector defaultValue="default" onChange={handleSelectorChange} />
                    <span className="error" aria-live="polite">{errorData.role}</span>
                </fieldset>}
                </div>
                <button type="submit">Submit</button>
                </form>
            </div>
        </div>
        
    )
}

export default Register;