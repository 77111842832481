import styles from './ImplantImage.module.css';

const ImplantImage = ({
    imageError,
    imageName,
}: {
    imageError: boolean;
    imageName: any;
}) => {
    return (
        <>
            {imageError && (
                <label style={{ color: 'red' }}>Please provide an image</label>
            )}
            {imageName && (
                <div className={`${styles.input} ${styles.ref}`}>
                    <label>Image source:</label>
                    <p>{imageName}</p>
                </div>
            )}
        </>
    );
};

export default ImplantImage;
