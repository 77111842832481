import FormInput from '../general-components/FormInput';
import { useEffect, useState } from 'react';
import Button, { ButtonType } from '../general-components/Button';
import { editSearchIssue } from '../../services/apiService';
import SelectItem from '../general-components/SelectItem';
import LoadingRing from '../ui-components/LoadingRing';
import SearchableDropdown from './SearchableDropdown';
import styles from './EditSearchIssue.module.css';

interface SearchIssueDTO {
    createdBy: string;
    state: string;
    searchLogs: string[];
    resolvedImplants: string[];
    comment: string;
}

const EditSearchIssue = ({
    issue,
    onUpdateOccurred,
}: {
    issue: any;
    onUpdateOccurred: Function;
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [editedIssue, setEditedIssue] = useState<any>({
        state: 'In Progress',
        searchLogs: [''],
        resolvedImplants: issue.resolvedImplants
            ? [...issue.resolvedImplants]
            : [],
        comment: '',
    });

    useEffect(() => {
        setIsLoading(true);
        setEditedIssue(JSON.parse(JSON.stringify(issue)));
        setIsLoading(false);
    }, [issue]);

    const handleSelectImplants = (implantIds: string[]) => {
        setEditedIssue((issue: any) => {
            return {
                ...issue,
                resolvedImplants: [...implantIds],
            };
        });
    };

    const handleChangeStatus = (newState: string) => {
        setEditedIssue((issue: any) => {
            return {
                ...issue,
                state: newState,
            };
        });
    };

    const handleChangeComment = (newComment: any) => {
        setEditedIssue((issue: any) => {
            return {
                ...issue,
                comment: newComment,
            };
        });
    };

    const handleSubmitChanges = async () => {
        const updatedIssue = await editSearchIssue(issue._id, editedIssue);
        onUpdateOccurred(true);
    };

    return (
        <>
            {isLoading ? (
                <LoadingRing size="small" />
            ) : (
                <div>
                    <div>
                        <h3>
                            <i>"{issue.searchLogs[0].searchTerm}"</i>
                        </h3>
                    </div>
                    <div>
                        <div className={styles.status}>
                            <h4>Set Status:</h4>
                            {[
                                'In Progress',
                                'Ranking',
                                'Bloated',
                                'Unknown',
                                'No Contact',
                                'Resolved',
                                'Awaiting',
                            ].map((val) => (
                                <SelectItem
                                    checked={editedIssue.state === val}
                                    type="radio"
                                    labelled={true}
                                    name={val}
                                    value={val}
                                    onChange={() => handleChangeStatus(val)}
                                />
                            ))}
                        </div>
                        {editedIssue.state !== 'Resolved' && (
                            <div>
                                <FormInput
                                    label="Comment"
                                    name="comment"
                                    type="text"
                                    tag="textArea"
                                    defaultValue={
                                        issue.comment === ''
                                            ? ''
                                            : issue.comment
                                    }
                                    onChange={(e: any) =>
                                        handleChangeComment(e.target.value)
                                    }
                                />
                            </div>
                        )}
                        {editedIssue.state === 'Resolved' && (
                            <>
                                <h3>Select Matching Implant:</h3>
                                <SearchableDropdown
                                    onSelectImplant={handleSelectImplants}
                                />
                            </>
                        )}
                    </div>

                    <Button
                        type={ButtonType.submit}
                        onClick={handleSubmitChanges}
                    >
                        Submit Changes
                    </Button>
                </div>
            )}
        </>
    );
};

export default EditSearchIssue;
