import React, { ReactNode } from 'react'
import {  Route, Navigate } from "react-router-dom";

export const UnguardedElement = (props: any) => {
    return !props.auth ? <>{props.children}</> : <></>;
};

export const GuardedElement = (props: any) => {
    return props.auth ? <React.Fragment>{props.children}</React.Fragment> : <React.Fragment/>;
};

export const GuardedRoute = (auth: boolean, component: ReactNode, rest: {}) => {
    return auth ? <Route {...rest} element={component} /> : <Navigate to="/" />;
  };

export const UnguardedRoute = (auth: boolean, component: ReactNode, rest: {}) => {
    return auth ? <Route {...rest} element={component} /> : <Navigate to="/" />;
  };