import React, {
    createContext,
    useState,
    useContext,
    useEffect,
    useReducer,
    Dispatch,
} from 'react';
import SETTING_KEYS from '../constants/setting-keys';
import {
    WaintingPeriodDTO,
    SpatialGradientDTO,
    SARDTO,
    ArtifactDTO,
    TempDTO,
    SlewDTO,
} from '../models/ConditionalsModels';
import { listSourceFiles } from '../services/apiService';

interface CustomField {
    heading: string;
    value: {
        type: 'doc';
        content: any[];
    };
}

export const initialInfo = {
    [SETTING_KEYS.manufacturer]: '',
    [SETTING_KEYS.model]: '',
    [SETTING_KEYS.region]: [] as String[],
    [SETTING_KEYS.category]: '',
    [SETTING_KEYS.mriStatus]: -1,
    [SETTING_KEYS.gudidDeviceId]: '',
    [SETTING_KEYS.distEndDate]: '',
    [SETTING_KEYS.catalogNumbers]: [
        {
            id: 0,
            value: '',
            info: '',
            diameter: null,
            length: null,
        },
    ] as any[],
    [SETTING_KEYS.gmdnDefinition]: '',
    [SETTING_KEYS.type]: '',
    [SETTING_KEYS.bodyPlacement]: [''],
    [SETTING_KEYS.notes]: '',
    [SETTING_KEYS.customFields]: [] as CustomField[],
};

export const initConditionals = {
    [SETTING_KEYS.addInfo]: '',
    [SETTING_KEYS.artefacts]: [] as ArtifactDTO[],
    [SETTING_KEYS.brms]: '',
    [SETTING_KEYS.coilType]: '',
    [SETTING_KEYS.mriArc]: '',
    [SETTING_KEYS.fieldStrength]: [] as any[],
    [SETTING_KEYS.spatialGradient]: { gradients: [] } as SpatialGradientDTO,
    [SETTING_KEYS.slewRate]: {} as SlewDTO,
    [SETTING_KEYS.sar]: [] as SARDTO[],
    [SETTING_KEYS.temp]: [] as TempDTO[],
    [SETTING_KEYS.prep]: '',
    [SETTING_KEYS.waitingPeriod]: {} as WaintingPeriodDTO,
};

type UpdateAction = {
    type:
        | 'UPDATE'
        | 'RESET'
        | 'BULK_UPDATE'
        | 'ADD_CUSTOM_FIELD'
        | 'UPDATE_CUSTOM_FIELD';
    name?: string;
    value?: any;
    payload?: any;
};

type ImplantContextType = {
    file: File | null;
    setFile: Dispatch<File | null>;
    fileName: string | null;
    setFileName: Dispatch<string | null>;
    info: any;
    dispatchInfo: Dispatch<UpdateAction>;
    conditionals: any;
    dispatchConditionals: Dispatch<UpdateAction>;
    sourceSelected: boolean;
    setSourceSelected: Dispatch<boolean>;
    usesExistingFile: boolean;
    setUsesExistingFile: Dispatch<boolean>;
    allFiles: string[];
    setAllFiles: Dispatch<string[]>;
    runPDFExtractor: boolean;
    setRunPDFExtractor: Dispatch<boolean>;
};

const defaultContextValue: ImplantContextType = {
    file: null,
    setFile: () => {},
    fileName: null,
    setFileName: () => {},
    info: initialInfo,
    dispatchInfo: () => {},
    conditionals: initConditionals,
    dispatchConditionals: () => {},
    sourceSelected: false,
    setSourceSelected: () => {},
    usesExistingFile: false,
    setUsesExistingFile: () => {},
    allFiles: [],
    setAllFiles: () => {},
    runPDFExtractor: true,
    setRunPDFExtractor: () => {},
};

export const infoReducer = (state: any, action: UpdateAction) => {
    switch (action.type) {
        case 'UPDATE':
            const { name, value } = action.payload;
            return {
                ...state,
                [name]: value,
            };
        case 'BULK_UPDATE':
            return {
                ...action.payload,
            };
        case 'RESET':
            return initialInfo;

        case 'ADD_CUSTOM_FIELD':
            return {
                ...state,
                [SETTING_KEYS.customFields]: [
                    ...(state[SETTING_KEYS.customFields] || []),
                    { heading: '', value: '' },
                ],
            };

        case 'UPDATE_CUSTOM_FIELD':
            const { index, field } = action.payload;
            const updatedCustomFields = state[SETTING_KEYS.customFields].map(
                (currentField: CustomField, currentIndex: number) => {
                    if (currentIndex === index) {
                        return field;
                    }
                    return currentField;
                }
            );
            return {
                ...state,
                [SETTING_KEYS.customFields]: updatedCustomFields,
            };

        default:
            return state;
    }
};

export const conditionalsReducer = (state: any, action: UpdateAction) => {
    switch (action.type) {
        case 'UPDATE':
            const { name, value } = action.payload;
            return {
                ...state,
                [name]: value,
            };
        case 'BULK_UPDATE':
            return {
                ...action.payload,
            };
        case 'RESET':
            return initConditionals;
        default:
            return state;
    }
};

const ImplantContext = createContext<ImplantContextType>(defaultContextValue);

export const ImplantProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [file, setFile] = useState<File | null>(null);
    const [fileName, setFileName] = useState<string | null>(null);
    const [conditionals, dispatchConditionals] = useReducer(
        conditionalsReducer,
        initConditionals
    );
    const [info, dispatchInfo] = useReducer(infoReducer, initialInfo);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(String);
    const [sourceSelected, setSourceSelected] = useState(false);
    const [usesExistingFile, setUsesExistingFile] = useState(false);
    const [allFiles, setAllFiles] = useState<string[]>([]);
    const [runPDFExtractor, setRunPDFExtractor] = useState(false);

    const getData = async () => {
        setIsLoading(true);
        try {
            const files = await listSourceFiles();
            setAllFiles(files);
        } catch (error) {
            setError('Error fetching data');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const value = {
        file,
        setFile,
        fileName,
        setFileName,
        conditionals,
        dispatchConditionals,
        info,
        dispatchInfo,
        isLoading,
        setIsLoading,
        error,
        setError,
        sourceSelected,
        setSourceSelected,
        usesExistingFile,
        setUsesExistingFile,
        allFiles,
        setAllFiles,
        runPDFExtractor,
        setRunPDFExtractor,
    };

    return (
        <ImplantContext.Provider value={value}>
            {children}
        </ImplantContext.Provider>
    );
};

export const useImplantContext = () => useContext(ImplantContext);
