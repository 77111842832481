import { useEffect, useState } from 'react';
import styles from './NewImplantBadge.module.css';

interface NewImplantBadgeProps {
    createdAt: Date | string;
}

const NewImplantBadge: React.FC<NewImplantBadgeProps> = ({ createdAt }) => {
    const [showBadge, setShowBadge] = useState(false);

    useEffect(() => {
        //prevent error if createdAt is a string, if it's a string, it convert it to a Date object.
        const createdAtDate =
            typeof createdAt === 'string' ? new Date(createdAt) : createdAt;
        //checks if createdAtDate is a valid Date object and if its time (getTime()) is a valid number
        if (createdAtDate instanceof Date && !isNaN(createdAtDate.getTime())) {
            const currentDate = new Date();
            const elapsedTime = currentDate.getTime() - createdAtDate.getTime();
            const elapsedDays = Math.floor(elapsedTime / (1000 * 60 * 60 * 24));
            setShowBadge(elapsedDays <= 30);
        } else {
            setShowBadge(false);
        }
    }, [createdAt]);

    return showBadge ? (
        <div className={styles.icon}>
            <span>NEW</span>
        </div>
    ) : null;
};

export default NewImplantBadge;
