import React, { useState } from 'react';

import Button, { ButtonType } from '../general-components/Button';

import styles from './EditLicenses.module.css';
import Modal from '../manage-user/Modal';
import { updateLicenses } from '../../services/apiService';
import FormInput from './FormInput';

const EditLicenses: React.FC<{
    close: () => void;
    /*  setLicenses: any; */
    deptId: string;
}> = ({ close, deptId }) => {
    const SaveNumber = () => {
        updateLicenses(deptId, parseInt(number));
        window.location.reload();
    };
    const [number, setNumber] = useState<string>('');
    return (
        <React.Fragment>
            <Modal
                info={{
                    title: 'Edit Licenses',
                    message:
                        'Please assign maximum number of active users for this department',
                }}
                updateView={close}
            >
                <div className={styles.wrapper}>
                    <form>
                        <FormInput
                            label="Number of active users"
                            type="number"
                            errorMsg={''}
                            onChange={(e: any) => {
                                setNumber(e.target.value);
                            }}
                        />
                        <Button
                            inline
                            key={'Save'}
                            type={ButtonType.primary}
                            onClick={SaveNumber}
                        >
                            Save
                        </Button>
                    </form>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default EditLicenses;
