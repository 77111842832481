import {
    WaintingPeriodDTO,
    SpatialGradientDTO,
    SARDTO,
    ArtifactDTO,
    TempDTO,
} from '../models/ConditionalsModels';

export interface ProcessedData {
    mriStatus: number;
    statusMessage?: string;
    conditionals: {
        artifact: ArtifactDTO[];
        fieldStrength: number[];
        spatialGradient: SpatialGradientDTO;
        temperature: TempDTO[];
        sar: SARDTO[];
        immediateScanning: WaintingPeriodDTO;
    };
    autofilledFields: AutofilledFieldsType;
}

export type AutofilledFieldsKeys =
    | 'status'
    | 'spatialGradient'
    | 'artefacts'
    | 'SAR'
    | 'fieldStrengths'
    | 'temperature'
    | 'tempCondition'
    | 'operatingMode'
    | 'pulseSequence'
    | 'mrSystem';

export type AutofilledFieldsType = {
    [key in AutofilledFieldsKeys]?: boolean;
};

const isEmptyData = (obj: any) => {
    const result = Object.entries(obj).every(([key, value]) => {
        const isFieldEmpty =
            key === 'immediateScanningPostImplantation' ||
            value === null ||
            value === '';
        return isFieldEmpty;
    });
    return result;
};

const processArtifactFields = (artifactData: any): ArtifactDTO[] => {
    let mrSystemValue: number | null = null;
    if (artifactData === null) return [];
    if ([1.5, 3, 7].includes(artifactData[2])) {
        mrSystemValue = artifactData[2];
    }

    let pulseSequenceValue = '';
    if (
        artifactData[1]?.includes('spin echo') &&
        artifactData[1]?.includes('gradient echo')
    ) {
        pulseSequenceValue = 'Both gradient and spin echo were returned';
    } else if (artifactData[1]?.includes('spin echo')) {
        pulseSequenceValue = 'Spin echo';
    } else if (artifactData[1]?.includes('gradient echo')) {
        pulseSequenceValue = 'Gradient echo';
    }

    return [
        {
            distance: artifactData[0] ?? null,
            mrSystem: mrSystemValue,
            pulseSequence: pulseSequenceValue,
            condition: '',
        },
    ];
};

const processSpatialGradientFields = (
    spatialGradientData: any[]
): SpatialGradientDTO => {
    if (!spatialGradientData || spatialGradientData.length === 0) {
        return { text: '', gradients: [] };
    }

    const gradientsArray = spatialGradientData.map((item) => {
        return {
            value: Array.isArray(item) ? parseFloat(item[0]) : parseFloat(item),
            condition: Array.isArray(item) && item[1] ? item[1] : '',
        };
    });

    return {
        text: '',
        gradients: gradientsArray,
    };
};

const processTemperatureFields = (temperatureData: any): TempDTO[] => {
    if (!temperatureData || temperatureData.length === 0) {
        return [];
    }

    const extractedTemp = extractTemperatureFromString(temperatureData[1]);
    const maxRise =
        typeof temperatureData[0] === 'number'
            ? temperatureData[0]
            : extractedTemp;

    return [
        {
            maxRise: maxRise ?? 0,
            condition: temperatureData[1] ?? '',
        },
    ];
};

const extractTemperatureFromString = (inputString: string): number | null => {
    const match = inputString.match(/(\d+(\.\d+)?)/);
    return match ? parseFloat(match[0]) : null;
};

const processSARFields = (sarData: any): SARDTO[] => {
    if (sarData === null) return [];
    const sarText = sarData[1]?.toLowerCase() ?? '';
    let operatingMode = '';
    if (sarText.includes('normal operating mode')) {
        operatingMode = 'Normal operating mode';
    } else if (
        sarText.includes('controlled operating mode') ||
        sarText.includes('first level controlled mode')
    ) {
        operatingMode = 'First Level Controlled Mode';
    }

    return [
        {
            value: sarData[0]?.toString() ?? '',
            operatingMode: operatingMode,
            condition: '',
        },
    ];
};

export const processData = (data: any): ProcessedData => {
    let mriStatus = data.MRIStatus;
    let statusMessage: string | undefined;
    let autofilledFields: AutofilledFieldsType = {};

    if (mriStatus === null && !isEmptyData(data)) {
        mriStatus = 2;
    } else if (mriStatus === 3 && !isEmptyData(data)) {
        mriStatus = 2;
        statusMessage =
            "MRI status changed from 'Safe' to 'Conditional' due to the presence of conditional data.";
    }

    const artifact = processArtifactFields(data.artifact);
    autofilledFields.artefacts = artifact.length > 0;
    autofilledFields.pulseSequence = artifact.some(
        (a) => a.pulseSequence !== null
    );
    autofilledFields.mrSystem = artifact.some((a) => a.mrSystem !== null);

    const fieldStrength = data.fieldStrength || [];
    autofilledFields.fieldStrengths = fieldStrength.length > 0;

    const spatialGradient = processSpatialGradientFields(data.spatialGradient);
    autofilledFields.spatialGradient = spatialGradient.gradients.length > 0;

    const temperature = processTemperatureFields(data.temperature);
    autofilledFields.temperature = temperature.length > 0;
    autofilledFields.tempCondition = temperature.some(
        (t) => t.condition !== ''
    );

    const sar = processSARFields(data.SAR);
    autofilledFields.SAR = sar.length > 0;
    autofilledFields.operatingMode = sar.some((s) => s.operatingMode !== '');

    const immediateScanning = data.immediateScanningPostImplantation
        ? {
              immediateScan: data.immediateScanningPostImplantation,
              weeksAfterScan: 0,
          }
        : { immediateScan: false, weeksAfterScan: 0 };
    autofilledFields.status = mriStatus !== null;

    return {
        mriStatus,
        statusMessage,
        conditionals: {
            artifact,
            fieldStrength,
            spatialGradient,
            temperature,
            sar,
            immediateScanning,
        },
        autofilledFields,
    };
};
