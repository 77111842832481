import React, { useEffect, useState } from 'react';
import { getInternalProtocolById } from '../../services/apiService';
import ProtocolComponent from './ProtocolComponent';
import styles from './ProtocolPage.module.css';
import EditLog from './EditLog/EditLog';
import AddToGroupButton from '../group/AddToGroupButton';

const ProtocolPage: React.FC<{ id: string; isPreview?: boolean }> = ({
    id,
    isPreview = false,
}) => {
    const [info, setInfo] = useState<any>();
    const [conditionals, setConditionals] = useState();

    const getData = async () => {
        const res = await getInternalProtocolById(id);
        setInfo(res.info);
        setConditionals(res.conditionals);
    };

    useEffect(() => {
        getData();
    }, []);

    if (isPreview) {
        return <ProtocolComponent info={info} conditionals={conditionals} />;
    }

    return (
        <div className={styles.grid}>
            <div>
                <AddToGroupButton type="protocol" id={id} />
            </div>
            <div className={styles.protocol}>
                <ProtocolComponent info={info} conditionals={conditionals} />
            </div>
            {info?.editLogProtocol !== undefined &&
                info.editLogProtocol.length > 0 && (
                    <div className={styles.logContainer}>
                        <EditLog
                            title="Latest edit"
                            log={info.editLogProtocol}
                        />
                    </div>
                )}
        </div>
    );
};

export default ProtocolPage;
