import React, { useReducer } from 'react';
import styles from './SignupLayout.module.css';
import breath from '../../images/breath.png';



const SignupLayout: React.FC<{children: React.ReactNode}> = ({children}) => {

    return (
        <div className={styles.wrapper}>
            <div className={styles.heart}>
                    <img src={breath} alt="breath" />
                    {/* <Heart />*/}
                </div>
            <div className={styles['signup-box']}>
                {children}
            </div>
        </div>
    )
}

export default SignupLayout;