import React, { ChangeEvent, ReactNode, useReducer, useState } from 'react';
import { sendInvitationReminders } from '../../services/apiService';
import Button, { ButtonType } from '../general-components/Button';
import Modal, { ModalInfo } from '../manage-user/Modal';
import styles from './SendReminders.module.css';
import FormInput from '../general-components/FormInput';
import {
    formReducer,
    errorReducer,
    camelCaseToSentence,
} from '../../utils/utils';

const initialFormData = {
    title: '',
    news: '',
};

// initialErrorData includes only required fields
const initialErrorData = { title: '', news: '' };

const SendReminders: React.FC<{
    infos: { email: string; id: string }[];
    children: ReactNode;
}> = ({ infos, children }) => {
    const [info, setInfo] = useState({
        title: 'Send sign up reminders',
        message: 'Please provide a title for the email and a quick news report',
    });

    const [showModal, setShowModal] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [reminder, setReminder] = useReducer(formReducer, initialFormData);
    const [errorData, setErrorData] = useReducer(
        errorReducer,
        initialErrorData
    );
    // Define a list of required fields in the form
    const requiredFields = Object.keys(initialErrorData);

    const handleInputChange = (
        e: ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
    ) => {
        const { name, value } = e.target;

        setReminder({
            type: 'update',
            name,
            value,
        });
        requiredFields.includes(name) &&
            setErrorData({ type: 'update', name, value: '' });
        console.log('reminder', reminder);
        console.log('errorData', errorData);
    };

    const submit = async () => {
        let allChecksPassed = false;
        Object.entries(reminder).forEach(([key, value]) => {
            if (requiredFields.includes(key) && value === '') {
                setErrorData({
                    type: 'update',
                    name: key,
                    value: `Please fill ${camelCaseToSentence(key)}`,
                });
            }
        });

        allChecksPassed = Object.entries(reminder)
            .filter(([key]) => requiredFields.includes(key))
            .every(([key, value]) => value !== '');

        if (allChecksPassed) {
            const res = await sendInvitationReminders(
                infos,
                reminder.title,
                reminder.news
            );
            if (res) {
                setSubmitted(true);
                setInfo({
                    title: 'Reminders have been sent',
                    message: '',
                });
            }
        }
    };

    return (
        <React.Fragment>
            <Button
                type={
                    infos.length > 1 ? ButtonType.primary : ButtonType.tietary
                }
                onClick={() => {
                    setShowModal(true);
                }}
            >
                {children}
            </Button>
            {showModal && (
                <Modal
                    updateView={() => {
                        setShowModal(false);
                    }}
                    info={info}
                >
                    {!submitted && (
                        <form className={styles.form}>
                            <FormInput
                                name="title"
                                type="text"
                                placeholder="title"
                                onChange={handleInputChange}
                                errorMsg={errorData.title}
                            />
                            <FormInput
                                name="news"
                                placeholder="news"
                                tag="textArea"
                                className="textArea"
                                onChange={handleInputChange}
                                errorMsg={errorData.news}
                            />
                            <Button type={ButtonType.submit} onClick={submit}>
                                Send
                            </Button>
                        </form>
                    )}
                    {submitted && (
                        <Button
                            type={ButtonType.primary}
                            onClick={() => {
                                setShowModal(false);
                                setSubmitted(false);
                                setReminder({
                                    type: 'reset',
                                    initialFormData,
                                });
                                setErrorData({
                                    type: 'reset',
                                    initialErrorData,
                                });
                            }}
                        >
                            Back to overview
                        </Button>
                    )}
                </Modal>
            )}
        </React.Fragment>
    );
};

export default SendReminders;
