import { useEffect, useState, useContext } from 'react';
import { getAllMRIMachinesByDepartment } from '../../services/apiService';
import AuthContext from '../../store/auth-context';
import RIGHTS_KEYS from '../../constants/rights-keys';
import LoadingRing from '../ui-components/LoadingRing';
import OverlayWindow from '../ui-components/OverlayWindow';
import AddMRIMachine from './AddMRIMachine';
import MRIMachine from './MRIMachine';
import Button, { ButtonType } from '../general-components/Button';
import DeleteMRIMachine from './DeleteMRIMachine';
import styles from './RoomOverview.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';

const RoomOverview = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [machinesList, setMachinesList] = useState([]);
    const [showAddMachine, setShowAddMachine] = useState(false);
    const [showDeleteMachine, setShowDeleteMachine] = useState(false);
    const [machineToDelete, setMachineToDelete] = useState({});
    const [showEditMachine, setShowEditMachine] = useState(false);
    const [machineIdToEdit, setMachineIdToEdit] = useState('');
    const context = useContext(AuthContext);

    const getData = async () => {
        setIsLoading(true);
        const res = await getAllMRIMachinesByDepartment();
        setMachinesList(res);
        setIsLoading(false);
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className={styles.wrapper}>
            <div className={styles.titleWrapper}>
                <h1>Room overview</h1>
                {context.orRights([RIGHTS_KEYS.manageMRIOverview]) && (
                    <Button
                        className={styles.addButton}
                        onClick={() => setShowAddMachine(true)}
                        type={ButtonType.secondary}
                    >
                        <FontAwesomeIcon icon={faPlusSquare} />
                        Add MRIMachine
                    </Button>
                )}
            </div>

            {showDeleteMachine && (
                <DeleteMRIMachine
                    machine={machineToDelete}
                    onClose={() => {
                        setShowDeleteMachine(false);
                        getData();
                    }}
                />
            )}
            {showAddMachine && (
                <OverlayWindow
                    close={() => {
                        setShowAddMachine(false);
                        getData();
                    }}
                >
                    <AddMRIMachine />
                </OverlayWindow>
            )}
            {showEditMachine && (
                <OverlayWindow
                    close={() => {
                        setShowEditMachine(false);
                        getData();
                    }}
                >
                    <MRIMachine machineId={machineIdToEdit} />
                </OverlayWindow>
            )}
            {!isLoading && machinesList.length === 0 && (
                <h3>No MRIMachines found</h3>
            )}
            {isLoading && (
                <div className={styles.loading}>
                    <LoadingRing size="large" />
                </div>
            )}
            {machinesList?.map((machine) => (
                <div className={styles.roomItem} key={machine._id}>
                    <h3>
                        {machine.Name}
                        {context.orRights([RIGHTS_KEYS.manageMRIOverview]) && (
                            <>
                                <Button
                                    onClick={() => {
                                        setShowEditMachine(true);
                                        setMachineIdToEdit(machine._id);
                                    }}
                                    type={ButtonType.tietary}
                                    small={true}
                                >
                                    Edit
                                </Button>
                                <Button
                                    onClick={() => {
                                        setShowDeleteMachine(true);
                                        setMachineToDelete(machine);
                                    }}
                                    type={ButtonType.cancel}
                                    small={true}
                                >
                                    Delete
                                </Button>
                            </>
                        )}
                    </h3>
                    <p>{machine.Model}</p>
                    {machine?.FieldStrength && (
                        <div className={styles.rowWrapper}>
                            <div>Static magnetic field</div>
                            <div>{machine.FieldStrength} Tesla</div>
                        </div>
                    )}

                    {machine?.SpatialGradient.map((sGradient: any, i: any) => {
                        return (
                            <div
                                className={styles.rowWrapper}
                                key={sGradient + i}
                            >
                                <div>
                                    Spatial gradient (Maximum spatial gradient)
                                    - Diameter: {sGradient.diameter} cm
                                </div>
                                <div>
                                    {sGradient.value} T/m(
                                    {sGradient.value * 100} Gauss/cm)
                                </div>
                            </div>
                        );
                    })}
                    {machine?.SlewRatePerAxis && (
                        <div className={styles.rowWrapper}>
                            <div>Maximum slew rate (per axis)</div>
                            <div>{machine.SlewRatePerAxis} T/m/s</div>
                        </div>
                    )}
                    {machine?.MaxSlewRate && (
                        <div className={styles.rowWrapper}>
                            <div>Maximum effective slew rate</div>
                            <div>{machine.MaxSlewRate} T/m/s</div>
                        </div>
                    )}
                    {machine?.MaxSAR.length ? (
                        <div className={styles.rowWrapper}>
                            <div>Maximum SAR - RF energy disposal</div>
                            <div>
                                {machine.MaxSAR.map((mSar: any, i: any) => {
                                    return (
                                        <div key={mSar + i}>
                                            <span>Level {mSar.level}: </span>
                                            <span>
                                                WholeBody {mSar.wholeBody} W/kg
                                                -
                                            </span>
                                            <span> Head {mSar.head} W/kg</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ) : (
                        ''
                    )}

                    {machine?.MaxSAR.map((mSar: any, i: any) => {})}

                    {machine?.MaxB1rms && (
                        <div className={styles.rowWrapper}>
                            <div>Maximum B1+rms (micro T) - RF exposure</div>
                            <div>{machine.MaxB1rms}</div>
                        </div>
                    )}
                    {machine?.TRCoils && (
                        <div className={styles.rowWrapper}>
                            <div>T/R Coils</div>
                            <div>{machine.TRCoils}</div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default RoomOverview;
