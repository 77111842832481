import React, { ChangeEvent, useReducer, useState } from 'react';
import {
    createAdminInvitation,
    createInvitations,
    createManufacturerAdminInvitation,
} from '../../services/apiService';
import { emailValidate } from '../../services/formValidationService';
import Button, { ButtonType } from '../general-components/Button';
import FormInput from '../general-components/FormInput';
import styles from './AddUser.module.css';
import Modal from './Modal';

import {
    formReducer,
    errorReducer,
    camelCaseToSentence,
} from '../../utils/utils';

const initialFormData = {
    email: '',
};

// initialErrorData includes only required fields
const initialErrorData = { email: '' };

const AddUser: React.FC<{
    deptId?: string;
    manuId?: string;
    isAdmin?: boolean;
    close: () => void;
    onSubmit: () => void;
}> = ({ deptId, manuId, isAdmin, close, onSubmit }) => {
    const [sending, setSending] = useState(false);
    const [sent, setSent] = useState(false);
    const [emails, setEmails] = useReducer(formReducer, initialFormData);
    const [errorData, setErrorData] = useReducer(
        errorReducer,
        initialErrorData
    );

    const requiredFields = Object.keys(initialErrorData);

    const handleInputChange = (
        e: ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
    ) => {
        const { name, value } = e.target;

        setEmails({
            type: 'update',
            name,
            value,
        });
        requiredFields.includes(name) &&
            setErrorData({ type: 'update', name, value: '' });
    };

    const submit = async (e: any) => {
        e.preventDefault();
        let allChecksPassed = false;

        const emailArr = emails.email
            .trim()
            .split(',')
            .map((e: string) => e.trim());

        for (let e of emailArr) {
            if (e.length === 0) {
                continue;
            }
            const ev = emailValidate(e);
            if (!ev.valid) {
                setErrorData({
                    type: 'update',
                    name: 'email',
                    value: 'invalid email',
                });
                return;
            }
        }

        Object.entries(emails).forEach(([key, value]) => {
            if (requiredFields.includes(key) && value === '') {
                setErrorData({
                    type: 'update',
                    name: key,
                    value: `Please fill ${camelCaseToSentence(key)}`,
                });
            }
        });

        allChecksPassed = Object.entries(emails)
            .filter(([key]) => requiredFields.includes(key))
            .every(([key, value]) => value !== '');

        if (allChecksPassed) {
            setSending(true);
            let success = false;

            if (isAdmin && (deptId || manuId)) {
                if (deptId) {
                    success = await createAdminInvitation(emailArr[0], deptId);
                } else if (manuId) {
                    success = await createManufacturerAdminInvitation(
                        emailArr[0],
                        manuId
                    );
                }
            } else {
                success = await createInvitations(emailArr, isAdmin);
            }
            if (success) {
                onSubmit();
                setSending(false);
                setSent(true);
            }
        }
    };

    return (
        <React.Fragment>
            {sent && (
                <Modal
                    info={{
                        title: 'Invitations have been sent',
                        message:
                            'Your colleagues will now receive an email with a link to sign up.',
                    }}
                    updateView={close}
                >
                    <Button type={ButtonType.secondary} onClick={close}>
                        Close
                    </Button>
                </Modal>
            )}
            {!sent && (
                <Modal
                    info={{
                        title: `Invite  ${isAdmin ? 'Admins' : 'Users'}`,
                        message:
                            'Provide emails (seperated by comma) of your colleagues to invite them to the platform',
                    }}
                    updateView={close}
                >
                    <div className={styles.wrapper}>
                        <form onSubmit={submit}>
                            <input type="submit" hidden />
                            <FormInput
                                className={styles.emails}
                                name="email"
                                onChange={handleInputChange}
                                type="text"
                                errorMsg={errorData.email}
                                label="Emails"
                                placeholder="john@hospital.com, sarah@corporation.com"
                            />
                            <Button
                                inline
                                type={ButtonType.submit}
                                loading={sending}
                                onClick={submit}
                            >
                                Send
                            </Button>
                        </form>
                    </div>
                </Modal>
            )}
        </React.Fragment>
    );
};

export default AddUser;
