import CloseIcon from '@mui/icons-material/Close';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button, { ButtonType } from '../general-components/Button';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import styles from '../edit-implant/EditImplant.module.css';

const SAROperatingMode = {
    Unknown: '',
    'Normal operating mode': 'Normal operating mode',
    'First Level Controlled Mode': 'First Level Controlled Mode',
};

const SpecificAbsorptionRate = ({
    d,
    value,
    autofilledFields,
    showWarningIcon,
    onUpdateConditionalField,
    onResetHighlights,
    onSetAutoFilledFields,
    onSetShowWarningIcon,
}: {
    d: string;
    value: any;
    autofilledFields?: any;
    showWarningIcon: any;
    onUpdateConditionalField: Function;
    onResetHighlights: Function;
    onSetAutoFilledFields: Function;
    onSetShowWarningIcon: Function;
}) => {
    return (
        <div>
            {value?.map((sar: any, index: number) => (
                <div className={styles['conditional-inputs']} key={sar + index}>
                    <div className={styles.conditionalGroup}>
                        <div className={styles['inline-inputs']}>
                            <input
                                type="number"
                                defaultValue={sar?.value}
                                placeholder="SAR(W/kg)"
                                style={
                                    autofilledFields.SAR
                                        ? {
                                              background: 'rgb(128, 128, 128)',
                                              border: '1px solid rgb(78, 165, 217)',
                                          }
                                        : {}
                                }
                                step="any"
                                onChange={(e) => {
                                    let sarNew = value;
                                    sarNew[index].value = Number(
                                        e.target.value
                                    );
                                    onUpdateConditionalField(d, sarNew);
                                    onResetHighlights(['SAR']);
                                }}
                            />
                            <select
                                defaultValue={sar?.operatingMode}
                                style={
                                    autofilledFields.operatingMode
                                        ? {
                                              background: 'rgb(128, 128, 128)',
                                              border: '1px solid rgb(78, 165, 217)',
                                              color: 'white',
                                          }
                                        : {}
                                }
                                onChange={(e) => {
                                    let sarNew = value;
                                    sarNew[index].operatingMode =
                                        e.target.value;
                                    onUpdateConditionalField(d, sarNew);
                                    onResetHighlights(['operatingMode']);
                                }}
                            >
                                {Object.entries(SAROperatingMode).map(
                                    ([key, value], index) => (
                                        <option key={index} value={value}>
                                            {key}
                                        </option>
                                    )
                                )}
                            </select>

                            <input
                                type="number"
                                value={sar?.scanningTime}
                                placeholder="Scanning Time"
                                onChange={(e) => {
                                    let sarNew = value;
                                    sarNew[index].scanningTime = e.target.value;
                                    onUpdateConditionalField(d, sarNew);
                                }}
                            />
                        </div>
                        <input
                            type="text"
                            value={sar?.condition}
                            placeholder="Condition"
                            onChange={(e) => {
                                let sarNew = value;
                                sarNew[index].condition = e.target.value;
                                onUpdateConditionalField(d, sarNew);
                            }}
                        />
                    </div>

                    <Button
                        type={ButtonType.cancel}
                        onClick={() => {
                            let sarDel = [...value];
                            sarDel.splice(index, 1);
                            onUpdateConditionalField(d, sarDel);
                            onResetHighlights(['SAR', 'operatingMode']);
                        }}
                    >
                        <CloseIcon />
                    </Button>
                </div>
            ))}
            <Button
                type={ButtonType.primary}
                onClick={() => {
                    onUpdateConditionalField(d, [
                        ...value,
                        {
                            value: '',
                            operatingMode: '',
                            condition: '',
                        },
                    ]);
                }}
            >
                <FontAwesomeIcon icon={faPlus} />
            </Button>
        </div>
    );
};

export { SpecificAbsorptionRate as SAR };
