import { ReactNode } from 'react';
import styles from './FileUpload.module.css';

const FileUpload: React.FC<{
    children?: ReactNode;
    small?: boolean;
    big?: boolean;
    className?: string;
    filesChange: Function;
    image?: boolean;
    video?: boolean;
    pdf?: boolean;
}> = ({ children, className, small, big, filesChange, image, video, pdf }) => {
    const onChange = (e: any) => {
        filesChange(e.target.files[0]);
    };
    let smallStyle = '';

    if (small) {
        smallStyle = styles.small;
    }
    if (big) {
        smallStyle = styles.big;
    }
    const defineFileFormat = (video?: boolean, image?: boolean, pdf?: boolean) => {
        if (video) {
            return { accept: 'video/mp4, webm' };
        }
        if (image) {
            return { accept: 'image/png, jpeg' };
        }
        if (pdf) {
            return { accept: 'application/pdf' };
        }
        return '';
    };
    return (
        <div
            className={`${styles.wrapper} ${
                className !== undefined ? className : ''
            }`}
        >
            <label className={`${styles.file} ${smallStyle}`}>
                <input
                    onChange={onChange}
                    type="file"
                    {...defineFileFormat(video, image, pdf)}
                />
                {children}
            </label>
        </div>
    );
};

export default FileUpload;
