import styles from './VideoModal.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const VideoModal: React.FC<{
    url: string;
    title: string;
    close: Function;
}> = ({ url, title, close }) => {
    return (
        <div className={styles.videoModal}>
            <div className={styles.videoFlexRow}>
                <h2>{title}</h2>
                <div className={styles.videoClose} onClick={() => close()}>
                    <FontAwesomeIcon icon={faTimes} />
                </div>
            </div>
            <video controls autoPlay width="100%">
                <source src={url} type="video/mp4" />
                Sorry, your browser doesn't support embedded videos.
            </video>
        </div>
    );
};
export default VideoModal;
