import { useState, useEffect, useContext } from 'react';
import {
    getInternalManagedProtocols,
    verifyInternalProtocol,
} from '../../services/apiService';
import Button, { ButtonType } from '../general-components/Button';
import AuthContext from '../../store/auth-context';
import {
    faBoxArchive,
    faClipboardCheck,
    faNotesMedical,
} from '@fortawesome/free-solid-svg-icons';
import {
    faCircleCheck,
    faNoteSticky,
} from '@fortawesome/free-regular-svg-icons';
import styles from './InternalProtocolList.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InternalProtocolListHeader from './InternalProtocolListHeader';
import InternalProtocolListRow from './InternalProtocolListRow';
import OverlayWindow from '../ui-components/OverlayWindow';
import EditInternalProtocol from './EditInternalProtocol';
import Modal from '../manage-user/Modal';
import ProtocolPage from '../protocols/ProtocolPage';
import VerificationModal from '../general/modal/VerificationModal';

const InternalProtocolList = ({}: {}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [department, setDepartment] = useState({ _id: '', name: '' });
    const [userInfo, setUserInfo] = useState<any>();
    const [tabStatus, setTabStatus] = useState('Completed');
    const [sortStatus, setSortStatus] = useState(['none', '']);
    const context = useContext(AuthContext);
    const [isEdit, setIsEdit] = useState({ edit: false, id: '' });
    const editStatuses = ['Drafts', 'Completed', 'Verified', 'Archived'];

    const [latestEditComment, setLatestEditComment] = useState('');

    const [drafts, setDrafts] = useState([]);
    const [completed, setCompleted] = useState([]);
    const [verified, setVerified] = useState([]);
    const [archived, setArchived] = useState([]);

    // useEffect(() => {

    useEffect(() => {
        const setDepartmentName = async () => {
            setDepartment(context.getContext().department);
        };
        setDepartmentName();
    }, []);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const list = await getInternalManagedProtocols();
            setDrafts(list.drafts);
            setCompleted(list.completed);
            setVerified(list.verified);
            setArchived(list.archived);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const u = await context.getAllInfo();
                setUserInfo(u.user);
            } catch (err) {
                console.log(err);
            }
        };
        fetchUserInfo();
    }, []);

    const iconFromStatus = (status: string) => {
        if (status === 'Drafts') return faNoteSticky;
        if (status === 'Completed') return faClipboardCheck;
        if (status === 'Verified') return faCircleCheck;
        if (status === 'Archived') return faBoxArchive;
    };

    const [verifyWindow, setVerfiyWindow] = useState('');

    const verifyProtocol = async (id: string) => {
        await verifyInternalProtocol(id);
        setVerfiyWindow('');
        fetchData();
    };

    const getCommentFromId = (id: string) => {
        const comment = completed.filter((p: any) => p._id.toString() === id)[0]
            ?.latestComment;
        return comment && comment !== 'Protocol Creation'
            ? 'Latest Edit: ' + comment
            : '';
    };

    return (
        <div className={styles.wrapper}>
            {verifyWindow !== '' && (
                <VerificationModal
                    onClose={() => setVerfiyWindow('')}
                    onVerify={() => {
                        verifyProtocol(verifyWindow);
                    }}
                    comment={`${getCommentFromId(verifyWindow)}`}
                >
                    <div className={styles.preview}>
                        <ProtocolPage isPreview={true} id={verifyWindow} />
                    </div>
                </VerificationModal>
            )}
            <div className={styles.protocols}>
                <h1>Internal Protocols for {department.name}</h1>
                <div className={styles.tabs}>
                    {editStatuses.map((status) => (
                        <button
                            name={status}
                            className={`${styles['status-button']} ${
                                tabStatus === status
                                    ? styles['active-button']
                                    : ''
                            }`}
                            onClick={(e: any) =>
                                setTabStatus(
                                    (e.target as HTMLButtonElement).name
                                )
                            }
                        >
                            <FontAwesomeIcon
                                icon={iconFromStatus(status)}
                                color="var(--main-white)"
                            />
                            {status}
                        </button>
                    ))}
                    <Button
                        className={styles.add}
                        type={ButtonType.submit}
                        onClick={() => {
                            window.location.href = '/protocols/new';
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faNotesMedical}
                            color="var(--main-white)"
                        />
                        New Protocol
                    </Button>
                </div>
                <div className={styles['internal-protocols-list']}>
                    <div className={styles['results']}>
                        <table className={styles.list}>
                            <InternalProtocolListHeader
                                sortStatus={sortStatus}
                            />
                            {tabStatus === 'Drafts' && (
                                <tbody>
                                    {drafts.map((protocol: any) => (
                                        <InternalProtocolListRow
                                            onVerify={(id: string) => {
                                                setVerfiyWindow(id);
                                            }}
                                            protocol={protocol}
                                            buttonStyle={
                                                styles['inline-button']
                                            }
                                            onEdit={setIsEdit}
                                        />
                                    ))}
                                </tbody>
                            )}
                            {tabStatus === 'Verified' && (
                                <tbody>
                                    {verified.map((protocol: any) => (
                                        <InternalProtocolListRow
                                            onVerify={(id: string) => {
                                                setVerfiyWindow(id);
                                            }}
                                            protocol={protocol}
                                            buttonStyle={
                                                styles['inline-button']
                                            }
                                            onEdit={setIsEdit}
                                        />
                                    ))}
                                </tbody>
                            )}
                            {tabStatus === 'Completed' && (
                                <tbody>
                                    {completed.map((protocol: any) => (
                                        <InternalProtocolListRow
                                            onVerify={(id: string) => {
                                                setVerfiyWindow(id);
                                            }}
                                            protocol={protocol}
                                            buttonStyle={
                                                styles['inline-button']
                                            }
                                            onEdit={setIsEdit}
                                        />
                                    ))}
                                </tbody>
                            )}
                            {tabStatus === 'Archived' && (
                                <tbody>
                                    {archived.map((protocol: any) => (
                                        <InternalProtocolListRow
                                            onVerify={(id: string) => {
                                                setVerfiyWindow(id);
                                            }}
                                            protocol={protocol}
                                            buttonStyle={
                                                styles['inline-button']
                                            }
                                            onEdit={setIsEdit}
                                        />
                                    ))}
                                </tbody>
                            )}
                        </table>
                    </div>
                    {isEdit.edit && (
                        <OverlayWindow
                            close={() => setIsEdit({ edit: false, id: '' })}
                        >
                            <EditInternalProtocol
                                id={isEdit.id}
                                isNew={false}
                                onSubmit={() => {
                                    setIsEdit({ edit: false, id: '' });
                                    fetchData();
                                }}
                            />
                        </OverlayWindow>
                    )}
                </div>
            </div>
        </div>
    );
};

export default InternalProtocolList;
