import { useEffect, useRef, useState } from 'react';
import styles from './ImplantImageComponent.module.css';
import { transform } from '@babel/core';

const ImplantImageComponent: React.FC<{ name: string }> = ({ name }) => {
    const imgRef = useRef(null);
    const [style, setStyle] = useState({});

    useEffect(() => {
        if (imgRef.current) {
            const { width, height } = imgRef.current.getBoundingClientRect();
            console.log(width, height);
            if (width > height) {
                setStyle({ height: '100%' });
            } else {
                setStyle({ width: '100%' });
            }
        }
    }, []);

    return (
        <div className={styles.container}>
            <img
                style={style}
                ref={imgRef}
                src={'https://files.nordinsight.com/implant-images/' + name}
                className={styles.image}
                alt=""
            ></img>
        </div>
    );
};

export default ImplantImageComponent;
