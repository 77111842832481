import React, { useState } from 'react';
import FormInput from '../general-components/FormInput';

const HospitalInfoForm: React.FC<{
    errorData: any;
    handleInputChange: any;
}> = ({ handleInputChange, errorData }) => {
    return (
        <>
            <FormInput
                label="Hospital"
                name="hospital"
                type="text"
                placeholder="Name of hospital"
                onChange={handleInputChange}
                errorMsg={errorData.hospital}
            />
            <FormInput
                label="Number of Scanners"
                name="scanners"
                type="number"
                placeholder="Scanners at your department"
                onChange={handleInputChange}
                errorMsg={errorData.scanners}
            />
            <FormInput
                label="Phone Number"
                name="phone"
                type="tel"
                placeholder="Department Phone"
                onChange={handleInputChange}
                errorMsg={errorData.phone}
            />
        </>
    );
};

export default HospitalInfoForm;
