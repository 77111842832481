import { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import VideoCard from './VideoCard';
import styles from './VideoOverview.module.css';
import LoadingRing from '../ui-components/LoadingRing';
import { getPublishedVideos } from '../../services/apiService';
import AuthContext, { StoredContext } from './../../store/auth-context';
import RIGHTS_KEYS from './../../constants/rights-keys.js';

const VideoOverview = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [videoList, setVideoList] = useState([]);
    const context = useContext(AuthContext);
    let ctx: StoredContext = context.getContext();

    const getData = async () => {
        setIsLoading(true);
        const res = await getPublishedVideos();
        setVideoList(res);
        setIsLoading(false);
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className={styles.wrapper}>
            <div className={styles.videoFlexRow}>
                <div>
                    <h1>MRI Safety Insights</h1>
                    <p>
                        Educational video material created in partnership with
                        MRI Safety Specialists{' '}
                    </p>
                </div>
                {ctx.isLoggedIn &&
                    context.orRights([RIGHTS_KEYS.manageVideoUpload]) && (
                        <Link to="/video-upload">
                            <span>Upload Video</span>
                        </Link>
                    )}
            </div>

            {!isLoading && videoList.length === 0 && <h3>No videos found</h3>}
            {isLoading && (
                <div className={styles.loading}>
                    <LoadingRing size="large" />
                </div>
            )}
            {!isLoading && videoList.length !== 0 && (
                <div className={styles.videosWrapper}>
                    {videoList?.map((video) => (
                        <VideoCard key={video._id} video={video} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default VideoOverview;
