import React, { useEffect, useState } from 'react';
import {
    createCommentPermission,
    getActorsWithCommentPermission,
    getDepartmentActors,
} from '../../../services/apiService';
import styles from './Permissions.module.css';
import SelectItem from '../../general-components/SelectItem';

const CommentPermissions = () => {
    const [actors, setActors] = useState([]);
    const [commentPermissions, setCommentPermissions] = useState([]);

    const allActors = async () => {
        const actors = await getDepartmentActors();
        const actorsWithCommentPermission =
            await getActorsWithCommentPermission();
        let actorsCommentPermissionList = [];
        if (actorsWithCommentPermission) {
            actorsCommentPermissionList = actorsWithCommentPermission?.map(
                (p: any) => p._id
            );
        }
        setActors(actors);
        setCommentPermissions(actorsCommentPermissionList);
    };

    const handleCheckboxChange = (actorId: string, checked: boolean) => {
        const updatedPermission = checked
            ? [...commentPermissions, actorId]
            : commentPermissions.filter((id) => id !== actorId);
        createCommentPermission(updatedPermission);
        setCommentPermissions(updatedPermission);
    };

    useEffect(() => {
        allActors();
    }, []);

    return (
        <React.Fragment>
            <div className={styles.container}>
                <h3>Users with access</h3>
                <div className={styles.table}>
                    <div className={styles.row}>
                        <div className={styles.cell}>Comment</div>
                    </div>
                    {actors.map((actor) => (
                        <div key={actor.role._id} className={styles.row}>
                            <div className={styles.cell}>
                                {/* <input
                                    className={styles.comment}
                                    type="checkbox"
                                    checked={commentPermissions.includes(
                                        actor.role._id
                                    )}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) =>
                                        handleCheckboxChange(
                                            actor.role._id,
                                            e.target.checked
                                        )
                                    }
                                /> */}
                                <SelectItem
                                    className={styles.comment}
                                    type="checkbox"
                                    value={actor.role.name}
                                    defaultChecked={commentPermissions.includes(
                                        actor.role._id
                                    )}
                                    onChange={(
                                        value: string,
                                        checked: boolean
                                    ) =>
                                        handleCheckboxChange(
                                            actor.role._id,
                                            checked
                                        )
                                    }
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
};

export default CommentPermissions;
