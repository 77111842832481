import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import styles from './Home.module.css';
import AuthContext, { StoredContext } from '../store/auth-context';
import Modal from './manage-user/Modal';
import Button, { ButtonType } from './general-components/Button';
import SuggestionSearch from './search/suggestion/SuggestionSearch';
import GroupsOverview from './group/GroupsOverview';

const Home = () => {
    const [hasResult, setHasResult] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [semanticEnabled, setSemanticEnabled] = useState(false);

    const context = useContext(AuthContext);
    let ctx: StoredContext = context.getContext();

    const [showTrialPrompt, setShowTrialPrompt] = useState(
        context.showTrialPrompt()
    );

    const closeTrialPrompt = () => {
        setShowTrialPrompt(false);
        context.setShowTrialPrompt(false);
    };

    const search = (term: string, s: boolean) => {
        setSearchTerm(term);
        setSemanticEnabled(s);
    };

    useEffect(() => {
        if (searchTerm.length > 0) {
            setHasResult(true);
        }
    }, [searchTerm]);

    return (
        <React.Fragment>
            {showTrialPrompt && (
                <Modal
                    info={{
                        title: '',
                        message: '',
                    }}
                    updateView={closeTrialPrompt}
                >
                    <div className={styles.trialBox}>
                        {ctx.actor.role !== 'LiteUser' && (
                            <h1>Your trial is almost up!</h1>
                        )}
                        {ctx.actor.role === 'LiteUser' && (
                            <h1>Your trial is up!</h1>
                        )}
                        <h4
                            style={{
                                marginBottom: '30px',
                            }}
                        >
                            Book a call to get a free 2 months extension or an
                            offer for full access to you and your colleagues.
                        </h4>
                        <Button
                            className={styles.trialBtn}
                            onClick={() => {
                                closeTrialPrompt();
                                window
                                    .open(
                                        process.env.REACT_APP_MEETING_LINK,
                                        '_blank'
                                    )
                                    .focus();
                            }}
                            type={ButtonType.submit}
                        >
                            Book a call
                        </Button>
                    </div>
                </Modal>
            )}
            {!hasResult && (
                <div className={styles.wrapper}>
                    <h2>Welcome, Search for Implants</h2>
                    <div className={styles.search}>
                        {/* <Search
                            hasQuickResult={true}
                            onSearch={(term: string, s: boolean) =>
                                search(term, s)
                            }
                        /> */}
                        <SuggestionSearch
                            onSearch={(term: string, s: boolean) =>
                                search(term, s)
                            }
                        />
                    </div>
                    {/* <FrontpagePredefinedList /> */}
                    <Button
                        type={ButtonType.tietary}
                        onClick={() => (window.location.href = '/all')}
                    >
                        See all implants
                    </Button>
                    <GroupsOverview />
                </div>
            )}
            {hasResult && (
                <Navigate
                    to={
                        '/search/query?term=' +
                        searchTerm +
                        (semanticEnabled
                            ? '&useSemantic=' + semanticEnabled
                            : '')
                    }
                />
            )}
        </React.Fragment>
    );
};

export default Home;
