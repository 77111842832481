import styles from './SelectItem.module.css';

const SelectItem: React.FC<{
    labelled?: boolean;
    defaultChecked?: boolean;
    checked?: boolean;
    className?: string;
    type: string;
    name?: string;
    value: string;
    onChange?: (value: string, checked: boolean) => void;
}> = ({
    labelled = true,
    className,
    type,
    defaultChecked,
    checked,
    name,
    value,
    onChange,
}) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(value, e.target.checked);
        }
    };

    const inputProps = checked !== undefined ? { checked } : { defaultChecked };

    return (
        <label
            className={`${className ? className : ''} ${styles.wrapper} ${
                labelled ? 'labelled' : ''
            }`}
        >
            <input
                {...inputProps}
                onChange={handleChange}
                value={value}
                type={type}
                name={name}
            />
            {labelled && <span className={styles.label}>{value}</span>}
            {!labelled && <span></span>}
        </label>
    );
};

export default SelectItem;
