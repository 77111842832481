import React, { ReactChild, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { GuardedElement } from '../general-components/authentication';
import mainStyles from './MainMenuItem.module.css';
import subStyles from './SubMenuItem.module.css';

const AppMenuItem: React.FC<{
    content: ReactChild;
    path: string;
    isHomeScreen?: boolean;
    auth?: boolean | void;
    type?: string;
    hasNotification?: boolean;
    children?: React.ReactNode;
}> = ({
    content,
    children,
    path,
    isHomeScreen = false,
    auth,
    type,
    hasNotification,
}) => {
    let styles = mainStyles;
    if (type === 'sub') {
        styles = subStyles;
    }

    const location = useLocation();
    const isActive =
        path === location.pathname ||
        (isHomeScreen && location.pathname === '/');

    const [clicked, setClicked] = useState(false);

    return (
        <GuardedElement auth={auth}>
            <li className={`${styles.item}`}>
                <NavLink
                    className={
                        isActive ? styles['is-active'] : styles['inactive']
                    }
                    to={path}
                    onClick={() => setClicked(true)}
                >
                    {type !== 'sub' && <span className={styles.active}></span>}
                    {content}
                </NavLink>
                {children}
                {hasNotification && !clicked && (
                    <span className={styles.notif}></span>
                )}
            </li>
        </GuardedElement>
    );
};

export default AppMenuItem;
