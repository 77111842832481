import { useState, useEffect } from 'react';
import ModalLayout from '../modal/ModalLayout';
import styles from './TimedAlert.module.css';
import { ReactComponent as DoneIcon } from '../../../icons/form-submitted.svg';

const TimedAlert: React.FC<{
    type?: 'success' | '';
    message: string;
    duration: number;
    onClose?: () => void;
}> = ({ message, duration, onClose, type = '' }) => {
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        if (message !== '') {
            setShowAlert(true);
            const timer = setTimeout(() => {
                setShowAlert(false);
                onClose && onClose();
            }, duration);

            return () => clearTimeout(timer);
        }
    }, [message, duration, onClose]);

    return (
        <>
            {showAlert && (
                <ModalLayout onClose={onClose} important={true}>
                    <div className={styles.alert}>
                        {type === 'success' && <DoneIcon />}
                        <h3>{message}</h3>
                    </div>
                </ModalLayout>
            )}
        </>
    );
};

export default TimedAlert;
