import SETTING_KEYS from '../../constants/setting-keys';
import RichText from '../general-components/RichText';

import styles from './AdditionalInfo.module.css';

const AdditionalInfo = ({
    conditionals,
    info,
    onUpdateProtocolInfo,
    onUpdateProtocolNotes,
    onUpdateConditionalField,
    onUpdateInfoField,
}: {
    conditionals: any;
    info: any;
    onUpdateProtocolInfo?: Function;
    onUpdateProtocolNotes?: Function;
    onUpdateConditionalField?: Function;
    onUpdateInfoField?: Function;
}) => {
    return (
        <div className={styles.backgroundContainer}>
            <h3>Additional Info</h3>
            <RichText
                label={SETTING_KEYS.addInfo}
                description={conditionals[SETTING_KEYS.addInfo]}
                onChange={(richText) => {
                    if (onUpdateConditionalField) {
                        onUpdateConditionalField(
                            SETTING_KEYS.addInfo,
                            richText
                        );
                    } else if (onUpdateProtocolInfo) {
                        onUpdateProtocolInfo(richText);
                    }
                }}
            />
            <RichText
                label={SETTING_KEYS.notes}
                description={info[SETTING_KEYS.notes]}
                onChange={(richText) => {
                    if (onUpdateInfoField) {
                        onUpdateInfoField(SETTING_KEYS.notes, richText);
                    } else if (onUpdateProtocolNotes) {
                        onUpdateProtocolNotes(richText);
                    }
                }}
            />
        </div>
    );
};

export default AdditionalInfo;
