import MRIMachineForm from './MRIMachineForm';
import { SpatialGradientDTO, MaxSARDTO } from '../../models/MRIMachineModels';

const AddMRIMachine = () => {
    const machine = {
        Name: '',
        Model: '',
        FieldStrength: 1.5,
        SpatialGradient: [] as SpatialGradientDTO[],
        SlewRatePerAxis: '',
        MaxSlewRate: '',
        MaxSAR: [] as MaxSARDTO[],
        MaxB1rms: '',
        TRCoils: '',
    };

    return <MRIMachineForm MRImachine={machine} isNew={true} />;
};

export default AddMRIMachine;
