import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button, { ButtonType } from '../general-components/Button';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import CloseIcon from '@mui/icons-material/Close';

import styles from './Conditional.module.css';

const SpatialGradient = ({
    d,
    value,
    autofilledFields,
    onUpdateConditionalField,
    onResetHighlights,
}: {
    d: string;
    value: any;
    autofilledFields: any;
    onUpdateConditionalField: Function;
    onResetHighlights: Function;
}) => {
    return (
        <div>
            {value?.gradients?.map((sg: any, index: number) => (
                <div className={styles['conditional-inputs']} key={sg + index}>
                    <div className={styles.conditionalGroup}>
                        <div>
                            <input
                                type="number"
                                step="any"
                                placeholder="Spatial Gradient(Gauss/cm)"
                                defaultValue={sg?.value}
                                style={
                                    autofilledFields.spatialGradient
                                        ? {
                                              background: 'rgb(128, 128, 128)',
                                              border: '1px solid rgb(78, 165, 217)',
                                          }
                                        : {}
                                }
                                onChange={(e) => {
                                    let grNewValue = value.gradients;
                                    grNewValue[index].value = Number(
                                        e.target.value
                                    );
                                    onUpdateConditionalField(d, {
                                        gradients: grNewValue,
                                    });
                                    onResetHighlights(['spatialGradient']);
                                }}
                            />
                        </div>
                        <div>
                            <input
                                type="text"
                                placeholder=" Condition"
                                value={sg?.conditional}
                                onChange={(e) => {
                                    let grNewCondition = value.gradients;
                                    grNewCondition[index].condition =
                                        e.target.value;
                                    onUpdateConditionalField(d, {
                                        gradients: grNewCondition,
                                    });
                                }}
                            />
                        </div>
                        {value?.text ? (
                            <input
                                type="text"
                                defaultValue={value?.text}
                                placeholder="Spatial gradient text"
                                onChange={(e) => {
                                    onUpdateConditionalField(d, {
                                        text: e.target.value,
                                    });
                                }}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                    <Button
                        type={ButtonType.cancel}
                        onClick={() => {
                            let gradientDel = [...value.gradients];
                            gradientDel.splice(index, 1);
                            onUpdateConditionalField(d, {
                                gradients: gradientDel,
                            });
                            onResetHighlights(['spatialGradient']);
                        }}
                    >
                        <CloseIcon />
                    </Button>
                </div>
            ))}

            <Button
                type={ButtonType.primary}
                onClick={() => {
                    onUpdateConditionalField(d, {
                        ...value,
                        gradients: [
                            ...value.gradients,
                            { value: '', condition: '' },
                        ],
                    });
                }}
            >
                <FontAwesomeIcon icon={faPlus} />
            </Button>
        </div>
    );
};

export default SpatialGradient;
