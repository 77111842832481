import { useState } from 'react';
import styles from './Verification.module.css';
import { extendImplantExpiry } from '../../services/apiService';
import Button, { ButtonType } from '../general-components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faPen } from '@fortawesome/free-solid-svg-icons';
import Implant from '../implant/Implant';
import Modal from '../manage-user/Modal';
import OverlayWindow from '../ui-components/OverlayWindow';

const VerificationWindow: React.FC<{ implantId: any; onSubmit?: any }> = ({
    implantId,
    onSubmit,
}) => {
    // States for data, loading, and error handling
    const [error, setError] = useState<string | null>(null);

    // States for handling extending expiry and edit actions
    const [isExtended, setIsExtended] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [open, setOpen] = useState(false);

    const [refreshKey, setRefreshKey] = useState(0);

    const submitExtendExpiry = async () => {
        try {
            await extendImplantExpiry(implantId);
            setOpen(false);
            setIsExtended(true);
            setTimeout(() => setIsExtended(false), 2000);
            onSubmit();
        } catch (error) {
            setError('Failed to verify the implant. Please try again later.');
        }
    };

    return (
        <>
            <Button
                className={styles['inline-button']}
                key={implantId + '_button'}
                onClick={(e: Event) => {
                    e.stopPropagation();
                    setOpen(true);
                }}
                type={ButtonType.submit}
                small={true}
            >
                <FontAwesomeIcon icon={faCircleCheck} />
                Verify
            </Button>
            {open && (
                <Modal
                    scrollable={true}
                    info={{
                        title: '',
                        message: '',
                    }}
                    updateView={() => {
                        setOpen(false);
                    }}
                >
                    <div className={styles.wrapper}>
                        <Implant
                            key={refreshKey}
                            isPreview={true}
                            id={implantId}
                        />
                        <form>
                            <Button
                                key={'Edit'}
                                type={ButtonType.secondary}
                                onClick={() => {
                                    setIsEdit(true);
                                }}
                                className={styles['grey-btn']}
                            >
                                <FontAwesomeIcon icon={faPen} />
                                Edit
                            </Button>
                            <Button
                                key={'Cancel'}
                                type={ButtonType.cancel}
                                onClick={() => {
                                    setOpen(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                key={'Save'}
                                type={ButtonType.submit}
                                onClick={submitExtendExpiry}
                            >
                                Verify
                            </Button>
                        </form>
                    </div>
                    {isEdit && (
                        <div className={styles.overlay}>
                            <OverlayWindow
                                close={() => {
                                    setIsEdit(false);
                                }}
                            >
                                <Implant
                                    isEdit={true}
                                    id={implantId}
                                    onSubmit={() => {
                                        setRefreshKey(refreshKey + 1);
                                        setIsEdit(false);
                                    }}
                                />
                            </OverlayWindow>
                        </div>
                    )}
                </Modal>
            )}
            {isExtended && (
                <Modal
                    info={{
                        title: '',
                        message: '',
                    }}
                    timed={true}
                >
                    <div className={styles.verified}>
                        <div className={styles['verified-icon']}>
                            <FontAwesomeIcon icon={faCircleCheck} />
                        </div>
                        <h3>Implant Verified</h3>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default VerificationWindow;
