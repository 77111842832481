import { useEffect, useRef, useReducer, useState } from 'react';
import styles from './DropdownSelect.module.css';
import SelectItem from './SelectItem';

const DropdownSelect: React.FC<{
    isSearchable?: boolean;
    isMulti?: boolean;
    name?: string;
    options: { [key: string]: boolean };
    onChange: Function;
}> = ({ isSearchable = false, isMulti, name, options, onChange }) => {
    const [data, setData] = useState<{ [key: string]: boolean }>();
    const [open, setOpen] = useState(false);

    const [searchedTerm, setSearchedTerm] = useState('');

    const updateData = (value: string) => {
        if (data[value]) {
            let tempObj = { ...data };
            tempObj[value] = false;
            setData(tempObj);
        } else if (isMulti) {
            let tempObj = { ...data };
            tempObj[value] = true;
            setData(tempObj);
        } else {
            let tempObj = { ...options };
            tempObj[value] = false;
            setData(tempObj);
        }
    };

    // setting the values of types, manufactures, fieldStrength / so they are only called once except if there is a change
    useEffect(() => {
        setData(options);
    }, [options]);

    const toggle = () => {
        if (open) {
            setOpen(false);
            onChange(data);
        } else {
            setOpen(true);
        }
    };

    const optionsRef = useRef(null);
    const triggerRef = useRef(null);

    function handleClickOutside(event: any) {
        if (
            optionsRef.current &&
            !optionsRef.current.contains(event.target) &&
            triggerRef.current &&
            !triggerRef.current.contains(event.target)
        ) {
            toggle();
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open, data]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.select}>
                {/* Filter-pannel */}
                <div ref={triggerRef}>
                    {!isSearchable && (
                        <div onClick={toggle} className={styles.trigger}>
                            <span>{name}</span>
                            <div
                                className={`${styles.arrow} ${
                                    open ? styles.open : ''
                                }`}
                            ></div>
                        </div>
                    )}
                    {isSearchable && (
                        <input
                            type="text"
                            placeholder="Search, select multiple types"
                            onFocus={() => setOpen(true)}
                            onChange={(e) => setSearchedTerm(e.target.value)}
                        />
                    )}
                </div>
                {open && (
                    <div ref={optionsRef} className={styles.options}>
                        {' '}
                        {Object.entries(data).map((item) =>
                            item[0]
                                .toLowerCase()
                                .includes(searchedTerm.toLowerCase()) ? (
                                <SelectItem
                                    key={item[0]}
                                    defaultChecked={item[1] as boolean}
                                    className={styles.item}
                                    type={isMulti ? 'checkbox' : 'radio'}
                                    value={item[0]}
                                    name={name}
                                    onChange={updateData}
                                />
                            ) : (
                                ''
                            )
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default DropdownSelect;
