import SETTING_KEYS from '../../constants/setting-keys';
import Badge from '../ui-components/Badge';
import styles from './FilteredImplants.module.css';

const FilteredRow = ({
    className,
    id,
    onClick,
    info,
    limitingFields,
}: {
    className: string;
    id: string;
    onClick: any;
    info: any;
    limitingFields: string[];
}) => {
    return (
        <div className={`${className}`} key={id}>
            <div
                style={{
                    position: 'relative',
                }}
            >
                <span className={styles.link} onClick={onClick}>
                    {info[SETTING_KEYS.model]}
                </span>
                <div>
                    {limitingFields?.map((f) => (
                        <Badge color="light-grey">{f}</Badge>
                    ))}
                </div>
            </div>
            <div>{info[SETTING_KEYS.manufacturer]}</div>
        </div>
    );
};

export default FilteredRow;
