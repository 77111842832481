import React from 'react';
import PROTOCOL_KEYS from '../../constants/protocol-keys';
import styles from './ProtocolComponent.module.css';
import Procedure from '../implant/Procedure';
import SETTING_KEYS from '../../constants/setting-keys';
import Status from '../general-components/Status';

interface Props {
    info: any;
    conditionals: string;
}

const ProtocolComponent: React.FC<Props> = ({ info, conditionals }) => {
    console.log('info', info);
    console.log(conditionals);
    return (
        <div className={styles.wrapper}>
            {info !== undefined && (
                <>
                    <div className={styles.info}>
                        {info[PROTOCOL_KEYS.title] && (
                            <div>
                                <h2>{info[PROTOCOL_KEYS.title]}</h2>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: info[PROTOCOL_KEYS.description],
                                    }}
                                ></p>
                            </div>
                        )}
                        {info[PROTOCOL_KEYS.def] &&
                            info[PROTOCOL_KEYS.def] !== '<p></p>' && (
                                <div>
                                    <h2>Definitions</h2>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: info[PROTOCOL_KEYS.def],
                                        }}
                                    ></p>
                                </div>
                            )}
                        {info[PROTOCOL_KEYS.scope] &&
                            info[PROTOCOL_KEYS.scope] !== '<p></p>' && (
                                <div>
                                    <h2>Scope</h2>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: info[PROTOCOL_KEYS.scope],
                                        }}
                                    ></p>
                                </div>
                            )}
                        {info[PROTOCOL_KEYS.procedure] &&
                            info[PROTOCOL_KEYS.procedure] !== '<p></p>' && (
                                <div>
                                    <h2>Procedure</h2>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: info[
                                                PROTOCOL_KEYS.procedure
                                            ],
                                        }}
                                    ></p>
                                </div>
                            )}
                        {Array.isArray(info[PROTOCOL_KEYS.ref]) &&
                            info[PROTOCOL_KEYS.ref].length > 0 && (
                                <div>
                                    <h3>References etc.</h3>
                                    <ul>
                                        {info[PROTOCOL_KEYS.ref].map(
                                            (r: any) => (
                                                <li
                                                    style={{
                                                        fontSize: '0.9rem',
                                                    }}
                                                >
                                                    {r}
                                                </li>
                                            )
                                        )}{' '}
                                    </ul>
                                </div>
                            )}
                        {info[PROTOCOL_KEYS.expiry] && (
                            <div style={{ marginTop: '10px' }}>
                                <h4>
                                    <i>
                                        Expires on{' '}
                                        {new Date(
                                            info[PROTOCOL_KEYS.expiry]
                                        ).toDateString()}
                                    </i>
                                </h4>
                            </div>
                        )}
                    </div>
                    {(info[SETTING_KEYS.bodyPlacement] ||
                        info[SETTING_KEYS.category] ||
                        info[SETTING_KEYS.type] ||
                        info[SETTING_KEYS.mriStatus]) && (
                        <div className={styles.info}>
                            <Status status={info[SETTING_KEYS.mriStatus]} />
                            <h3>{info[SETTING_KEYS.type]}</h3>
                            <Procedure
                                info={info}
                                conditionals={conditionals}
                                isPreview={true}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default ProtocolComponent;
