import { ReactNode, useState } from 'react';
import styles from './ListToggle.module.css';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const ListToggle: React.FC<{ title: string; children: ReactNode }> = ({
    title,
    children,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            <div className={styles.title} onClick={toggle}>
                {!isOpen && <ExpandLess />}
                {isOpen && <ExpandMore />}
                <h4>{title}</h4>
            </div>
            {isOpen && <div className={styles.toggled}>{children}</div>}
        </div>
    );
};

export default ListToggle;
