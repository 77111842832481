import React, { useContext } from 'react';
import AuthContext from '../../store/auth-context';
import { Route, Routes, NavLink, useLocation } from 'react-router-dom';
import AddActor from './AddActor';
import ManageActors from './ManageActors';
import AddNews from './AddNews';
import RIGHTS_KEYS from '../../constants/rights-keys';
import AddDepartment from '../manage-user/AddDepartment';
import Analytics from './Analytics';
import InviteAdmin from './InviteAdmin';
import MissingImplantList from './MissingImplantList';
import GudidLoad from './GudidLoad';

import Permissions from './user-permission/Permissions';

import ManageLicenses from './ManageLicenses';

import ReportedImplants from './ReportedImplant';
import InviteManufacturer from './InviteManufacturer';
import ManageSynonyms from './ManageSynonyms';

const AdminPage = () => {
    const context = useContext(AuthContext);
    return (
        <>
            <ul className="admin-nav">
                {/* <li>
                    {context.orRights([
                        RIGHTS_KEYS.createNews,
                        RIGHTS_KEYS.createDeptNews,
                    ]) && <NavLink to="/admin/add-news">Add news</NavLink>}
                </li> */}
                <li>
                    {context.orRights([RIGHTS_KEYS.manageActors]) && (
                        <NavLink to="/admin/manage-actors">
                            Manage actors
                        </NavLink>
                    )}
                </li>
                <li>
                    {context.orRights([RIGHTS_KEYS.manageAllUsers]) && (
                        <NavLink to="/admin/add-department">
                            Add new Department
                        </NavLink>
                    )}
                </li>
                <li>
                    {context.orRights([RIGHTS_KEYS.manageAllUsers]) && (
                        <NavLink to="/admin/analytics">Analytics</NavLink>
                    )}
                </li>
                <li>
                    {context.orRights([RIGHTS_KEYS.manageAllUsers]) && (
                        <NavLink to="/admin/invite">
                            Invite Department Admin
                        </NavLink>
                    )}
                </li>
                <li>
                    {context.orRights([RIGHTS_KEYS.manageAllUsers]) && (
                        <NavLink to="/admin/invite-manufacturer">
                            Invite Manufacturer Admin
                        </NavLink>
                    )}
                </li>
                <li>
                    {context.orRights([RIGHTS_KEYS.manageAllUsers]) && (
                        <NavLink to="/admin/reports">
                            Missing Implant reports
                        </NavLink>
                    )}
                </li>
                <li>
                    {context.orRights([RIGHTS_KEYS.manageAllUsers]) && (
                        <NavLink to="/admin/gudid">GUDID</NavLink>
                    )}
                </li>
                <li>
                    {context.orRights([RIGHTS_KEYS.manageLicenses]) && (
                        <NavLink to="/admin/managelicenses">
                            Manage Licenses
                        </NavLink>
                    )}
                </li>
                <li>
                    {context.orRights([RIGHTS_KEYS.manageEmployees]) && (
                        <NavLink to="/admin/permissions">
                            User Permissions
                        </NavLink>
                    )}
                </li>
                <li>
                    {context.orRights([RIGHTS_KEYS.manageAllImplants]) && (
                        <NavLink to="/admin/manage-synonyms">
                            Manage Synonyms
                        </NavLink>
                    )}
                </li>
            </ul>

            <Routes>
                {context.orRights([
                    RIGHTS_KEYS.createNews,
                    RIGHTS_KEYS.createDeptNews,
                ]) && <Route path="/add-news" element={<AddNews />} />}
                {context.orRights([RIGHTS_KEYS.manageAllUsers]) && (
                    <Route path="/add-department" element={<AddDepartment />} />
                )}
                {context.orRights([RIGHTS_KEYS.manageActors]) && (
                    <Route
                        path="/manage-actors"
                        element={
                            <React.Fragment>
                                <AddActor />
                                <ManageActors />
                            </React.Fragment>
                        }
                    />
                )}
                {context.orRights([RIGHTS_KEYS.manageAllUsers]) && (
                    <Route path="/analytics" element={<Analytics />} />
                )}
                {context.orRights([RIGHTS_KEYS.manageAllUsers]) && (
                    <Route path="/invite" element={<InviteAdmin />} />
                )}
                {context.orRights([RIGHTS_KEYS.manageAllUsers]) && (
                    <Route
                        path="/invite-manufacturer"
                        element={<InviteManufacturer />}
                    />
                )}
                {context.orRights([RIGHTS_KEYS.manageAllUsers]) && (
                    <Route path="/reports" element={<MissingImplantList />} />
                )}
                {context.orRights([RIGHTS_KEYS.manageAllUsers]) && (
                    <Route path="/gudid" element={<GudidLoad />} />
                )}

                {context.orRights([RIGHTS_KEYS.manageAllUsers]) && (
                    <Route
                        path="/missing-conditional"
                        element={<ReportedImplants />}
                    />
                )}
                {context.orRights([RIGHTS_KEYS.manageEmployees]) && (
                    <Route path="/permissions" element={<Permissions />} />
                )}

                {context.orRights([RIGHTS_KEYS.manageLicenses]) && (
                    <Route
                        path="/managelicenses"
                        element={<ManageLicenses />}
                    />
                )}
                {context.orRights([RIGHTS_KEYS.manageAllImplants]) && (
                    <Route
                        path="/manage-synonyms"
                        element={<ManageSynonyms />}
                    />
                )}
            </Routes>
        </>
    );
};

export default AdminPage;
