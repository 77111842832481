const getTermVars = (str) => {
    const terms = [
      str.toLowerCase(),
      str.toUpperCase(),
      str.substr(0,1).toUpperCase() + str.substr(1, str.length -1).toLowerCase()
    ]
    return terms
  }

 export const getRelevantName = (names, searchTerm) => {
    if(names.length > 0){
      for(let name of names){
        if(getTermVars(searchTerm).some(x => name.includes(x))){
          return name;
        }
      }
    }
    return '';
  }

 export const highlighter = (text, searchTerm, className) => {
    if(text === undefined) return;
    let filtered = getTermVars(searchTerm).filter(x => text.includes(x));
    
    let term = ''

    if(filtered.length > 0){
      term = filtered[0];
    }

    if(term.length > 0){
      const highlightIndex = text.indexOf(term);
      const highlight = text.substr(highlightIndex, term.length);
      const prefix = text.substr(0, highlightIndex);
      const suffix = text.substr(highlightIndex + term.length, text.length - highlightIndex - term.length)

      const hElement = <em className={className}>{highlight}</em>;

      return <span>{prefix}{hElement}{suffix}</span>
    }
    else{
      return <span>{text}</span>
    }
  }