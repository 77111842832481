import React, { useState, useEffect, useContext } from 'react';
import RIGHTS_KEYS from '../../constants/rights-keys';
import {
    getAllDepartments,
    getDepartmentsByContext,
} from '../../services/apiService';
import AuthContext from '../../store/auth-context';
import styles from './Selectors.module.css';

const DepartmentSelector = ({
    id,
    onChange,
    defaultValue,
    handleInputFocus,
    handleInputBlur,
}) => {
    const context = useContext(AuthContext);

    const [departments, setDepartments] = useState([]);

    if (!departments.some((d) => d._id === defaultValue)) {
        defaultValue = 'default';
    }

    const getData = async () => {
        let depts = [];
        if (context.orRights([RIGHTS_KEYS.manageEmployees])) {
            depts = await getDepartmentsByContext();
        } else {
            depts = await getAllDepartments();
        }
        setDepartments(depts);
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <React.Fragment>
            {departments.length > 0 && (
                <select
                    required
                    id={id}
                    name="department"
                    defaultValue={defaultValue}
                    onChange={onChange}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    className={styles.select}
                >
                    {defaultValue === 'default' && (
                        <option value="default" disabled>
                            Select a Department
                        </option>
                    )}
                    {departments.map((d) => (
                        <option key={d._id} value={d._id}>
                            {d.name}
                        </option>
                    ))}
                </select>
            )}
        </React.Fragment>
    );
};

export default DepartmentSelector;
