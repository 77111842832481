import SETTING_KEYS from '../../constants/setting-keys';
import Button, { ButtonType } from '../general-components/Button';
import RichText from '../general-components/RichText';
import SelectItem from '../general-components/SelectItem';
import CloseIcon from '@mui/icons-material/Close';

import styles from './CustomFields.module.css';

const CustomFields = ({
    info,
    onAddField,
    onUpdateField,
    onDeleteField,
}: {
    info: any;
    onAddField: Function;
    onUpdateField: Function;
    onDeleteField: Function;
}) => {
    const renderCustomFields = (field: any, index: number) => {
        return (
            <div key={index} className={styles.customFieldContainer}>
                <div className={styles.fieldInputContainer}>
                    <SelectItem
                        checked={field.isCritical}
                        type="checkbox"
                        labelled={true}
                        value="This is critical information. Relevant for the filter"
                        onChange={() =>
                            onUpdateField(index, {
                                ...field,
                                isCritical: !field.isCritical,
                            })
                        }
                    />
                    <input
                        type="text"
                        placeholder="Enter title"
                        value={field.heading}
                        onChange={(e) =>
                            onUpdateField(index, {
                                ...field,
                                heading: e.target.value,
                            })
                        }
                    />
                    <RichText
                        description={field.value}
                        onChange={(richText) =>
                            onUpdateField(index, { ...field, value: richText })
                        }
                    />
                </div>
                <Button
                    type={ButtonType.cancel}
                    onClick={() => onDeleteField(index)}
                >
                    <CloseIcon />
                </Button>
            </div>
        );
    };

    return (
        <div className={styles.backgroundContainer}>
            <h3>Custom fields</h3>
            <div className={styles.customFields}>
                {info[SETTING_KEYS.customFields] &&
                    info[SETTING_KEYS.customFields].map(renderCustomFields)}
            </div>
            <Button
                type={ButtonType.primary}
                onClick={onAddField}
                className={styles.customBtn}
            >
                Add custom field
            </Button>
        </div>
    );
};

export default CustomFields;
