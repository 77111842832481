import React from 'react';
import Button, { ButtonType } from '../general-components/Button';
import styles from './EditUserPage.module.css';
import FormInput from '../general-components/FormInput';

const UserInfoForm: React.FC<{
    handleInfoChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    handleInfoSubmit: Function;
    errorMsg: any;
    defaultName: string;
    defaultEmail: string;
}> = ({
    handleInfoChange,
    handleInfoSubmit,
    errorMsg,
    defaultName,
    defaultEmail,
}) => {
    return (
        <React.Fragment>
            <div className={styles.userEditSection}>
                <form className={styles.userInfo}>
                    <div className={styles.userDetails}>
                        <FormInput
                            defaultValue={defaultName}
                            label="Full name"
                            type="text"
                            onChange={handleInfoChange}
                            name="name"
                            errorMsg={errorMsg.name}
                        />
                        <FormInput
                            defaultValue={defaultEmail}
                            label="Email"
                            type="email"
                            onChange={handleInfoChange}
                            name="email"
                            errorMsg={errorMsg.email}
                        />
                    </div>
                    <div className={styles.submitBtnField}>
                        <Button
                            type={ButtonType.submit}
                            onClick={handleInfoSubmit}
                            className={styles.submitBtn}
                        >
                            Update information
                        </Button>
                        <span className={styles.btnError}>{errorMsg.btn}</span>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
};

export default UserInfoForm;
