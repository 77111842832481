import styles from './PreviewCardTable.module.css';

const PreviewCardTable = ({
    title,
    value,
}: {
    title: string;
    value: string;
}) => {
    return (
        <div className={styles.table}>
            <div className={styles.key}>{title}</div>
            <div
                className={styles.value}
                dangerouslySetInnerHTML={{ __html: value }}
            ></div>
        </div>
    );
};

export default PreviewCardTable;
