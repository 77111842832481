import { useState, useReducer, ChangeEvent } from 'react';
import Button, { ButtonType } from '../general-components/Button';
import { addMRIMachine, updateMRIMachine } from '../../services/apiService';
import styles from './MRIMachineForm.module.css';
import FormInput from '../general-components/FormInput';
import {
    formReducer,
    errorReducer,
    camelCaseToSentence,
} from '../../utils/utils';

const fieldStrengthValues = [1.5, 3, 7];

const EditMRIMachine: React.FC<{
    MRImachine: any;
    isNew: boolean;
}> = ({ MRImachine, isNew }) => {
    const initialErrorData = {
        Name: '',
        Model: '',
        SlewRatePerAxis: '',
        MaxSlewRate: '',
        MaxB1rms: '',
        TRCoils: '',
        diameter: '',
    };

    const [machine, setMachine] = useReducer(formReducer, MRImachine);
    const [errorData, setErrorData] = useReducer(
        errorReducer,
        initialErrorData
    );
    const [isSaving, setIsSaving] = useState(false);
    const [saved, setSaved] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isAddMachineError, setIsAddMachineError] = useState(false);
    const [isMachineAdded, setIsMachineAdded] = useState(false);

    // Define a list of required fields in the form
    const requiredFields = Object.keys(initialErrorData);

    const handleInputChange = (
        e: ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >,
        number?: string
    ) => {
        const { name, value } = e.target;

        number
            ? setMachine({
                  type: 'update',
                  name,
                  value: Number(value),
              })
            : setMachine({
                  type: 'update',
                  name,
                  value,
              });
        requiredFields.includes(name) &&
            setErrorData({ type: 'update', name, value: '' });
    };

    const handleAddFields = (fieldSetName: string) => {
        fieldSetName === 'addSpatialGradient' &&
            setMachine({
                type: 'addFieldSet',
                name: 'SpatialGradient',
                value: [
                    ...machine.SpatialGradient,
                    {
                        diameter: '',
                        value: '',
                    },
                ],
            });

        fieldSetName === 'addMaxSAR' &&
            setMachine({
                type: 'addFieldSet',
                name: 'MaxSAR',
                value: [
                    ...machine.MaxSAR,
                    {
                        level: '',
                        wholeBody: '',
                        head: '',
                    },
                ],
            });
    };

    const save = async () => {
        setIsSaving(true);
        setIsAddMachineError(false);
        setSaved(false);
        await updateMRIMachine(machine);
        setIsSaving(false);
        setSaved(true);
    };

    const addNewMachine = async () => {
        setIsSaving(true);
        setSaved(false);
        const res = await addMRIMachine(machine);
        setIsSaving(false);
        if (res.status !== 200) {
            setIsAddMachineError(true);
            setSaved(false);
        } else {
            setIsMachineAdded(true);
            setSaved(true);
        }
    };

    const handleOnSave = () => {
        let allChecksPassed = false;
        Object.entries(machine).forEach(([key, value]) => {
            if (requiredFields.includes(key) && value === '') {
                setErrorData({
                    type: 'update',
                    name: key,
                    value: `Please fill ${camelCaseToSentence(key)}`,
                });
            }
        });

        allChecksPassed = Object.entries(machine)
            .filter(([key]) => requiredFields.includes(key))
            .every(([key, value]) => value !== '');

        setIsError(!allChecksPassed);
        if (allChecksPassed && !isNew) {
            save();
        } else if (allChecksPassed && isNew && !isMachineAdded) {
            addNewMachine();
        }
    };

    const deleteValue = (delValue: string, index: number) => {
        let newValue = [...machine[delValue]];
        newValue.splice(index, 1);
        setMachine({
            name: delValue,
            value: newValue,
        });
    };

    return (
        <form className={styles.inputsWrapper}>
            <FormInput
                type="text"
                name="Name"
                defaultValue={machine?.Name}
                label="Room name"
                onChange={handleInputChange}
                errorMsg={errorData.Name}
            />
            <FormInput
                type="text"
                name="Model"
                defaultValue={machine?.Model}
                label="MRI Machine model"
                onChange={handleInputChange}
                errorMsg={errorData.Model}
            />
            <FormInput
                label="Static magnetic field"
                name="FieldStrength"
                defaultValue={machine?.FieldStrength}
                errorMsg={errorData.FieldStrength}
                tag="select"
                onSelect={(e) => handleInputChange(e)}
            >
                {fieldStrengthValues.map((fs, index) => (
                    <option key={index} value={fs}>
                        {fs}
                    </option>
                ))}
            </FormInput>
            <div>
                <label className={styles.label}>Spatial Gradient</label>
                {machine?.SpatialGradient.map((spGrad: any, index: number) => (
                    <div className={styles.flexItemsRow} key={spGrad + index}>
                        <FormInput
                            name="diameter"
                            type="number"
                            defaultValue={spGrad?.diameter}
                            label="Diameter (cm)"
                            errorMsg={errorData.diameter}
                            onChange={(e) => {
                                let spatialGradientNew =
                                    machine.SpatialGradient;
                                spatialGradientNew[index].diameter = Number(
                                    e.target.value
                                );
                                setMachine({
                                    type: 'update',
                                    name: 'SpatialGradient',
                                    value: spatialGradientNew,
                                });
                            }}
                        />
                        <FormInput
                            name="gradient"
                            type="number"
                            defaultValue={spGrad?.value}
                            label="Spatial Gradient (Gauss/cm)"
                            onChange={(e) => {
                                let spatialGradientNew =
                                    machine.SpatialGradient;
                                spatialGradientNew[index].value = Number(
                                    e.target.value
                                );
                                setMachine({
                                    type: 'update',
                                    name: 'SpatialGradient',
                                    value: spatialGradientNew,
                                });
                            }}
                        />
                        <Button
                            type={ButtonType.cancel}
                            onClick={() => {
                                deleteValue('SpatialGradient', index);
                            }}
                        >
                            Del
                        </Button>
                    </div>
                ))}
                <Button
                    type={ButtonType.tietary}
                    onClick={() => handleAddFields('addSpatialGradient')}
                >
                    Add
                </Button>
            </div>
            <FormInput
                type="number"
                name="SlewRatePerAxis"
                defaultValue={machine?.SlewRatePerAxis}
                label="Maximum Slew Rate (per axis) (T/m/s)"
                errorMsg={errorData.SlewRatePerAxis}
                onChange={(e) => handleInputChange(e, 'number')}
            />
            <FormInput
                type="number"
                name="MaxSlewRate"
                defaultValue={machine?.MaxSlewRate}
                label="Maximum Effective Slew Rate (T/m/s)"
                errorMsg={errorData.MaxSlewRate}
                onChange={(e) => handleInputChange(e, 'number')}
            />
            <div>
                <label className={styles.label}>
                    Maximum SAR - RF energy disposal
                </label>
                {machine?.MaxSAR.map((maxSar: any, index: number) => (
                    <div className={styles.flexItemsRow} key={maxSar + index}>
                        <FormInput
                            type="number"
                            name="level"
                            defaultValue={maxSar?.level}
                            label="Level"
                            errorMsg=""
                            onChange={(e) => {
                                let maxSarNew = machine.MaxSAR;
                                maxSarNew[index].level = Number(e.target.value);
                                setMachine({
                                    type: 'update',
                                    name: 'MaxSAR',
                                    value: maxSarNew,
                                });
                            }}
                        />
                        <FormInput
                            type="number"
                            name="wholeBody"
                            defaultValue={maxSar?.wholeBody}
                            label="WholeBody (W/kg)"
                            errorMsg=""
                            onChange={(e) => {
                                let maxSarNew = machine.MaxSAR;
                                maxSarNew[index].wholeBody = Number(
                                    e.target.value
                                );
                                setMachine({
                                    type: 'update',
                                    name: 'MaxSAR',
                                    value: maxSarNew,
                                });
                            }}
                        />
                        <FormInput
                            type="number"
                            name="head"
                            defaultValue={maxSar?.head}
                            label="Head (W/kg)"
                            errorMsg=""
                            onChange={(e) => {
                                let maxSarNew = machine.MaxSAR;
                                maxSarNew[index].head = Number(e.target.value);
                                setMachine({
                                    type: 'update',
                                    name: 'MaxSAR',
                                    value: maxSarNew,
                                });
                            }}
                        />

                        <Button
                            type={ButtonType.cancel}
                            onClick={() => {
                                deleteValue('MaxSAR', index);
                            }}
                        >
                            Del
                        </Button>
                    </div>
                ))}
                <Button
                    type={ButtonType.tietary}
                    onClick={() => handleAddFields('addMaxSAR')}
                >
                    Add
                </Button>
            </div>
            <FormInput
                name="MaxB1rms"
                type="text"
                defaultValue={machine?.MaxB1rms}
                label="Maximum B1+rms (micro T) - RF exposure"
                errorMsg={errorData.MaxB1rms}
                onChange={(e) => {
                    setMachine({
                        type: 'update',
                        name: 'MaxB1rms',
                        value: e.target.value,
                    });
                }}
            />
            <FormInput
                name="TRCoils"
                type="text"
                defaultValue={machine?.TRCoils}
                label="T/R Coils"
                errorMsg={errorData.TRCoils}
                onChange={(e) => {
                    setMachine({
                        type: 'update',
                        name: 'TRCoils',
                        value: e.target.value,
                    });
                }}
            />
            <div className={styles.save}>
                <Button type={ButtonType.submit} onClick={handleOnSave}>
                    Save
                </Button>
                {isError && (
                    <div>
                        Please check empty fields. Delete empty or fill them
                        before saving.
                    </div>
                )}
                {isSaving && <div>Saving...</div>}
                {isNew && isAddMachineError && (
                    <div>Not saved. Something went wrong.</div>
                )}
                {saved && <div>Succesfully saved changes</div>}
            </div>
        </form>
    );
};

export default EditMRIMachine;
