import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './SuggestionSearch.module.css';
import {
    faArrowRight,
    faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';
import SuggestionWrapper from './SuggestionWrapper';
import { useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';

const SuggestionSearch: React.FC<{
    defaultValue?: string;
    onSearch: Function;
}> = ({ defaultValue, onSearch }) => {
    const [searchTerm, setSearchTerm] = useState<string>('');

    const search = () => {
        onSearch(searchTerm, false);
    };

    const handleKeyDown = (event: any) => {
        console.log(event.key);
        if (event.key === 'Enter') {
            event.preventDefault();
            search();
        }
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.search}>
                <div className={styles.icon}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />{' '}
                </div>
                <input
                    value={searchTerm}
                    autoComplete="off"
                    placeholder="Search"
                    onChange={handleSearch}
                    onKeyDown={handleKeyDown}
                />
                {searchTerm.length > 0 && (
                    <button
                        className={styles.cancel}
                        onClick={() => setSearchTerm('')}
                    >
                        <CancelIcon />
                    </button>
                )}
                <button
                    id="search-button"
                    className={styles.submit}
                    onClick={search}
                >
                    <FontAwesomeIcon icon={faArrowRight} color="white" />{' '}
                </button>
            </div>
            {searchTerm.length >= 3 && (
                <div className={styles.suggestions}>
                    <SuggestionWrapper term={searchTerm} />
                </div>
            )}
        </div>
    );
};

export default SuggestionSearch;
