import React, { useState, useEffect, useContext } from 'react';
import RIGHTS_KEYS from '../../constants/rights-keys';
import { getAllActors } from '../../services/apiService';
import AuthContext from '../../store/auth-context';
import styles from './Selectors.module.css';

const RoleSelector = ({
    id,
    onChange,
    defaultValue,
    handleInputFocus,
    handleInputBlur,
}) => {
    const context = useContext(AuthContext);

    const [actors, setActors] = useState([]);

    const getActors = async () => {
        const acts = await getAllActors();

        if (context.orRights([RIGHTS_KEYS.manageEmployees])) {
            const availableActs = ['Radiologist', 'Radiographer', 'DeptAdmin'];
            setActors(acts.filter((a) => availableActs.includes(a.role)));
        } else {
            setActors(acts);
        }
    };

    useEffect(() => {
        getActors();
    }, []);

    return (
        <React.Fragment>
            {actors?.length > 0 && (
                <select
                    required
                    name="role"
                    id={id}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    className={styles.select}
                >
                    {defaultValue === 'default' && (
                        <option
                            value="default"
                            className="role-placeholder"
                            disabled
                        >
                            Select a role
                        </option>
                    )}
                    {actors.map((a) => (
                        <option key={a._id} value={a.role}>
                            {a.role}
                        </option>
                    ))}
                </select>
            )}
        </React.Fragment>
    );
};

export default RoleSelector;
