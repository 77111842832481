import { useContext } from 'react';
import { FilterContext } from '../../store/filter-context';
import FilteredImplants from '../filtered-implants/FilteredImplants';
import styles from './CreatePredefinedConditionals.module.css';
import SETTING_KEYS from '../../constants/setting-keys';

const ImplantList = () => {
    const {
        updateConditionalsFromList,
        shownImplants,
        selectedLimitingImplants,
        hoveredConditional,
    } = useContext(FilterContext);

    const needUnsafeCheck = (list: any) => {
        let hasUnsafe = false;
        let hasConditional = false;
        for (let i of list) {
            console.log(i[SETTING_KEYS.mriStatus]);
            if (i.info[SETTING_KEYS.mriStatus] === 1) {
                hasUnsafe = true;
            } else if (i.info[SETTING_KEYS.mriStatus] === 2) {
                hasConditional = true;
            }
            if (hasUnsafe && hasConditional) {
                return true;
            }
        }
        return false;
    };

    return (
        <div className={styles.result}>
            <FilteredImplants
                onCheck={(checkedImplants) =>
                    updateConditionalsFromList(checkedImplants)
                }
                hasUnsafeCheck={needUnsafeCheck(shownImplants)}
                hoveredConditional={hoveredConditional}
                returned={shownImplants.length}
                filteredResult={shownImplants}
                limitingResults={selectedLimitingImplants}
            />
        </div>
    );
};

export default ImplantList;
