import { useContext, useEffect, useState } from 'react';
import { recentlyUpdatedImplants } from '../../services/apiService';
import SETTING_KEYS from '../../constants/setting-keys';
import styles from './RecentImplantsPage.module.css';
import UpdateElasticButton from './UpdateElasticButton';
import VerificationWindow from '../implant-verification/VerificationWindow';
import AuthContext from '../../store/auth-context';

const RecentImplantsPage = () => {
    const userContext = useContext(AuthContext);
    const [userInfo, setUserInfo] = useState<any>();

    const [implants, setImplants] = useState<any[]>([]);

    const getData = async () => {
        const date = new Date();
        date.setTime(date.getTime() - 30 * 24 * 60 * 60 * 1000);
        const i = await recentlyUpdatedImplants(date);
        setImplants(i);
    };

    const getUserInfo = async () => {
        const info = await userContext.getAllInfo();
        setUserInfo(info.user);
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        getUserInfo();
    }, [userContext]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <h1>Recent Implants</h1>
                <UpdateElasticButton>
                    Update Searchable Implants
                </UpdateElasticButton>
            </div>
            <div className={styles.row}>
                <h3>Model</h3>
                <h3>Updated By</h3>
                <h3>Verified By</h3>
                <h3>Verification status</h3>
                <h3>Date created</h3>
            </div>
            {implants.map((implant) => {
                console.log(userInfo !== implant.updBy[0]?._id);
                return (
                    <div key={implant._id} className={styles.row}>
                        <p>
                            <a href={'/implant/' + implant._id}>
                                {implant[SETTING_KEYS.model]}
                            </a>
                        </p>
                        <p>{implant.updBy[0]?.name}</p>
                        <p>{implant.verBy[0]?.name}</p>
                        <p>
                            {implant[SETTING_KEYS.editStatus] === 0
                                ? 'Archived'
                                : ''}
                            {implant[SETTING_KEYS.editStatus] === 1
                                ? 'Draft'
                                : ''}
                            {implant[SETTING_KEYS.editStatus] === 2
                                ? 'Completed'
                                : ''}
                            {implant[SETTING_KEYS.editStatus] === 3 ? (
                                'Verified'
                            ) : (
                                <>
                                    {userInfo &&
                                        userInfo._id !==
                                            implant.updBy[0]?._id && (
                                            <VerificationWindow
                                                implantId={implant._id}
                                                onSubmit={getData}
                                            />
                                        )}
                                </>
                            )}
                        </p>
                        <p>{implant.updatedAt}</p>
                    </div>
                );
            })}
        </div>
    );
};

export default RecentImplantsPage;
