import React, { useState, useEffect, useContext } from 'react';
import RIGHTS_KEYS from '../../constants/rights-keys';
import {
    addNewsCategory,
    createNewsPost,
    getAllActors,
    getAllDepartments,
    getAllNewsCategories,
} from '../../services/apiService';
import AuthContext from '../../store/auth-context';
import Button, { ButtonType } from '../general-components/Button';
import styles from './AddNews.module.css';
import FormInput from '../general-components/FormInput';

const AddNews = () => {
    const context = useContext(AuthContext);

    const [data, setData] = useState([]);
    const [catInfo, setCatInfo] = useState({ departments: [], actors: [] });

    const [actors, setActors] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [categories, setCategories] = useState([]);

    const getData = async () => {
        const act = await getAllActors();
        if (act && act.length > 0) setActors(act);
        const dep = await getAllDepartments();
        if (dep && dep.length > 0) setDepartments(dep);
        const cat = await getAllNewsCategories();
        if (cat && cat.length > 0) setCategories(cat);
    };

    useEffect(() => {
        getData();
    }, []);

    const handleChange = ({ target }: any) => {
        setData((s) => ({ ...s, [target.name]: target.value }));
    };

    const handleCategoryItemChange = ({ target }: any) => {
        setCatInfo((s) => ({ ...s, [target.name]: target.value }));
    };

    const handleCategoryListChange = ({ target }: any) => {
        setCatInfo((s) => ({ ...s, [target.name]: [target.value] }));
    };

    const addNews = (event: any) => {
        event.preventDefault();
        createNewsPost(data);
    };

    const addCategory = (event: any) => {
        event.preventDefault();
        addNewsCategory(catInfo);
    };

    return (
        <React.Fragment>
            <div className={styles.wrapper}>
                <h1>Add News</h1>
                <form>
                    <FormInput
                        label="Title"
                        name="title"
                        type="text"
                        onChange={handleChange}
                        errorMsg=""
                    />
                    <FormInput
                        label="Content"
                        name="description"
                        tag="textArea"
                        errorMsg=""
                        className="textArea"
                        onChange={handleChange}
                    />
                    <FormInput
                        label="Category"
                        name="category"
                        defaultValue={'default'}
                        errorMsg={''}
                        tag="select"
                        onSelect={handleChange}
                    >
                        <option value="default" disabled>
                            Please select a Category
                        </option>
                        {categories.map((c) => (
                            <option value={c._id}>{c.name}</option>
                        ))}
                    </FormInput>
                    <div>
                        <Button
                            onClick={addNews}
                            className={styles.submit}
                            type={ButtonType.submit}
                        >
                            Post
                        </Button>
                    </div>
                </form>
            </div>
            <div className={styles.wrapper}>
                <h1>Add Category</h1>
                <form onSubmit={addCategory}>
                    <FormInput
                        label="Name"
                        name="name"
                        type="text"
                        errorMsg=""
                        onChange={handleCategoryItemChange}
                    />
                    {context.orRights([RIGHTS_KEYS.createNews]) && (
                        <FormInput
                            label="Department"
                            name="departments"
                            defaultValue={'default'}
                            errorMsg={''}
                            tag="select"
                            onSelect={handleCategoryListChange}
                        >
                            <option value="default" disabled>
                                Select a Department with access
                            </option>
                            {departments.map((d) => (
                                <option value={d._id}>{d.name}</option>
                            ))}
                        </FormInput>
                    )}
                    {context.orRights([RIGHTS_KEYS.createDeptNews]) && (
                        <FormInput
                            label="Department"
                            name="departments"
                            defaultValue={context.getContext().department._id}
                            errorMsg={''}
                            tag="select"
                            onSelect={handleCategoryListChange}
                        >
                            <option value={context.getContext().department._id}>
                                {context.getContext().department.name}
                            </option>
                        </FormInput>
                    )}
                    <FormInput
                        label="Actor"
                        name="actors"
                        defaultValue="default"
                        errorMsg={''}
                        tag="select"
                        onSelect={handleCategoryListChange}
                    >
                        <option value="default">
                            Select an Actor with access
                        </option>
                        {actors.map((a) => (
                            <option value={a._id}>{a.role}</option>
                        ))}
                    </FormInput>
                    <div>
                        <Button
                            onClick={addCategory}
                            className={styles.submit}
                            type={ButtonType.primary}
                        >
                            Save
                        </Button>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
};

export default AddNews;
