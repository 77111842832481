import React from 'react';
import styles from './Alert.module.css';
import CloseIcon from '@mui/icons-material/Close';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface AlertProps {
    message: string;
    onClose: () => void;
}

const Alert: React.FC<AlertProps> = ({ message, onClose }) => {
    return (
        <div className={styles.alertBox}>
            <span className={styles.icon}>
                {' '}
                <HighlightOffIcon />
            </span>
            <span>{message}</span>
            <button onClick={onClose} className={styles.closeButton}>
                <CloseIcon />
            </button>
        </div>
    );
};

export default Alert;
