import React, { useContext, useEffect, useState } from 'react';
import Procedure from './../implant/Procedure';
import SETTING_KEYS from '../../constants/setting-keys';
import styles from './Implant.module.css';
import { getImplantById } from '../../services/apiService';
import LoadingRing from '../ui-components/LoadingRing';
import EditImplant from '../edit-implant/EditImplant';
import AuthContext from '../../store/auth-context';
import RIGHTS_KEYS from '../../constants/rights-keys';
import { mriCodeValidate } from '../../services/dataValidation';
import Comment from './comment/Comment';
import { getHistoryVersionsByImplantId } from '../../services/apiService';
import { useImplantContext } from '../../store/implant-context';
import Button, { ButtonType } from '../general-components/Button';

const Implant: React.FC<{
    isPreview?: boolean;
    isEdit?: boolean;
    onSubmit?: Function;
    id: string;
}> = ({ isPreview = false, isEdit = false, id, onSubmit }) => {
    const [info, setInfo] = useState<any>({});
    const [historyInfo, setHistoryInfo] = useState({});

    const [historyVersions, setHistoryVersions] = useState([]);
    const [selectedVersion, setSelectedVersion] = useState(null);

    const [conditionals, setConditional] = useState({});
    const [conditionalsHistory, setConditionalHistory] = useState({});

    const [hasDepartment, setHasDepartment] = useState(false);

    const userContext = useContext(AuthContext);
    const {
        dispatchInfo,
        dispatchConditionals,
        setFileName,
        setRunPDFExtractor,
    } = useImplantContext();

    const [isLoading, setIsLoading] = useState(true);

    // let validMriCode = null;
    // if (info.mriCode) {
    //     validMriCode = await mriCodeValidate(info.mriCode);
    // }

    const getData = async () => {
        setIsLoading(true);
        const implantData = await getImplantById(id);
        const historyVersionsData = await getHistoryVersionsByImplantId(id);
        setIsLoading(false);

        setInfo({
            [SETTING_KEYS.manufacturer]: implantData.Manufacturer,
            ...implantData.info,
        });

        console.log(implantData.info);
        setRunPDFExtractor(false);
        setFileName(implantData.info.Filename);

        dispatchInfo({
            type: 'BULK_UPDATE',
            payload: {
                [SETTING_KEYS.manufacturer]: implantData.Manufacturer,
                ...implantData.info,
            },
        });

        if (!isEdit) {
            const userInfo = await userContext?.getAllInfo();

            setHasDepartment(
                userInfo.user.department_id !== null &&
                    userInfo.user.department_id !== undefined
            );
        }

        setConditional(implantData.conditionals);
        dispatchConditionals({
            type: 'BULK_UPDATE',
            payload: implantData.conditionals,
        });
        setHistoryVersions(historyVersionsData);
    };

    useEffect(() => {
        getData();
    }, [id]);

    useEffect(() => {
        return () => {
            dispatchInfo({ type: 'RESET' });
            dispatchConditionals({ type: 'RESET' });
            setFileName('');
        };
    }, [dispatchInfo, dispatchConditionals, setFileName]);

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = historyVersions.find(
            (version) => version.id === event.target.value
        );

        setSelectedVersion(selectedOption);
        if (selectedOption) {
            setConditionalHistory(selectedOption.conditionals);
            setHistoryInfo({
                [SETTING_KEYS.manufacturer]: selectedOption.Manufacturer,
                ...selectedOption.info,
            });
        }
    };

    return (
        <>
            {isLoading && (
                <div className={styles.loadingwrapper}>
                    <LoadingRing size="large" />
                </div>
            )}
            {isPreview &&
                userContext.orRights([RIGHTS_KEYS.manageAllImplants]) && (
                    <div>
                        {info.documentDate && (
                            <h2>Document date: {info.documentDate}</h2>
                        )}
                        {info.updatedAt && (
                            <h2>
                                Last updated:{' '}
                                {new Date(info.updatedAt).toLocaleDateString()}
                            </h2>
                        )}
                        <Button
                            key={'Edit'}
                            type={ButtonType.primary}
                            onClick={() =>
                                window.open(
                                    'https://www.google.com/search?q=' +
                                        info[SETTING_KEYS.model] +
                                        'mri safety',
                                    '_blank'
                                )
                            }
                            className={styles['grey-btn']}
                        >
                            Google info
                        </Button>
                    </div>
                )}
            {!isEdit && !isLoading && (
                <React.Fragment>
                    {selectedVersion ? (
                        <Procedure
                            isPreview={isPreview}
                            info={historyInfo}
                            conditionals={conditionalsHistory}
                            historyVersions={historyVersions}
                            selectedVersion={selectedVersion}
                            handleChange={handleChange}
                            mriCode={info.mriCode}
                            withComments={
                                !isPreview &&
                                info._id &&
                                userContext.orRights([RIGHTS_KEYS.deptTools]) &&
                                hasDepartment
                            }
                        />
                    ) : (
                        <Procedure
                            isPreview={isPreview}
                            info={info}
                            conditionals={conditionals}
                            historyVersions={historyVersions}
                            selectedVersion={selectedVersion}
                            handleChange={handleChange}
                            mriCode={info.mriCode}
                            withComments={
                                !isPreview &&
                                info._id &&
                                userContext.orRights([RIGHTS_KEYS.deptTools]) &&
                                hasDepartment
                            }
                        />
                    )}
                </React.Fragment>
            )}
            {userContext.orRights([
                RIGHTS_KEYS.manageImplant,
                RIGHTS_KEYS.manageAllImplants,
            ]) &&
                isEdit &&
                !isLoading && <EditImplant onSubmit={onSubmit} isNew={false} />}
        </>
    );
};

export default Implant;
