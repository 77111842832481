import {  useEffect, useRef } from "react";
import styles from './OverlayWindow.module.css';
import {
    faGripVertical,
    faAnglesRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button, { ButtonType } from "../general-components/Button";


const OverlayWindow: React.FC<{children: React.ReactNode, close: Function}> = ({children, close}) => {

    const windowRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
            let mousePos: number;

            const resize = (e: any) => {
                const dx = mousePos - e.x;
                mousePos = e.x;
                windowRef.current.style.width =
                    parseInt(
                        getComputedStyle(windowRef.current, '').width
                    ) +
                    dx +
                    'px';
            };
            const grab = (e: MouseEvent) => {
                document.getElementsByTagName('body')[0].style.userSelect = 'none';
                if (e.offsetX < 20 && e.offsetY > 50) {
                    window.addEventListener('mousemove', resize);
                }
            };
            const reset = () => {
                document.getElementsByTagName('body')[0].style.userSelect = '';
                window.removeEventListener('mousemove', resize);
            };

            windowRef.current.addEventListener('mousedown', grab);
            window.addEventListener('mouseup', reset);
            
            return () => {
                windowRef?.current?.removeEventListener('mousedown', grab);
                window.removeEventListener('mousemove', resize);
                window.removeEventListener('mouseup', reset);
            };
    }, []);


    return (<div
        key="window"
        ref={windowRef}
        className={styles.window}
    >
        <Button
            type={ButtonType.tietary}
            onClick={close}
        >
            <FontAwesomeIcon icon={faAnglesRight} />
        </Button>
        <span className={styles.grab}>
            <FontAwesomeIcon icon={faGripVertical} />
        </span>
        <div className={styles.wrapper}>
            {children}
        </div>
    </div>)
}

export default OverlayWindow;