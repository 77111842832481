import React, { useEffect, useState, useRef } from 'react';
import styles from './Drag-and-drop.module.css';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

interface DragAndDropProps {
    noBg?: boolean;
    dropHandler: (file: File) => void;
    children: React.ReactNode;
    label?: string;
    useContainerStyle?: boolean;
}

const DragAndDrop: React.FC<DragAndDropProps> = ({
    noBg,
    dropHandler,
    children,
    label,
    useContainerStyle = false,
}) => {
    const dropRef = useRef<HTMLDivElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [dragging, setDragging] = useState<boolean>(false);

    let dragCounter = 0;

    const handleFileClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleDragIn = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter++;
        if (e.dataTransfer?.items && e.dataTransfer.items.length > 0) {
            setDragging(true);
        }
    };
    const handleDragOut = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter--;
        if (dragCounter > 0) return;
        setDragging(false);
    };
    const handleDrag = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDrop = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
        if (e.dataTransfer?.files && e.dataTransfer.files.length > 0) {
            dropHandler(e.dataTransfer.files[0]);
            e.dataTransfer.clearData();
            dragCounter = 0;
        }
    };

    useEffect(() => {
        let div = dropRef.current;
        if (div) {
            div.addEventListener('dragenter', handleDragIn);
            div.addEventListener('dragleave', handleDragOut);
            div.addEventListener('dragover', handleDrag);
            div.addEventListener('drop', handleDrop);
        }

        return () => {
            if (div) {
                div.removeEventListener('dragenter', handleDragIn);
                div.removeEventListener('dragleave', handleDragOut);
                div.removeEventListener('dragover', handleDrag);
                div.removeEventListener('drop', handleDrop);
            }
        };
    }, []);

    return (
        <>
            {useContainerStyle ? (
                <div>
                    <label>{label}</label>
                    <div
                        className={`${styles.container} ${
                            dragging ? styles['dragging-over'] : ''
                        } ${noBg ? '' : styles.background}`}
                        ref={dropRef}
                    >
                        {dragging && (
                            <div className={styles['dragging-out']}></div>
                        )}
                        <FileUploadOutlinedIcon />
                        <div className={styles['file-upload-link']}>
                            {children}
                            <div>
                                Drag & Drop or{' '}
                                <span
                                    className={styles['upload-link']}
                                    onClick={handleFileClick}
                                >
                                    Choose file to upload
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div
                    className={`${styles['drag-and-drop']} ${
                        noBg ? '' : styles.background
                    }`}
                    ref={dropRef}
                >
                    {dragging && (
                        <div className={styles['dragging-over']}>
                            <div className={styles['dragging-out']}>
                                <div>drop here</div>
                            </div>
                        </div>
                    )}
                    {children}
                </div>
            )}

            <input
                type="file"
                ref={fileInputRef}
                onChange={(e) => {
                    if (e.target.files && e.target.files.length) {
                        dropHandler(e.target.files[0]);
                    }
                }}
                style={{ display: 'none' }}
            />
        </>
    );
};

export default DragAndDrop;
