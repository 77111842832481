import { instance, GETREQUESTOPTIONS } from '../apiService';

export enum EVENT_KEYS {
    suggestion = 'Suggestion',
    clickSuggestion = 'Suggestion Click',
    viewGroup = 'View Group',
    filterResults = 'Filter Results',
    clickResult = 'Result Click',
    viewProtocol = 'View Protocol',
    allImplants = 'All Implants',
    viewHistory = 'View History',
    viewBookmarks = 'View Bookmarks',
    bookmarkImplant = 'Bookmark Implant',
    clickOpenIFU = 'Click Open IFU',
    clickViewDetails = 'Click View Details',
}

interface TrackedInfo {
    searchTerm?: string;
    implantModel?: string;
    groupName?: string;
    protocolName?: string;
    type?: string;
    manufacturer?: string;
    actionName?: string;
}

export const trackClick = (eventType: EVENT_KEYS, info: TrackedInfo) => {
    instance.post(
        '/api/tracking/click',
        { eventType, info },
        GETREQUESTOPTIONS()
    );
};
