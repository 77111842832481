import React, { useEffect, useState } from 'react'
import { getRecurringJob } from '../../services/apiService';

const Bar: React.FC<{progress: number}> = ({progress}) => {

    return <h1>{progress}</h1>
}

const RecurringJobProgress: React.FC<{name: string}> = ({name}) => {

    const [prog, setProg] = useState({p: 0});

    const getData = async () => {
        const recInfo = await getRecurringJob(name);
        setProg({p: recInfo.info.page});
    }

    useEffect(() => {
        setTimeout(getData, 10000);
    },[prog])

    return <Bar progress={prog.p} />
}

export default RecurringJobProgress;