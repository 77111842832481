import React, { useEffect, useRef, useState } from 'react';
import styles from './CommentItem.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import sanitizeHtml from 'sanitize-html';
import { getTimeDifference } from '../../../utils/utils';
import { IComment } from './CommentModel';
import CommentForm from './CommentForm';
import UserImageIcon from '../../general-components/UserImageIcon';

const renderHTML = (rawHTML: string) =>
    React.createElement('p', {
        dangerouslySetInnerHTML: {
            __html: sanitizeHtml(rawHTML).replace(/\r?\n/g, '<br/>'),
        },
    });

const CommentItem: React.FC<{
    comment: IComment;
    authUser: any;
    onDelete: (id: string) => void;
    onSave: (comment: string, id: string) => void;
}> = ({ comment, authUser, onDelete, onSave }) => {
    const [showEdit, setShowEdit] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    const editPopupEventRef = useRef(null);

    const commentChange = (newComment: string) => {
        onSave(newComment, comment._id);
    };

    const handleClickOutsideOfEditBox = (event: MouseEvent) => {
        if (
            editPopupEventRef.current &&
            !editPopupEventRef.current.contains(event.target as Node)
        ) {
            setShowMenu(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutsideOfEditBox);
        return () => {
            document.removeEventListener(
                'mousedown',
                handleClickOutsideOfEditBox
            );
        };
    }, []);

    const CommentMenu = () => {
        const authUserId = authUser._id;
        const userCommentId = comment.user._id;
        if (authUserId === userCommentId) {
            return (
                <div
                    className={styles.threeDots}
                    onClick={() => setShowMenu(!showMenu)}
                >
                    <FontAwesomeIcon
                        icon={faEllipsisH}
                        color="#ddd"
                        size={'lg'}
                    />
                    {showMenu && (
                        <div
                            className={styles.editPopUp}
                            ref={editPopupEventRef}
                        >
                            <span
                                className={styles.menuTop}
                                onClick={() => {
                                    setShowEdit(true);
                                    setShowMenu(false);
                                }}
                            >
                                edit
                            </span>
                            <span
                                className={styles.menuBottom}
                                onClick={(_e: any) => onDelete(comment._id)}
                            >
                                delete
                            </span>
                        </div>
                    )}
                </div>
            );
        }
    };

    return (
        <div className={styles.comment}>
            <div className={styles.commentHeader}>
                <div className={styles.commentInfo}>
                    <UserImageIcon />
                    <span className={styles.username}>
                        {comment.user.name}{' '}
                        <span className={styles.dot}> · </span>
                        <span className={styles.time}>
                            {getTimeDifference(
                                new Date(comment.updatedAt).getTime()
                            )}
                        </span>
                    </span>
                </div>
                <CommentMenu />
            </div>
            {showEdit && (
                <CommentForm
                    isEdit={showEdit}
                    comment={comment.comment}
                    onSubmit={(c: string) => {
                        commentChange(c);
                        setShowEdit(false);
                    }}
                    onCancel={() => setShowEdit(false)}
                />
            )}
            {!showEdit && renderHTML(comment.comment)}
        </div>
    );
};

export default CommentItem;
