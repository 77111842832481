import SortIcon from '../ui-components/SortIcon';

const InternalProtocolListHeader = ({ sortStatus }: { sortStatus: any }) => {
    return (
        <thead>
            <tr>
                <th>
                    Title <SortIcon sortStatus={sortStatus} headerId="title" />
                </th>
                <th>Date Created</th>
                <th>Last Updated</th>
                <th>Verified By</th>
                <th>Edit</th>
                <th>Archive</th>
            </tr>
        </thead>
    );
};

export default InternalProtocolListHeader;
