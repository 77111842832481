import { useEffect, useState } from 'react';
import SETTING_KEYS from '../../constants/setting-keys';
import { useImplantContext } from '../../store/implant-context';
import SelectItem from '../general-components/SelectItem';
import styles from './EditCatalogNumbers.module.css';
import Button, { ButtonType } from '../general-components/Button';
import Modal from '../manage-user/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const EditCatalogNumbers: React.FC = () => {
    const { dispatchInfo, info } = useImplantContext();

    const [catalogNumbers, setCatalogNumbers] = useState(
        info[SETTING_KEYS.catalogNumbers] !== undefined &&
            info[SETTING_KEYS.catalogNumbers] !== null &&
            info[SETTING_KEYS.catalogNumbers].length > 0
            ? info[SETTING_KEYS.catalogNumbers]
            : [
                  {
                      id: 0,
                      value: '',
                      info: '',
                      diameter: null,
                      length: null,
                  },
              ]
    );

    const [tableRows, setTableRows] = useState([null]);
    const [tableColumns, setTableColumns] = useState([null]);

    const [tableIds, setTableIds] = useState([[0]]);

    const [view, setView] = useState('Basic');
    const [confirmUnavailable, setConfirmUnavailable] = useState(false);

    const [error, setError] = useState(false);

    const loadTable = () => {
        const newTableRows: number[] = [];
        const newTableColumns: number[] = [];
        const newTableIds: number[][] = [];
        for (const cat of catalogNumbers) {
            if (!newTableRows.includes(cat.diameter)) {
                newTableRows.push(cat.diameter);
            }
            if (!newTableColumns.includes(cat.length)) {
                newTableColumns.push(cat.length);
            }
        }
        newTableRows.sort((a, b) => (a > b ? 1 : -1));
        newTableColumns.sort((a, b) => (a > b ? 1 : -1));
        for (let i = 0; i < newTableRows.length; i++) {
            newTableIds.push([]);
            for (let j = 0; j < newTableColumns.length; j++) {
                newTableIds[i].push(-1);
            }
        }
        for (let i = 0; i < newTableRows.length; i++) {
            for (let j = 0; j < newTableColumns.length; j++) {
                const filt = catalogNumbers.filter(
                    (cat: any) =>
                        cat.diameter === newTableRows[i] &&
                        cat.length === newTableColumns[j]
                );
                if (filt.length === 1) {
                    newTableIds[i][j] = filt[0].id;
                } else {
                    setError(true);
                }
            }
        }
        setTableRows(newTableRows);
        setTableColumns(newTableColumns);
        setTableIds(newTableIds);
    };

    const addCatalogNumber = (
        value: string,
        diameter: number,
        length: number,
        info?: string
    ) => {
        const curmax = catalogNumbers.map((cat: any) => cat.id);

        const id = Math.max(...catalogNumbers.map((cat: any) => cat.id)) + 1;
        setCatalogNumbers([
            ...catalogNumbers,
            { id, value, info, diameter, length },
        ]);
        return id;
    };

    const updateData = (id: number, field: string, value: any) => {
        const newCatalogNumbers = catalogNumbers.map((cat: any) => {
            if (cat.id === id) {
                return { ...cat, [field]: value };
            }
            return cat;
        });
        setCatalogNumbers(newCatalogNumbers);
    };

    const removeCatalogNumber = (id: number) => {
        const newCatalogNumbers = catalogNumbers.filter((cat: any) => {
            return cat.id !== id;
        });
        setCatalogNumbers(newCatalogNumbers);
    };

    const updateDiameter = (index: number, value: number) => {
        const tempTableRows = [...tableRows];
        tempTableRows[index] = value;
        setTableRows(tempTableRows);
        for (let i = 0; i < tableColumns.length; i++) {
            const id = tableIds[index][i];
            if (id !== -1) {
                updateData(id, 'diameter', value);
            }
        }
    };

    const updateLength = (index: number, value: number) => {
        const tempTableCols = [...tableColumns];
        tempTableCols[index] = value;
        setTableColumns(tempTableCols);
        for (let i = 0; i < tableRows.length; i++) {
            const id = tableIds[i][index];
            if (id !== -1) {
                updateData(id, 'length', value);
            }
        }
    };

    const updateValue = (row: number, col: number, newValue: any) => {
        const id = tableIds[row][col];
        if (id === -1) {
            const newId = addCatalogNumber(
                newValue,
                tableRows[row],
                tableColumns[col]
            );
            const tempTableIds = [...tableIds];
            tempTableIds[row][col] = newId;
            setTableIds(tempTableIds);
        } else {
            updateData(id, 'value', newValue);
        }
    };

    const addRow = () => {
        const tempTableRows = [...tableRows];
        tempTableRows.push(null);
        setTableRows(tempTableRows);
        const tempTableIds = [...tableIds];
        tempTableIds.push([]);
        for (let i = 0; i < tableColumns.length; i++) {
            tempTableIds[tempTableIds.length - 1].push(-1);
        }
        setTableIds(tempTableIds);
    };

    const addColumn = () => {
        const tempTableCols = [...tableColumns];
        tempTableCols.push(null);
        setTableColumns(tempTableCols);
        const tempTableIds = [...tableIds];
        for (let i = 0; i < tableRows.length; i++) {
            tempTableIds[i].push(-1);
        }
        setTableIds(tempTableIds);
    };

    const updateView = (value: any, checked: boolean) => {
        if (value === 'Table') {
            loadTable();
        } else {
            setTableRows([null]);
            setTableColumns([null]);
            setTableIds([[0]]);
        }
        if (checked) {
            if (value === 'Unavailable') {
                setConfirmUnavailable(true);
            } else {
                dispatchData(catalogNumbers);
                setView(value);
            }
        }
    };

    const dispatchData = (value: any) => {
        dispatchInfo({
            type: 'UPDATE',
            payload: { name: [SETTING_KEYS.catalogNumbers], value: value },
        });
    };

    useEffect(() => {
        dispatchData(catalogNumbers);
    }, [catalogNumbers]);

    return (
        <div className={styles.catalogNumbers}>
            {confirmUnavailable && (
                <Modal
                    info={{
                        title: 'Sure no catalog number is available?',
                        message:
                            'If you set it to unavailable, any data input in the other views will be deleted.',
                    }}
                    updateView={() => setConfirmUnavailable(false)}
                >
                    <Button
                        className={styles.button}
                        type={ButtonType.primary}
                        onClick={() => {
                            setView('Unavailable');
                            setConfirmUnavailable(false);
                        }}
                    >
                        Confirm
                    </Button>
                </Modal>
            )}
            <div className={styles.selectView}>
                <SelectItem
                    checked={view === 'Basic'}
                    type="radio"
                    labelled={true}
                    name="Basic"
                    value="Basic"
                    onChange={updateView}
                />
                <SelectItem
                    checked={view === 'Extended'}
                    type="radio"
                    labelled={true}
                    name="Extended"
                    value="Extended"
                    onChange={updateView}
                />
                <SelectItem
                    checked={view === 'Table'}
                    type="radio"
                    labelled={true}
                    name="Table"
                    value="Table"
                    onChange={updateView}
                />
                <SelectItem
                    checked={view === 'Unavailable'}
                    type="radio"
                    labelled={true}
                    name="Unavailable"
                    value="Unavailable"
                    onChange={updateView}
                />
            </div>
            {(view === 'Basic' || view === 'Extended') && (
                <>
                    <div className={styles.basic}>
                        {catalogNumbers?.map((cat: any) => {
                            return (
                                <div key={cat.id} className={styles.item}>
                                    <input
                                        placeholder={'Catalog number'}
                                        type="text"
                                        name={'value'}
                                        value={cat.value}
                                        onChange={(e) => {
                                            updateData(
                                                cat.id,
                                                e.target.name,
                                                e.target.value
                                            );
                                        }}
                                    />
                                    <input
                                        placeholder={'Description'}
                                        type="text"
                                        name={'info'}
                                        value={cat.info}
                                        onChange={(e) => {
                                            updateData(
                                                cat.id,
                                                e.target.name,
                                                e.target.value
                                            );
                                        }}
                                    />
                                    {view === 'Extended' && (
                                        <>
                                            <input
                                                placeholder={'Diameter'}
                                                type="number"
                                                name={'diameter'}
                                                defaultValue={cat.diameter}
                                                onChange={(e) => {
                                                    updateData(
                                                        cat.id,
                                                        e.target.name,
                                                        Number(e.target.value)
                                                    );
                                                }}
                                            />
                                            <input
                                                placeholder={'Length'}
                                                type="number"
                                                name={'length'}
                                                defaultValue={cat.length}
                                                onChange={(e) => {
                                                    updateData(
                                                        cat.id,
                                                        e.target.name,
                                                        Number(e.target.value)
                                                    );
                                                }}
                                            />
                                        </>
                                    )}
                                    <Button
                                        type={ButtonType.cancel}
                                        onClick={() => {
                                            removeCatalogNumber(cat.id);
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faXmark} />
                                    </Button>
                                </div>
                            );
                        })}
                    </div>
                    <Button
                        // small={true}
                        className={styles.button}
                        type={ButtonType.primary}
                        onClick={() => addCatalogNumber('', null, null)}
                    >
                        +
                    </Button>
                </>
            )}
            {view === 'Table' && (
                <div className={styles.table}>
                    <div className={styles.tableRow}>
                        <div className={styles.corner}></div>
                        {tableColumns.map((val: any, index: number) => {
                            return (
                                <div>
                                    <input
                                        placeholder={'Length'}
                                        type="number"
                                        name={'length'}
                                        defaultValue={val}
                                        onChange={(e) =>
                                            updateLength(
                                                index,
                                                Number(e.target.value)
                                            )
                                        }
                                    />
                                </div>
                            );
                        })}
                        <Button
                            // small={true}
                            className={styles.button}
                            type={ButtonType.primary}
                            onClick={() => addColumn()}
                        >
                            +
                        </Button>
                    </div>

                    {tableRows.map((dia: any, rowIndex: number) => (
                        <div className={styles.tableRow}>
                            <div>
                                <input
                                    placeholder={'Diameter'}
                                    type="number"
                                    name={'diameter'}
                                    defaultValue={dia}
                                    onChange={(e) =>
                                        updateDiameter(
                                            rowIndex,
                                            Number(e.target.value)
                                        )
                                    }
                                />
                            </div>
                            {tableColumns.map((len: any, colIndex: number) => {
                                const filt = catalogNumbers.filter(
                                    (cat: any) =>
                                        cat.id === tableIds[rowIndex][colIndex]
                                );
                                let val = '';
                                if (filt.length === 1) {
                                    val = filt[0].value;
                                }
                                return (
                                    <div>
                                        <input
                                            placeholder={'Catalog Number'}
                                            type="text"
                                            name={'value'}
                                            value={val}
                                            onChange={(e) =>
                                                updateValue(
                                                    rowIndex,
                                                    colIndex,
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    ))}
                    <div className={styles.tableRow}>
                        <Button
                            // small={true}
                            className={styles.button}
                            type={ButtonType.primary}
                            onClick={() => addRow()}
                        >
                            +
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EditCatalogNumbers;
