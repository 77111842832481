enum SETTING_KEYS {
    link = 'Link',
    manufacturer = 'Manufacturer',
    model = 'Model',
    region = 'Region',
    category = 'Category',
    type = 'Type',
    bodyPlacement = 'Body placement',
    addInfo = 'Precaution',
    notes = 'Notes',
    modelNo = 'Model number',
    mriStatus = 'MRI-status',
    gudidDeviceId = 'GUDID Device Id',
    catalogNumbers = 'Catalog numbers',
    waitingPeriod = 'Waiting period',
    fieldStrength = 'Static magnetic field',
    mriArc = 'MRI architecture',
    spatialGradient = 'Spatial gradient',
    slewRate = 'Slew rate (Gr)',
    sar = 'SAR',
    brms = 'B1+rms',
    coilType = 'Coil type',
    artefacts = 'Artefacts',
    temp = 'Temperature',
    prep = 'Preparation',
    mriCode = 'mriCode',
    filename = 'Filename',
    distEndDate = 'Commercial Distribution End Date',
    gmdnDefinition = 'GMDN definition',
    RFExc = 'RF Excitation',
    editStatus = 'Edit-Status',
    verifiedByNI = 'Verified by NI',
    verifiedByManufacturer = 'Verified by Manufacturer',
    updatedBy = 'Updated by',
    createdAt = 'createdAt',
    image = 'Image',
    expiryDate = 'expiryDate',
    customFields = 'Custom fields',
    maxStentLength = 'Maximum Stent length',
    source = 'Source',
    docDate = 'Document date',
}

export default SETTING_KEYS;
