import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthContextProvider } from './store/auth-context';

let root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

if (process.env.REACT_APP_ENV !== 'development') {
    const script = document.createElement('script');

    script.src = '//code.tidio.co/ay1gjvtsnztcwvotks3zsaj4ucti1bew.js';
    script.async = true;

    document.body.appendChild(script);
}

root.render(
    // <React.StrictMode>
    <AuthContextProvider>
        <App />
    </AuthContextProvider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
