import { useContext, useEffect, useState } from 'react';
import Button, { ButtonType } from '../general-components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import styles from './ApprovedComponent.module.css';
import {
    approveImplant,
    getApprovalInfo,
    removeImplantApproval,
} from '../../services/apiService';
import EditLog from '../protocols/EditLog/EditLog';
import ConfirmationModal from '../general/modal/ConfirmationModal';
import AuthContext from '../../store/auth-context';
import GppGoodIcon from '@mui/icons-material/GppGood';

const ApprovedComponent: React.FC<{ implantId: string }> = ({ implantId }) => {
    const ctx = useContext(AuthContext);
    const context = ctx.getContext();

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [info, setInfo] = useState<any>();

    const [comment, setComment] = useState('');
    const [isRemoving, setIsRemoving] = useState(false);

    const confirmApproval = async () => {
        await approveImplant(implantId, comment);
        const name = (await ctx.getAllInfo()).user.name;
        setInfo({
            ...info,
            status: 'Approved',
            comment,
            approvedBy: name,
            approvedAt: new Date().toDateString(),
        });
        togglePreview();
    };

    const removeApproval = async () => {
        setIsRemoving(true);
        await removeImplantApproval(implantId);
        setInfo({});
        setIsRemoving(false);
    };

    const togglePreview = () => {
        setShowConfirmation(!showConfirmation);
    };

    const getData = async () => {
        const i = await getApprovalInfo(implantId);
        setInfo(i);
        setComment(comment);
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            {showConfirmation && (
                <ConfirmationModal
                    title={'Confirm Approval'}
                    message="(Optional) Add comment regarding approval"
                    onClose={togglePreview}
                    confirmText="Approve"
                    cancelText="Cancel"
                    onConfirm={confirmApproval}
                >
                    <textarea
                        defaultValue={info.comment}
                        style={{ width: '100%' }}
                        onChange={(e) => setComment(e.target.value)}
                    />
                </ConfirmationModal>
            )}
            {ctx.hasPermission('Approve') && info?.status !== 'Approved' && (
                <Button type={ButtonType.dept} onClick={togglePreview}>
                    <FontAwesomeIcon icon={faThumbsUp} />
                    Approve Implant
                </Button>
            )}
            {info?.status === 'Approved' && (
                <div>
                    <EditLog
                        title={
                            <>
                                <GppGoodIcon /> Approved at{' '}
                                {context.department.name}
                            </>
                        }
                        approve
                        log={[
                            {
                                name: info.approvedBy,
                                comment: info.comment,
                                date: new Date(info.approvedAt),
                            },
                        ]}
                    >
                        {ctx.hasPermission('Approve') && (
                            <Button
                                loading={isRemoving}
                                className={styles.remove}
                                small={true}
                                type={ButtonType.cancel}
                                onClick={removeApproval}
                            >
                                Remove Aprroval
                            </Button>
                        )}
                    </EditLog>
                </div>
            )}
        </>
    );
};

export default ApprovedComponent;
