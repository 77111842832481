import { useParams, useSearchParams } from 'react-router-dom';
import ResultsComponent from './ResultsComponent';

const ResultsPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    let { term } = useParams<{ term: string }>();

    if (term === 'query') {
        term = searchParams.get('term');
    }

    return (
        <div>
            <ResultsComponent
                term={term}
                defaultSemantic={
                    searchParams.get('useSemantic') === 'true' ? true : false
                }
            />
        </div>
    );
};

export default ResultsPage;
