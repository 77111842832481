import { useContext, useEffect } from 'react';
import styles from './CreatePredefinedConditionals.module.css';
import { useLocation } from 'react-router-dom';
import {
    FilterContext,
    FilterContextProvider,
} from '../../store/filter-context';
import FilterMenu from './FilterMenu';
import ImplantList from './ImplantList';
import ConditionalsView from './ConditionalsView';
import EditPredefinedMenu from './EditPredefinedMenu';

const NewPredefinedConditionals = () => {
    const { setSavedSettings } = useContext(FilterContext);

    useEffect(() => {
        setSavedSettings({ types: [], manufacturers: [], fieldStrength: [] });
    }, []);

    return (
        <div className={styles.wrapper}>
            <EditPredefinedMenu />
            <div className={styles.implant}>
                <ImplantList />
                <ConditionalsView />
            </div>
        </div>
    );
};

export default NewPredefinedConditionals;
