import React, {
    ChangeEvent,
    useContext,
    useEffect,
    useReducer,
    useState,
} from 'react';
import AuthContext from '../../store/auth-context';
import styles from './Login.module.css';
import Button, { ButtonType } from './../general-components/Button';
import WelcomePage from '../ui-components/WelcomeLayout';
import FormInput from '../general-components/FormInput';
import Modal from '../manage-user/Modal';
import { emailValidate } from '../../services/formValidationService';
import {
    formReducer,
    errorReducer,
    camelCaseToSentence,
} from '../../utils/utils';

const initialFormData = {
    email: '',
    password: '',
};
// initialErrorData includes only required fields
const initialErrorData = { email: '', password: '' };

const Login = () => {
    const [formData, setFormData] = useReducer(formReducer, initialFormData);
    const [errorData, setErrorData] = useReducer(
        errorReducer,
        initialErrorData
    );
    const ctx = useContext(AuthContext);
    const [errorMessage, setErrorMessage] = useState('');
    const [loginLoading, setLoginLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [inactiveErrorMessage, setInactiveErrorMessage] = useState('');

    const requiredFields = Object.keys(initialErrorData);

    const handleInputChange = (
        e: ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
    ) => {
        const { name, value } = e.target;

        setFormData({
            type: 'update',
            name,
            value,
        });
        requiredFields.includes(name) &&
            setErrorData({ type: 'update', name, value: '' });
    };

    const submitLogin = () => {
        let allChecksPassed = false;
        const ev = emailValidate(formData.email);
        if (!ev.valid) {
            setErrorData({
                type: 'update',
                name: 'email',
                value: 'invalid email',
            });
        }
        Object.entries(formData).forEach(([key, value]) => {
            if (requiredFields.includes(key) && value === '') {
                setErrorData({
                    type: 'update',
                    name: key,
                    value: `Please fill ${camelCaseToSentence(key)}`,
                });
            }
        });

        allChecksPassed =
            Object.entries(formData)
                .filter(([key]) => requiredFields.includes(key))
                .every(([key, value]) => value !== '') && ev.valid;

        if (allChecksPassed) {
            setLoginLoading(true);
            ctx.onLogin(formData.email, formData.password).then((res: any) => {
                if (res.status === 401) {
                    const pw = formData.password.trim();
                    ctx.onLogin(formData.email, pw).then((res: any) => {
                        if (res.status === 401) {
                            setErrorMessage(
                                'The email or password you have provided is wrong'
                            );
                        }
                        setLoginLoading(false);
                    });
                } else {
                    if (res.status === 403) {
                        setInactiveErrorMessage(res.data.message);
                        setShowModal(true);
                    }
                    if (res.status === 400) {
                        setErrorMessage(res.data.message);
                    }
                    setLoginLoading(false);
                }
            });
        }
    };

    const inactiveReminder = {
        title: 'Inactive Account!',
        message: inactiveErrorMessage,
        className: 'Warning-modal',
        children: [<></>],
    };
    useEffect(() => {
        const listener = (event: any) => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                event.preventDefault();
                submitLogin();
            }
        };
        document.addEventListener('keydown', listener);
        return () => {
            document.removeEventListener('keydown', listener);
        };
    }, [formData]);

    return (
        <WelcomePage>
            <React.Fragment>
                <div className={styles.info}>
                    <h3>Let's get you signed in!</h3>
                    <p>Welcome to NordInsight implant database</p>
                </div>
                <form className={styles['login-form']}>
                    {errorMessage && (
                        <div className={styles.alert}>
                            <p id="error-message">{errorMessage}</p>
                        </div>
                    )}
                    <FormInput
                        type="email"
                        name="email"
                        label="Email"
                        placeholder="Enter your email"
                        onChange={handleInputChange}
                        errorMsg={errorData.email}
                    />
                    <div>
                        <FormInput
                            type="password"
                            name="password"
                            label="Password"
                            placeholder="Enter your password"
                            onChange={handleInputChange}
                            errorMsg={errorData.password}
                        />
                        <label>
                            Forgot your password?{' '}
                            <a href={'/initiate-reset'}>Click here</a> and we’ll
                            help you
                        </label>
                    </div>
                    <Button
                        className={styles.button}
                        type={ButtonType.submit}
                        onClick={() => {
                            submitLogin();
                        }}
                        loading={loginLoading}
                    >
                        Login
                    </Button>
                    {/* <label>
                        Not yet a user?{' '}
                        <a href="/sign-up/trial">Sign up here</a>
                    </label> */}
                </form>
                {showModal && (
                    <Modal
                        info={inactiveReminder}
                        updateView={() => {
                            setShowModal(!showModal);
                        }}
                    >
                        {inactiveReminder.children}
                    </Modal>
                )}
            </React.Fragment>
        </WelcomePage>
    );
};

export default Login;
