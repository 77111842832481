import Modal from "./Modal";
import styles from './DeleteUser.module.css';
import { useContext } from "react";
import ManageUserContext from "../../store/manage-user-context";
import Button, { ButtonType } from "../general-components/Button";


const DeleteUser:React.FC<{onChange: (user: any, deleteUser: boolean) => void; onClose: () => void }> = ({onChange, onClose}) => {
    const userInfoContext = useContext(ManageUserContext);

    const deleteUser = () => {
        userInfoContext.deleteUser();
        onChange(userInfoContext.user, true);
    }

    const deleteModalObj = {
        title: 'Delete User',
        message: 'Are you sure you want to delete user permanently?',
        userId: userInfoContext.user.id,
        className: styles['sub-modal'],
        children: [<div className={styles.buttons}><Button type={ButtonType.primary} onClick={deleteUser}>Yes</Button><Button type={ButtonType.secondary} onClick={onClose}>no</Button></div>],
    }

    return (<div>
            <Modal info={deleteModalObj} updateView={onClose} >{deleteModalObj.children}</Modal>
    </div>)
}

export default DeleteUser;