import PreviewLayout from './PreviewLayout';
import PreviewCard from './PreviewCard';
import PreviewCardTable from './PreviewCardTable';
import styles from './SuggestionPreview.module.css';
import { ReactNode, useEffect, useState } from 'react';
import {
    getImplantPreview,
    getManufacturerPreview,
    getProtocolPreview,
    getTypePreview,
} from '../../../../services/apiService';
import SETTING_KEYS from '../../../../constants/setting-keys';
import Status from '../../../general-components/Status';

const SuggestionPreview = ({
    type,
    id,
}: {
    type: '' | 'implant' | 'type' | 'manufacturer' | 'protocol';
    id: string;
}) => {
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState<string | ReactNode>('');
    const [info, setInfo] = useState<{ [key: string]: any }>({});
    const [snapshot, setSnapshot] = useState<ReactNode>(null);

    const getPreview = async () => {
        if (type === 'implant') {
            const res = await getImplantPreview(id);
            setTitle(res[SETTING_KEYS.model]);
            setSubtitle(res[SETTING_KEYS.type]);
            setInfo({
                [SETTING_KEYS.manufacturer]: res[SETTING_KEYS.manufacturer],
                'Updated At': new Date(res.updatedAt).toLocaleDateString(),
            });
            setSnapshot(
                <PreviewCard>
                    <p
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '30px',
                            gap: '10px',
                        }}
                    >
                        <Status status={res[SETTING_KEYS.mriStatus]} />
                        {res[SETTING_KEYS.mriStatus] === 3 ? 'MR Safe' : ''}
                        {res[SETTING_KEYS.mriStatus] === 2
                            ? 'MR Conditional'
                            : ''}
                        {res[SETTING_KEYS.mriStatus] === 1 ? 'MR Unsafe' : ''}
                        {res[SETTING_KEYS.mriStatus] === 0 ? 'MR Unknown' : ''}
                    </p>
                    {res[SETTING_KEYS.fieldStrength] && (
                        <PreviewCardTable
                            title={SETTING_KEYS.fieldStrength}
                            value={res[SETTING_KEYS.fieldStrength]}
                        />
                    )}
                    {res[SETTING_KEYS.spatialGradient] && (
                        <PreviewCardTable
                            title={SETTING_KEYS.spatialGradient}
                            value={res[SETTING_KEYS.spatialGradient]}
                        />
                    )}
                </PreviewCard>
            );
        }
        if (type === 'manufacturer') {
            const res = await getManufacturerPreview(id);
            setTitle(res.name);
            setSubtitle(res.numberOfImplants + ' implants in total');
            setInfo({});
            setSnapshot(
                <PreviewCard>
                    {res.implants.map((i: string) => (
                        <p style={{ display: 'flex' }}>{i}</p>
                    ))}
                </PreviewCard>
            );
        }
        if (type === 'type') {
            const res = await getTypePreview(id);
            setTitle(res.name);
            setSubtitle(res.numberOfImplants + ' implants in total');
            setInfo({
                [SETTING_KEYS.bodyPlacement]:
                    res[SETTING_KEYS.bodyPlacement]?.join(', '),
                // Definition: res.definition,
            });
            setSnapshot(
                <>
                    {res.definition && (
                        <PreviewCard>{res.definition}</PreviewCard>
                    )}
                </>
            );
        }
        if (type === 'protocol') {
            const res = await getProtocolPreview(id);
            setTitle(res.title);
            setSubtitle('');
            setInfo({
                'Approved by': res.approvedBy,
                'Last edited on': res.lastEditDate,
            });
            setSnapshot(
                <PreviewCard>
                    <span
                        dangerouslySetInnerHTML={{ __html: res.description }}
                    ></span>
                </PreviewCard>
            );
        }
    };

    useEffect(() => {
        getPreview();
    }, [id]);

    return (
        <PreviewLayout title={title} subtitle={subtitle} info={info}>
            {snapshot}
        </PreviewLayout>
    );
};

export default SuggestionPreview;
