import React, {
    useState,
    useEffect,
    Dispatch,
    SetStateAction,
    ReactElement,
} from 'react';
import styles from './Search.module.css';
import { autocomplete, logSearchStart } from '../../services/apiService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import SelectItem from '../general-components/SelectItem';
import Tooltip from '../ui-components/Tooltip';
import { highlighter } from '../../tools/searchFilter';

const Search: React.FC<{
    defaultSemantic?: boolean;
    defaultValue?: string;
    onSearch: Function;
    hasQuickResult: boolean;
}> = ({ defaultSemantic = false, defaultValue, onSearch, hasQuickResult }) => {
    const [implants, setImplants] = useState<any[]>([]);
    const [curImp, setCurImp] = useState<any[]>([]);
    const [searchInFocus, setSearchInFocus] = useState(false);

    const [searchHasStarted, setSearchHasStarted] = useState(false);

    const onFocus = (isInFocus: boolean) => {
        console.log(isInFocus);
        setSearchInFocus(isInFocus);
    };

    document.addEventListener(
        'click',
        function (event: MouseEvent) {
            if (
                !(event.target as HTMLDivElement).closest(
                    `.${styles['search-box']}`
                ) &&
                !(event.target as HTMLDivElement).closest(
                    `.${styles['active-search']}`
                ) &&
                !(event.target as HTMLInputElement).closest('.search-field')
            ) {
                setSearchInFocus(false);
            }
        },
        false
    );

    const [searchTerm, setSearchTerm, Input] = useInput({ type: 'text' });

    const getData = async () => {
        // const implants = await getAllImplants();
        // if (Array.isArray(implants)) {
        //     setImplants(implants);
        //     setCurImp(implants);
        // } else if (typeof implants === typeof '') {
        //     setImplants(new Array(implants));
        //     setCurImp(new Array(implants));
        // }
    };

    const [useSemantic, setUseSemantic] = useState(defaultSemantic);

    const setSemantic = (value: string, checked: boolean) => {
        setUseSemantic(checked);
    };

    useEffect(() => {
        getData();
    }, []);

    const search = () => {
        onSearch(searchTerm, useSemantic);
    };

    const updateList = async (sTerm: string) => {
        if (!searchHasStarted) {
            logSearchStart(sTerm);
            setSearchHasStarted(true);
        }
        if (sTerm.length > 0) {
            const auto = await autocomplete(sTerm);
            console.log(auto);
            setCurImp(auto);
        } else {
            setCurImp([]);
        }
    };

    function useInput({
        type,
    }: {
        type: string;
    }): [string, Dispatch<SetStateAction<string>>, ReactElement] {
        const handleKeyDown = (
            event: React.KeyboardEvent<HTMLInputElement>
        ) => {
            if (event.key === 'Enter') {
                setSearchInFocus(false);
                search();
            }
        };
        const [value, setValue] = useState(defaultValue ? defaultValue : '');
        const input = (
            <input
                autoComplete="off"
                placeholder="Search"
                value={value}
                className="search-field"
                id="search-box"
                type={type}
                onChange={(e) => {
                    if (!searchInFocus) {
                        setSearchInFocus(true);
                    }
                    setValue(e.target.value);
                    updateList(e.target.value);
                }}
                onKeyDown={handleKeyDown}
                onFocus={(e) => onFocus(true)}
            />
        );
        return [value, setValue, input];
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles['search']}>
                {Input}
                <div className={styles.icon}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />{' '}
                </div>
                {searchInFocus && curImp.length > 0 && (
                    <ul>
                        {curImp.map((i) => (
                            <li>
                                <FontAwesomeIcon icon={faMagnifyingGlass} />
                                <a href={'/search/' + i}>
                                    {highlighter(
                                        i,
                                        searchTerm,
                                        styles.highlighter
                                    )}
                                </a>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            {process.env.REACT_APP_SEMANTIC_SEARCH_ENABLED === 'true' && (
                <div className={styles.semantic}>
                    <SelectItem
                        defaultChecked={defaultSemantic}
                        onChange={setSemantic}
                        labelled={false}
                        type="checkbox"
                        value="semantic"
                    />
                    <span className={styles.label}>Enable Semantic search</span>
                    <Tooltip
                        text={
                            'By enabling semantic search you are able to find implants, not only by their model, type and manufacturer, but also through relevant bodyparts, surgery or medical conditions.'
                        }
                    />
                </div>
            )}
        </div>
    );
};

export default Search;
