import React, { useEffect, useState } from 'react';
import SETTING_KEYS from '../../constants/setting-keys';
import SelectItem from '../general-components/SelectItem';
import styles from './FilteredImplants.module.css';
import Modal from '../manage-user/Modal';
import Implant from '../implant/Implant';
import FilteredRow from './FilteredRow';
import ListToggle from '../ui-components/ListToggle';
import { onlyUnique } from '../../utils/utils';
import FilteredCard from './FilteredCard';

const FilteredImplants: React.FC<{
    onCheck?: (checkedImplants: string[]) => void;
    returned?: number;
    filteredResult: any[];
    limitingResults: any;
    hoveredConditional: string;
    hasUnsafeCheck?: boolean;
}> = ({
    onCheck,
    returned,
    filteredResult,
    limitingResults,
    hoveredConditional,
    hasUnsafeCheck,
}) => {
    const [isAllChecked, setIsAllChecked] = useState<boolean>(true);
    const [checkedImplants, setCheckedImplants] = useState<string[]>([]);
    const [includeUnsafe, setIncludeUnsafe] = useState(true);

    const [viewImplantId, setViewImplantId] = useState('');
    const [limitingIds, setLimitingIds] = useState([]);
    const [limitingFields, setLimitingFields] = useState<{
        [key: string]: string[];
    }>({});

    useEffect(() => {
        setCheckedImplants(
            filteredResult.map((imp) => {
                return imp.info._id;
            })
        );
    }, [filteredResult]);

    const uncheckUnsafe = () => {
        let updChecked;
        if (includeUnsafe) {
            const filt = filteredResult.filter((imp) => {
                return imp.info[SETTING_KEYS.mriStatus] !== 1;
            });
            updChecked = filt.map((i) => i.info._id);
            setIncludeUnsafe(false);
        } else {
            updChecked = filteredResult.map((i) => i.info._id);
            setIncludeUnsafe(true);
        }
        onCheck(updChecked);
        setCheckedImplants(updChecked);
    };

    const handleCheck = (id: any) => {
        let updChecked = checkedImplants;
        if (checkedImplants.includes(id)) {
            updChecked = checkedImplants.filter((imp) => imp !== id);
        } else {
            updChecked = [...checkedImplants, id];
        }
        onCheck(updChecked);
        setCheckedImplants(updChecked);
    };

    const handleCheckAll = () => {
        let updChecked = checkedImplants;
        if (isAllChecked) {
            updChecked = [];
            setIsAllChecked(false);
        } else {
            updChecked = filteredResult.map((imp) => imp.info._id);
            setIsAllChecked(true);
        }
        onCheck(updChecked);
        setCheckedImplants(updChecked);
    };

    const updateLimited = () => {
        let ids: string[] = [];
        let fields: { [key: string]: string[] } = {};
        if (limitingResults) {
            const unsafe = filteredResult.filter(
                (i) => i.info[SETTING_KEYS.mriStatus] === 1
            );
            if (unsafe) {
                ids = checkedImplants.filter((c) =>
                    unsafe.some((i) => i.info._id === c)
                );
            }
            if (ids.length === 0) {
                for (const [k, v] of Object.entries(limitingResults)) {
                    if (typeof v === 'object') {
                        for (const [sk, sv] of Object.entries(v)) {
                            ids.push(sv);
                            if (fields[sv] === undefined) {
                                fields[sv] = [k + '.' + sk];
                            } else {
                                fields[sv].push(k + '.' + sk);
                            }
                        }
                    } else {
                        ids.push(v as string);
                        if (fields[v as string] === undefined) {
                            fields[v as string] = [k];
                        } else {
                            fields[v as string].push(k);
                        }
                    }
                }
                ids = ids.filter((value, index, array) => {
                    return array.indexOf(value) === index;
                });
            }
        }
        setLimitingFields(fields);
        setLimitingIds(ids);
    };

    useEffect(() => {
        updateLimited();
    }, [checkedImplants]);

    return (
        <div className={styles.results}>
            {viewImplantId !== '' && (
                <Modal
                    scrollable={true}
                    info={{
                        title: '',
                        message: '',
                    }}
                    updateView={() => {
                        setViewImplantId('');
                    }}
                >
                    <div className={styles.wrapper}>
                        <Implant key={viewImplantId} id={viewImplantId} />
                    </div>
                </Modal>
            )}
            <ListToggle
                title={`${returned - limitingIds.length} other implants
                in this group`}
            >
                <div className={styles.tableContainer}>
                    <div className={styles.tableRow + ' ' + styles.header}>
                        {/* <span className={styles.checkbox}>
                        <SelectItem
                            defaultChecked={isAllChecked}
                            labelled={false}
                            type="checkbox"
                            value={'all'}
                            onChange={handleCheckAll}
                        />
                    </span> */}
                        <span id={SETTING_KEYS.model}>Model</span>
                        {/* <span id={SETTING_KEYS.type}>Type</span> */}
                        <span id={SETTING_KEYS.manufacturer}>Manufacturer</span>
                    </div>
                    <div className={styles.list}>
                        {filteredResult
                            .filter((i) => !limitingIds.includes(i.info._id))
                            .sort((a, b) =>
                                a[SETTING_KEYS.manufacturer].localeCompare(
                                    b[SETTING_KEYS.manufacturer]
                                )
                            )
                            .map((imp) => {
                                return (
                                    <FilteredRow
                                        className={`${styles.items} ${styles.tableRow}`}
                                        id={imp.info._id}
                                        limitingFields={
                                            limitingFields[imp.info._id]
                                        }
                                        info={{
                                            ...imp.info,
                                            [SETTING_KEYS.manufacturer]:
                                                imp[SETTING_KEYS.manufacturer],
                                        }}
                                        onClick={() =>
                                            setViewImplantId(imp.info._id)
                                        }
                                    />
                                );
                            })}
                    </div>
                </div>
            </ListToggle>
            <div className={styles.restrictingTitle}>
                <h4>{limitingIds.length} restricting implant(s)</h4>
                {hasUnsafeCheck && (
                    <div style={{ margin: '10px' }}>
                        <SelectItem
                            defaultChecked={includeUnsafe}
                            type="checkbox"
                            value={'Include Unsafe Implants'}
                            onChange={uncheckUnsafe}
                        />
                    </div>
                )}
            </div>
            <div className={styles.tableContainer}>
                <div className={styles.list}>
                    {filteredResult
                        .filter((i) => limitingIds.includes(i.info._id))
                        .sort((a, b) =>
                            a[SETTING_KEYS.manufacturer].localeCompare(
                                b[SETTING_KEYS.manufacturer]
                            )
                        )
                        .map((imp) => {
                            return (
                                <FilteredCard
                                    id={imp.info._id}
                                    limitingFields={limitingFields[imp.info._id]
                                        ?.map((f) =>
                                            f
                                                .replace('.spin', '')
                                                .replace('.operatingMode', '')
                                                .replace('.value', '')
                                                .replace('.gradient', '')
                                        )
                                        .filter(onlyUnique)}
                                    info={{
                                        ...imp.info,
                                        [SETTING_KEYS.manufacturer]:
                                            imp[SETTING_KEYS.manufacturer],
                                    }}
                                    onClick={() =>
                                        setViewImplantId(imp.info._id)
                                    }
                                />
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default FilteredImplants;
