import { useParams } from 'react-router-dom';
import ConditionalsView from '../ConditionalsView';
import ImplantList from '../ImplantList';
import styles from './PredefinedView.module.css';
import { useContext, useEffect, useState } from 'react';
import { FilterContext } from '../../../store/filter-context';
import TypeList from './TypeList';
import LoadingRing from '../../ui-components/LoadingRing';
import Disclaimer from '../../ui-components/Disclaimer';

const PredefinedView = () => {
    let { id } = useParams<{ id: string }>();

    const { getResultById } = useContext(FilterContext);

    const [title, setTitle] = useState('');
    const [includedTypes, setIncludedTypes] = useState<string[]>([]);
    const [includedManufacturers, setIncludedManufacturers] = useState<
        string[]
    >([]);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        (async () => {
            const res = await getResultById(id);
            setTitle(res.title);
            setIncludedTypes(res.settings.types);
            setIncludedManufacturers(res.settings.manufacturers);
            setIsLoading(false);
        })();
    }, []);

    return (
        <div className={styles.wrapper}>
            {!isLoading && (
                <>
                    <Disclaimer>
                        This feature is still being tested. Please verify
                        information of each implant individually
                    </Disclaimer>
                    <div>
                        <h1>{title}</h1>
                        <p>
                            These are the most restrictive conditionals for
                            Implant group {title}. Each value in the displayed
                            Conditionals contains the most restritive for all of
                            the implants listed on the right. The resticting
                            implants are labelled with its corresponding
                            conditional value.
                        </p>
                    </div>
                </>
            )}
            <div className={styles.filters}>
                {includedTypes?.length > 0 && (
                    <TypeList
                        title="Exact included Types:"
                        types={includedTypes}
                    />
                )}
                {includedManufacturers?.length > 0 && (
                    <TypeList
                        title="Exact included Manufacturers:"
                        types={includedManufacturers}
                    />
                )}
            </div>

            {isLoading && (
                <div className={styles.loading}>
                    <LoadingRing size="large" />
                </div>
            )}
            {!isLoading && (
                <div className={styles.implant}>
                    <ConditionalsView />
                    <ImplantList />
                </div>
            )}
        </div>
    );
};

export default PredefinedView;
