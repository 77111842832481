import styles from './CardComponent.module.css';

const CardComponent: React.FC<{
    large?: boolean;
    preview: React.ReactNode;
    title: string;
    description: string;
    extra?: React.ReactNode;
    link?: string;
}> = ({ large, preview, title, description, extra, link }) => {
    return (
        <div className={`${styles.wrapper} ${large ? styles.large : ''}`}>
            <a href={link} className={styles.box}>
                <div className={styles.preview}>{preview}</div>
                <div className={styles.info}>
                    <h4 className={styles.title}>{title}</h4>
                    <p className={styles.description}>{description}</p>
                </div>
            </a>
            {extra && large && <div className={styles.extra}>{extra}</div>}
        </div>
    );
};

export default CardComponent;
