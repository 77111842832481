import React, { ReactNode } from 'react';
import { ReactComponent as Close } from '../../icons/close.svg';
import styles from './Modal.module.css';

export interface ModalInfo {
    title: string;
    message: string;
    userId?: string;
    className?: string;
    children?: JSX.Element[];
}

const Modal: React.FC<{
    timed?: boolean;
    scrollable?: boolean;
    info: ModalInfo;
    updateView?: Function;
    children?: ReactNode;
}> = ({ timed = false, scrollable, info, updateView, children }) => {
    return (
        <div
            id={info.userId + '_popup'}
            className={`${styles.wrapper} ${info.className}}}`}
        >
            <div
                className={`${styles['popup-box']}  ${
                    scrollable ? styles['scrollable-box'] : ''
                }`}
            >
                {!timed && (
                    <div
                        className={styles['modal-close']}
                        onClick={() => {
                            updateView();
                        }}
                    >
                        <Close />
                    </div>
                )}
                <div
                    className={`${
                        scrollable
                            ? styles['scrollable-content']
                            : styles['content']
                    }`}
                >
                    <h2>{info.title}</h2>
                    <p>{info.message}</p>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Modal;
