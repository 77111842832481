import SelectItem from '../general-components/SelectItem';
import styles from './Category.module.css';

const Category = ({
    categoryString,
    isStent,
    maxStentLengthString,
    info,
    onActivePassiveToggle,
    onUpdateInfoField,
}: {
    categoryString: string;
    isStent: boolean;
    maxStentLengthString: string;
    info: any;
    onActivePassiveToggle: Function;
    onUpdateInfoField: Function;
}) => {
    const renderStent = () => {
        return (
            <div className={styles.category}>
                <label className={styles.infoLabel}>
                    Maximum Stent length (in mm)
                </label>
                <input
                    name={maxStentLengthString}
                    value={info[maxStentLengthString]}
                    type="number"
                    placeholder="Enter maximum stent length (mm)"
                    onChange={(e) =>
                        onUpdateInfoField(maxStentLengthString, e.target.value)
                    }
                />
            </div>
        );
    };

    return (
        <>
            <label className={`${styles.infoLabel} ${styles.required}`}>
                Category
            </label>
            <div className={styles.inputWrapper}>
                <SelectItem
                    checked={info[categoryString] === 'Active'}
                    type="radio"
                    value="Active"
                    onChange={() => onActivePassiveToggle('Active')}
                />
                <SelectItem
                    checked={info[categoryString] === 'Passive'}
                    type="radio"
                    value="Passive"
                    onChange={() => onActivePassiveToggle('Passive')}
                />
                <SelectItem
                    checked={info[categoryString] === 'Stent'}
                    type="radio"
                    value="Stent"
                    onChange={() => onActivePassiveToggle('Stent')}
                />
            </div>
            {isStent && renderStent()}
        </>
    );
};

export default Category;
