import { useEffect, useState } from 'react';
import PROTOCOL_KEYS from '../../constants/protocol-keys';
import Button, { ButtonType } from '../general-components/Button';
import editStyles from './EditInternalProtocol.module.css';
import styles from './EditReferences.module.css';
import { Clear } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const EditReferences: React.FC<{
    references: string[];
    onChange: Function;
}> = ({ references, onChange }) => {
    const [ref, setRef] = useState(references);

    const handleChange = (val: string, index: number) => {
        const tempArr = [...ref];
        tempArr[index] = val;
        setRef(tempArr);
    };

    const addField = () => {
        const tempArr = [...ref];
        tempArr.push('');
        setRef(tempArr);
    };

    const removeField = (i: number) => {
        const tempArr = [...ref];
        tempArr.splice(i, 1);
        setRef(tempArr);
    };

    useEffect(() => {
        onChange(PROTOCOL_KEYS.ref, ref);
    }, [ref]);

    return (
        <div>
            <label className={editStyles.infoLabel}>
                References, links, laws or other evidence
            </label>
            {references.map((r: string, index: number) => (
                <div className={styles.input}>
                    <input
                        placeholder="Add new reference"
                        type="text"
                        name={PROTOCOL_KEYS.ref}
                        value={r || ''}
                        onChange={(e) => handleChange(e.target.value, index)}
                    />
                    <Button
                        type={ButtonType.tietary}
                        onClick={() => removeField(index)}
                    >
                        <FontAwesomeIcon icon={faXmark} color="red" />
                    </Button>
                </div>
            ))}
            <div style={{ marginTop: '0px' }}>
                {' '}
                <Button type={ButtonType.primary} onClick={addField}>
                    +
                </Button>
            </div>
        </div>
    );
};

export default EditReferences;
