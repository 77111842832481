import { useContext, useEffect, useState } from 'react';
import {
    getImplantsByManufacturer,
    getImplantsFromManufacturer,
} from '../../services/apiService';
import Results from '../search/Results';
import styles from './ImplantOverview.module.css';
import AuthContext from '../../store/auth-context';
import RIGHTS_KEYS from '../../constants/rights-keys';
import Button, { ButtonType } from '../general-components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBoxArchive,
    faCircle,
    faClipboardCheck,
    faNotesMedical,
} from '@fortawesome/free-solid-svg-icons';
import {
    faCircleCheck,
    faNoteSticky,
} from '@fortawesome/free-regular-svg-icons';

const ImplantOverview: React.FC<{ manufacturerId?: string }> = ({
    manufacturerId,
}) => {
    const userContext = useContext(AuthContext);

    const editStatuses = ['Drafts', 'Completed', 'Verified', 'Archived'];

    const [tabStatus, setTabStatus] = useState('Completed');

    const [draftImplants, setDraftImplants] = useState([]);
    const [completedImplants, setCompletedImplants] = useState([]);
    const [verifiedImplants, setVerifiedImplants] = useState([]);
    const [archivedImplants, setArchivedImplants] = useState([]);

    const fetchImplants = async () => {
        let implantsRes;
        if (userContext.orRights([RIGHTS_KEYS.manageImplant])) {
            implantsRes = await getImplantsFromManufacturer();
        } else if (userContext.orRights([RIGHTS_KEYS.manageAllImplants])) {
            implantsRes = await getImplantsByManufacturer(manufacturerId);
        }
        setDraftImplants(implantsRes.drafts);
        setCompletedImplants(implantsRes.completed);
        setVerifiedImplants(implantsRes.verified);
        setArchivedImplants(implantsRes.archived);
    };

    const iconFromStatus = (status: string) => {
        if (status === 'Drafts') return faNoteSticky;
        if (status === 'Completed') return faClipboardCheck;
        if (status === 'Verified') return faCircleCheck;
        if (status === 'Archived') return faBoxArchive;
    };

    const openAddImplant = () => {
        window.location.href = '/add-implant';
    };

    const hasChanged = () => {
        fetchImplants();
    };

    useEffect(() => {
        fetchImplants();
    }, [manufacturerId]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.tabs}>
                {editStatuses.map((status) => (
                    <button
                        className={`${styles['status-button']} ${
                            tabStatus === status ? styles['active-button'] : ''
                        }`}
                        name={status}
                        onClick={(e) =>
                            setTabStatus((e.target as HTMLButtonElement).name)
                        }
                    >
                        <FontAwesomeIcon
                            icon={iconFromStatus(status)}
                            color="var(--main-white)"
                        />
                        {status}
                    </button>
                ))}

                <Button
                    className={styles.add}
                    type={ButtonType.submit}
                    onClick={openAddImplant}
                >
                    <FontAwesomeIcon
                        icon={faNotesMedical}
                        color="var(--main-white)"
                    />
                    New Implant
                </Button>
            </div>
            <div className={styles['implant-list']}>
                {tabStatus === 'Drafts' && (
                    <>
                        {draftImplants.length > 0 && (
                            <Results
                                defaultResults={draftImplants}
                                isManufacturer={true}
                                editStatus={1}
                                onChange={hasChanged}
                            />
                        )}
                        {draftImplants.length === 0 && (
                            <span>
                                <h2>No Drafted implants</h2>
                            </span>
                        )}
                    </>
                )}
                {tabStatus === 'Completed' && (
                    <>
                        {completedImplants.length > 0 && (
                            <Results
                                defaultResults={completedImplants}
                                isManufacturer={true}
                                editStatus={2}
                                onChange={hasChanged}
                            />
                        )}
                        {completedImplants.length === 0 && (
                            <span>
                                <h2>No Completed implants</h2>
                            </span>
                        )}
                    </>
                )}
                {tabStatus === 'Verified' && (
                    <>
                        {verifiedImplants.length > 0 && (
                            <Results
                                defaultResults={verifiedImplants}
                                isManufacturer={true}
                                editStatus={3}
                                onChange={hasChanged}
                            />
                        )}
                        {verifiedImplants.length === 0 && (
                            <span>
                                <h2>No Verified implants</h2>
                            </span>
                        )}
                    </>
                )}
                {tabStatus === 'Archived' && (
                    <>
                        {archivedImplants.length > 0 && (
                            <Results
                                defaultResults={archivedImplants}
                                isManufacturer={true}
                                editStatus={0}
                                onChange={hasChanged}
                            />
                        )}
                        {archivedImplants.length === 0 && (
                            <span>
                                <h2>No Archived implants</h2>
                            </span>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default ImplantOverview;
