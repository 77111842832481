import { useEffect, useState } from 'react';
import styles from './SourceFilePage.module.css';
import { listSourceFiles } from '../../services/apiService';

const SourceFilePage = () => {
    const [files, setFiles] = useState([]);

    const getFiles = async () => {
        const f = await listSourceFiles();

        setFiles(f);
    };

    useEffect(() => {
        getFiles();
    }, []);

    return (
        <div className={styles.wrapper}>
            {files.map((file: any) => (
                <p>
                    <a
                        href={'https://files.nordinsight.com/source/' + file}
                        target="_blank"
                    >
                        {file}
                    </a>
                </p>
            ))}
        </div>
    );
};

export default SourceFilePage;
