import { useContext } from 'react';
import ManageUserContext from '../../store/manage-user-context';
import UserRow from './UserRow';

const ContextualUserRow: React.FC<{
    onChange?: (obj: any, remove?: boolean) => void;
    deptLicenses: number;
}> = ({ onChange, deptLicenses }) => {
    const userContext = useContext(ManageUserContext);
    return (
        <UserRow
            userInfo={{
                ...userContext.user,
                expiryDate: userContext.getExpiry(),
            }}
            isInvite={false}
            onChange={onChange}
            deptLicenses={deptLicenses}
        />
    );
};

export default ContextualUserRow;
