import styles from './TypeList.module.css';

const TypeList = ({ title, types }: { title: string; types: string[] }) => {
    return (
        <div className={styles.settings}>
            <h4>{title}</h4>
            <ul>
                {types.map((type) => (
                    <li key={type}>{type}</li>
                ))}
            </ul>
        </div>
    );
};

export default TypeList;
