import { useState } from 'react';
import styles from './ConditionsTip.module.css';

const ConditionsTip: React.FC<{
    placeholder: string;
    title: string;
    content: { [key: string]: any };
}> = ({ placeholder, title, content }) => {
    const [showTip, setShowTip] = useState(false);

    const open = () => {
        setShowTip(true);
    };

    const close = () => {
        setShowTip(false);
    };

    return (
        <div className={styles.wrapper}>
            <p
                className={styles.placeholder}
                onMouseEnter={open}
                onMouseLeave={close}
            >
                {placeholder}
            </p>
            {showTip && (
                <div className={styles.tip}>
                    <h4>{title}</h4>
                    <div className={styles.content}>
                        {Object.keys(content).map((key) => (
                            <>
                                {content[key] !== undefined &&
                                    content[key] !== null &&
                                    content[key] !== '' && (
                                        <div>
                                            <h5>{key}</h5>
                                            <p>{content[key]}</p>
                                        </div>
                                    )}
                            </>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ConditionsTip;
