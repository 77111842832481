import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

import {
    getNewContext,
    GETREQUESTOPTIONS,
    logout,
    UserEditedInfo,
    UserSettings,
} from '../services/apiService';
import RIGHTS_KEYS from '../constants/rights-keys';

export interface StoredContext {
    isLoggedIn: boolean;
    actor?: {
        rights: string[];
        role: string;
    };
    department?: {
        _id: string;
        name: string;
    };
    deptPermissions?: string[];
    expiryDate?: Date;
    contextExpriry?: Date;
    token?: string;
    fontSize?: string;
}

const DEFAULT_CONTEXT: StoredContext = {
    isLoggedIn: false,
    actor: {
        rights: [''],
        role: 'unauthorized',
    },
    expiryDate: new Date(),
    token: null,
};

const AuthContext = React.createContext({
    isLoggedIn: false,
    actor: {
        rights: [''],
        role: 'unauthorized',
    },
    orRights: (rights: string[]): boolean => {
        return;
    },
    hasPermission: (perm: string): boolean => {
        return;
    },
    getContext: (): StoredContext => {
        return;
    },
    getAllInfo: async (): Promise<any> => {},
    getAllSettings: async (): Promise<any> => {},
    onLogout: () => {},
    onLogin: async (email: string, password: string) => {},
    retrieveContext: () => {},

    showTrialPrompt: (): boolean => {
        return;
    },
    setShowTrialPrompt: (state: boolean) => {},

    theme: '',
    themeToggle: () => {},
    updateUserInfo: async (user: UserEditedInfo): Promise<any> => {},

    updateUserSettings: async (user: UserSettings): Promise<any> => {},
});

export const AuthContextProvider = (props: any) => {
    let [ctx, setCtx] = useState<StoredContext>(DEFAULT_CONTEXT);
    let [theme, setTheme] = useState('dark');

    const themeToggle = () => {
        if (theme === 'light') {
            setTheme('dark');
            localStorage.setItem('selectedTheme', 'dark');
        } else {
            setTheme('light');
            localStorage.setItem('selectedTheme', 'light');
        }
    };

    const orRights = (rights: string[]) => {
        return rights.some((val) => getContext().actor.rights.includes(val));
    };

    const hasPermission = (perm: string) => {
        return getContext()?.deptPermissions.includes(perm);
    };

    const retrieveContext = async () => {
        getNewContext().then((newContext) => {
            let context: StoredContext = ctx;
            if (
                newContext.isLoggedIn !== undefined &&
                newContext.isLoggedIn !== null
            ) {
                context.isLoggedIn = newContext.isLoggedIn;
            }
            if (newContext.actor !== undefined && newContext.actor !== null) {
                context.actor = newContext.actor;
            }
            if (
                newContext.department !== undefined &&
                newContext.department !== null
            ) {
                context.department = newContext.department;
            }
            if (newContext.token !== undefined && newContext.token !== null) {
                context.token = newContext.token;
            }
            if (newContext.user !== undefined && newContext.user !== null) {
                if (newContext.user.expiryDate !== undefined) {
                    context.expiryDate = newContext.user.expiryDate;
                }
                if (newContext.user.deptPermissions !== undefined) {
                    context.deptPermissions = newContext.user.deptPermissions;
                }
            }

            localStorage.setItem('context', JSON.stringify(context));
            setCtx(context);
        });
    };

    const retrieveContextCallback = useCallback(retrieveContext, [
        retrieveContext,
        setCtx,
    ]);

    const getAllInfo = async () => {
        const res = await axios.get('/api/user/info', GETREQUESTOPTIONS());
        return res.data;
    };

    const updateUserInfo = async (user: UserEditedInfo) => {
        const res = await axios.post(
            '/api/user-profile/edit-info',
            user,
            GETREQUESTOPTIONS()
        );

        return res.status === 200;
    };

    const getUserSettings = async () => {
        const res = await axios.get(
            '/api/user-profile/settings',
            GETREQUESTOPTIONS()
        );
        return res.data;
    };

    const updateUserSettings = async (user: UserSettings) => {
        const res = await axios.post(
            '/api/user-profile/edit-settings',
            user,
            GETREQUESTOPTIONS()
        );

        if (res.status === 200 && ctx.fontSize !== user.fontSize) {
            const tempCtx = getContext();
            tempCtx.fontSize = res.data.settings.fontSize;
            localStorage.setItem('context', JSON.stringify(tempCtx));
            setCtx(tempCtx);
        }

        return res.status === 200;
    };

    const getContext = (): StoredContext => {
        const storedContext = JSON.parse(
            localStorage.getItem('context') as string
        );

        if (storedContext !== null) {
            return storedContext;
        } else if (ctx !== undefined) {
            return ctx;
        }
    };

    const loginHandler = async (email: string, password: string) => {
        const res = await axios
            .post(
                '/api/user/login',
                {
                    email: email,
                    password: password,
                },
                {
                    withCredentials: true,
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Credentials': true,
                    },
                }
            )
            .catch((error) => {
                return error.response;
            });
        if (res.status === 200) {
            const context: StoredContext = {
                isLoggedIn: res.data.isLoggedIn,
                actor: res.data.actor,
                department: res.data.department,
                deptPermissions: res.data.user.deptPermissions,
                expiryDate: res.data.user.expiryDate,
                token: res.data.token,
                fontSize: res.data.user.fontSize,
            };
            const d = new Date();
            d.setTime(d.getTime() + 60 * 1000);
            context.contextExpriry = d;
            ctx = context;
            localStorage.setItem('context', JSON.stringify(context));

            if (
                (ctx.actor.role === 'TrialUser' &&
                    new Date(ctx.expiryDate).getTime() <
                        new Date().getTime() + 3 * 24 * 60 * 60 * 1000) ||
                ctx.actor.role === 'LiteUser'
            ) {
                setShowTrialPrompt(true);
            } else {
                setShowTrialPrompt(false);
            }

            if (ctx.fontSize) {
                document.documentElement.style.fontSize = ctx.fontSize + 'px';
            }

            // TODO mathias: Figure out the right sretup for context and login
            // retrieveContext();
            window.location.reload();
            // if (ctx.actor.role === 'DeptAdmin') {
            //     window.location.assign('/users');
            // }
            // if (ctx.actor.rights.includes(RIGHTS_KEYS.manageImplant)) {
            //     window.location.assign('/implants');
            // }
        }
        return res;
    };

    const logoutHandler = async () => {
        const status = await logout();

        if (status === 200) {
            localStorage.removeItem('context');
            window.location.assign('/');
        }
    };

    const showTrialPrompt = () => {
        return localStorage.getItem('showTrialPrompt') === 'true';
    };

    const setShowTrialPrompt = (state: boolean) => {
        console.log(state);
        localStorage.setItem('showTrialPrompt', state ? 'true' : 'false');
    };

    useEffect(() => {
        const ctxLoop = setInterval(retrieveContextCallback, 4 * 60 * 1000);
        return () => {
            clearInterval(ctxLoop);
        };
    }, [retrieveContextCallback]);
    useEffect(() => {
        const selectedTheme = localStorage.getItem('selectedTheme');
        if (selectedTheme === 'light') {
            setTheme('light');
        }
    }, []);
    const contextValue = {
        actor: ctx.actor,
        isLoggedIn: ctx.isLoggedIn,
        orRights: orRights,
        hasPermission: hasPermission,
        getContext: getContext,
        getAllInfo: getAllInfo,
        getAllSettings: getUserSettings,
        onLogout: logoutHandler,
        onLogin: loginHandler,
        retrieveContext: retrieveContext,

        showTrialPrompt: showTrialPrompt,
        setShowTrialPrompt: setShowTrialPrompt,

        theme: theme,
        themeToggle: themeToggle,

        updateUserInfo: updateUserInfo,

        updateUserSettings: updateUserSettings,
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
