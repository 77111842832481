import { useParams, useSearchParams } from 'react-router-dom';
import styles from './AllImplants.module.css';
import AllImplantsList from './AllImplantsList';
import { useEffect, useState } from 'react';

const AllImplantsPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [selectedCategory, setSelectedCategory] = useState<
        '' | 'types' | 'manufacturers'
    >('');
    const [defaultVal, setDefaultVal] = useState('');

    useEffect(() => {
        const type = searchParams.get('type');
        const manufacturer = searchParams.get('manufacturer');
        console.log(type);
        if (type) {
            setSelectedCategory('types');
            setDefaultVal(type);
        } else if (manufacturer) {
            setSelectedCategory('manufacturers');
            setDefaultVal(manufacturer);
        }
    }, [searchParams]);

    return (
        <div className={styles.wrapper}>
            <h2>Implant database</h2>
            <div className={styles.tabs}>
                <div
                    onClick={() => setSelectedCategory('types')}
                    className={`${styles.tab} ${
                        selectedCategory === 'types' ? styles.selected : ''
                    }`}
                >
                    Types
                </div>
                <div
                    onClick={() => setSelectedCategory('manufacturers')}
                    className={`${styles.tab} ${
                        selectedCategory === 'manufacturers'
                            ? styles.selected
                            : ''
                    }`}
                >
                    Manufacturers
                </div>
            </div>
            <div className={styles.lists}>
                <AllImplantsList
                    category={selectedCategory}
                    defaultValue={defaultVal}
                />
            </div>
        </div>
    );
};

export default AllImplantsPage;
