import React, { useState } from 'react';
import styles from './CommentForm.module.css';
import CustomTextArea from './CustomTextArea';
import Button, { ButtonType } from '../../general-components/Button';
import UserImageIcon from '../../general-components/UserImageIcon';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';

interface ICommentForm {
    comment?: string;
    placeholder?: string;
    onSubmit: (c: string) => void;
    onCancel?: () => void;
    isEdit?: boolean;
}

const CommentForm: React.FC<ICommentForm> = ({
    comment,
    placeholder,
    onSubmit,
    onCancel,
    isEdit,
}) => {
    const [currComment, setCurrComment] = useState(comment);

    const commentChange = (newComment: string) => {
        setCurrComment(newComment);
    };

    const submit = () => {
        onSubmit(currComment);
        setCurrComment('');
    };

    const saveButtonTitle = isEdit ? 'Save' : 'Add';

    return (
        <form
            onSubmit={() => onSubmit(currComment)}
            className={isEdit ? styles['edit-form'] : styles['comment-form']}
        >
            {!isEdit && <UserImageIcon />}
            {isEdit && (
                <Button
                    className={styles.button}
                    small={true}
                    type={ButtonType.cancel}
                    onClick={onCancel}
                >
                    <CloseIcon />
                </Button>
            )}
            <CustomTextArea
                currComment={currComment}
                commentChange={commentChange}
                isEdit={isEdit}
                placeholder={placeholder}
                submit={submit}
                onCancel={onCancel}
            />
            <Button
                small={true}
                className={styles.button}
                type={ButtonType.tietary}
                onClick={(e: SubmitEvent) => {
                    e.preventDefault();
                    submit();
                }}
            >
                <SendIcon />
            </Button>
        </form>
    );
};

export default CommentForm;
