import React, { useState, useEffect } from 'react';
import {
    createSynonym,
    getAllSynonyms,
    updateSynonymById,
    deleteSynonymById,
    configElastic,
} from '../../services/apiService';
import Button, { ButtonType } from '../general-components/Button';
import ConfirmationModal from '../general/modal/ConfirmationModal';

const ManageSynonyms: React.FC = () => {
    const [synonyms, setSynonyms] = useState<{ _id: string; value: string }[]>(
        []
    );
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const fetchSynonyms = async () => {
            const res = await getAllSynonyms();
            setSynonyms(res.data);
        };

        fetchSynonyms();
    }, []);

    const handleSave = async (_id: string, value: string) => {
        if (_id) {
            await updateSynonymById(_id, value);
            const updatedSynonyms = synonyms.map((synonym) =>
                synonym._id === _id ? { ...synonym, value } : synonym
            );
            setSynonyms(updatedSynonyms);
        } else {
            const res = await createSynonym(value);
            const updatedSynonyms = synonyms.map((synonym) =>
                synonym._id === _id
                    ? { ...synonym, _id: res.data._id, value }
                    : synonym
            );
            setSynonyms(updatedSynonyms);
        }
    };

    const handleDelete = async (_id: string) => {
        if (_id) {
            await deleteSynonymById(_id);
        }
        const updatedSynonyms = synonyms.filter(
            (synonym) => synonym._id !== _id
        );
        setSynonyms(updatedSynonyms);
    };

    const handleAdd = () => {
        setSynonyms([...synonyms, { _id: '', value: '' }]);
    };

    const handleConfigElastic = async () => {
        await configElastic();
        setShowModal(false);
    };

    return (
        <div>
            <h2>Manage Synonyms</h2>
            <div>
                {synonyms.map((synonym, index) => (
                    <div
                        key={index}
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <input
                            type="text"
                            defaultValue={synonym.value}
                            onChange={(e) =>
                                setSynonyms(
                                    synonyms.map((s) =>
                                        s._id === synonym._id
                                            ? { ...s, value: e.target.value }
                                            : s
                                    )
                                )
                            }
                        />
                        <Button
                            type={ButtonType.submit}
                            onClick={() =>
                                handleSave(synonym._id, synonym.value)
                            }
                        >
                            Save
                        </Button>
                        <Button
                            type={ButtonType.cancel}
                            onClick={() => handleDelete(synonym._id)}
                        >
                            Delete
                        </Button>
                    </div>
                ))}
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '20px',
                }}
            >
                <Button type={ButtonType.primary} onClick={handleAdd}>
                    Add
                </Button>
                <Button
                    type={ButtonType.primary}
                    onClick={() => setShowModal(true)}
                >
                    Run Config Elastic
                </Button>
            </div>
            {showModal && (
                <ConfirmationModal
                    title="Confirm Action"
                    message="Are you sure you want to run configElastic?"
                    confirmText="Confirm"
                    cancelText="Cancel"
                    onConfirm={handleConfigElastic}
                    onClose={() => setShowModal(false)}
                />
            )}
        </div>
    );
};

export default ManageSynonyms;
