import styles from './Organizations.module.css';
import ActiveOrganizations from './ActiveOrganizations';

import Guests from './Guests';

const Organizations = () => {
    return (
        <div className={styles.wrapper}>
            <h1>Manage Organizations and Users</h1>
            <ActiveOrganizations />
            <Guests />
        </div>
    );
};

export default Organizations;
