import { useContext, useEffect, useState } from 'react';
import {
    getAllInternalProtocols,
    getProtocolFromImplantId,
    linkProtocolAndImplant,
    unlinkProtocolAndImplant,
} from '../../../services/apiService';
import AuthContext from '../../../store/auth-context';
import styles from './ProtocolLink.module.css';
import ModalLayout from '../../general/modal/ModalLayout';
import Button, { ButtonType } from '../../general-components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import ComponentSelectList from '../../general/select/ComponentSelectList';
import CardComponent from '../../general/Cards/CardComponent';

const ProtocolLink: React.FC<{ implantId: string }> = ({ implantId }) => {
    const ctx = useContext(AuthContext);

    const DEFAULT = { Title: '', id: '' };

    const [linkedProtocol, setLinkedProtocol] = useState<{
        Title: string;
        id: string;
    }>(DEFAULT);
    const [hasLink, setHasLink] = useState(false);
    const [protocolList, setProtocolList] = useState([]);
    const [isEditing, setIsEditing] = useState(false);

    const getLinkedProtocol = async () => {
        const p = await getProtocolFromImplantId(implantId);
        if (p === 'No protocol') {
            setHasLink(false);
        } else {
            setHasLink(true);
            setLinkedProtocol({ Title: p.Title, id: p._id });
        }
    };

    const getProtocols = async () => {
        const p = await getAllInternalProtocols();
        setProtocolList(p);
    };

    useEffect(() => {
        if (isEditing) {
            getProtocols();
        }
    }, [isEditing]);

    useEffect(() => {
        getLinkedProtocol();
    }, [implantId]);

    const linkProtocol = (name: string, protocolId: string) => {
        linkProtocolAndImplant(protocolId, implantId);
        setLinkedProtocol({ Title: name, id: protocolId });
        setIsEditing(false);
        setHasLink(true);
    };

    const unlinkProtocol = (name: string, protocolId: string) => {
        if (linkedProtocol.Title === name) {
            unlinkProtocolAndImplant(protocolId, implantId);
            setLinkedProtocol(DEFAULT);
            setHasLink(false);
        }
    };

    return (
        <>
            {isEditing && (
                <ModalLayout
                    hasCloseButton={true}
                    size="large"
                    onClose={() => setIsEditing(false)}
                >
                    <h4 className={styles.selectTitle}>
                        Choose a Protocol to link
                    </h4>
                    <div className={styles.modal}>
                        <ComponentSelectList
                            items={protocolList.map((p) => ({
                                comp: (
                                    <CardComponent
                                        key={p._id}
                                        preview={
                                            <FontAwesomeIcon icon={faLink} />
                                        }
                                        title={p.Title}
                                        description={new Date(
                                            p.createdAt
                                        ).toLocaleDateString()}
                                    />
                                ),
                                id: p._id,
                                selected: p._id === linkedProtocol.id,
                            }))}
                            onChange={(id, selected) => {
                                if (selected) {
                                    linkProtocol(
                                        protocolList.find((p) => p._id === id)
                                            .Title,
                                        id
                                    );
                                } else {
                                    unlinkProtocol(
                                        protocolList.find((p) => p._id === id)
                                            .Title,
                                        id
                                    );
                                }
                            }}
                        />
                        <Button
                            onClick={() => setIsEditing(false)}
                            type={ButtonType.primary}
                        >
                            Close
                        </Button>
                    </div>
                </ModalLayout>
            )}
            {ctx.hasPermission('Manage Protocols') && !hasLink && (
                <Button
                    type={ButtonType.dept}
                    onClick={() => setIsEditing(true)}
                >
                    <FontAwesomeIcon icon={faLink} />
                    Link Protocol
                </Button>
            )}
            {hasLink && (
                <div className={styles.link}>
                    <h4>
                        Linked Protocol
                        {ctx.hasPermission('Manage Protocols') && (
                            <span onClick={() => setIsEditing(true)}>
                                <FontAwesomeIcon icon={faPenToSquare} />
                            </span>
                        )}
                    </h4>{' '}
                    <p>
                        <FontAwesomeIcon icon={faLink} />
                        <a href={'/protocols/' + linkedProtocol.id}>
                            {linkedProtocol.Title}
                        </a>
                    </p>
                </div>
            )}
        </>
    );
};

export default ProtocolLink;
