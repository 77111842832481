import { useEffect, useState } from 'react';
import { getAllInternalProtocols } from '../../services/apiService';
import styles from './ProtocolList.module.css';
import { Article } from '@mui/icons-material';
import PROTOCOL_KEYS from '../../constants/protocol-keys';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { onlyUnique } from '../../utils/utils';

const ProtocolList = () => {
    const [protocols, setProtocols] = useState([]);

    useEffect(() => {
        const getData = async () => {
            const p = await getAllInternalProtocols();
            console.log(p);
            setProtocols(p);
        };
        getData();
    }, []);

    return (
        <div className={styles.wrapper}>
            <h2>Department Protocols</h2>
            <div className={styles.list}>
                {protocols.length === 0 && (
                    <div className={styles.noresult}>
                        <div className={styles.loupe}>
                            <FontAwesomeIcon icon={faCircleXmark} />
                        </div>
                        <h3>No Verified Protocols yet</h3>
                    </div>
                )}
                {protocols.map((p) => {
                    return (
                        <a
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '15px',
                            }}
                            className={styles.item}
                            href={'/protocols/' + p._id}
                        >
                            <div style={{ transform: 'translateY(3px)' }}>
                                <Article />
                            </div>
                            <div className={styles.details}>
                                <div className={styles.itemHeader}>
                                    <div>
                                        <h3>{p[PROTOCOL_KEYS.title]}</h3>
                                        <p>
                                            {p.editLogProtocol
                                                .map((l: any) => l.name)
                                                .filter(onlyUnique)
                                                .join(', ')}
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexFlow: 'column',
                                            gap: '3px',
                                            textAlign: 'right',
                                            fontSize: '0.9rem',
                                        }}
                                    >
                                        <p className={styles.date}>
                                            Created on{' '}
                                            {new Date(
                                                p.createdAt
                                            ).toLocaleDateString()}
                                        </p>
                                        {p[PROTOCOL_KEYS.expiry] !== null && (
                                            <p className={styles.date}>
                                                Expires{' '}
                                                {new Date(
                                                    p[PROTOCOL_KEYS.expiry]
                                                ).toLocaleDateString()}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: p[PROTOCOL_KEYS.description],
                                    }}
                                ></p>
                            </div>
                        </a>
                    );
                })}
            </div>
        </div>
    );
};

export default ProtocolList;
