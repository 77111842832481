import styles from './Model.module.css';

const Model = ({
    modelString,
    info,
    onUpdateInfoField,
}: {
    modelString: string;
    info: any;
    onUpdateInfoField: Function;
}) => {
    return (
        <div className={styles.model}>
            <label className={`${styles.infoLabel} ${styles.required}`}>
                Model
            </label>
            <input
                name={modelString}
                type="text"
                placeholder="Enter model name"
                value={info[modelString]}
                onChange={(e) => onUpdateInfoField(modelString, e.target.value)}
            />
        </div>
    );
};

export default Model;
