import RichText from '../general-components/RichText';

const MriArc = ({
    description,
    d,
    onUpdateConditionalField,
}: {
    description: string;
    d: string;
    onUpdateConditionalField: Function;
}) => {
    return (
        <RichText
            description={description}
            onChange={(richText) => onUpdateConditionalField(d, richText)}
        />
    );
};

export default MriArc;
