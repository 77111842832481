import FormInput from '../general-components/FormInput';
import styles from './SignupForm.module.css';

const UserInfoForm: React.FC<{
    dict: any;
    handleInputChange: any;
    errorData: any;
    hasRoleSelect: boolean;
}> = ({ dict, handleInputChange, errorData, hasRoleSelect }) => {
    console.log('UserInfoForm', errorData);
    return (
        <>
            <FormInput
                type="text"
                name="name"
                label={dict.name}
                placeholder="Enter your name"
                onChange={handleInputChange}
                errorMsg={errorData.name}
            />
            <FormInput
                type="email"
                name="email"
                label={dict.email}
                placeholder="Enter your email"
                onChange={handleInputChange}
                errorMsg={errorData.email}
            />
            <FormInput
                type="password"
                name="password"
                label={dict.password}
                placeholder="Enter your password"
                onChange={handleInputChange}
                errorMsg={errorData.password}
            />
            <FormInput
                type="password"
                name="repeatPassword"
                label={dict.repeatPassword}
                placeholder="Enter your password"
                onChange={handleInputChange}
                errorMsg={errorData.repeatPassword}
            />

            {hasRoleSelect && (
                <>
                    <select
                        name="actor"
                        defaultValue={'default'}
                        onChange={handleInputChange}
                    >
                        <option value="default" disabled>
                            Role
                        </option>
                        <option value="Radiographer">Radiographer</option>
                        <option value="Radiologist">Radiologist</option>
                    </select>
                    <span className={styles.error} aria-live="polite">
                        {errorData.actor}
                    </span>
                </>
            )}
        </>
    );
};

export default UserInfoForm;
