import SignupLayout from '../ui-components/SignupLayout';
import React, { useEffect, useState } from 'react';
import styles from './SignupPage.module.css';
import Button, { ButtonType } from '../general-components/Button';
import { confirmEmail } from '../../services/apiService';

const FailedToConfirm = () => (
    <React.Fragment>
        <h3>Something went wrong.</h3>
        <p>Please contact us on info@nordinsight.com</p>
        <Button
            className={styles.button}
            type={ButtonType.primary}
            onClick={() => {
                window.location.href = '/';
            }}
        >
            Return to login page
        </Button>
    </React.Fragment>
);

const Confirmed = () => (
    <React.Fragment>
        <h3>You are now registered.</h3>
        <p style={{ marginBottom: '30px' }}>
            You have 7 days access to NordInsight's extensive Implant Library.
            <br />
            <br />
            If you want an additional 2 months free access and access to all our
            features for you and your department, book an introduction call.
        </p>
        <Button
            className={styles.button}
            type={ButtonType.submit}
            onClick={() => {
                const win = window.open(
                    process.env.REACT_APP_MEETING_LINK,
                    '_blank'
                );
                window.location.href = '/';
                win.focus();
            }}
        >
            Book meeting
        </Button>
        <Button
            className={styles.button}
            type={ButtonType.primary}
            onClick={() => {
                window.location.href = '/';
            }}
        >
            Return to login page
        </Button>
    </React.Fragment>
);

const ConfirmEmailPage: React.FC = () => {
    const [confirmed, setConfirmed] = useState(false);

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get('id');

    const emailConfirmation = async () => {
        console.log(id);
        const emailConfirmed = await confirmEmail(id);
        console.log(emailConfirmed);
        setConfirmed(emailConfirmed);
    };

    useEffect(() => {
        emailConfirmation();
    }, []);

    return (
        <SignupLayout>
            {confirmed && <Confirmed />}
            {!confirmed && <FailedToConfirm />}
        </SignupLayout>
    );
};

export default ConfirmEmailPage;
