import React from 'react';
import SETTING_KEYS from '../../constants/setting-keys';
import styles from './Conditional.module.css';
import WaitingPeriod from './WaitingPeriod';
import FieldStrength from './FieldStrength';
import SpatialGradient from './SpatialGradient';
import Artefacts from './Artefacts';
import { SAR } from './SpecificAbsorptionRate';
import Temperature from './Temperature';
import ConditionalTextField from './ConditionalTextField';
import MriArc from './MriArc';
import SlewRate from './SlewRate';
import Prep from './Prep';

const Conditional = ({
    settings,
    conditionals,
    fieldStrengths,
    autofilledFields,
    showWarningIcon,
    itemsRef,
    onSetShowWarningIcon,
    onSetAutoFilledFields,
    onFieldStrengthChange,
    onUpdateConditionalField,
    onResetHighlights,
    onUpdateConditional,
}: {
    settings: any[];
    conditionals: any;
    fieldStrengths: any;
    autofilledFields?: any;
    showWarningIcon: any;
    itemsRef: any;
    onSetShowWarningIcon: Function;
    onSetAutoFilledFields?: Function;
    onFieldStrengthChange: (e: any) => void;
    onUpdateConditionalField: Function;
    onResetHighlights?: Function;
    onUpdateConditional: Function;
}) => {
    const renderInput = (d: string, i: number) => {
        const value = conditionals[d];

        let autofilled = autofilledFields === undefined ? '' : autofilledFields;

        switch (d) {
            case SETTING_KEYS.fieldStrength:
                return (
                    <FieldStrength
                        value={value}
                        fieldStrengths={fieldStrengths}
                        onFieldStrengthChange={onFieldStrengthChange}
                    />
                );
            case SETTING_KEYS.waitingPeriod:
                return (
                    <WaitingPeriod
                        d={d}
                        value={value}
                        conditionals={conditionals}
                        onUpdateConditionalField={onUpdateConditionalField}
                    />
                );
            case SETTING_KEYS.spatialGradient:
                return (
                    <SpatialGradient
                        d={d}
                        value={value}
                        autofilledFields={autofilled}
                        onUpdateConditionalField={onUpdateConditionalField}
                        onResetHighlights={onResetHighlights}
                    />
                );
            case SETTING_KEYS.artefacts:
                return (
                    <Artefacts
                        d={d}
                        value={value}
                        autofilledFields={autofilled}
                        showWarningIcon={showWarningIcon}
                        onUpdateConditionalField={onUpdateConditionalField}
                        onResetHighlights={onResetHighlights}
                        onSetAutoFilledFields={onSetAutoFilledFields}
                        onSetShowWarningIcon={onSetShowWarningIcon}
                    />
                );
            case SETTING_KEYS.sar:
                return (
                    <SAR
                        d={d}
                        value={value}
                        autofilledFields={autofilled}
                        showWarningIcon={showWarningIcon}
                        onUpdateConditionalField={onUpdateConditionalField}
                        onResetHighlights={onResetHighlights}
                        onSetAutoFilledFields={onSetAutoFilledFields}
                        onSetShowWarningIcon={onSetShowWarningIcon}
                    />
                );
            case SETTING_KEYS.temp:
                return (
                    <Temperature
                        d={d}
                        value={value}
                        autofilledFields={autofilled}
                        onUpdateConditionalField={onUpdateConditionalField}
                        onResetHighlights={onResetHighlights}
                    />
                );
            case SETTING_KEYS.addInfo:
                return (
                    <ConditionalTextField
                        d={d}
                        value={value}
                        placeholder="Inform health care professional about any precautions they need to take"
                        onUpdateConditionalField={onUpdateConditionalField}
                    />
                );
            case SETTING_KEYS.mriArc:
                return (
                    <MriArc
                        description={conditionals[d]}
                        d={d}
                        onUpdateConditionalField={onUpdateConditionalField}
                    />
                );
            case SETTING_KEYS.slewRate:
                return (
                    <SlewRate
                        d={d}
                        value={value}
                        onUpdateConditionalField={onUpdateConditionalField}
                    />
                );
            case SETTING_KEYS.brms:
                return (
                    <ConditionalTextField
                        d={d}
                        value={value}
                        onUpdateConditionalField={onUpdateConditionalField}
                    />
                );
            case SETTING_KEYS.coilType:
                return (
                    <ConditionalTextField
                        d={d}
                        value={value}
                        onUpdateConditionalField={onUpdateConditionalField}
                    />
                );
            case SETTING_KEYS.prep:
                return (
                    <Prep
                        description={conditionals[d]}
                        d={d}
                        onUpdateConditionalField={onUpdateConditionalField}
                    />
                );
            default:
                return (
                    <textarea
                        ref={(el) => (itemsRef.current[i] = el)}
                        name={d}
                        value={conditionals[d]}
                        onChange={() => onUpdateConditional}
                    />
                );
        }
    };

    const renderColumns = () => {
        return settings.map((key: any, i: any) => (
            <div className={styles.column}>
                <React.Fragment key={i}>
                    <label className={styles.infoLabel}>
                        {key + (key === SETTING_KEYS.fieldStrength ? ' *' : '')}
                    </label>
                    {renderInput(key, i)}
                </React.Fragment>
            </div>
        ));
    };

    return (
        <div className={styles.conditionals && styles.backgroundContainer}>
            <h3>Conditionals</h3>
            <div className={styles.flex}>{renderColumns()}</div>
        </div>
    );
};

export default Conditional;
