import { useState } from 'react';
import Select from '../general-components/Select';
import styles from './SearchFilter.module.css';
import Button, { ButtonType } from '../general-components/Button';

interface Props {
    title: string;
    values: string[];
    defaultSelected?: string[];
    onChange: (selected: string[]) => void;
}

const SearchFilter: React.FC<Props> = ({
    title,
    values,
    defaultSelected,
    onChange,
}) => {
    const [selected, setSelected] = useState(defaultSelected);

    const handleChange = (sel: string) => {
        if (selected.includes(sel)) {
            const newSelected = [...selected];
            newSelected.splice(selected.indexOf(sel), 1);
            onChange(newSelected);
            setSelected(newSelected);
        } else {
            onChange([...selected, sel]);
            setSelected([...selected, sel]);
        }
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>
                <h3>{title}</h3>
                <Button
                    small={true}
                    type={ButtonType.primary}
                    onClick={() => {
                        setSelected([]);
                        onChange([]);
                    }}
                >
                    Reset
                </Button>
            </div>
            <div className={styles.list}>
                <Select
                    type="checkbox"
                    name={title}
                    options={values}
                    defaultChecked={selected}
                    changeSelected={handleChange}
                />
            </div>
        </div>
    );
};

export default SearchFilter;
