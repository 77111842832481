import { useState } from 'react';
import VideoModal from './VideoModal';
import { getTimeDifference } from './../../utils/utils';
import styles from './VideoCard.module.css';
import UserImageIcon from './../general-components/UserImageIcon';

const VideoCard: React.FC<{
    video: any;
}> = ({ video }) => {
    const [showVideo, setShowVideo] = useState(false);

    return (
        <>
            <div className={styles.videocard}>
                <div className={styles.videoHeader}>
                    <div className={styles.authorIcon}>
                        <UserImageIcon />
                    </div>
                    <span className={styles.author}>{video.author}</span>
                </div>
                <div
                    className={styles.videoImage}
                    onClick={() => setShowVideo(true)}
                >
                    <img
                        src={`${process.env.REACT_APP_VIDEOTHUMBNAIL_SOURCE}${video.thumbnail}`}
                        alt=""
                    />
                </div>
                <div className={styles.videoBody}>
                    <p className={styles.postTime}>
                        {getTimeDifference(
                            new Date(video.createdAt).getTime()
                        ) + ' ago'}
                    </p>
                    <p
                        className={styles.videoTitle}
                        onClick={() => setShowVideo(true)}
                    >
                        {video.title}
                    </p>
                    {video.tags.length !== 0 && (
                        <div className={styles.tagsWrapper}>
                            {video.tags?.map((tag: string) => (
                                <span key={video._id + tag}>#{tag}</span>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            {showVideo && (
                <VideoModal
                    url={`${process.env.REACT_APP_VIDEOFILE_SOURCE}${video.file_name}`}
                    title={video.title}
                    close={() => setShowVideo(false)}
                />
            )}
        </>
    );
};
export default VideoCard;
