import { useState, useReducer, ChangeEvent } from 'react';
import RIGHTS_KEYS from '../../constants/rights-keys';
import { addActor } from '../../services/apiService';
import FormInput from '../general-components/FormInput';
import {
    formReducer,
    errorReducer,
    camelCaseToSentence,
} from '../../utils/utils';

const initialFormData = {
    role: '',
};

// initialErrorData includes only required fields
const initialErrorData = { role: '' };

const AddActor = () => {
    const [actor, setRole] = useReducer(formReducer, initialFormData);
    const [errorData, setErrorData] = useReducer(
        errorReducer,
        initialErrorData
    );

    const allRigths = {};

    for (let right of Object.entries(RIGHTS_KEYS)) {
        allRigths[right[1]] = false;
    }
    const [rights, setRights] = useState(allRigths);

    // Define a list of required fields in the form
    const requiredFields = Object.keys(initialErrorData);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setRole({
            type: 'update',
            name,
            value,
        });
        requiredFields.includes(name) &&
            setErrorData({ type: 'update', name, value: '' });
    };

    const handleRightsToggle = ({ target }) => {
        setRights((s) => ({ ...s, [target.name]: !s[target.name] }));
    };

    const submit = (event) => {
        event.preventDefault();

        let allChecksPassed = false;

        Object.entries(actor).forEach(([key, value]) => {
            if (requiredFields.includes(key) && value === '') {
                setErrorData({
                    type: 'update',
                    name: key,
                    value: `Please fill ${camelCaseToSentence(key)}`,
                });
            }
        });

        allChecksPassed = Object.entries(actor)
            .filter(([key]) => requiredFields.includes(key))
            .every(([key, value]) => value !== '');

        if (allChecksPassed) {
            const addedRights = [];
            for (let [r, s] of Object.entries(rights)) {
                if (s) {
                    addedRights.push(r);
                }
            }
            addActor(actor.role, addedRights);
        }
    };

    return (
        <form onSubmit={submit}>
            <h1>{actor.role}</h1>
            <FormInput
                type="text"
                name="role"
                placeholder="role name"
                onChange={handleInputChange}
                errorMsg={errorData.role}
            />
            {Object.entries(rights).map(([r, s]) => (
                <div key={r}>
                    <input
                        type="checkbox"
                        name={r}
                        onChange={handleRightsToggle}
                    />
                    <label>{r}</label>
                </div>
            ))}
            <button type="submit">Add actor</button>
        </form>
    );
};

export default AddActor;
