import React, { ChangeEvent, useEffect, useReducer, useState } from 'react';
import {
    signupWithInvitation,
    trialSignUp,
    userExists,
} from '../../services/apiService';
import {
    emailValidate,
    nameValidate,
    passwordValidate,
    repeatPasswordValidate,
} from '../../services/formValidationService';
import Button, { ButtonType } from '../general-components/Button';
import SignupLayout from '../ui-components/SignupLayout';
import styles from './SignupForm.module.css';
import sanitizeHtml from 'sanitize-html';
import {
    formReducer,
    errorReducer,
    camelCaseToSentence,
} from '../../utils/utils';
import HospitalInfoForm from './HospitalInfo';
import UserInfoForm from './UserInfo';

const initialFormData = {
    name: '',
    email: '',
    password: '',
    repeatPassword: '',
    actor: '',
};

// initialErrorData includes only required fields
const initialErrorData = {
    name: '',
    email: '',
    password: '',
    repeatPassword: '',
    actor: '',
    hospital: '',
    scanners: '',
    phone: '',
};

interface SignupFormData {
    type: string;
    signupId?: string;
    isAdmin: boolean;
    hasActor: boolean;
    isTrial?: boolean;
    successfulSubmit: () => void;
}

const ENG_DICT = {
    title: 'Sign up',
    description:
        'Please provide your information so we can set everything up for you.',
    name: 'Full name',
    email: 'Email',
    password: 'Password',
    repeatPassword: 'Repeat Password',
    button: 'Sign up',
    help: 'When signing up you accept the <a target="__blank" href="https://nordinsight.com/terms">terms of service</a>',
};

const SignupForm: React.FC<SignupFormData> = ({
    type,
    signupId,
    isAdmin,
    isTrial,
    hasActor,
    successfulSubmit,
}) => {
    const [formData, setFormData] = useReducer(formReducer, initialFormData);
    const [errorData, setErrorData] = useReducer(
        errorReducer,
        initialErrorData
    );
    const [generalError, setGeneralError] = useState('');

    const [submitLoading, setSubmitLoading] = useState(false);

    const [step, setStep] = useState(1);

    let dict = ENG_DICT;

    const handleInputChange = (
        e: ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
    ) => {
        const { name, value } = e.target;

        setErrorData({
            type: 'update',
            name,
            value: '',
        });

        setFormData({
            type: 'update',
            name,
            value,
        });
    };

    const validateFields = (trial: boolean) => {
        let validated = true;
        const nameResult = nameValidate(formData.name);
        if (!nameResult.valid) {
            setErrorData({
                type: 'update',
                name: 'name',
                value: nameResult.message,
            });
            validated = false;
        }
        const emailResult = emailValidate(formData.email);
        if (!emailResult.valid) {
            setErrorData({
                type: 'update',
                name: 'email',
                value: emailResult.message,
            });
            validated = false;
        }
        const passwordResult = passwordValidate(formData.password);
        if (!passwordResult.valid) {
            setErrorData({
                type: 'update',
                name: 'password',
                value: passwordResult.message,
            });
            validated = false;
        }
        const pwRepeatResult = repeatPasswordValidate(
            formData.password,
            formData.repeatPassword
        );
        if (!pwRepeatResult.valid) {
            setErrorData({
                type: 'update',
                name: 'repeatPassword',
                value: pwRepeatResult.message,
            });
            validated = false;
        }
        if (!formData.actor && !hasActor && !isAdmin && !isTrial) {
            setErrorData({
                type: 'update',
                name: 'actor',
                value: 'Choose your role',
            });
            validated = false;
        }
        if (trial) {
            if (!formData.hospital) {
                setErrorData({
                    type: 'update',
                    name: 'hospital',
                    value: 'Please provide your hospital name',
                });
                validated = false;
            }
            if (!formData.scanners) {
                setErrorData({
                    type: 'update',
                    name: 'scanners',
                    value: 'Please provide the number of scanners in your hospital',
                });
                validated = false;
            }
            if (!formData.phone) {
                setErrorData({
                    type: 'update',
                    name: 'phone',
                    value: 'Please provide your phone number',
                });
                validated = false;
            }
        }
        console.log('validated', validated);
        return validated;
    };

    const nextStep = async () => {
        const emailExists = await userExists(formData.email);
        if (emailExists) {
            setErrorData({
                type: 'update',
                name: 'email',
                value: 'User with email already exists',
            });
        }
        validateFields(false) && !emailExists && setStep(2); // If all fields are valid, move to step 2
    };

    const submitHandler = async () => {
        if (validateFields(isTrial)) {
            setSubmitLoading(true);

            let result;
            if (isTrial) {
                result = await trialSignUp(formData);
            } else {
                result = await signupWithInvitation(formData, signupId);
            }
            if (result.success) {
                successfulSubmit();
            } else {
                setSubmitLoading(false);
                setGeneralError(result.message);
            }
        }
    };

    const setIsAdmin = async () => {
        if (isAdmin) {
            if (type === 'Manufacturer') {
                setFormData({
                    type: 'update',
                    name: 'actor',
                    value: 'ManufacturerAdmin',
                });
            } else {
                setFormData({
                    type: 'update',
                    name: 'actor',
                    value: 'DeptAdmin',
                });
            }
        }
    };

    useEffect(() => {
        setIsAdmin();
    }, [isAdmin]);

    return (
        <SignupLayout>
            <React.Fragment>
                {step === 1 && (
                    <div className={styles.info}>
                        <h3>{dict.title}</h3>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(dict.description),
                            }}
                        ></p>
                    </div>
                )}
                {step === 2 && (
                    <div className={styles.info}>
                        <h3>We just need a little more info</h3>
                        <p>
                            Please provide us with some more details about your
                            department or hospital.
                        </p>
                    </div>
                )}
                <form className={styles.form} autoComplete="off">
                    <span className={styles.error} aria-live="polite">
                        {generalError}
                    </span>
                    {step === 2 && (
                        <HospitalInfoForm
                            errorData={errorData}
                            handleInputChange={handleInputChange}
                        />
                    )}
                    {step === 1 && (
                        <UserInfoForm
                            dict={dict}
                            handleInputChange={handleInputChange}
                            errorData={errorData}
                            hasRoleSelect={
                                !isTrial &&
                                !isAdmin &&
                                type !== 'Manufacturer' &&
                                !hasActor
                            }
                        />
                    )}
                    {(step === 2 || !isTrial) && (
                        <Button
                            className={styles.button}
                            type={ButtonType.submit}
                            onClick={() => {
                                submitHandler();
                            }}
                            loading={submitLoading}
                        >
                            {dict.button}
                        </Button>
                    )}
                    {step === 1 && isTrial && (
                        <Button
                            className={styles.button}
                            type={ButtonType.submit}
                            onClick={() => {
                                nextStep();
                            }}
                        >
                            Continue
                        </Button>
                    )}
                    <label
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(dict.help),
                        }}
                    ></label>
                </form>
            </React.Fragment>
        </SignupLayout>
    );
};

export default SignupForm;
