import validator from 'email-validator';

const pwRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;

export const passwordValidate = (p: string) => {
    const errors = [];
    if (p === undefined || p === null) {
        return {
            valid: false,
            message: 'Please fill out password',
        };
    }
    if (p.length < 8) {
        errors.push('- Your password must be at least 8 characters');
    }
    // if (p.search(/[a-z]/i) < 0) {
    //     errors.push(
    //         '- Your password must contain at least one lowercase letter.'
    //     );
    // }
    // if (p.search(/[A-Z]/i) < 0) {
    //     errors.push(
    //         '- Your password must contain at least one UPPERCASE letter.'
    //     );
    // }
    if (p.search(/[0-9]/) < 0) {
        errors.push('- Your password must contain at least one digit.');
    }
    // if (p.search(/[!@#$%^&*]/) < 0) {
    //     errors.push(
    //         '- Your password must contain at least one special character.'
    //     );
    // }
    if (errors.length > 0) {
        return {
            valid: false,
            message: errors.join('<br>'),
        };
    }
    return {
        valid: true,
    };
};

export const repeatPasswordValidate = (p: string, rp: string) => {
    if (rp === undefined || rp === null) {
        return {
            valid: false,
            message: 'Please repeat the password',
        };
    }
    if (p !== rp) {
        return {
            valid: false,
            message: 'Passwords do not match',
        };
    }
    return {
        valid: true,
    };
};

export const emailValidate = (email: string) => {
    if (email === undefined || email === null) {
        return {
            valid: false,
            message: 'Please fill out your email',
        };
    }
    if (!validator.validate(email)) {
        return {
            valid: false,
            message: 'Please enter a valid email',
        };
    }
    return {
        valid: true,
        message: '',
    };
};

export const nameValidate = (name: string, isOptional?: boolean) => {
    if (
        !isOptional &&
        (name === undefined || name === null || name.length === 0)
    ) {
        return {
            valid: false,
            message: 'Please fill out your name',
        };
    }
    if (name.length < 2) {
        return {
            valid: false,
            message: 'Please input a name containing 2 or more characters',
        };
    }
    if (name.search(/[0-9]/i) > 0 || name.search(/[!@#$%^&*]/i) > 0) {
        return {
            valid: false,
            message: 'Please input a name only using letters',
        };
    }
    return {
        valid: true,
    };
};
