import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './CreatePredefinedConditionals.module.css';
import { useContext, useState } from 'react';
import { BooleanDict, FilterContext } from '../../store/filter-context';
import Button, { ButtonType } from '../general-components/Button';
import DropdownSelect from '../general-components/DropdownSelect';
import FormInput from '../general-components/FormInput';
import FlashMessage from '../general-components/FlashMessage';
import TypeList from './view/TypeList';

const EditPredefinedMenu = ({ isEdit = false }: { isEdit?: boolean }) => {
    const {
        setData,
        filteredTypes,
        filteredManufactures,
        filteredFieldStrength,
        types,
        manufactures,
        fieldStrength,
        onSaveFilterOptions,
        filterName,
        setFilterName,
    } = useContext(FilterContext);

    const [flashMessage, setflashMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [isFilterSettingSaved, setIsFilterSettingSaved] = useState(false);
    const [isSaveButton, setIsSaveButton] = useState(true);

    const saveFilter = async () => {
        let result;
        if (isEdit) {
            result = await onSaveFilterOptions(filterName, true);
        } else {
            result = await onSaveFilterOptions(filterName);
        }

        if (result) {
            setIsSaveButton(true);
            setIsFilterSettingSaved(true);
            setflashMessage('Saved Successfully');
        }
    };

    const showSaveForm = () => {
        setIsSaveButton(false);
    };

    const onSubmitHandler = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        saveFilter();
    };

    return (
        <div className={styles.menu}>
            <div className={styles.options}>
                <div>
                    Types:
                    <DropdownSelect
                        isSearchable={true}
                        name="Type"
                        isMulti={true}
                        options={types}
                        onChange={(v: BooleanDict) => {
                            setData('types', v);
                        }}
                    />
                </div>
                <div>
                    Manufacturers
                    <DropdownSelect
                        isSearchable={true}
                        name="Manufacturers"
                        isMulti={true}
                        options={manufactures}
                        onChange={(v: BooleanDict) => {
                            setData('manu', v);
                        }}
                    />
                </div>
                <DropdownSelect
                    name="Field Strength"
                    isMulti={true}
                    options={fieldStrength}
                    onChange={(v: BooleanDict) => {
                        setData('field', v);
                    }}
                />
                <div className={styles['save-filter']}>
                    {isSaveButton ? (
                        <Button
                            onClick={() => showSaveForm()}
                            type={ButtonType.primary}
                            small={false}
                            className={styles['saveBtn']}
                        >
                            <div>
                                <FontAwesomeIcon icon={faFloppyDisk} />
                                Save Filter
                            </div>
                        </Button>
                    ) : (
                        <form
                            onSubmit={onSubmitHandler}
                            className={styles.formControll}
                        >
                            <input type="submit" hidden />
                            <FormInput
                                defaultValue={filterName}
                                onChange={(e) => {
                                    setFilterName(
                                        (e.target as HTMLInputElement).value
                                    );
                                }}
                                type="test"
                                label={'Enter filter name'}
                                errorMsg={errorMessage}
                            />
                            <Button
                                type={ButtonType.submit}
                                small={false}
                                onClick={onSubmitHandler}
                            >
                                Save
                            </Button>
                        </form>
                    )}
                    {isFilterSettingSaved && (
                        <FlashMessage
                            type={'Success'}
                            message={flashMessage}
                            duration={200000}
                            key={flashMessage}
                        />
                    )}
                </div>
            </div>
            <TypeList
                title="Exact included Types of implants:"
                types={filteredTypes.map((t: boolean) => t)}
            />
            <TypeList
                title="Exact included Manufacturers of implants:"
                types={filteredManufactures.map((t: boolean) => t)}
            />
        </div>
    );
};

export default EditPredefinedMenu;
