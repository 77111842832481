enum PROTOCOL_KEYS {
    procedure = 'Procedure',
    title = 'Title',
    description = 'Description',
    def = 'Definitions',
    scope = 'Scope',
    ref = 'References',
    expiry = 'Expiry Date',
}

export default PROTOCOL_KEYS;
