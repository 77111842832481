import SignupLayout from '../ui-components/SignupLayout';
import { useParams } from 'react-router-dom';
import SignupForm from './SignupForm';
import React, { useEffect, useState } from 'react';
import styles from './SignupPage.module.css';
import Button, { ButtonType } from '../general-components/Button';
import { validateAndGetInvitationData } from '../../services/apiService';

const DisabledSignup = () => (
    <React.Fragment>
        <h3 style={{ width: '400px' }}>
            We are currently only open for registration through invitation.{' '}
            <br />
            <br /> Contact us on info@nordinsight.com to get invited.
        </h3>
    </React.Fragment>
);

const WrongInvitationSignup = () => (
    <React.Fragment>
        <h3 style={{ width: '400px' }}>
            Sorry, your invitation has either expired or you are trying to
            access an invalid link.
            <br />
            <br /> Contact the manager who invited you or us on
            info@nordinsight.com to help resolve this issue.
        </h3>
    </React.Fragment>
);

const ConfirmedSignup = () => (
    <React.Fragment>
        <h3>You are now registered.</h3>
        <p>
            Go to the login page and use the credentials that you just provided.
        </p>
        <Button
            className={styles.button}
            type={ButtonType.primary}
            onClick={() => {
                window.location.href = '/';
            }}
        >
            Return to login page
        </Button>
    </React.Fragment>
);

const ConfirmedTrialSignup = () => (
    <React.Fragment>
        <h3>One last step</h3>
        <p style={{ marginBottom: '30px' }}>
            Please check your email to finish setting up your account.
            <br />
            <br />
            If you want an additional 2 months free access and access to all of
            our features for you and your department, book an introduction call.
            <br />
            <br />
            If you haven't received an email within 5-10 min. please check your
            spam or contact us at info@nordinsight.com.
        </p>
        <Button
            className={styles.button}
            type={ButtonType.submit}
            onClick={() => {
                const win = window.open(
                    process.env.REACT_APP_MEETING_LINK,
                    '_blank'
                );
                window.location.href = '/';
                win.focus();
            }}
        >
            Book meeting
        </Button>
    </React.Fragment>
);

const SignupPage: React.FC = () => {
    const [currentElement, setCurrentElement] = useState('signup');
    const [id, setId] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    const [hasActor, setHasActor] = useState(false);
    const [type, setType] = useState('');

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const inviteId = params.get('invite');

    let { code } = useParams<{ code: string }>();

    const getDeptFromInv = async () => {
        const id = inviteId ? inviteId : code;

        if (id === 'trial') {
            setCurrentElement('disabled');
            return;
        }
        const invdata = await validateAndGetInvitationData(id);

        setHasActor(invdata.actor !== undefined && invdata.actor !== null);
        setIsAdmin(invdata.isAdmin);
        setId(id);
        setType(invdata.type);
        if (id === undefined || id === null) {
            setCurrentElement('disabled');
        }
        if (invdata.type === 'Not found' || invdata.accepted) {
            setCurrentElement('wrong');
        }
    };

    useEffect(() => {
        getDeptFromInv();
    }, []);

    return (
        <SignupLayout>
            {currentElement === 'signup' && (
                <SignupForm
                    type={type}
                    signupId={id}
                    isAdmin={isAdmin}
                    isTrial={false}
                    hasActor={hasActor}
                    successfulSubmit={() => setCurrentElement('confirm')}
                />
            )}
            {currentElement === 'disabled' && <DisabledSignup />}
            {currentElement === 'wrong' && <WrongInvitationSignup />}
            {currentElement === 'confirm' && code !== 'trial' && (
                <ConfirmedSignup />
            )}
            {currentElement === 'confirm' && code === 'trial' && (
                <ConfirmedTrialSignup />
            )}
        </SignupLayout>
    );
};

export default SignupPage;
