import React, { useState, useEffect } from 'react';
import styles from './TypeSearchField.module.css';

interface TypeSearchFieldProps {
    types: string[];
    updateType: (type: string) => void;
    currentValue: string;
}

const TypeSearchField: React.FC<TypeSearchFieldProps> = ({
    types,
    updateType,
    currentValue,
}) => {
    const [searchTerm, setSearchTerm] = useState<string>(currentValue);
    const [filteredTypes, setFilteredTypes] = useState<string[]>([]);

    useEffect(() => {
        setSearchTerm(currentValue);
    }, [currentValue]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const term = e.target.value;
        updateType(term);
        setSearchTerm(term);

        if (term.length > 0) {
            console.log(types);
            const filtered = types.filter((t) =>
                t.toLowerCase().includes(term.toLowerCase())
            );
            setFilteredTypes(filtered);
        } else {
            setFilteredTypes([]);
        }
    };

    const handleSelectType = (type: string) => {
        updateType(type);
        setSearchTerm(type);
        setFilteredTypes([]);
    };

    return (
        <div className={styles.wrapper}>
            <label className={styles.infoLabel}>Type *</label>
            <input
                type="text"
                value={searchTerm}
                placeholder="Search type"
                onChange={handleSearchChange}
                className={styles.searchInput}
            />
            <div className={styles.examples}>
                {filteredTypes.map((type, index) => (
                    <li key={index} onClick={() => handleSelectType(type)}>
                        {type}
                    </li>
                ))}
            </div>
        </div>
    );
};

export default TypeSearchField;
