import { ReactNode, useState } from 'react';
import styles from './ComponentSelectList.module.css';
import SelectItem from '../../general-components/SelectItem';
const ComponentSelectList: React.FC<{
    checkType?: 'checkbox' | 'radio';
    items: { comp: ReactNode; id: string; selected: boolean }[];
    onChange: (id: string, selected: boolean) => void;
}> = ({ items, onChange, checkType = 'checkbox' }) => {
    return (
        <div className={styles.wrapper}>
            <ul>
                {items.map((item) => (
                    <li key={item.id}>
                        {item.comp}
                        <SelectItem
                            defaultChecked={item.selected}
                            labelled={false}
                            type={checkType}
                            value={item.id}
                            onChange={onChange}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ComponentSelectList;
