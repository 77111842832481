import { useContext, useEffect, useState } from 'react';
import {
    getAllManufactures,
    getImplantsFromManufacturer,
} from '../../services/apiService';
import Results from '../search/Results';
import styles from './ImplantOverviewPage.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNotesMedical } from '@fortawesome/free-solid-svg-icons';
import AuthContext from '../../store/auth-context';
import OverlayWindow from '../ui-components/OverlayWindow';
import RIGHTS_KEYS from '../../constants/rights-keys';
import AddImplantPage from '../add-implant/AddImplantPage';
import ImplantOverview from './ImplantOverview';

const ManufacturerPage = () => {
    const userContext = useContext(AuthContext);
    const [showNewImplant, setShowNewImplant] = useState(false);

    const [manufacturerList, setManufacturerList] = useState([]);
    const [currentManufacturerId, setCurrentManufacturerId] = useState<any>();

    const [manufacturerName, setManufacturerName] = useState<string>();

    const fetchManufacturerName = async () => {
        const userInfo = await userContext.getAllInfo();
        setManufacturerName(userInfo.manufacturer.name);
    };

    const fetchManufacturers = async () => {
        const mans = await getAllManufactures();
        setManufacturerList(mans);
    };

    useEffect(() => {
        if (userContext.orRights([RIGHTS_KEYS.manageAllImplants])) {
            fetchManufacturers();
        }
        if (userContext.orRights([RIGHTS_KEYS.manageImplant])) {
            fetchManufacturerName();
        }
    }, []);

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>
                {userContext.orRights([RIGHTS_KEYS.manageAllImplants]) && (
                    <select
                        onChange={(e) =>
                            setCurrentManufacturerId(e.target.value)
                        }
                        defaultValue={'default'}
                    >
                        <option value="default" disabled>
                            Choose manufacturer
                        </option>
                        {manufacturerList.map((m: any) => (
                            <option key={m._id} value={m._id}>
                                {m.name}
                            </option>
                        ))}
                    </select>
                )}
                {userContext.orRights([RIGHTS_KEYS.manageImplant]) && (
                    <div className={styles.title}>
                        <h2>Implant Overview</h2>
                        <h3>{manufacturerName}</h3>
                    </div>
                )}
            </div>
            <div className={styles.overview}>
                {userContext.orRights([RIGHTS_KEYS.manageImplant]) && (
                    <ImplantOverview />
                )}
                {userContext.orRights([RIGHTS_KEYS.manageAllImplants]) &&
                    currentManufacturerId && (
                        <ImplantOverview
                            manufacturerId={currentManufacturerId as string}
                        />
                    )}
            </div>
        </div>
    );
};

export default ManufacturerPage;
