import React, { useContext } from 'react';
import SETTING_KEYS from '../../constants/setting-keys';
import styles from './Procedure.module.css';
import ConditionalsTable from './conditionals/ConditionalsTable';
import ConditionalsComponent from './conditionals/ConditionalsComponent';
import ImplantInfoComponent from './info/ImplantInfoComponent';
import ImplantDetailsComponent from './info/ImplantDetailsComponent';
import AuthContext from '../../store/auth-context';
import RIGHTS_KEYS from '../../constants/rights-keys';
import ImplantImageComponent from './info/ImplantImageComponent';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import Comment from './comment/Comment';
import ProtocolLink from './department-tools/ProtocolLink';
import ApprovedComponent from '../approval/ApprovedComponent';
import AddToGroupButton from '../group/AddToGroupButton';

interface HistoryVersion {
    id: string;
    version: string;
    info: any;
}
interface ProcedureProps {
    info: any;
    conditionals: any;
    isPreview?: boolean;
    isPredefined?: boolean;
    historyVersions?: HistoryVersion[];
    selectedVersion?: HistoryVersion;
    handleChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    mriCode?: string;
    withComments?: boolean;
}

const Procedure: React.FC<ProcedureProps> = ({
    info,
    conditionals,
    isPreview = false,
    isPredefined = false,
    historyVersions,
    selectedVersion,
    handleChange,
    mriCode,
    withComments = false,
}) => {
    const ctx = useContext(AuthContext);
    return (
        <div className={!isPreview ? styles.implant : ''}>
            <div className={styles.info}>
                <ImplantInfoComponent
                    info={info}
                    isPreview={isPreview}
                    historyVersions={historyVersions}
                    selectedVersion={selectedVersion}
                    handleChange={handleChange}
                    mriCode={mriCode}
                />
            </div>
            {ctx.orRights([RIGHTS_KEYS.detailedInfo]) && !isPreview && (
                <div className={styles.image}>
                    <ImplantImageComponent name={info[SETTING_KEYS.image]} />
                </div>
            )}
            <div className={styles.details}>
                <ImplantDetailsComponent info={info} hasToggle={!isPreview} />
                {info[SETTING_KEYS.customFields]?.some(
                    (field: any) => field.isCritical
                ) &&
                    info[SETTING_KEYS.customFields]
                        .filter((field: any) => field.isCritical)
                        .map((field: any) => {
                            if (
                                field === undefined ||
                                field === null ||
                                field === ''
                            ) {
                                return '';
                            }
                            return (
                                <div className={styles.customField}>
                                    <ConditionalsTable
                                        style="critical"
                                        headers={[
                                            <>
                                                <ReportGmailerrorredIcon />
                                                {field.heading}
                                            </>,
                                        ]}
                                        fields={[[field.value]]}
                                    />
                                </div>
                            );
                        })}
                <div style={{ marginBottom: '30px' }}>
                    <ConditionalsTable
                        title={SETTING_KEYS.addInfo}
                        style="info"
                        headers={[
                            <>
                                <ReportGmailerrorredIcon />
                                {SETTING_KEYS.addInfo}
                            </>,
                        ]}
                        fields={[
                            [
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: conditionals[
                                            SETTING_KEYS.addInfo
                                        ] as string,
                                    }}
                                ></div>,
                            ],
                        ]}
                    />
                </div>
                {info[SETTING_KEYS.mriStatus] === 2 && (
                    <ConditionalsComponent conditionals={conditionals} />
                )}

                {info[SETTING_KEYS.notes] !== undefined &&
                    info[SETTING_KEYS.notes] !== null &&
                    info[SETTING_KEYS.notes] !== '' && (
                        <div className={styles.customField}>
                            <ConditionalsTable
                                headers={[SETTING_KEYS.notes]}
                                fields={[[info[SETTING_KEYS.notes]]]}
                            />
                        </div>
                    )}
                {info[SETTING_KEYS.customFields]?.some(
                    (field: any) => !field.isCritical
                ) &&
                    info[SETTING_KEYS.customFields]
                        .filter((field: any) => !field.isCritical)
                        .map((field: any) => {
                            if (
                                field === undefined ||
                                field === null ||
                                field === '' ||
                                field === '<p></p>'
                            ) {
                                return '';
                            }
                            return (
                                <div className={styles.customField}>
                                    <ConditionalsTable
                                        headers={[field.heading]}
                                        fields={[[field.value]]}
                                    />
                                </div>
                            );
                        })}
            </div>
            {!isPreview && (
                <div className={styles.deptTools}>
                    <div className={styles.deptActions}>
                        {withComments && (
                            <ApprovedComponent implantId={info._id} />
                        )}
                        {withComments && (
                            <AddToGroupButton type="implant" id={info._id} />
                        )}
                        {withComments && <ProtocolLink implantId={info._id} />}
                    </div>
                    {withComments && <Comment implantId={info._id} />}
                </div>
            )}
        </div>
    );
};

export default Procedure;
