import { createContext, useEffect, useState } from 'react';
import {
    getAllFieldstrength,
    getAllManufacturesCustomConditional,
    getTypesForConditonalFinder,
    getCustomConditionals,
    saveFilterSetting,
    getCustomConditionalsFromList,
    getPredefinedById,
    editFilterSetting,
} from '../services/apiService';
import SETTING_KEYS from '../constants/setting-keys';

export interface BooleanDict {
    [key: string]: boolean;
}

interface Setting {
    types: string[];
    manufacturers: string[];
    fieldStrength: string[];
}

const initSet: { [key: string]: any } = {};
const initArr: string[] = [];
const initSaved: any = {
    types: [],
    manufacturers: [],
    fieldStrength: [],
};

// Initial state
const initialState = {
    updateConditionalsFromList: async (implants: any) => {},
    shownImplants: initArr,
    selectedLimitingImplants: initArr,
    customFilters: initArr,
    addCustomFilter: (filter: any) => {},
    setData: (type: string, value: BooleanDict) => {},
    filteredFieldStrength: initSet,
    filteredManufactures: initSet,
    filteredTypes: initSet,
    selectedFilter: 'All filters',
    separatedImplants: initSet,
    types: initSet,
    manufactures: initSet,
    fieldStrength: initSet,
    updateList: (fil: string) => {},
    info: {} as any,
    conditionals: {},
    setSavedSettings: (setting: Setting) => {},
    onSaveFilterOptions: async (filterName: string, isEdit?: boolean) => true,
    getResultById: async (id: string) => {
        return {} as any;
    },
    filterName: '',
    setFilterName: (name: string) => {},
    hoveredConditional: '',
    setHoveredConditional: (cond: string) => {},
};

// Create context
export const FilterContext = createContext(initialState);

// Provider component
export const FilterContextProvider = ({ children }: any) => {
    const [types, setTypes] = useState<BooleanDict>(initSet);
    const [manufactures, setManufactures] = useState<BooleanDict>(initSet);
    const [fieldStrength, setFieldStrength] = useState<BooleanDict>(initSet);

    const filteredTypes = Object.entries(types)
        .filter((t) => t[1])
        .map((t) => t[0]);
    const filteredManufactures = Object.entries(manufactures)
        .filter((t) => t[1])
        .map((t) => t[0]);
    const filteredFieldStrength = Object.entries(fieldStrength)
        .filter((t) => t[1])
        .map((t) => t[0]);

    const [selectedFilter, setSelectedFilter] = useState('All filters');
    const [savedSettings, setSavedSettings] = useState<Setting>(initSaved);

    const [customFilters, setCustomFilters] = useState(initArr);

    const [info, setInfo] = useState({});
    const [conditionals, setConditional] = useState({});

    const [hoveredConditional, setHoveredConditional] = useState('');

    const [filterName, setFilterName] = useState('');
    const [id, setId] = useState('');

    const [separatedImplants, setSeparatedImplants] = useState(initSet);
    const [all, setAll] = useState(initSet);

    const [shownImplants, setshownImplants] = useState(initArr);
    const [selectedImplants, setSelectedImplants] = useState(initArr);
    const [selectedLimitingImplants, setSelectedLimitingImplants] =
        useState(initArr);

    const addCustomFilter = (filter: any) => {
        if (filter.trim() === '') return;
        setCustomFilters([...customFilters, filter]);
    };

    const updateList = (fil: string) => {
        setSelectedFilter(fil);
        if (fil === 'All filters') {
            setshownImplants(separatedImplants.all);
            setSelectedImplants(separatedImplants.all);
            setInfo({
                [SETTING_KEYS.mriStatus]: all.all.final[SETTING_KEYS.mriStatus],
                title: 'Most Restrictive Conditionals',
            });
            setConditional(all.all.final);
            setSelectedLimitingImplants(all.all.restrictingImplants);
        } else {
            setshownImplants(separatedImplants[fil]);
            setSelectedImplants(separatedImplants[fil]);
            setInfo({
                [SETTING_KEYS.mriStatus]:
                    all[fil].final[SETTING_KEYS.mriStatus],
                title: 'Most Restrictive Conditionals',
            });
            setConditional(all[fil].final);
            setSelectedLimitingImplants(all[fil].restrictingImplants);
        }
    };

    const updateConditionalsFromList = async (implants: any) => {
        setSelectedImplants(implants);
        const cond = await getCustomConditionalsFromList(implants);
        setConditional(cond.final);
        setInfo({ ...info, [SETTING_KEYS.mriStatus]: cond.mriStatus });
        setSelectedLimitingImplants(cond.restrictingImplants);
    };

    const getResultById = async (id: string) => {
        const res = await getPredefinedById(id);
        let types = await getAllTypesDict();
        let manu = await getAllManufacturersDict();

        console.log(res);

        for (let t of res.settings.types) {
            types[t] = true;
        }
        if (res.settings.manufacturers?.length > 0) {
            for (let t of res.settings.manufacturers) {
                manu[t] = true;
            }
        }

        setFilterName(res.title);
        setTypes(types);
        setManufactures(manu);
        setId(id);
        setStateFromResult(res.conditionals);

        return res;
    };

    const setStateFromResult = (res: any) => {
        if (res !== '' && res !== undefined) {
            setAll(res);
            let separated: { [key: string]: any } = {};
            for (let k of Object.keys(res)) {
                separated[k] = res[k].implants;
            }
            setSeparatedImplants(separated);
            if (res?.all.implants) {
                setshownImplants(res.all.implants);
                setSelectedImplants(res.all.implants);

                setInfo({
                    [SETTING_KEYS.mriStatus]: res.all.mriStatus,
                    title: 'Most Restrictive Conditionals',
                });

                setConditional(res.all.final);
                setSelectedLimitingImplants(res.all.restrictingImplants);
            }
        }
    };

    const getResult = async () => {
        let settings = {
            types: filteredTypes,
            manufacturers: filteredManufactures,
            fieldStrength: filteredFieldStrength,
            customFilters: customFilters,
        };

        let res: any;
        if (
            filteredTypes.length > 0 ||
            filteredManufactures.length > 0 ||
            filteredFieldStrength.length > 0 ||
            customFilters.length > 0
        ) {
            // TODO(Mathias): return error message
            res = await getCustomConditionals(settings);
        }

        setStateFromResult(res);
    };

    const setData = (type: string, value: BooleanDict) => {
        if (type === 'types') {
            setTypes(value);
        }
        if (type === 'manu') {
            setManufactures(value);
        }
        if (type === 'field') {
            setFieldStrength(value);
        }
    };

    const getAllTypesDict = async () => {
        const t: string[] = await getTypesForConditonalFinder();
        let typeDict: BooleanDict = {};
        for (let type of t) {
            if (
                savedSettings.types.length > 0 &&
                savedSettings.types.includes(type)
            ) {
                const types = savedSettings.types;
                types.forEach((typeValue) => (typeDict[typeValue] = true));
            } else {
                typeDict[type] = false;
            }
        }
        return typeDict;
    };

    const getAllManufacturersDict = async () => {
        const m = await getAllManufacturesCustomConditional();
        let manDict: BooleanDict = {};
        for (let man of m) {
            if (
                savedSettings.manufacturers.length > 0 &&
                savedSettings.manufacturers.includes(man)
            ) {
                const manufacturers = savedSettings.manufacturers;
                manufacturers.forEach((manValue) => {
                    manDict[manValue] = true;
                });
            } else {
                manDict[man] = false;
            }
        }
        return manDict;
    };

    const getData = async () => {
        const typeDict = await getAllTypesDict();

        const manDict = await getAllManufacturersDict();

        const f = await getAllFieldstrength();
        let fieldDict: BooleanDict = {};
        for (let field of f) {
            if (
                savedSettings.manufacturers.length > 0 &&
                savedSettings.fieldStrength.includes(field)
            ) {
                const fieldStrengths = savedSettings.fieldStrength;
                fieldStrengths.forEach((fieldValue) => {
                    fieldDict[fieldValue] = true;
                });
            } else {
                fieldDict[field] = false;
            }
        }
        setTypes(typeDict);
        setManufactures(manDict);
        setFieldStrength(fieldDict);
    };

    const onSaveFilterOptions = async (filterName: string, isEdit = false) => {
        let name = filterName.trim();
        let options = {
            name: name,
            settings: {
                types: filteredTypes,
                manufacturers: filteredManufactures,
                fieldStrength: filteredFieldStrength,
                customFilters: customFilters,
            },
        };

        // TODO(Mathias): Proper error handling for form validation
        if (
            filteredTypes.length > 0 ||
            filteredManufactures.length > 0 ||
            filteredFieldStrength.length > 0 ||
            customFilters.length > 0
        ) {
            if (isEdit) {
                await editFilterSetting(id, options);
            } else {
                await saveFilterSetting(options);
            }
            return true;
        } else {
            return false;
        }
    };

    useEffect(() => {
        if (
            id === '' &&
            (types !== initSet ||
                manufactures !== initSet ||
                fieldStrength !== initSet ||
                customFilters !== initArr)
        ) {
            getResult();
        }
    }, [types, manufactures, fieldStrength, customFilters]);

    useEffect(() => {
        if (savedSettings !== initSaved) {
            getData();
        }
    }, [savedSettings]);

    const contextValue = {
        updateConditionalsFromList,
        shownImplants,
        selectedLimitingImplants,
        customFilters,
        addCustomFilter,
        setData,
        filteredFieldStrength,
        filteredManufactures,
        filteredTypes,
        selectedFilter,
        separatedImplants,
        types,
        manufactures,
        fieldStrength,
        updateList,
        info,
        conditionals,
        setSavedSettings,
        onSaveFilterOptions,
        getResultById,
        filterName,
        setFilterName,
        hoveredConditional,
        setHoveredConditional,
    };

    return (
        <FilterContext.Provider value={contextValue}>
            {children}
        </FilterContext.Provider>
    );
};
