import styles from './Conditional.module.css';

const SlewRate = ({
    d,
    value,
    onUpdateConditionalField,
}: {
    d: string;
    value: any;
    onUpdateConditionalField: Function;
}) => {
    return (
        <div className={styles.conditionalGroup}>
            <div>
                <input
                    type="number"
                    step="any"
                    defaultValue={value?.rate || ''}
                    placeholder="Rate"
                    onChange={(e) => {
                        onUpdateConditionalField(d, {
                            ...value,
                            rate: Number(e.target.value),
                        });
                    }}
                />
            </div>
            <div>
                <input
                    type="text"
                    defaultValue={value?.condition || ''}
                    placeholder="Condition"
                    onChange={(e) => {
                        onUpdateConditionalField(d, {
                            ...value,
                            condition: e.target.value,
                        });
                    }}
                />
            </div>
        </div>
    );
};

export default SlewRate;
