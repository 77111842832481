import styles from './ActiveFilters.module.css';

const ActiveFilters: React.FC<{ filters: { [key: string]: string[] } }> = ({
    filters,
}) => {
    return (
        <div className={styles.wrapper}>
            {Object.keys(filters).map((cat) => (
                <>
                    {filters[cat].length > 0 && (
                        <div className={styles.category}>
                            {cat}:
                            {filters[cat].map((i) => (
                                <span className={styles.item}>{i}</span>
                            ))}
                        </div>
                    )}
                </>
            ))}
        </div>
    );
};

export default ActiveFilters;
