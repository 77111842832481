import { ReactNode } from 'react';
import styles from './ConditionalsText.module.css';

const ConditionalsText: React.FC<{
    title: string | ReactNode;
    text: string;
    delimiter: string;
    inline?: boolean;
}> = ({ title, text, delimiter, inline = false }) => {
    const inlineStyle = () => {
        return inline ? styles.inline : '';
    };

    return (
        <div>
            <div className={`${styles.wrapper} ${inlineStyle()}`}>
                <p className={`${styles.title} ${inlineStyle()}`}>
                    {title}
                    {delimiter}
                </p>
                <p
                    className={`${styles.text} ${inlineStyle()}`}
                    dangerouslySetInnerHTML={{ __html: text }}
                ></p>
            </div>
        </div>
    );
};

export default ConditionalsText;
