import { useEffect, useState } from 'react';
import { checkSearchLog, getAllSearchIssues } from '../../services/apiService';
import LoadingRing from '../ui-components/LoadingRing';
import { formatTimestamp } from '../../utils/utils';
import OverlayWindow from '../ui-components/OverlayWindow';
import EditSearchIssue from './EditSearchIssue';
import Button, { ButtonType } from '../general-components/Button';
import ConditionalsTable from '../implant/conditionals/ConditionalsTable';

const stateStyleFromState = (state: string) => {
    switch (state) {
        case 'New Issue':
            return { color: 'white' };
        case 'Ranking':
            return { color: 'turqoise' };
        case 'Bloated':
            return { color: 'turqoise' };
        case 'Unknown':
            return { color: 'gray' };
        case 'No Contact':
            return { color: 'red' };
        case 'Resolved':
            return { color: 'green' };
        case 'Awaiting':
            return { color: 'orange' };
        default:
            return { color: 'gray' };
    }
};
const SearchIssue = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [searchIssues, setSearchIssues] = useState<any[]>([]);
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);
    const [selectedIssue, setSelectedIssue] = useState<any>();
    const [updateOccurred, setUpdateOccurred] = useState(false);
    const [hideResolved, setHideResolved] = useState(true);

    const [filteredIssues, setFilteredIssues] = useState<any>();

    const fetchIssues = async () => {
        setIsLoading(true);
        const allIssues = await getAllSearchIssues();
        setSearchIssues([...allIssues.message]);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchIssues();
    }, []);

    useEffect(() => {
        const filtered = hideResolved
            ? searchIssues.filter((issue: any) => issue.state !== 'Resolved')
            : searchIssues;
        setFilteredIssues(filtered);
    }, [hideResolved, searchIssues]);

    useEffect(() => {
        fetchIssues();
        setUpdateOccurred(false);
        setIsOverlayOpen(false);
    }, [updateOccurred]);

    const handleOpenIssue = (issue: any) => {
        if (isOverlayOpen) {
            setSelectedIssue('');
            setIsOverlayOpen(false);
        } else {
            setSelectedIssue(issue);
            setIsOverlayOpen(true);
        }
    };

    return (
        <div>
            <h1>Search Issues</h1>
            {isLoading && <LoadingRing size="small" />}
            {!isLoading && searchIssues && searchIssues.length > 0 && (
                <>
                    <Button
                        type={ButtonType.submit}
                        onClick={() => setHideResolved(!hideResolved)}
                    >
                        {hideResolved
                            ? 'Show All Issues'
                            : 'Hide Unresolved Issues'}
                    </Button>
                    <ConditionalsTable
                        hasSeeMore={false}
                        headers={[
                            'Search Term',
                            'Searched By',
                            'State',
                            'Comment',
                            'Created At',
                            'Created By',
                        ]}
                        fields={filteredIssues.map((issue: any) => {
                            return [
                                <span
                                    style={{
                                        cursor: 'pointer',
                                        wordBreak: 'break-all',
                                    }}
                                    onClick={() => handleOpenIssue(issue)}
                                >
                                    {issue.searchLogs[0].searchTerm}
                                </span>,
                                issue.searchLogs[0]?.user,
                                <span style={stateStyleFromState(issue.state)}>
                                    {issue.state}
                                </span>,
                                issue.comment,
                                formatTimestamp(issue.createdAt),
                                issue.createdBy ? issue.createdBy : '',
                            ];
                        })}
                    />
                </>
            )}
            {!isOverlayOpen ? (
                ''
            ) : (
                <OverlayWindow close={() => setIsOverlayOpen(false)}>
                    <EditSearchIssue
                        issue={selectedIssue}
                        onUpdateOccurred={setUpdateOccurred}
                    />
                </OverlayWindow>
            )}
        </div>
    );
};

export default SearchIssue;
