import { useEffect, useState } from 'react';
import {
    getSearchQyeryByUser,
    getFilterSearchesByUser,
} from '../../services/apiService';
import { formatTimestamp } from '../../utils/utils';
import styles from './Analytics.module.css';

const Analytics: React.FC = () => {
    const [searches, setSearches] = useState([]);
    const [filterings, setFilterings] = useState([]);

    const getLogs = async () => {
        const searchQueries = await getSearchQyeryByUser();
        setSearches(searchQueries);
        const filterSearches = await getFilterSearchesByUser();
        setFilterings(filterSearches);
    };

    useEffect(() => {
        getLogs();
    }, []);

    return (
        <div className={styles.wrapper}>
            <div className={styles.all}>
                <h2>Searches</h2>
                <ul>
                    {searches?.map((u) => {
                        return (
                            <li key={u._id}>
                                <h4>
                                    {u.user.length < 1
                                        ? 'Unknown name'
                                        : u.user[0].name}
                                </h4>
                                <ul>
                                    {u.searches.map((log: any) => {
                                        return (
                                            <li key={log._id}>
                                                <ul className={styles.logentry}>
                                                    <li>
                                                        {formatTimestamp(
                                                            log.createdAt
                                                        )}
                                                    </li>
                                                    <li>{log.searchTerm}</li>
                                                </ul>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </li>
                        );
                    })}
                </ul>
                <h2>Conditional filterings</h2>
                <ul>
                    {filterings?.map((u) => {
                        return (
                            <li key={u._id}>
                                <h4>
                                    {u.user.length < 1
                                        ? 'Unknown name'
                                        : u.user[0].name}
                                </h4>
                                <ul>
                                    {u.searches.map((log: any) => {
                                        return (
                                            <li key={log._id}>
                                                <ul className={styles.logentry}>
                                                    <li>
                                                        {formatTimestamp(
                                                            log.createdAt
                                                        )}
                                                    </li>
                                                    <li>
                                                        Types: <br></br>
                                                        {
                                                            log
                                                                .conditionalsFilter
                                                                .types
                                                        }
                                                        <br></br>
                                                        <br></br>
                                                        Manufacturers: <br></br>
                                                        {
                                                            log
                                                                .conditionalsFilter
                                                                .manufacturers
                                                        }
                                                        <br></br>
                                                        <br></br>
                                                        Field Strength:{' '}
                                                        <br></br>
                                                        {
                                                            log
                                                                .conditionalsFilter
                                                                .fieldStrength
                                                        }
                                                    </li>
                                                </ul>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default Analytics;
