import { useContext } from 'react';
import Procedure from '../implant/Procedure';
import styles from './CreatePredefinedConditionals.module.css';
import { FilterContext } from '../../store/filter-context';

const ConditionalsView = () => {
    const { info, conditionals } = useContext(FilterContext);

    return (
        <div className={styles.procedure}>
            <Procedure
                info={info}
                conditionals={conditionals}
                isPredefined={true}
            />
        </div>
    );
};

export default ConditionalsView;
