import React, { useContext, useReducer, useState } from 'react';
import AuthContext from '../../store/auth-context';
import { addDepartment } from '../../services/apiService';

const formReducer = (state, event) => {
    return {
        ...state,
        [event.name]: event.value,
    };
};

const AddDepartment = () => {
    const [formData, setFormData] = useReducer(formReducer, {});
    const [errorData, setErrorData] = useReducer(formReducer, {});

    const context = useContext(AuthContext);

    const validatingInputs = [
        // 'name',
        // 'email',
        // 'repeatemail',
        // 'institution',
        // 'department',
        // 'id',
        // 'password',
        // 'repeatpassword'
    ];

    const setErrorOnField = (field) => {
        if (!formData[field]) {
            setErrorData({
                name: field,
                value: 'Please fill out ' + field,
            });
        }
    };

    const submitHandler = async (event) => {
        event.preventDefault();
        for (let inp of validatingInputs) {
            setErrorOnField(inp);
        }

        const isValid = validatingInputs.every((x) => formData[x]?.length > 0);
        
        if (isValid) {
            const strReg = /^[a-zA-Z\s]*/;
            const numReg = /\d+/;
            const street = formData.street.match(strReg);
            const number = formData.street.match(numReg);

            const dept = {
                name: formData.name,
                address: {
                    postalCode: formData.postal,
                    city: formData.city,
                    country: formData.country,
                },
            };

            if(street !== null){
              dept.address.street = street[0]
            }
            if(number !== null){
              dept.address.number = number[0]
            }

            const success = await addDepartment(dept);
            if (success){
                window.location.assign('/');
            }
        }
    };

    const handleChange = (event) => {
        if (event.target.value.length > 0) {
            errorData[event.target.name] = '';
        }
        setFormData({
            name: event.target.name,
            value: event.target.value,
        });
    };

    return (
        <div className="register-box">
            <div className="register-form">
                <form onSubmit={submitHandler}>
                    <h2>Create a new Department</h2>
                    <div className="form-inputs">
                        <fieldset>
                            <input
                                type="text"
                                name="name"
                                placeholder="name"
                                onChange={handleChange}
                            />
                            <span className="error" aria-live="polite">
                                {errorData.name}
                            </span>
                        </fieldset>
                        <fieldset>
                            <input
                                type="text"
                                name="street"
                                placeholder="street"
                                onChange={handleChange}
                            />
                            <span className="error" aria-live="polite">
                                {errorData.street}
                            </span>
                            <input
                                type="text"
                                name="postal"
                                placeholder="postal"
                                onChange={handleChange}
                            />
                            <span className="error" aria-live="polite">
                                {errorData.postal}
                            </span>
                            <input
                                type="text"
                                name="city"
                                placeholder="city"
                                onChange={handleChange}
                            />
                            <span className="error" aria-live="polite">
                                {errorData.city}
                            </span>
                            <input
                                type="text"
                                name="country"
                                placeholder="country"
                                onChange={handleChange}
                            />
                            <span className="error" aria-live="polite">
                                {errorData.country}
                            </span>
                        </fieldset>
                    </div>
                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
    );
};

export default AddDepartment;
