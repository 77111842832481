import { useEffect, useState } from "react"
import { getAllMissingImplantReports } from "../../services/apiService"
import styles from "./MissingImplantList.module.css";

const MissingImplantList = () => {
    const [reports, setReports] = useState([]);

    const getData = async () => {
        const reps  = await getAllMissingImplantReports();
        setReports(reps);
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <div className={styles.wrapper}>
            {reports.map(r => <li>
                <h4>Term: {r.searchTerm}</h4>
                <h4>Name: {r.name}</h4>
                <h4>type: {r.type}</h4>
                <h4>Additional info: {r.info}</h4>
            </li>)}
        </div>
    )

}

export default MissingImplantList;