import { useState, useEffect, ChangeEvent, useReducer } from 'react';
import {
    getGuestUsers,
    getGuestsInvitations,
    createCustomInvitation,
    getAllActors,
} from '../../services/apiService';
import Modal from './Modal';
import GuestRow from './GuestRow';
import { emailValidate } from '../../services/formValidationService';
import LoadingRing from '../ui-components/LoadingRing';
import Button, { ButtonType } from '../general-components/Button';
import styles from './Organizations.module.css';
import FormInput from '../general-components/FormInput';
import {
    formReducer,
    errorReducer,
    camelCaseToSentence,
} from '../../utils/utils';

const initialFormData = {
    email: '',
    activationDate: new Date().toLocaleDateString('en-US'),
    expiryDate: '',
    actor: '',
};

// initialErrorData includes only required fields
const initialErrorData = { email: '', expiryDate: '' };

const Guests = () => {
    const [guests, setGuests] = useState([]);
    const [guestUsers, setGuestUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isAddGuestModal, setIsAddGuestModal] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [newGuest, setNewGuest] = useReducer(formReducer, initialFormData);
    const [errorData, setErrorData] = useReducer(
        errorReducer,
        initialErrorData
    );

    const [actors, setActors] = useState([]);
    // Define a list of required fields in the form
    const requiredFields = Object.keys(initialErrorData);

    const handleInputChange = (
        e: ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
    ) => {
        const { name, value } = e.target;

        setNewGuest({
            type: 'update',
            name,
            value,
        });
        requiredFields.includes(name) &&
            setErrorData({ type: 'update', name, value: '' });
    };

    const getActors = async () => {
        const actors = await getAllActors();
        setActors(actors);
    };

    const getData = async () => {
        const invitations = await getGuestsInvitations();
        setGuests(invitations);
        const guests = await getGuestUsers();
        setGuestUsers(guests);
        setIsLoading(false);
    };

    const addGuest = async () => {
        let allChecksPassed = false;
        const ev = emailValidate(newGuest.email);
        if (!ev.valid) {
            setErrorData({
                type: 'update',
                name: 'email',
                value: 'invalid email',
            });
        }
        Object.entries(newGuest).forEach(([key, value]) => {
            if (requiredFields.includes(key) && value === '') {
                setErrorData({
                    type: 'update',
                    name: key,
                    value: `Please fill ${camelCaseToSentence(key)}`,
                });
            }
        });

        allChecksPassed =
            Object.entries(newGuest)
                .filter(([key]) => requiredFields.includes(key))
                .every(([key, value]) => value !== '') && ev.valid;

        if (allChecksPassed) {
            const result = await createCustomInvitation(newGuest);
            if (result) {
                setIsLoading(true);
                setIsSaved(true);
                getData();
            }
        }
    };

    useEffect(() => {
        getData();
        getActors();
    }, []);

    return (
        <>
            <h2>Guests</h2>
            <Button
                type={ButtonType.submit}
                onClick={() => {
                    setIsAddGuestModal(true);
                }}
            >
                Add New Guest
            </Button>
            {isAddGuestModal && (
                <Modal
                    info={{
                        title: 'New guest trial',
                        submitButtonText: 'Send',
                        message: 'New guest invited',
                        submitButtonDanger: false,
                        isSubmitted: isSaved,
                        className: 'add-orgnization-modal',
                    }}
                    updateView={() => {
                        setIsAddGuestModal(false);
                        setNewGuest({ type: 'reset', initialFormData });
                        setErrorData({ type: 'reset', initialErrorData });
                        setIsSaved(false);
                    }}
                    submitModal={addGuest}
                >
                    <FormInput
                        label="Email"
                        name="email"
                        type="text"
                        defaultValue={newGuest.email}
                        placeholder="Guest email"
                        errorMsg={errorData.email}
                        onChange={handleInputChange}
                    />
                    <FormInput
                        label="Actor (optional)"
                        name="actor"
                        defaultValue={'default'}
                        tag="select"
                        onSelect={handleInputChange}
                    >
                        <option value="default" disabled>
                            Choose an Actor
                        </option>
                        {actors.map((a) => (
                            <option value={a._id}>{a.role}</option>
                        ))}
                    </FormInput>
                    <div>
                        <FormInput
                            label="Start Date"
                            type="text"
                            placeholder={newGuest.activationDate}
                            disabled={true}
                            onChange={() => {}}
                        />
                        <FormInput
                            label="End Date"
                            name="expiryDate"
                            type="date"
                            defaultValue={newGuest.expiryDate}
                            errorMsg={errorData.expiryDate}
                            onChange={handleInputChange}
                        />
                    </div>
                </Modal>
            )}
            {isLoading && (
                <div className={styles.loading}>
                    <LoadingRing size="large" />
                </div>
            )}
            {!isLoading && guests.length === 0 && guestUsers.length === 0 && (
                <p className={styles.noInfoMessage}>
                    No pending invitations found
                </p>
            )}
            {!isLoading && (guests.length > 0 || guestUsers.length > 0) && (
                <>
                    <table>
                        <thead>
                            <tr>
                                <th>Guest Email</th>
                                <th>Status</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Days Until</th>
                                <th>Revoke</th>
                            </tr>
                        </thead>
                        <tbody>
                            {guests.map((guest) => (
                                <GuestRow
                                    guest={guest}
                                    refreshData={getData}
                                    key={guest._id}
                                />
                            ))}
                            {guestUsers.map((guest) => (
                                <GuestRow
                                    guest={guest}
                                    refreshData={getData}
                                    key={guest._id}
                                />
                            ))}
                        </tbody>
                    </table>
                </>
            )}
        </>
    );
};
export default Guests;
