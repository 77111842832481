import React from 'react';
// import { ManageUserContextProvider } from '../../store/manage-user-context';
import Button, { ButtonType } from '../general-components/Button';
import styles from './EditUserPage.module.css';
import FormInput from '../general-components/FormInput';

const UserPasswordForm: React.FC<{
    handlePasswordChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    handlePasswordSubmit: Function;
    errorMsg: any;
}> = ({ handlePasswordChange, handlePasswordSubmit, errorMsg }) => {
    return (
        <form className={styles.userPassword}>
            <h3>Reset password</h3>
            <FormInput
                label="Old password"
                className={styles.userInput}
                type="password"
                onChange={handlePasswordChange}
                name="oldPassword"
                errorMsg={errorMsg.oldPassword}
            />
            <FormInput
                label="New password"
                className={styles.userInput}
                type="password"
                onChange={handlePasswordChange}
                name="newPassword"
                errorMsg={errorMsg.newPassword}
            />
            <FormInput
                label="Repeat new password"
                className={styles.userInput}
                type="password"
                onChange={handlePasswordChange}
                name="repeatNewPassword"
                errorMsg={errorMsg.repeatNewPassword}
            />
            <Button
                type={ButtonType.submit}
                onClick={handlePasswordSubmit}
                className={styles.submitBtn}
            >
                Save password
            </Button>
        </form>
    );
};

export default UserPasswordForm;
