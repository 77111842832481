import React, { useState } from 'react';
import Button, { ButtonType } from '../general-components/Button';
import Modal from './Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faTrashCan } from '@fortawesome/free-solid-svg-icons';

const DeleteInvite: React.FC<{
    onDelete: () => void;
    id: string;
    email: string;
}> = ({ onDelete, id, email }) => {
    const [showModal, setShowModal] = useState(false);

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const deleteInvite = () => {
        onDelete();
        setShowModal(false);
    };

    const yesButton = (
        <Button type={ButtonType.primary} onClick={deleteInvite}>
            Yes
        </Button>
    );

    const noButton = (
        <Button type={ButtonType.secondary} onClick={toggleModal}>
            No
        </Button>
    );

    const modalObj = {
        title: 'Delete invitation',
        message: 'Delete invitation for ' + email,
        userId: id,
        className: 'deactivate-modal',
        children: [
            <div>
                {yesButton} {noButton}
            </div>,
        ],
    };

    return (
        <React.Fragment>
            {showModal && (
                <Modal info={modalObj} updateView={toggleModal}>
                    {modalObj.children}
                </Modal>
            )}
            <Button type={ButtonType.cancel} onClick={toggleModal} small={true}>
                <FontAwesomeIcon icon={faTrashCan} color="var(--main-red)" />
            </Button>
        </React.Fragment>
    );
};

export default DeleteInvite;
