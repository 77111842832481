import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './SortIcon.module.css'

const SortIcon: React.FC<{sortStatus: string[], headerId: string}> = ( { sortStatus, headerId } ) => {

    const isDesc = sortStatus[0] !== headerId || (sortStatus[0] === headerId && sortStatus[1] !== 'asc')
    const isAsc = sortStatus[0] !== headerId || (sortStatus[0] === headerId && sortStatus[1] !== 'desc')

    return (
        <div className={styles.icon} >
            <div className={styles.desc} id={headerId + '-desc'}>
                {isDesc && <FontAwesomeIcon icon={faCaretUp} color='#eee' /> }
            </div>
            <div className={styles.asc} id={headerId + '-asc'}>
                {isAsc && <FontAwesomeIcon icon={faCaretDown} color='#eee'/> }
            </div>
        </div>
    )
}

export default SortIcon