import { useContext, useEffect, useState } from 'react';
import styles from './ScrapingOverview.module.css';
import {
    batchResolveSearchLog,
    checkSearchLog,
    createNewSearchIssue,
    getAnonymizedSearches,
    getSearchIssue,
} from '../../services/apiService';
import Button, { ButtonType } from '../general-components/Button';
import Modal from '../manage-user/Modal';
import ResultsComponent from '../search/ResultsComponent';
import SelectItem from '../general-components/SelectItem';
import EditSearchIssue from '../search-issues/EditSearchIssue';
import OverlayWindow from '../ui-components/OverlayWindow';
import { formatTimestamp } from '../../utils/utils';
import React from 'react';
import AuthContext from '../../store/auth-context';

const ScrapingOverview: React.FC = () => {
    const [searches, setSearches] = useState<any[]>([]);
    const [openTerm, setOpenTerm] = useState<string>('');
    const [openRowId, setOpenRowId] = useState<string | null>(null);
    const [isDeletingSearch, setIsDeletingSearch] = useState<boolean>(false);
    const [deletingId, setDeletingId] = useState<string>('');
    const [issue, setIssue] = useState<any>();
    const [toResolve, setToResolve] = useState<string[]>([]);
    const [successfulResolve, setSuccessfulResolve] = useState(false);
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);
    const [updateOccurred, setUpdateOccurred] = useState(false);
    const context = useContext(AuthContext);

    const onDelete = async (e: MouseEvent) => {
        e.preventDefault();
        await checkSearchLog(deletingId);
        setSearches(searches.filter((s) => s._id !== deletingId));
    };

    const getData = async () => {
        const s = await getAnonymizedSearches();
        console.log(s);
        setSearches(s);
    };
    useEffect(() => {
        setSuccessfulResolve(false);
        getData();
    }, [successfulResolve, updateOccurred]);

    const handleUpdate = () => {
        // setUpdateOccurred(true);
        setIsOverlayOpen(false);
        setIssue('');
        getData();
    };

    const handleCreateIssue = async (searchLogId: string) => {
        const user = await context.getAllInfo();

        const newSearchIssue = await createNewSearchIssue(
            searchLogId,
            user.user._id
        );
        const searchIssueData = await getSearchIssue(newSearchIssue.message);
        setIssue(searchIssueData.message);
        setIsOverlayOpen(true);
    };

    const handleMarkForResolve = (issueId: string) => {
        if (!toResolve.includes(issueId)) {
            setToResolve((prev) => {
                return [...prev, issueId];
            });
        } else {
            return setToResolve((prev) => {
                return prev.filter((id) => id !== issueId);
            });
        }
    };

    const handleMassResolve = async () => {
        const resolveStatus = await batchResolveSearchLog(toResolve);
        setSearches((prev) => {
            return prev.filter((item) => !item.isResolved);
        });
        setToResolve([]);
        setSuccessfulResolve(true);
    };

    const toggleResults = (searchLogId: string, term: string) => {
        if (openRowId === searchLogId) {
            setOpenRowId(null);
            setOpenTerm('');
        } else {
            setOpenRowId(searchLogId);
            setOpenTerm(term);
        }
    };

    return (
        <div className={styles.wrapper}>
            <h1>Recent Searches</h1>
            {toResolve.length <= 0 ? (
                ''
            ) : (
                <Button onClick={handleMassResolve} type={ButtonType.submit}>
                    Resolve Selected
                </Button>
            )}
            <div className={styles.overview}>
                <div className={''}>
                    <table>
                        <thead>
                            <th className={''}>Search Term</th>
                            <th className={''}>User</th>
                            <th className={''}>Date</th>
                            <th className={''}>Results</th>
                            <th className={''}></th>
                            <th className={''}></th>
                            <th className={''}></th>
                            <th className={''}></th>
                        </thead>
                        <tbody>
                            {searches
                                .filter((item) => !item.isResolved)
                                .map((search) => (
                                    <React.Fragment key={search._id}>
                                        <tr key={search._id}>
                                            <td>{search.searchTerm}</td>
                                            <td>
                                                <a
                                                    style={{
                                                        color: '#49d3fc',
                                                        textDecoration:
                                                            'underline',
                                                    }}
                                                    href={`mailto:${search.user[0]?.email}`}
                                                >
                                                    {search.user[0]?.email}
                                                </a>
                                            </td>
                                            <td>
                                                {formatTimestamp(
                                                    search.createdAt
                                                )}
                                            </td>
                                            <td>{search.resultAmount}</td>
                                            <td>
                                                <Button
                                                    small={true}
                                                    onClick={() => {
                                                        window.open(
                                                            'https://google.com/search?q=' +
                                                                search.searchTerm +
                                                                ' MRI safety',
                                                            '_blank'
                                                        );
                                                    }}
                                                    type={ButtonType.submit}
                                                >
                                                    Open
                                                </Button>
                                            </td>
                                            <td>
                                                <Button
                                                    small={true}
                                                    onClick={() => {
                                                        // setOpenTerm(
                                                        //     search.searchTerm
                                                        // );
                                                        // handleReportingSearch(
                                                        //     search._id
                                                        // );
                                                        toggleResults(
                                                            search._id,
                                                            search.searchTerm
                                                        );
                                                    }}
                                                    type={ButtonType.primary}
                                                >
                                                    See Results
                                                </Button>
                                            </td>
                                            <td>
                                                <Button
                                                    small={true}
                                                    onClick={() =>
                                                        handleCreateIssue(
                                                            search._id
                                                        )
                                                    }
                                                    type={ButtonType.primary}
                                                >
                                                    Create Issue
                                                </Button>
                                            </td>
                                            <td className={''}>
                                                <SelectItem
                                                    defaultChecked={false}
                                                    type="checkbox"
                                                    value="Mark for resolving"
                                                    checked={
                                                        toResolve.includes(
                                                            search._id
                                                        )
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={() =>
                                                        handleMarkForResolve(
                                                            search._id
                                                        )
                                                    }
                                                ></SelectItem>
                                            </td>
                                        </tr>
                                        <tr
                                            className={`${styles.resultsRow} ${
                                                openRowId === search._id
                                                    ? styles.open
                                                    : ''
                                            }`}
                                        >
                                            <td colSpan={8}>
                                                {openRowId === search._id && (
                                                    <ResultsComponent
                                                        term={openTerm}
                                                        defaultSemantic={false}
                                                        isFullview={false}
                                                    />
                                                )}
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                        </tbody>
                    </table>
                </div>

                {isDeletingSearch && (
                    <Modal
                        info={{
                            title: 'Delete',
                            message:
                                'Are you sure you want to delete this entry?',
                        }}
                    >
                        <Button
                            onClick={(e: MouseEvent) => {
                                onDelete(e);
                                setIsDeletingSearch(false);
                            }}
                            type={ButtonType.cancel}
                            small={true}
                        >
                            Delete
                        </Button>
                        <Button
                            onClick={() => setIsDeletingSearch(false)}
                            type={ButtonType.tietary}
                            small={true}
                        >
                            Cancel
                        </Button>
                    </Modal>
                )}
            </div>
            {isOverlayOpen && (
                <OverlayWindow
                    close={() => {
                        setIssue('');
                        setIsOverlayOpen(false);
                    }}
                >
                    <EditSearchIssue
                        issue={issue}
                        onUpdateOccurred={handleUpdate}
                    />
                </OverlayWindow>
            )}
        </div>
    );
};

export default ScrapingOverview;
