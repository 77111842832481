import SelectItem from '../general-components/SelectItem';

const WaitingPeriod = ({
    d,
    value,
    conditionals,
    onUpdateConditionalField,
}: {
    d: string;
    value: any;
    conditionals: any;
    onUpdateConditionalField: Function;
}) => {
    return (
        <>
            <SelectItem
                checked={value?.immediateScan || false}
                type="checkbox"
                value={'Can be scanned immediately'}
                onChange={(o: any) => {
                    onUpdateConditionalField(d, {
                        immediateScan: !value?.immediateScan,
                    });
                }}
            />
            {!value?.immediateScan && (
                <input
                    placeholder="How long after insertion (in weeks) can the patient be scanned?"
                    type="number"
                    step="any"
                    defaultValue={conditionals[d]?.weeksAfterScan}
                    onChange={(e) => {
                        onUpdateConditionalField(d, {
                            weeksAfterScan: Number(e.target.value),
                        });
                    }}
                />
            )}
        </>
    );
};

export default WaitingPeriod;
