import React, { useEffect } from 'react';
import { useState } from 'react';
import { initiatePwReset } from '../../services/apiService';
import { emailValidate } from '../../services/formValidationService';
import FormInput from '../general-components/FormInput';
import Button, { ButtonType } from './../general-components/Button';
import SignupLayout from './../ui-components/SignupLayout';
import styles from './InitiatePasswordReset.module.css';

const InitiatePasswordReset = () => {
    const [shownComponent, setShownComponent] = useState('reset');
    const [resetEmail, setResetEmail] = useState('');

    const [error, setError] = useState('');

    const submit = async () => {
        const ev = emailValidate(resetEmail);
        if (ev.valid) {
            const res = await initiatePwReset(resetEmail);
            if (res.succeded) setShownComponent('confirm');
            else setError(res.message);
        } else {
            setError(ev.message);
        }
    };

    useEffect(() => {
        const listener = (event: any) => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                event.preventDefault();
                submit();
            }
        };
        document.addEventListener('keydown', listener);
        return () => {
            document.removeEventListener('keydown', listener);
        };
    }, [resetEmail]);

    return (
        <SignupLayout>
            {shownComponent === 'confirm' && (
                <React.Fragment>
                    <div className={styles.info}>
                        <h3>Email has been sent</h3>
                        <p>
                            Check your email for a link to reset your password.
                            If it doesn’t appear within a few minutes, check
                            your spam folder.
                        </p>
                    </div>
                    <form className={styles.form}>
                        <Button
                            className={styles.button}
                            type={ButtonType.primary}
                            onClick={() => {
                                window.location.href = '/login';
                            }}
                        >
                            Return to login page
                        </Button>
                    </form>
                </React.Fragment>
            )}
            {shownComponent === 'reset' && (
                <React.Fragment>
                    <div className={styles.info}>
                        <h3>Reset your Password</h3>
                        <p>
                            Just give us your email and we'll send you a link to
                            reset it.
                        </p>
                    </div>
                    <form className={styles.form}>
                        <FormInput
                            className={styles.email}
                            label="Email"
                            name="email"
                            placeholder="Enter you email"
                            onChange={({ target }) => {
                                setResetEmail(
                                    (target as HTMLInputElement).value
                                );
                            }}
                            type="email"
                            errorMsg={error}
                        />
                        <Button
                            className={styles.button}
                            type={ButtonType.submit}
                            onClick={submit}
                        >
                            Send
                        </Button>
                        <Button
                            className={styles.button}
                            type={ButtonType.cancel}
                            onClick={() => {
                                window.location.href = '/login';
                            }}
                        >
                            Cancel
                        </Button>
                    </form>
                </React.Fragment>
            )}
        </SignupLayout>
    );
};

export default InitiatePasswordReset;
