import React, { useState, useContext } from 'react';
import ManageUserContext from '../../store/manage-user-context';
import Button, { ButtonType } from '../general-components/Button';
import styles from './DatePicker.module.css';

const DatePicker: React.FC<{ onSubmit: Function }> = ({ onSubmit }) => {
    const context = useContext(ManageUserContext);

    const [error, setError] = useState('');

    const setDate = (e: any) => {
        const date = new Date(e.target.value).toISOString();
        context.setExpiry(date);
    };

    const submit = async () => {
        const error = await context.setCurrentExpiry();
        if (error === '') {
            onSubmit(context.user.id);
        }
        setError(error);
    };

    return (
        <React.Fragment>
            <div>
                <input
                    className={styles.picker}
                    id="_expiry"
                    type="date"
                    value={context.getExpiry()?.split('T')[0]}
                    onChange={setDate}
                />
            </div>
            <span className={styles.error}>{error}</span>
            <Button key={'Save'} type={ButtonType.primary} onClick={submit}>
                Save
            </Button>
        </React.Fragment>
    );
};

export default DatePicker;
