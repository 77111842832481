import React from 'react';
import styles from './Select.module.css';
import SelectItem from './SelectItem';

const Select: React.FC<{
    type: string;
    name: string;
    options: string[];
    defaultChecked?: string[];
    changeSelected?: Function;
}> = ({ type, name, options, changeSelected, defaultChecked }) => {
    return (
        <div className={styles.wrapper}>
            {options.map((o) => (
                <SelectItem
                    key={o}
                    checked={defaultChecked?.includes(o)}
                    className={styles.item}
                    onChange={() => changeSelected(o)}
                    value={o}
                    type={type}
                />
            ))}
        </div>
    );
};

export default Select;
