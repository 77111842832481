import SelectItem from '../general-components/SelectItem';
import CustomTooltip from '../general-components/Tooltip';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CloseIcon from '@mui/icons-material/Close';
import Button, { ButtonType } from '../general-components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import styles from '../edit-implant/EditImplant.module.css';

const ArtifactMrSystem = {
    'Select MR System': '',
    '1.5T': 1.5,
    '3T': 3,
    '7T': 7,
};

const ArtifactPulseSequence = {
    Unknown: '',
    'Spin echo': 'Spin echo',
    'Gradient echo': 'Gradient echo',
};

const Artefacts = ({
    d,
    value,
    autofilledFields,
    showWarningIcon,
    onUpdateConditionalField,
    onResetHighlights,
    onSetAutoFilledFields,
    onSetShowWarningIcon,
}: {
    d: string;
    value: any;
    autofilledFields: any;
    showWarningIcon: any;
    onUpdateConditionalField: Function;
    onResetHighlights: Function;
    onSetAutoFilledFields: Function;
    onSetShowWarningIcon: Function;
}) => {
    return (
        <div>
            <SelectItem
                defaultChecked={
                    value?.length > 0 ? value[0]?.hasArtifact : false
                }
                type="checkbox"
                value={
                    'MR Image Quality near Device possible compromised. No further information available'
                }
                onChange={(o: any) => {
                    onUpdateConditionalField(d, [
                        {
                            hasArtifact:
                                value?.length > 0
                                    ? !value[0]?.hasArtifact
                                    : true,
                        },
                    ]);
                }}
            />
            {value?.length > 0 &&
                !value[0].hasArtifact &&
                value?.map((artefact: any, index: number) => (
                    <div
                        className={styles['conditional-inputs']}
                        key={artefact + index}
                    >
                        <div className={styles.conditionalGroup}>
                            <div className={styles['inline-inputs']}>
                                <input
                                    type="number"
                                    defaultValue={artefact?.distance}
                                    placeholder="Artifact distance(mm)"
                                    style={
                                        autofilledFields.artefact
                                            ? {
                                                  background:
                                                      'rgb(128, 128, 128)',
                                                  border: '1px solid rgb(78, 165, 217)',
                                              }
                                            : {}
                                    }
                                    step="any"
                                    onChange={(e) => {
                                        let artefactNew = value;
                                        artefactNew[index].distance = Number(
                                            e.target.value
                                        );
                                        onUpdateConditionalField(
                                            d,
                                            artefactNew
                                        );
                                        onResetHighlights(['artefacts']);
                                    }}
                                />
                                <select
                                    defaultValue={artefact?.mrSystem}
                                    style={
                                        autofilledFields.mrSystem
                                            ? {
                                                  background:
                                                      'rgb(128, 128, 128)',
                                                  border: '1px solid rgb(78, 165, 217)',
                                                  color: 'white',
                                              }
                                            : {}
                                    }
                                    onChange={(e) => {
                                        let artefactNew = value;
                                        artefactNew[index].mrSystem = Number(
                                            e.target.value
                                        );
                                        onUpdateConditionalField(
                                            d,
                                            artefactNew
                                        );
                                        if (autofilledFields.mrSystem) {
                                            onSetAutoFilledFields(
                                                (prev: any) => ({
                                                    ...prev,
                                                    mrSystem: false,
                                                })
                                            );
                                        }
                                    }}
                                >
                                    {Object.entries(ArtifactMrSystem).map(
                                        ([key, value], index) => (
                                            <option key={index} value={value}>
                                                {key}
                                            </option>
                                        )
                                    )}
                                </select>
                                <div className={styles.selectContainer}>
                                    <select
                                        defaultValue={artefact?.pulseSequence}
                                        style={{
                                            ...(autofilledFields.pulseSequence
                                                ? {
                                                      background:
                                                          'rgb(128, 128, 128)',
                                                      border: '1px solid rgb(78, 165, 217)',
                                                      color: 'white',
                                                  }
                                                : {}),
                                            ...(showWarningIcon
                                                ? {
                                                      border: '1px solid orange',
                                                  }
                                                : {}),
                                        }}
                                        onChange={(e) => {
                                            onSetShowWarningIcon(false);
                                            let artefactNew = value;
                                            artefactNew[index].pulseSequence =
                                                e.target.value;
                                            onUpdateConditionalField(
                                                d,
                                                artefactNew
                                            );
                                            onResetHighlights([
                                                'pulseSequence',
                                            ]);
                                        }}
                                    >
                                        {Object.entries(
                                            ArtifactPulseSequence
                                        ).map(([key, value], index) => (
                                            <option key={index} value={value}>
                                                {key}
                                            </option>
                                        ))}
                                    </select>
                                    {showWarningIcon && (
                                        <CustomTooltip
                                            content="Both spin and gradient echo were returned"
                                            className={styles.tooltip}
                                            children={
                                                <WarningAmberIcon
                                                    className={
                                                        styles.warningIcon
                                                    }
                                                />
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                            <input
                                type="text"
                                value={artefact?.condition}
                                placeholder="Condition"
                                onChange={(e) => {
                                    let artefactNew = [...value];
                                    artefactNew[index] = {
                                        ...artefactNew[index],
                                        condition: e.target.value,
                                    };
                                    onUpdateConditionalField(d, artefactNew);
                                }}
                            />
                        </div>
                        <Button
                            type={ButtonType.cancel}
                            onClick={() => {
                                let artefactDel = [...value];
                                artefactDel.splice(index, 1);
                                onUpdateConditionalField(d, artefactDel);
                                onResetHighlights([
                                    'pulseSequence',
                                    'artefacts',
                                    'mrSystem',
                                ]);
                            }}
                        >
                            <CloseIcon />
                        </Button>
                    </div>
                ))}

            {((value?.length > 0 && !value[0].hasArtifact) ||
                value?.length === 0) && (
                <Button
                    type={ButtonType.primary}
                    onClick={() => {
                        onUpdateConditionalField(d, [
                            ...value,
                            {
                                distance: '',
                                mrSystem: '',
                                pulseSequence: '',
                            },
                        ]);
                    }}
                >
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
            )}
        </div>
    );
};

export default Artefacts;
