import { useEffect, useState } from 'react';
import { getAllManufactures } from '../../services/apiService';
import Button, { ButtonType } from '../general-components/Button';
import AddUser from '../manage-user/AddUser';

const InviteManufacturer = () => {
    const [manufacturers, setManufacturers] = useState([]);
    const [selectedManufacturerId, setSelectedManufacturerId] = useState('');
    const [showModal, setShowModal] = useState(false);

    const getManufacturers = async () => {
        const manufacturers = await getAllManufactures();
        setManufacturers(manufacturers);
    };

    const onManufacturerChange = (e: any) => {
        setSelectedManufacturerId(e.target.value);
    };

    const openModal = () => {
        if (selectedManufacturerId.length > 0) {
            setShowModal(true);
        }
    };

    useEffect(() => {
        getManufacturers();
    }, []);

    return (
        <div>
            <select onChange={onManufacturerChange} defaultValue={'default'}>
                <option value="default" disabled>
                    Choose a Manufacturer
                </option>
                {manufacturers.map((Manufacturer) => (
                    <option key={Manufacturer._id} value={Manufacturer._id}>
                        {Manufacturer.name}
                    </option>
                ))}
            </select>
            <Button type={ButtonType.primary} onClick={openModal}>
                Choose
            </Button>
            {showModal && (
                <AddUser
                    manuId={selectedManufacturerId}
                    isAdmin={true}
                    close={() => setShowModal(!showModal)}
                    onSubmit={() => {}}
                />
            )}
        </div>
    );
};

export default InviteManufacturer;
