import { useEffect, useState } from 'react';
import { getMRIMachineById } from '../../services/apiService';
import styles from './RoomOverview.module.css';
import LoadingRing from '../ui-components/LoadingRing';
import MRIMachineForm from './MRIMachineForm';

const MRIMachine: React.FC<{
    machineId: string;
}> = ({ machineId }) => {
    const [machine, setMachine] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const getData = async () => {
        const machine = await getMRIMachineById(machineId);
        setMachine(machine);
        setIsLoading(false);
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            {isLoading && (
                <div className={styles.loading}>
                    <LoadingRing size="large" />
                </div>
            )}
            {!isLoading && (
                <MRIMachineForm MRImachine={machine} isNew={false} />
            )}
        </>
    );
};

export default MRIMachine;
