import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import styles from './CustomTextArea.module.css';

interface CustomTextAreaProps {
    currComment: string;
    commentChange: (c: string) => void;
    isEdit?: boolean;
    placeholder?: string;
    submit: () => void;

    onCancel?: () => void;
}

const CustomTextArea: React.FC<CustomTextAreaProps> = ({
    currComment,
    commentChange,
    isEdit,
    placeholder,
    submit,
    onCancel,
}) => {
    const [inputValue, setInputValue] = useState(currComment);
    const [isSetSelectionRangeOnce, setIsSetSelectionRangeOnce] =
        useState(true);
    const textAreaRef = useRef(null);

    const inputValueChange = (inputValue: string) => {
        setInputValue(inputValue);
        commentChange(inputValue);
    };

    useLayoutEffect(() => {
        const autoResizeTextArea = () => {
            const { current } = textAreaRef;

            if (current) {
                current.style.height = 0;
                current.style.height = current.scrollHeight + 'px';
                if (isEdit) {
                    current.focus();
                }
                if (isSetSelectionRangeOnce) {
                    current.setSelectionRange(
                        current.value.length,
                        current.value.length
                    );
                    setIsSetSelectionRangeOnce(false);
                }
            }
        };

        autoResizeTextArea();
    }, [inputValue]);

    useEffect(() => setInputValue(currComment), [currComment]);

    const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            submit();
        }
        if (isEdit && e.key === 'Escape' && !e.shiftKey) {
            onCancel();
        }
    };

    return (
        <textarea
            className={isEdit ? styles['edit-TextArea'] : styles['textArea']}
            ref={textAreaRef}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            onChange={(e) => {
                inputValueChange(e.target.value);
            }}
            value={inputValue
                ?.split('<br/>')
                .join('\n')
                .split('<br>')
                .join('\n')}
        />
    );
};

export default CustomTextArea;
