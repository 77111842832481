import React, { useState, useEffect, ReactNode } from 'react';
import axios from 'axios';
import { GETREQUESTOPTIONS } from '../services/apiService';

export interface ContextUser {
    name: string;
    id: string;
    email: string;
    role: string;
    department: {
        name: string;
    };
    activationDate: Date;
    institution: string;
}
interface ctx {
    user: any;
    setUser: React.Dispatch<any>;
    updateUser: (user: any) => void;
    deleteUser: () => void;
    getExpiry: () => any;
    setExpiry: (date: Date) => Promise<string>;
    setCurrentExpiry: () => Promise<string>;
    setExpiryDate: (exp: Date) => void;
}

const ManageUserContext = React.createContext({
    user: {
        name: '',
        id: '',
        email: '',
        role: '',
        department: { name: '' },
        activationDate: new Date(),
        institution: '',
    },
    setUser: (u: any) => {},
    updateUser: (u: any) => {},
    deleteUser: () => {},
    getExpiry: () => {
        return '';
    },
    setExpiry: (date: string) => {},
    setCurrentExpiry: () => {
        return Promise.resolve('');
    },
    setExpiryDate: (date: Date) => {
        return Promise.resolve('');
    },
});

export const ManageUserContextProvider: React.FC<{
    user: any;
    userDeleted?: (id: string) => void;
    children: ReactNode;
}> = ({ user, userDeleted, children }) => {
    const [expiry, setExpiry] = useState(user.expiryDate);
    const [currentUser, setUser] = useState(user);

    const updateUser = (user: any) => {
        setUser(user);
        axios
            .post('/api/user/update', user, GETREQUESTOPTIONS())
            .then((res) => {});
    };

    const deleteUser = () => {
        setUser({});
        axios
            .post(
                '/api/user/delete',
                { userId: currentUser.id },
                GETREQUESTOPTIONS()
            )
            .then((res) => {
                userDeleted(currentUser.id);
            });
    };

    const getExpiry = () => {
        return expiry;
    };

    const setExp = (date: string) => {
        setExpiry(date);
    };

    const setExpiryDate = async (exp: Date) => {
        const date = new Date(exp);
        return axios
            .post(
                '/api/user/set-expiry',
                {
                    userId: currentUser.id,
                    newExpiryDate: date,
                },
                GETREQUESTOPTIONS()
            )
            .then((res) => {
                setUser((prevUser: any) => ({
                    ...prevUser,
                    expiryDate: exp,
                }));
                return Promise.resolve('');
            })
            .catch((err: any) => {
                return Promise.resolve(err.response.data.message as string);
            });
    };

    const setCurrentExpiry = async () => {
        return await setExpiryDate(expiry);
    };

    const ctxVal = {
        user: currentUser,
        setUser: setUser,
        updateUser: updateUser,
        deleteUser: deleteUser,
        getExpiry: getExpiry,
        setExpiry: setExp,
        setCurrentExpiry: setCurrentExpiry,
        setExpiryDate: setExpiryDate,
    };

    return (
        <ManageUserContext.Provider value={ctxVal}>
            {children}
        </ManageUserContext.Provider>
    );
};

export default ManageUserContext;
