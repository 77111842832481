import { useState } from 'react';
import { deleteMRIMachine } from '../../services/apiService';
import Modal from '../manage-user/Modal';
import styles from '../manage-user/Modal.module.css';
import Button, { ButtonType } from '../general-components/Button';

const DeleteMRIMachine: React.FC<{
    machine: any;
    onClose: () => void;
}> = ({ machine, onClose }) => {
    const deleteMachine = async () => {
        const res = await deleteMRIMachine(machine._id);
        if (res.status === 200) {
            setDeleteModalInfo((prev) => ({
                ...prev,
                message: machine.Model + ' was successfully deleted.',
                children: [
                    <div className={styles.buttons}>
                        <Button type={ButtonType.primary} onClick={onClose}>
                            Ok
                        </Button>
                    </div>,
                ],
            }));
        } else {
            setDeleteModalInfo((prev) => ({
                ...prev,
                message: 'Something went wrong. Please try again.',
                children: [
                    <div className={styles.buttons}>
                        <Button type={ButtonType.primary} onClick={onClose}>
                            Ok
                        </Button>
                    </div>,
                ],
            }));
        }
    };

    const [deleteModalInfo, setDeleteModalInfo] = useState({
        title: 'Delete MRIMachine',
        message:
            'Are you sure you want to delete ' +
            machine.Model +
            ' permanently?',
        className: styles['sub-modal'],
        children: [
            <div className={styles.buttons}>
                <Button type={ButtonType.primary} onClick={deleteMachine}>
                    Yes
                </Button>
                <Button type={ButtonType.secondary} onClick={onClose}>
                    No
                </Button>
            </div>,
        ],
    });

    return (
        <Modal info={deleteModalInfo} updateView={onClose}>
            {deleteModalInfo.children}
        </Modal>
    );
};

export default DeleteMRIMachine;
