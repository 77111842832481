import styles from './GeneralInfo.module.css';

const GeneralInfo = ({ children }: { children: any }) => {
    return (
        <div>
            <h3>General info</h3>
            {children}
        </div>
    );
};

export default GeneralInfo;
