import { useEffect, useState } from 'react';
import { getBookmarks } from '../../services/apiService';
import LoadingRing from '../ui-components/LoadingRing';
import styles from './Bookmarks.module.css';
import Results from '../search/Results';

const Bookmarks = () => {
    const [favorites, setFavorite] = useState([]);
    const [favoritesLoading, setFavoritesLoading] = useState(true);

    const getBookmarkedData = async () => {
        const bookmarkList = await getBookmarks();
        setFavorite(bookmarkList);
        setFavoritesLoading(false);
    };

    useEffect(() => {
        getBookmarkedData();
    }, []);

    return (
        <div className={styles.wrapper}>
            {!favoritesLoading && favorites.length === 0 && (
                <div className={styles.loading}>
                    <h3>No bookmarked implants</h3>
                </div>
            )}
            {favoritesLoading && (
                <div className={styles.loading}>
                    <LoadingRing size="large" />
                </div>
            )}
            {!favoritesLoading && favorites.length > 0 && (
                <Results title="Bookmarks" term="" defaultResults={favorites} />
            )}
        </div>
    );
};

export default Bookmarks;
