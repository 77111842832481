import CopyConditionals from './CopyConditionals';
import DragAndDrop from '../general-components/drag-and-drop';
import styles from './SelecSource.module.css';
import {
    initConditionals,
    initialInfo,
    useImplantContext,
} from '../../store/implant-context';
import SelectItem from '../general-components/SelectItem';

type SelectSourceProps = {
    isPreview?: boolean;
};

const SelectSource: React.FC<SelectSourceProps & { onSelect?: () => void }> = ({
    isPreview,
    onSelect,
}) => {
    const {
        setFile,
        setFileName,
        setSourceSelected,
        setUsesExistingFile,
        dispatchInfo,
        dispatchConditionals,
        fileName,
        allFiles,
        runPDFExtractor,
        setRunPDFExtractor,
    } = useImplantContext();

    const handleCheckboxChange = (value: string, isChecked: boolean) => {
        setRunPDFExtractor(isChecked);
    };

    const handleNewFileUpload = (file: File) => {
        setUsesExistingFile(false);
        if (runPDFExtractor) {
            dispatchInfo({ type: 'RESET', payload: initialInfo });
            dispatchConditionals({ type: 'RESET', payload: initConditionals });
        }
        setFile(file);
        setFileName(file.name);
        setSourceSelected(true);
        onSelect?.();
    };

    const handleExistingFileSelection = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setFile(null);
        setUsesExistingFile(true);
        const selectedFileName = event.target.value;
        setFileName(selectedFileName);
        setSourceSelected(true);
        dispatchInfo({ type: 'RESET', payload: initialInfo });
        dispatchConditionals({ type: 'RESET', payload: initConditionals });
        setRunPDFExtractor(false);
        onSelect?.();
    };

    const handleSetConditionals = (implant: any) => {
        const updatedInfo = { ...implant.info };
        delete updatedInfo._id;
        dispatchInfo({
            type: 'BULK_UPDATE',
            payload: updatedInfo,
        });
        let newConditionals = { ...implant.conditionals };
        delete newConditionals._id;
        dispatchConditionals({
            type: 'BULK_UPDATE',
            payload: newConditionals,
        });
        setFileName(implant.info.Filename);
        setRunPDFExtractor(false);
        setSourceSelected(true);
        setUsesExistingFile(true);
        onSelect?.();
    };

    return (
        <div className={isPreview ? styles.previewWrapper : styles.wrapper}>
            <h3>Choose source</h3>
            <div className={styles.container}>
                <div>
                    <CopyConditionals setConditionals={handleSetConditionals} />
                </div>

                <div>
                    <SelectItem
                        checked={runPDFExtractor}
                        type="checkbox"
                        value={'Run PDF Extractor'}
                        onChange={handleCheckboxChange}
                    />
                    <DragAndDrop
                        noBg={true}
                        dropHandler={handleNewFileUpload}
                        label="New Source File"
                        useContainerStyle={true}
                    >
                        {fileName && (
                            <div>
                                <label>Source file:</label>
                                <p>{fileName}</p>
                            </div>
                        )}
                    </DragAndDrop>
                    <select
                        id={'select-existing-file'}
                        className={styles.selectFile}
                        value={fileName || 'default'}
                        onChange={handleExistingFileSelection}
                    >
                        <option value={'default'} disabled>
                            Select existing PDF
                        </option>
                        {allFiles.map((f) => (
                            <option key={f} value={f}>
                                {f}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
};

export default SelectSource;
