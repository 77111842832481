const ConditionalTextField = ({
    d,
    value,
    onUpdateConditionalField,
    placeholder,
}: {
    d: string;
    value: any;
    onUpdateConditionalField: Function;
    placeholder?: string;
}) => {
    return (
        <input
            type="text"
            defaultValue={value}
            placeholder={placeholder}
            onChange={(e) => {
                onUpdateConditionalField(d, e.target.value);
            }}
        />
    );
};

export default ConditionalTextField;
