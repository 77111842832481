import React, { useContext, useEffect, useState, useReducer } from 'react';
import AuthContext from '../../store/auth-context';
import Button, { ButtonType } from '../general-components/Button';
import LoadingRing from '../ui-components/LoadingRing';
import styles from './EditUserPage.module.css';
import { updateUserInfo, updateUserPassword } from '../../services/apiService';
import Modal from '../manage-user/Modal';
import UserInfoForm from './UserInfoForm';
import UserPasswordForm from './UserPasswordForm';
import {
    passwordValidate,
    repeatPasswordValidate,
    emailValidate,
    nameValidate,
} from '../../services/formValidationService';
import Appearance from './Appearance';

const formReducer = (state: any, event: any): any => {
    return {
        ...state,
        [event.name]: event.value,
    };
};

const UserProfilePage = () => {
    const context = useContext(AuthContext);

    const [userInfo, setUserInfo] = useState<any>([]);
    const [dataUserInfo, setDataUserInfo] = useReducer(formReducer, {});
    const [dataUserPassword, setDataUserPassword] = useReducer(formReducer, {});
    const [errorData, setErrorData] = useReducer(formReducer, {});
    const [isLoading, setIsLoading] = useState(true);
    const [isSent, setIsSent] = useState(false);
    const [fontSizeValue, setFontSizeValue] = useState('');
    const [modalResponse, setModalResponse] = useState({
        title: '',
        message: '',
    });

    const getData = async () => {
        const info = await context.getAllInfo();
        const settings = await context.getAllSettings();
        if (settings.fontSize) {
            setFontSizeValue(settings.fontSize);
        }
        setUserInfo(info);
        setIsLoading(false);
    };

    useEffect(() => {
        getData();
    }, []);

    const handleInfoChange = (
        e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const element = e.target as HTMLInputElement;
        if (element.value.length > 0) {
            errorData[element.name] = '';
        }
        setDataUserInfo({
            name: element.name,
            value: element.value,
        });
    };

    const handleInfoSubmit = async (e: any) => {
        e.preventDefault();
        if (!dataUserInfo.email) {
            delete dataUserInfo.email;
        } else {
            const emailValid = emailValidate(dataUserInfo.email);
            if (!emailValid.valid) {
                setErrorData({
                    name: 'email',
                    value: emailValid.message,
                });
                return;
            }
        }
        if (!dataUserInfo.name) {
            delete dataUserInfo.name;
        } else {
            const nameValid = nameValidate(dataUserInfo.name, true);
            if (!nameValid.valid) {
                setErrorData({
                    name: 'name',
                    value: nameValid.message,
                });
                return;
            }
        }
        const response = await updateUserInfo(dataUserInfo);
        setIsLoading(false);
        if (response) {
            setIsSent(true);
            setModalResponse({
                ...modalResponse,
                title: 'Done',
                message: 'Your info has been updated successfully!',
            });
        } else {
            const emailValid = emailValidate(dataUserInfo.email);
            if (!emailValid.valid) {
                setErrorData({
                    name: 'email',
                    value: emailValid.message,
                });
                return;
            }
        }
    };

    const setErrorOnField = (field: string) => {
        if (!dataUserPassword[field]) {
            setErrorData({
                name: field,
                value: 'Please fill out this field',
            });
        }
    };

    const handlePasswordChange = (
        e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const element = e.target as HTMLInputElement;
        if (element.value.length > 0) {
            errorData[element.name] = '';
        }
        setDataUserPassword({
            name: element.name,
            value: element.value,
        });
    };

    const handlePasswordSubmit = async (e: any) => {
        e.preventDefault();
        setErrorOnField('oldPassword');
        setErrorOnField('newPassword');
        setErrorOnField('repeatNewPassword');
        if (Object.values(dataUserPassword).every((value) => !value)) {
            setIsLoading(false);
            setIsSent(false);
            return;
        } else {
            const pw = passwordValidate(dataUserPassword.newPassword);
            const rpw = repeatPasswordValidate(
                dataUserPassword.newPassword,
                dataUserPassword.repeatNewPassword
            );
            if (!pw.valid) {
                setErrorData({
                    name: 'repeatNewPassword',
                    value: pw.message,
                });
            } else if (!rpw.valid) {
                setErrorData({
                    name: 'repeatNewPassword',
                    value: rpw.message,
                });
            } else {
                const response = await updateUserPassword(dataUserPassword);
                if (response.success === false) {
                    setErrorData({
                        name: 'oldPassword',
                        value: 'Incorrect password',
                    });
                    setIsSent(false);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    setIsSent(true);
                    setModalResponse({
                        ...modalResponse,
                        title: 'Done',
                        message: 'Your password has been updated successfully!',
                    });
                }
            }
        }
    };

    const handleCloseModal = () => {
        setIsSent(false);
    };

    const handleChangeFontSize = async (e: any) => {
        e.preventDefault();
        if (context.updateUserSettings({ fontSize: fontSizeValue })) {
            document.documentElement.style.fontSize = fontSizeValue + 'px';
            setIsSent(true);
            setModalResponse({
                ...modalResponse,
                title: 'Done',
                message:
                    'Your text size preferences have been updated successfully!',
            });
        }
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.userPage}>
                <h2>Edit your information</h2>
                {isLoading && !isSent && (
                    <div className={styles.loading}>
                        <LoadingRing size="large" />
                    </div>
                )}
                {isSent && !isLoading && (
                    <Modal
                        info={{
                            title: modalResponse.title,
                            message: modalResponse.message,
                        }}
                        updateView={handleCloseModal}
                    >
                        <Button
                            type={ButtonType.secondary}
                            onClick={handleCloseModal}
                        >
                            Close
                        </Button>
                    </Modal>
                )}
                {!isLoading && (
                    <>
                        <UserInfoForm
                            handleInfoChange={handleInfoChange}
                            handleInfoSubmit={handleInfoSubmit}
                            errorMsg={errorData}
                            defaultName={userInfo.user.name}
                            defaultEmail={userInfo.user.email}
                        />
                        <div>
                            <p>Text size - {fontSizeValue} </p>
                            <input
                                type="range"
                                min="10"
                                max="48"
                                value={fontSizeValue}
                                step="1"
                                onChange={(e) => {
                                    setFontSizeValue(e.target.value);
                                }}
                            />
                            <p style={{ fontSize: fontSizeValue + 'px' }}>
                                Lorem ipsum dolor, sit amet consectetur
                                adipisicing elit. Impedit corporis obcaecati aut
                                repellat nulla excepturi rem cumque neque at
                                unde suscipit ad dignissimos non, culpa error
                                nihil est nemo ut?
                            </p>
                            <Button
                                type={ButtonType.submit}
                                onClick={handleChangeFontSize}
                                className={styles.submitBtn}
                            >
                                Save text size
                            </Button>
                        </div>
                        <Appearance />
                        <UserPasswordForm
                            handlePasswordChange={handlePasswordChange}
                            handlePasswordSubmit={handlePasswordSubmit}
                            errorMsg={errorData}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default UserProfilePage;
