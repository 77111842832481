import React, { useState, useEffect } from 'react';
import { recentlyUpdatedImplants } from '../../services/apiService';
import styles from './SearchableDropdown.module.css';
import ComponentSelectList from '../general/select/ComponentSelectList';
import CardComponent from '../general/Cards/CardComponent';
import SETTING_KEYS from '../../constants/setting-keys';
import Status from '../general-components/Status';

interface SearchableDropdownProps {
    onSelectImplant: (selectedImplants: string[]) => void;
}

const SearchableDropdown: React.FC<SearchableDropdownProps> = ({
    onSelectImplant,
}) => {
    const [implants, setImplants] = useState<any[]>([]);
    const [selectedImplants, setSelectedImplants] = useState<any[]>([]);

    useEffect(() => {
        const fetchRecentImplants = async () => {
            const date = new Date();
            date.setTime(date.getTime() - 30 * 24 * 60 * 60 * 1000);
            const recentImplants = await recentlyUpdatedImplants(date);
            console.log(recentImplants);
            setImplants(recentImplants);
        };

        fetchRecentImplants();
    }, []);

    const handleSelectImplant = (id: string, checked: boolean) => {
        console.log(id, checked);
        if (checked) {
            const updatedSelectedImplants = [
                ...selectedImplants,
                implants.find((implant) => implant._id === id),
            ];
            setSelectedImplants(updatedSelectedImplants);
            onSelectImplant(updatedSelectedImplants);
        } else {
            const updatedSelectedImplants = selectedImplants.filter(
                (selectedImplant) => selectedImplant._id !== id
            );
            setSelectedImplants(updatedSelectedImplants);
            onSelectImplant(updatedSelectedImplants);
        }
    };

    return (
        <div className={styles.searchableDropdown}>
            <h4>Recent Implants:</h4>
            <div className={styles.list}>
                <ComponentSelectList
                    checkType="checkbox"
                    items={implants.map((implant) => ({
                        comp: (
                            <CardComponent
                                title={implant.Model}
                                description={implant.Type}
                                preview={
                                    <Status
                                        status={implant[SETTING_KEYS.mriStatus]}
                                    />
                                }
                            />
                        ),
                        id: implant._id,
                        selected: selectedImplants.some(
                            (selectedImplant) =>
                                selectedImplant._id === implant._id
                        ),
                    }))}
                    onChange={handleSelectImplant}
                />
            </div>

            {selectedImplants.length > 0 && (
                <div className={styles.selectedImplants}>
                    <h4>Selected Implants:</h4>
                    <ul>
                        {selectedImplants.map((implant) => (
                            <li key={implant._id}>{implant.Model}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default SearchableDropdown;
