import { useState, useEffect, useReducer, ChangeEvent } from 'react';
import {
    getInvitations,
    deleteAllInvitations,
    sendInvitationReminders,
} from '../../services/apiService';
import Modal from './Modal';
import Button, { ButtonType } from '../general-components/Button';
import LoadingRing from '../ui-components/LoadingRing';
import PendingRow from './PendingRow';
import styles from './Organizations.module.css';
import FormInput from '../general-components/FormInput';
import {
    formReducer,
    errorReducer,
    camelCaseToSentence,
} from '../../utils/utils';

const initialFormData = { title: '', news: '' };

// initialErrorData includes only required fields
const initialErrorData = { title: '', news: '' };

const PendingInvitations: React.FC<{
    refreshData: boolean;
}> = ({ refreshData }) => {
    const [invites, setInvites] = useState([]);
    const [isShowRevoke, setIsShowRevoke] = useState(false);
    const [isRevoked, setIsRevoked] = useState(false);
    const [isShowRemind, setIsShowRemind] = useState(false);
    const [isRemindes, setIsReminded] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [remindInvitationData, setRemindInvitationData] = useReducer(
        formReducer,
        initialFormData
    );
    const [errorData, setErrorData] = useReducer(
        errorReducer,
        initialErrorData
    );
    // Define a list of required fields in the form
    const requiredFields = Object.keys(initialErrorData);

    const handleInputChange = (
        e: ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
    ) => {
        const { name, value } = e.target;

        setRemindInvitationData({
            type: 'update',
            name,
            value,
        });
        requiredFields.includes(name) &&
            setErrorData({ type: 'update', name, value: '' });
    };

    const getData = async () => {
        const invitations = await getInvitations();
        setInvites(invitations);
        setIsLoading(false);
    };

    useEffect(() => {
        getData();
    }, [refreshData]);

    const revokeAllInvitations = async () => {
        const result = await deleteAllInvitations();
        if (result) {
            getData();
            setIsRevoked(true);
        }
    };

    const remindAllInvitations = async () => {
        let allChecksPassed = false;

        Object.entries(remindInvitationData).forEach(([key, value]) => {
            if (requiredFields.includes(key) && value === '') {
                setErrorData({
                    type: 'update',
                    name: key,
                    value: `Please fill ${camelCaseToSentence(key)}`,
                });
            }
        });

        allChecksPassed = Object.entries(remindInvitationData)
            .filter(([key]) => requiredFields.includes(key))
            .every(([key, value]) => value !== '');

        if (allChecksPassed) {
            const emailsToRemind = [];
            for (const invite of invites) {
                emailsToRemind.push({ email: invite.email, id: invite._id });
            }
            const res = await sendInvitationReminders(
                emailsToRemind,
                remindInvitationData.title,
                remindInvitationData.news
            );
            if (res) {
                setIsReminded(true);
            }
        }
    };

    return (
        <>
            <h2>Pending invitations</h2>
            {isLoading && (
                <div className={styles.loading}>
                    <LoadingRing size="large" />
                </div>
            )}
            {!isLoading && invites.length === 0 && (
                <p className={styles.noInfoMessage}>
                    No pending invitations found
                </p>
            )}
            {isShowRevoke && (
                <Modal
                    info={{
                        title: 'Revoke All',
                        submitButtonText: 'Revoke',
                        message: 'All invitations are revoked',
                        submitButtonDanger: true,
                        isSubmitted: isRevoked,
                    }}
                    updateView={() => {
                        setIsShowRevoke(false);
                        setIsRevoked(true);
                    }}
                    submitModal={revokeAllInvitations}
                >
                    <p>All pending invitations will be revoked.</p>
                </Modal>
            )}
            {isShowRemind && (
                <Modal
                    info={{
                        title: 'Remind All',
                        submitButtonText: 'Send',
                        message: 'A reminder has been sent to all invitations',
                        submitButtonDanger: false,
                        isSubmitted: isRemindes,
                        className: 'remind-modal',
                    }}
                    updateView={() => {
                        setIsShowRemind(false);
                        setIsReminded(false);
                        setRemindInvitationData({
                            type: 'reset',
                            initialFormData,
                        });
                        setErrorData({ type: 'reset', initialErrorData });
                    }}
                    submitModal={remindAllInvitations}
                >
                    <p>Sign-up Reminder</p>
                    <FormInput
                        label="Title"
                        name="title"
                        placeholder="Description"
                        defaultValue={remindInvitationData.title}
                        onChange={handleInputChange}
                        errorMsg={errorData.title}
                    />
                    <FormInput
                        label="News"
                        placeholder="Type something"
                        defaultValue={remindInvitationData.news}
                        name="news"
                        tag="textArea"
                        errorMsg={errorData.news}
                        className="textArea"
                        onChange={handleInputChange}
                    />
                </Modal>
            )}
            {!isLoading && invites.length > 0 && (
                <>
                    <div className={styles.pendingButtonsWrapper}>
                        <Button
                            type={ButtonType.cancel}
                            onClick={() => setIsShowRevoke(true)}
                        >
                            Revoke All
                        </Button>
                        <Button
                            type={ButtonType.submit}
                            onClick={() => setIsShowRemind(true)}
                        >
                            Remind Everyone
                        </Button>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>License</th>
                                <th>Contact</th>
                                <th>Start Date</th>
                                <th>Reminders</th>
                                <th>Revoke</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invites.map((invite) => (
                                <PendingRow
                                    key={invite._id}
                                    invite={invite}
                                    refreshData={getData}
                                />
                            ))}
                        </tbody>
                    </table>
                </>
            )}
        </>
    );
};
export default PendingInvitations;
