import React, { useState } from 'react';
import Button, { ButtonType } from '../general-components/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFileVideo,
    faFileImage,
    faArrowUpFromBracket,
} from '@fortawesome/free-solid-svg-icons';

import styles from './VideoUploadPage.module.css';
import DragAndDrop from '../general-components/drag-and-drop';
import {
    uploadThumbnailFile,
    uploadVideoFile,
} from '../../services/apiService';
import FileUpload from '../general-components/FileUpload';
import LoadingRing from '../ui-components/LoadingRing';
import Modal, { ModalInfo } from '../manage-user/Modal';
import FormInput from '../general-components/FormInput';

const VideoUploadPage: React.FC = () => {
    // States to update from the form elements
    const [title, setTitle] = useState<string>('');
    const [tags, setTags] = useState<string[]>([]);
    const [author, setAuthor] = useState<string>('');
    const [videoFile, setVideoFile] = useState<File | null>(null);
    const [videoThumbnail, setVideoThumbnail] = useState<File | null>(null);

    // States to manage component state/view
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [isUploaded, setIsUploaded] = useState<boolean>(false);

    const [modalMessage, setModalMessage] = useState('');

    // Predefined modal content for successful and unsuccessful files uploading
    const modalInfoError: ModalInfo = {
        title: 'Video was not uploaded',
        message: modalMessage,
    };
    const modalInfoUploaded: ModalInfo = {
        title: 'Video successfully uploaded',
        message: modalMessage,
    };

    const refreshPage = () => {
        window.location.reload();
    };
    // Function to handle form submission
    const onFormSubmit = async (
        e: React.FormEvent<HTMLFormElement>,
        isPublished?: true
    ) => {
        e.preventDefault();
        // Check if all required information provided
        if (!videoFile || !videoThumbnail || !title || !tags || !author) {
            showModal('Please fill in all fields');
            return;
        }
        setIsLoading(true);

        const metaData = {
            title: title.trim(),
            tags: tags.map((tag) => tag.trim()),
            author: author.trim(),
            thumbnail: videoThumbnail.name,
        };
        try {
            // Upload video file and its metadata
            const uploadVideoFileResponse = await uploadVideoFile(
                videoFile,
                metaData,
                isPublished
            );
            const { videoUploadMessage, videoUploadStatus } =
                uploadVideoFileResponse.data;
            if (
                uploadVideoFileResponse.status !== 200 &&
                videoUploadStatus !== 'video_or_image_exists'
            ) {
                showModal(videoUploadMessage);
                throw new Error('Video File Upload Failed');
            }
            if (videoUploadStatus === 'video_or_image_exists') {
                showModal(videoUploadMessage);
                setIsLoading(false);
                return;
            }
            // Upload thumbnail file with the video id from the uploadVideoFileResponse
            const uploadThumbnailResponse = await uploadThumbnailFile(
                videoThumbnail,
                uploadVideoFileResponse.data._id
            );
            if (uploadThumbnailResponse.status !== 200) {
                throw new Error('Video File Upload Failed');
            }
            resetForm();
            setIsUploaded(true);
            showModal('Thanks for your contribution!');
        } catch (error) {
            showModal(
                'Upload Failed: Server error. Please check internet connection'
            );
            resetForm();
        }

        setIsLoading(false);
    };

    const resetForm = () => {
        setTitle('');
        setAuthor('');
        setTags([]);
        setVideoFile(null);
        setVideoThumbnail(null);
    };

    const showModal = (message: string) => {
        setModalMessage(message);
        setIsShowModal(true);
    };
    // Function to reset some component states, refresh page if submission was successful
    const updateView = () => {
        isUploaded && refreshPage();
        setIsUploaded(false);
        setIsShowModal(false);
    };

    return (
        <>
            <h1 className={styles['page-title']}>Upload Video</h1>
            <form onSubmit={onFormSubmit} className={styles.form}>
                <FormInput
                    label="Title"
                    name="title"
                    type="text"
                    defaultValue={title}
                    placeholder="Title of the video"
                    onChange={(e) => setTitle(e.target.value)}
                    errorMsg=""
                />
                <FormInput
                    label="Tags"
                    name="tags"
                    type="text"
                    defaultValue={tags.join(',')}
                    placeholder="Add up to five keywords (separated by ,)"
                    onChange={(e) => setTags(e.target.value.split(','))}
                    errorMsg=""
                />
                <FormInput
                    label="Name"
                    name="author-name"
                    type="text"
                    defaultValue={author}
                    placeholder="Video Author"
                    onChange={(e) => setAuthor(e.target.value)}
                    errorMsg=""
                />
                <DragAndDrop dropHandler={setVideoFile}>
                    <div className={styles['drag-and-drop-container']}>
                        <FontAwesomeIcon
                            icon={faFileVideo}
                            color="var(--grey)"
                            size="4x"
                        />
                        <div
                            className={styles['drag-and-drop-area-description']}
                        >
                            <p>Drag and drop video file to upload.</p>
                            <p className={styles['description-bottom-text']}>
                                Your videos will be private until you publish
                                them.
                            </p>
                        </div>
                        {videoFile ? (
                            <div className={styles['file-indicator']}>
                                {videoFile.name}
                            </div>
                        ) : (
                            <div className={styles['file-indicator']}></div>
                        )}
                        <FileUpload
                            filesChange={(file: File) => {
                                setVideoFile(file);
                            }}
                            big={true}
                            className={styles['no-margin']}
                            video={true}
                        >
                            Select file
                        </FileUpload>
                    </div>
                </DragAndDrop>
                <DragAndDrop dropHandler={setVideoThumbnail}>
                    <div className={styles['drag-and-drop-container']}>
                        <FontAwesomeIcon
                            icon={faFileImage}
                            color="var(--grey)"
                            size="4x"
                        />
                        <div
                            className={styles['drag-and-drop-area-description']}
                        >
                            <p>
                                Drag and drop an image file to upload it as a
                                thumbnail for the video.
                            </p>
                            <p className={styles['description-bottom-text']}>
                                Your videos will be private until you publish
                                them.
                            </p>
                        </div>
                        {videoThumbnail ? (
                            <div className={styles['file-indicator']}>
                                {videoThumbnail.name}
                            </div>
                        ) : (
                            <div className={styles['file-indicator']}></div>
                        )}
                        <FileUpload
                            filesChange={(file: File) =>
                                setVideoThumbnail(file)
                            }
                            big={true}
                            className={styles['no-margin']}
                            image={true}
                        >
                            Select image
                        </FileUpload>
                    </div>
                </DragAndDrop>

                <div className={styles['form-controllers-container']}>
                    <Button
                        onClick={(e: React.FormEvent<HTMLFormElement>) => {
                            onFormSubmit(e);
                        }}
                        type={ButtonType.primary}
                        className={styles['save-button']}
                    >
                        Save
                    </Button>
                    <Button
                        onClick={(e: React.FormEvent<HTMLFormElement>) => {
                            onFormSubmit(e, true);
                        }}
                        type={ButtonType.submit}
                        className={styles['upload-button']}
                    >
                        <FontAwesomeIcon
                            icon={faArrowUpFromBracket}
                            color="var(--main-white)"
                            className={styles['upload-icon']}
                        />
                        Upload
                    </Button>
                </div>
                {isLoading && (
                    <div className={styles['backdrop']}>
                        <LoadingRing size="large" />
                    </div>
                )}
                {isShowModal && !isUploaded && (
                    <Modal updateView={updateView} info={modalInfoError}>
                        <Button
                            onClick={() => {
                                updateView();
                            }}
                            type={ButtonType.primary}
                            small={true}
                        >
                            <span className={styles['button-shape']}>Ok</span>
                        </Button>
                    </Modal>
                )}
                {isShowModal && isUploaded && !isLoading && (
                    <Modal updateView={updateView} info={modalInfoUploaded}>
                        <Button
                            onClick={() => {
                                updateView();
                            }}
                            type={ButtonType.primary}
                            small={true}
                        >
                            <span className={styles['button-shape']}>ok</span>
                        </Button>
                    </Modal>
                )}
            </form>
        </>
    );
};

export default VideoUploadPage;
