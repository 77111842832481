import { useEffect, useState } from 'react';
import { getAllDepartments } from '../../services/apiService';
import Button, { ButtonType } from '../general-components/Button';

import styles from './ManageLicenses.module.css';

import EditLicenses from '../general-components/EditLicenses';
import AuthContext from '../../store/auth-context';
import RIGHTS_KEYS from '../../constants/rights-keys';
interface IProps {
    deptName: string;
    setShowModal: (showModal: boolean) => void;
    licenses: number;
    deptId: string;
    setDeptId: any;
}

const ManageLicenses = () => {
    const [departments, setDepartments] = useState([]);

    const [showModal, setShowModal] = useState(false);

    const [deptId, setDeptId] = useState();

    const getDepartments = async () => {
        const depts = await getAllDepartments();
        setDepartments(depts);
    };

    useEffect(() => {
        getDepartments();
    }, []);

    return (
        <div>
            <div className={styles.licenses}>
                <table>
                    <thead>
                        <tr>
                            <th>Department Name</th>
                            <th>Licenses</th>
                        </tr>
                    </thead>
                    <tbody>
                        {departments.map((dept) => (
                            <LicenseItem
                                key={dept._id}
                                deptName={dept.name}
                                setShowModal={setShowModal}
                                licenses={dept.licenses}
                                deptId={dept._id}
                                setDeptId={setDeptId}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
            {showModal && (
                <EditLicenses
                    close={() => setShowModal(!showModal)}
                    deptId={deptId}
                />
            )}
        </div>
    );
};
const LicenseItem = (props: IProps) => {
    const openModal = () => {
        props.setShowModal(true);
        props.setDeptId(props.deptId);
    };
    return (
        <tr>
            <td>{props.deptName}</td>
            <td>{props.licenses}</td>
            <td>
                <Button
                    type={ButtonType.tietary}
                    onClick={openModal}
                    className={styles.edit}
                >
                    Edit
                </Button>
            </td>
        </tr>
    );
};
export default ManageLicenses;
