import { useState } from 'react';
import Modal from './Modal';
import Button, { ButtonType } from '../general-components/Button';
import {
    deactivateDepartmentsLisense,
    updateDepartment,
} from '../../services/apiService';
import styles from './Organizations.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';

const OrganizationRow: React.FC<{
    department: any;
    refreshData: Function;
}> = ({ department, refreshData }) => {
    const [showModal, setShowModal] = useState('');
    const [isSaved, setIsSaved] = useState(false);

    const d = department.end_date?.split('T')[0];

    const [newExpiry, setNewExpiry] = useState(new Date());

    let daysLeft =
        new Date(department.end_date).getTime() - new Date().getTime();
    daysLeft = Math.ceil(daysLeft / (1000 * 3600 * 24));

    const diactivateLicenses = async () => {
        const success = await deactivateDepartmentsLisense(department._id);
        if (success) {
            setIsSaved(true);
            refreshData();
        }
    };

    const updateDepartmentLicense = () => {
        const success = updateDepartment(department._id, {
            end_date: newExpiry,
        });
        if (success) {
            setIsSaved(true);
            refreshData();
        }
    };

    return (
        <>
            {showModal === 'deactivate' && (
                <Modal
                    info={{
                        title: 'Dieactivate license',
                        submitButtonText: 'Deactivate',
                        message: 'Deactivate license',
                        submitButtonDanger: true,
                        isSubmitted: isSaved,
                    }}
                    updateView={() => {
                        setShowModal('');
                    }}
                    submitModal={diactivateLicenses}
                >
                    <p>
                        The "End Date" for {department.name} license will be set
                        to today.
                    </p>
                </Modal>
            )}
            {showModal === 'edit' && (
                <Modal
                    info={{
                        title: 'Edit Department License expiry',
                        submitButtonText: 'Update',
                        message: `Update the end date for ${department.name}`,
                        submitButtonDanger: true,
                        isSubmitted: isSaved,
                    }}
                    updateView={() => {
                        setShowModal('');
                    }}
                    submitModal={updateDepartmentLicense}
                >
                    <input
                        style={{ color: 'black' }}
                        defaultValue={d}
                        type="date"
                        onChange={(e) => {
                            const date = new Date(e.target.value);
                            setNewExpiry(date);
                        }}
                    />
                </Modal>
            )}
            <tr>
                <td>{department.name}</td>
                <td>{department.licenses}</td>
                <td
                    className={
                        department.active_licenses > 5
                            ? styles.licensesGreen
                            : styles.licensesOrange
                    }
                >
                    <span>{department.active_licenses}</span>
                </td>
                <td>{department.users[0].email}</td>
                <td>{new Date(department.start_date).toLocaleDateString()}</td>
                <td className={styles.endDate}>
                    {new Date(department.end_date).toLocaleDateString()}
                    <Button
                        type={ButtonType.tietary}
                        onClick={() => {
                            setShowModal('edit');
                        }}
                    >
                        <FontAwesomeIcon icon={faPenToSquare} />{' '}
                    </Button>
                </td>
                <td>{daysLeft > 0 ? daysLeft : 0}</td>
                <td>
                    <Button
                        type={ButtonType.cancel}
                        onClick={() => {
                            setShowModal('deactivate');
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faTrashCan}
                            color="var(--main-white)"
                        />
                    </Button>
                </td>
            </tr>
        </>
    );
};

export default OrganizationRow;
