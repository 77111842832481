import { ReactNode } from 'react';
import Button, { ButtonType } from '../../general-components/Button';
import ModalLayout from './ModalLayout';
import styles from './ConfirmationModal.module.css';

const ConfirmationModal: React.FC<{
    isDelete?: boolean;
    children?: ReactNode;
    title: string;
    message: string;
    onConfirm: () => void;
    onClose: () => void;
    confirmText?: string;
    cancelText?: string;
}> = ({
    isDelete = false,
    children,
    title,
    message,
    confirmText,
    cancelText,
    onConfirm,
    onClose,
}) => {
    return (
        <ModalLayout onClose={onClose}>
            <div className={styles.wrapper}>
                <h2 className={styles.title}>{title}</h2>
                <p className={styles.message}>{message}</p>
                <div className={styles.childWrapper}>{children}</div>
                <div className={styles.buttons}>
                    <Button
                        type={isDelete ? ButtonType.cancel : ButtonType.submit}
                        onClick={onConfirm}
                    >
                        {confirmText}
                    </Button>
                    <Button type={ButtonType.primary} onClick={onClose}>
                        {cancelText}
                    </Button>
                </div>
            </div>
        </ModalLayout>
    );
};

export default ConfirmationModal;
