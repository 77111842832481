import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useImplantContext } from '../../store/implant-context';
import SelectSource from '../edit-implant/SelectSource';
import EditImplant from '../edit-implant/EditImplant';
import styles from './AppImplantPage.module.css';

const AddImplantPage = () => {
    const {
        sourceSelected,
        setSourceSelected,
        setFileName,
        setUsesExistingFile,
    } = useImplantContext();

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const currentFileName = queryParams.get('fileName');

    useEffect(() => {
        if (currentFileName) {
            setFileName(currentFileName);
            setUsesExistingFile(true);
            setSourceSelected(true);
        }
    }, [currentFileName, setFileName, setUsesExistingFile, setSourceSelected]);


    useEffect(() => {
        if (showSuccessMessage) {
            const timer = setTimeout(() => {
                setShowSuccessMessage(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [showSuccessMessage]);

    const handleSubmit = () => {
        setShowSuccessMessage(true);
        setSourceSelected(false);
        setFileName('');
    };

    return (
        <div className={styles.wrapper}>
            <h1>Add New Implant</h1>
            {showSuccessMessage ? (
                <div>Implant was successfully saved!</div>
            ) : (
                <>
                    {sourceSelected ? (
                        <EditImplant isNew={true} onSubmit={handleSubmit} />
                    ) : (
                        <SelectSource />
                    )}
                </>
            )}
        </div>
    );
};

export default AddImplantPage;


  