import styles from './WelcomeLayout.module.css';
import linkedin from '../../icons/linkedin.png';
import email from '../../icons/email.png';
import { ReactNode } from 'react';
import logo from '../../images/LogohvidFinal.png';
import breath from '../../images/breath.png';

const WelcomePage: React.FC<{ children: ReactNode }> = ({ children }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.splash}>
                <div className={styles.heart}>
                    <img src={breath} alt="breath" />
                    {/* <Heart />*/}
                </div>
                <div className={styles.title}>
                    <h2>Find MRI-safety information faster than ever</h2>
                    <button>Learn more here</button>
                </div>
                <div className={styles.contact}>
                    <a>
                        <img src={linkedin} />
                        LinkedIn
                    </a>
                    <a>
                        <img src={email} />
                        Mail
                    </a>
                </div>
            </div>

            <div className={styles['auth-box']}>
                <div className={styles.logo}>
                    <img src={logo} />
                </div>
                {children}
            </div>
        </div>
    );
};

export default WelcomePage;
